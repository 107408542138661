
import React,{useState,useEffect}  from 'react';

import { Grid, Row, Col } from 'react-flexbox-grid';

import styles from './index.module.less';

// 切换语言
import { useTranslation } from 'react-i18next';
import LazyLoad from 'react-lazyload';


import { simpleFn } from '../../../components/Notification';


import wxPay from '../../../assets/images/explore/wxpay.svg';
import zfbPay from '../../../assets/images/explore/zfb.svg';
import bankCardPay from '../../../assets/images/explore/bankCard.svg'

import TimeContent from '../../../components/TimeContent';



const baseUrl = process.env.BASE_URL+"/ipfs/";
const NFTDetail =({onChange,itemData}:any)=>{
    console.log("==================itemData",itemData)
    const { t } = useTranslation();
    const [amount,changAmount] = useState<any>(1);
    
    return (
        <div className={`flex ai-center jc-center ${styles.popUps}`} >
            <div className={`${styles.popBg}`} onClick={() => onChange && onChange(false)}>背景</div>
            <Col xs={10} sm={10} lg={8} xl={6} className={`flex jc-center ai-center fd-column ${styles.popUpsMode}`}>
                <div className={`${styles.popClose}`}  onClick={() => onChange && onChange(false)}>close</div>
              
                <h3 className={`${styles.logTitle}`}>开启结果</h3>
                
               <Row className={`pt-40 ${styles.logMain}`}>
                   <Row style={{width:'100%',height:'405px'}}>
                        {itemData.map((item:any,index:number)=>{
                            // console.log(item.meta,eval('('+item.meta+')'))
                            const itemMeta= eval('('+item.meta+')');
                            return  (
                            <Col xs={3} className={`mb-5`} key={index}>
                                    <div className={``}>
                                        <div className={`text-center`}>
                                            <LazyLoad><img src={baseUrl + itemMeta.dh  } alt='' className={`${styles.openImg}`} width={70} height={70} /></LazyLoad>
                                        </div>
                                        <div className={`pl-10 pr-10`}>
                                            <div className={`flex ai-center jc-center mt-5`}>
                                                <h3 className={`font-12 lh-24 ellipsis-1`}>{item.name}</h3>
                                            </div>
                                            <p className={`font-12 lh-24 col-666 text-center`}>x {item.amount}</p>
                                        </div>
                                    </div>
                                
                                </Col> 
                                )
                            })
                        }
                    </Row>
                    <Col lg={10} lgOffset={1} className={`pt-40 pb-40 ${styles.modeBtnTop}`}>
                        
                        <Row>
                            <Col md={8} mdOffset={2}>
                                <button className={`${styles.medalBtn}`}  onClick={() => onChange && onChange(false)}>关闭</button>
                            </Col>
                        </Row>
                    </Col>
                </Row> 
               
            </Col>
            
        </div>
    )
}
export default NFTDetail;