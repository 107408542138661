import React, { useState, useEffect, useRef } from 'react';

import { BrowserRouter as Router, Switch, } from 'react-router-dom';

// 引入路由
import publicRoutes from './router/publicRoutes';
import AuthRoute from './components/AuthRoute';
import privateRoutes from './router/privateRoutes';

//语言
import './assets/locales/react-i18next/i18n';




function App() {

  return (
    <>
      <Router>
        <Switch>
          {publicRoutes.map(
            // ({path, component, ...routes}) => <Route key={path} path={path} component={component} {...routes}/>
            (route) => <AuthRoute key={route.path} {...route} />
          )}
          {privateRoutes.map(
            (route) => <AuthRoute key={route.path} {...route} />
          )}
        </Switch>
      </Router>
    </>
  );
}

export default App;
