import React, { useState } from 'react';
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';

// props.changeTime(moment(formatStrings[0]).valueOf(), moment(formatStrings[1]).valueOf());

const DataPicker: React.FC<any> = (props) => {
  const [value, onChange] = useState([new Date(), new Date()]);

  return (
    <div>
      <DateTimeRangePicker style={{ width: '100%' }} format="y/MM/dd HH:mm:ss" onChange={(val: any) => {
        if (val !== null) {
          props.changeTime(val[0].getTime(), val[1].getTime());
        }
        onChange(val)
      }} value={value} />
    </div>
  );
}

export default DataPicker;