
import React,{useState,useEffect}  from 'react';

import { Grid, Row, Col } from 'react-flexbox-grid';

import styles from './index.module.less';

// 切换语言
import { useTranslation } from 'react-i18next';

// api
import { ownerofhash,hasfilehash,uploadfile,gridSetData,updateGrid,getMyGridList} from '../../../api/public'

import { simpleFn } from '../../../components/Notification';



import Hash from 'ipfs-only-hash';
// web3
import Web3 from 'web3';
import { blob } from 'node:stream/consumers';

let web3 = new Web3(Web3.givenProvider);

let ipfsKey=process.env.IPFS_KEY;

const Mode = ({onChange,data,dataList,baseUrl}:any)=>{
    console.log("datadata",dataList)
    const { t } = useTranslation();
  

    const [checkImg, setCheckImg] = useState(0);
    const hiddenFileInput = React.useRef<any>(null);
    const [fileItem, setFileItem] = useState<any>();
    const [metaDataSystem, setMetaDataSystem] = useState<any>();
    const userName = sessionStorage.getItem('userName');
    const userToken= web3.utils.keccak256(sessionStorage.getItem('userName')).substr(2);
    // const userToken = sessionStorage.getItem('userToken');
    const [checkName, setCheckName] = useState(0);
    const [metaDataUser, setMetaDataUser] = useState<any>({
        ...data,
        gridID:data.id,
        userName:userName,
        name: data.appName || data.metaDataUser.name,
        desc: data.appDesc,
        linkUrl:data.appLinkUrl,
        imgHash:data.appImg,//格子大图
        thumbHash:data.appThumb//格子缩略图
      }
    );



    // 选择文件
    const uploadClick = () => {
        hiddenFileInput.current.click();
    };
    const handleFileChange = async (event: any) => {
        if (event.target.files.length !== 0) {
          let filesItem = event.target.files[0]
          setFileItem(filesItem)
          const fileUploaded = filesItem;
          await checkOwnerofHash(fileUploaded)
          setCheckName(2);
        } else {
          setCheckImg(1);
        }
    };



    // 查询用户是否拥有该HASH
  const checkOwnerofHash = async (val: object) => {
    const fileBytes = await calcuFileIpfsHash(val);
    const ipfsHash = await Hash.of(fileBytes);
    const TimeStamp = Math.round(+new Date());
    const strSeriesConnMsg = `${userName}${userToken}${TimeStamp}`;
    const MsgKecak256 = web3.utils.keccak256(`\u0019Ethereum Signed Message:\n${new Blob([strSeriesConnMsg]).size}${strSeriesConnMsg
      }`)
    const owerofData={
      "userName": userName,
      "dataHash": ipfsHash,
      "timeStamp": TimeStamp,
      "msgKeccak256": MsgKecak256.substr(2)
    }
    const res = await ownerofhash(owerofData);
    if (res.code === 0 && res.data.isExisted !== true) {
      console.log("该文件没有拥有者");
      let temp = { ...metaDataUser };
      temp.image = 'ipfs://' + ipfsHash;
      setMetaDataUser(temp);
      setCheckImg(2);
      checkHasFileHash(ipfsHash, val)
    } else if (res.code === 0 && res.data.isExisted === true) {
      // 提示用户文件已有其他用户上传
      setCheckImg(1)
      console.log("该文件已有拥有者");
    } else {
      console.log('ownerofhash接口调用失败', '错误代码: ', res.code, "res.msg")
    }
  }  

  // 查询文件Hash是否已经上传
  const checkHasFileHash = async (val: string, file: any) => {
    const res = await hasfilehash({ hash: val });

    if (res.code == 0 && res.data.isExisted != true) {
      console.log("该文件还没有上传");
      let imgData={
        imgHash:res.data.dataHash,//格子大图
        thumbHash:res.data.thumbHash//格子缩略图
      }
      setMetaDataSystem({
        type: file.type,
        size: file.size,
        ...imgData
      })
      setMetaDataUser({...metaDataUser,...imgData})
      UploadFile(file);
    } else if (res.code == 0 && res.data.isExisted == true) {
      console.log("该文件已上传",res)
      let imgData={
        imgHash:res.data.dataHash,//格子大图
        thumbHash:res.data.thumbHash//格子缩略图
      }
      setMetaDataSystem({
        type: file.type,
        size: file.size,
        ...imgData
      })
      setMetaDataUser({...metaDataUser,...imgData})
    } else {
      console.log('ownerofhash接口调用失败', '错误代码: ', res.code, "res.msg")
    }
  }

  // 上传文件
  const UploadFile = async (val: object) => {
    const TimeStamp = Math.round(+new Date());
    const strSeriesConnMsg = web3.utils.keccak256(ipfsKey).substr(2) + TimeStamp;
    const MsgKeccak256 = web3.utils.keccak256("\u0019Ethereum Signed Message:\n" + new Blob([strSeriesConnMsg]).size + strSeriesConnMsg);

    const formData: any = new FormData();
    formData.append("size", 350);
    formData.append("file", val);
    formData.append("thumbnail", 1);
    formData.append("msgKeccak256", MsgKeccak256.substr(2));
    formData.append("timeStamp", TimeStamp);

    const res = await uploadfile(formData);
    if (res.code == 0) {
      console.log("文件上传成功",res)
      let imgData={
        imgHash:res.data.dataHash,//格子大图
        thumbHash:res.data.thumbHash//格子缩略图
      }
      setMetaDataUser({...metaDataUser,...imgData})
    } else {
      console.log("文件上传失败",res)
      simpleFn(res.msg,'error')
    }
  }


  // 计算上传文件的Hash
  const calcuFileIpfsHash = (file: any) => {
    return new Promise((resolve, reject) => {
      let fileReaderObj = new FileReader();
      fileReaderObj.onload = function (e: any) {
        let DataStr: any = fileReaderObj.result;
        let bytes = new Uint8Array(DataStr);
        resolve(bytes)
      }
      fileReaderObj.readAsArrayBuffer(file)
    })
  }
  const [clickUpload,setClickUpload] = useState<boolean>(false)
  useEffect(()=>{
    if(clickUpload){
      uploadMetaData(metaDataUser);
    }
  },[clickUpload])

  const uploadMetaData = async (val: any) => {
    setClickUpload(false)
    fileItem && await checkOwnerofHash(fileItem);
    console.log('格子数据',metaDataSystem,metaDataUser)
    
    const data = {...metaDataUser}
    const res = await updateGrid(data)
    if (res.code === 0) {
        onChange(data)
        simpleFn('保存成功！','success')
      console.log("格子参数修改成功",res)
    } else {
      console.log("格子参数修改失败")

      simpleFn(res.data,'error')
    }
  }
// 删除图片
  const deleteUpImg=()=>{
    setFileItem(undefined)
  }

  const getLogPages =async ()=>{
    let dataMise = {
        userName:userName,
        skip: 0,
        limit: 100,
        page:201
    }
    const res = await getMyGridList(dataMise);
    console.log('getMyGridList',res)
    mergeGrid(res.data)
  }
  const mergeGrid=(e:any)=>{
    console.log(data.location,e)
    
  }

useEffect(()=>{
  getLogPages()
},[])
    return (
        <div className={`flex ai-center jc-center ${styles.popUps}`} >
            <div className={`${styles.popBg}`} onClick={() => onChange && onChange()}>背景</div>
            <Col xs={12} sm={10} lg={8} xl={6} className={`pb-40 ${styles.popUpsMode}`}>
                <div className={`${styles.popClose}`}  onClick={() => onChange && onChange()}>close</div>
                <h3 className={`${styles.modeTitle}`}>编辑格子</h3>
                <Row className={`${styles.modeMain}`}>
                    <Col xs={5}>
                        <div className={`${styles.exhibitImg}`}>
                            <img src={(fileItem && URL.createObjectURL(fileItem)) || (data.appImg && data.baseUrl + data.appImg) || data.baseUrl+data.dataHash } alt=""  width={`100%`}/>
                        </div>
                        

                        <div className={`flex ai-flex-start mt-12 space-16`} style={{width:"100%"}}>
                            <>
                                <div  className={` ${styles.uploadExhibitImg}`}>
                                    <div className={`flex ai-center jc-center ${styles.uploadModel}`} onClick={uploadClick}>
                                        <input type='file' style={{ display: 'none' }} ref={hiddenFileInput} onChange={handleFileChange} />
                                        {fileItem === undefined ? <img src={require('../../../assets/icon/image.png')} alt='' style={{ width: 84, height: 84 }} /> : ''}
                                        {fileItem?.type.split('/')[0] === 'image' ? <img src={URL.createObjectURL(fileItem)} alt="" /> : ''}
                                        {fileItem?.type.split('/')[0] === 'video' ? <video autoPlay src={URL.createObjectURL(fileItem)} controls loop preload="auto"></video> : ''}
                                        {fileItem?.type.split('/')[0] === 'audio' ? <audio style={{ width: '100%', height: '100%', backgroundImage: `url(${require('../../../assets/icon/music.png')})` }} autoPlay={true} src={URL.createObjectURL(fileItem)} controls={true} loop={true} ></audio> : ''}

                                    </div>
                                    <div className={`flex fd-column ai-center ${styles.uploadExhibitImgBtn}`}>
                                        <div className={`flex-1 ${styles.upAddBtn}`} onClick={uploadClick}>Add</div>
                                       {fileItem && <div className={`flex-1 ${styles.upDeleteBtn}`} onClick={deleteUpImg}>Delete</div>} 
                                    </div>
                                </div>
                            </>
                            <div className={`flex-1 font-14 ${styles.uploadfileTips}`}>
                                {/* Upload an image to preview your grid (JPG,PNG,WEBP). The file should be less than 100kb. */}
                                上传文件格式为（JPG、PNG、GIF）。该文件应小于 20MB。
                            </div>
                        </div>
                    </Col>
                    <Col xs={7}>
                        
                        <div className={`mt-10 ${styles.required} ${styles.field}`}>
                            <label className={`mb-12`}>{t('methods.name')}</label>
                            <input type="text" name="Item-name"
                                style={{
                                border: `1px solid ${checkName == 1 ? 'rgba(219, 40, 40, 0.6)' : 'rgba(34, 36, 38, 0.15)'} `
                                }}
                                onChange={(e) => {
                                let temp = { ...metaDataUser };
                                temp.name = e.target.value;
                                setMetaDataUser(temp);
                                if (e.target.value !== '') {
                                    setCheckName(2);
                                } else {
                                    setCheckName(1)
                                }
                                }} value={metaDataUser.name} placeholder="请输入应用格子名称" />
                            {checkName == 1 ? <div className={`font-14 mt-12 col-red`}>{t('methods.checkName')}</div> : ''}
                        </div>
                        <div className={`mt-20 ${styles.field}`}>
                            <label className={`mb-12`}>描述</label>
                            <textarea placeholder="输入应用格子描述" value={metaDataUser.desc} onChange={(e) => {
                                let temp = { ...metaDataUser };
                                temp.desc = e.target.value;
                                setMetaDataUser(temp);
                                if (e.target.value !== '') {
                                    setCheckName(2);
                                }
                            }}></textarea>
                        </div>
                        <div className={`mt-20 ${styles.field}`}>
                            <label className={`mb-12`}>链接</label>
                          
                            <input type="text" name="Item-name"
                                style={{
                                border: `1px solid rgba(34, 36, 38, 0.15) `
                                }} 
                                value={metaDataUser.linkUrl}
                                onChange={(e) => {
                                let temp = { ...metaDataUser };
                                temp.linkUrl= e.target.value;
                                setMetaDataUser(temp);
                                if (e.target.value !== '') {
                                    setCheckName(2);
                                }
                                }} placeholder="输入格子链接"/>
                        </div>

                        <button className={`mt-20 ${styles.createBtn}`} disabled={ checkName !== 2} onClick={(e) => {
                            e.preventDefault();
                            let temp = { ...metaDataUser };
                            
                            console.log(temp);
                            // setMetaDataUser(temp)
                            setClickUpload(true)
                            // uploadMetaData(temp);
                            }}>保存</button>
                    </Col>
                </Row>
              


            </Col>
            
        </div>
    )
}
export default Mode;
