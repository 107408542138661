/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useRef, useState ,useMemo, useLayoutEffect }  from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import { Link,useHistory } from "react-router-dom";
import Texty from 'rc-texty';

// import Select from 'react-select';
import LazyLoad from 'react-lazyload';

import Pagination from '../../components/Pagination';

import GridEdit from "./GridEdit";
import GridCollect from './GridCollect';
import GridMerge from './GridMerge';
import BuyGrid from './BuyGrid'

import Pay from '../Explore/Detail/Pay';
import Order from '../Explore/Detail/Order';
import OrderDetail from '../Explore/Detail/OrderDetail';
import Popup from '../../components/Popup';
// import GridMain from '../../components/GridMain'

import ReactLoading from 'react-loading';

import Markdown from 'markdown-to-jsx';
// api
import { 
    getGridList,
    getGridDetail,
    getGridIndexList,
    getFavourite,
    getMyGridList,
    searchGridList,
    vpCenterLogin,
    mergeGrid,
    salesPageList,
    salesPageListItem,
    createOrder,
    aliPagePay,
    getOrderStatus,
    cancelOrder } from '../../api/public'

// 切换语言
import {  useTranslation } from 'react-i18next';


import { simpleFn } from '../../components/Notification/index';

import styles from "./index.module.less"


import ReactTooltip from 'react-tooltip';
import { url } from 'inspector';
// import { userInfo } from 'os';

import Menu from '../../components/TopMenu';

import ShowLoadding from '../../components/ShowLoadding';

import Pin from '../../components/User/Pin'

import CryptoJS from "crypto-js";
// web3
import Web3 from 'web3';
// import { setTimeout } from 'timers/promises';
const web3 = new Web3(Web3.givenProvider);

const SECRET_KEY = CryptoJS.enc.Utf8.parse("f097eae0d2c86d3b");
// 主题
const themePrimaryColor = 'theme';
const primaryColor =  localStorage.getItem(themePrimaryColor)||'light';
const locales = localStorage.getItem('localesLng')||'zh'

const noImgList =['noImg1.png','noImg2.png','noImg3.png']

const userName = sessionStorage.getItem('userName');
const myBtnClick=()=>{
    if(!userName){
        simpleFn('请先登录！', 'error')
        return ;
    }
}
const NoData:React.FC =()=>{

    const {t} = useTranslation();
    return (
        <div className={`flex ai-center jc-center fd-column`} style={{height:"100%"}}>
            <img src={require('../../assets/icon/noData.png')} style={{ width: 100, height: 'auto', display: 'block' }} alt="" />
            <p className={`flex ai-center jc-center font-14 col-9f pt-20`}>{t(`methods.noData`)}</p>
        </div>
    )
}

const MyCollectBtn = ({onChange,data}:any)=>{
    const {t} = useTranslation();
    
    const [favouriteList,setFavouriteList]=useState<any>(data);
    
     // 我的收藏
    const [isItemFolder,setIsItemFolder] = useState<boolean>(false)
    const [thisFavourite,setThisFavourite] = useState<any>({});
    const favouriteItem=(e:any)=>{
        // console.log('favouriteItem====================',e)

        !e.isFolder && onChange(e);
    }
    const isFolderItem=(e:any)=>{
        // console.log('isFolderItem',e)
        setIsItemFolder(e.isFolder===1)
        setThisFavourite(e)
        // e.isFolder===1 && onChange(e)
    }
    
    const editItem=(e:any,item:any)=>{
        
		
        e.stopPropagation();
        console.log("================123=======",e,item)
        let data={
            type:"editCollect",
            data:item
        }
        onChange(data)
    }

    return (
        <div className={`relative ${styles.myNavBtn}`}>
            {/* {JSON.stringify(favouriteList)} */}
            <div className={`flex ai-center pointer ${styles.myIcons} ${styles.myCollect}`} data-tip={t('methods.MyCollection')} data-for='fooRef' onClick={myBtnClick} >{data && data.count>0 && <span className={`pr-15`}>{data.count}</span>}</div>

           {userName? <div className={`${styles.showMyList}`}>
                <div  className={``}>
                    <h3 className={`font-16 ${styles.MyListTitle}`}>{t('methods.MyCollection')}</h3>
                    <div className={` ${styles.favouriteMain}`}>
                        {favouriteList.items.length > 0 ? favouriteList.items.map((item:any,index:number)=>{
                            return (
                                <div key={item.gridID||item.gridFavFolderID}>
                                    <div className={`flex space-16 pointer ${styles.favouriteItem}`} onClick={()=>favouriteItem(item)} onMouseOver={()=>{isFolderItem(item)}} data-tip data-for='itemFolder' >
                                        {item.isFolder?<i className={`${styles.folderImg} ${thisFavourite.gridFavFolderID===item.gridFavFolderID?styles.isfolderImg:''}`} />:<img src={item.thumbHash || favouriteList.baseUrl+item.dataHash} onError={(e:any) => {e.target.onerror = null;e.target.src=require('../../assets/images/'+noImgList[Math.floor(Math.random() * noImgList.length)])}}  alt='' className={`${styles.favouriteImg}`} />}
                                        
                                        <div className={`flex-1`} style={{width: "0"}}>
                                            <h3 className={`font-16 pb-10 ellipsis-1 ${styles.favouriteTitle}`}>{item.name}</h3>
                                            
                                            <div className={`flex jc-space-between ai-center`}>
                                                {item.isFolder?
                                                <span className={`font-14 col-666`}>
                                                    {t('methods.Numberofgrids')} {item.sub && item.sub.count}
                                                </span>
                                                :
                                                <>
                                                    <span className={`font-14 ai-center ${styles.favouriteLot}`}>
                                                        <img src={require('../../assets/images/apps/address-icon-black.png')} alt="icon" className={`mr-5`} />
                                                        {item.location && `${item.location.pageNum},${item.location.grid_y},${item.location.grid_z}`}
                                                    </span>
                                                    <div className={`${styles.gridItemCollect} ${styles.gridItemCollect}`}  data-tip={t('methods.CancelCollection')}  onClick={(e)=>editItem(e,item)} >Collect</div>
                                                    <ReactTooltip place="top" type="dark" effect="solid"/> 
                                                </>
                                                }
                                                
                                            </div>
                                        </div>
                                        {item.isFolder?<i className={`mr-10 ${styles.folderArrow}`} />:null}
                                    </div>
                                    
                                    
                                </div>
                            )
                        }):<NoData />}
                    </div>
                    {isItemFolder &&<div className={`${styles.showMyListItem} ${styles.isShowMyListItem}`}>
                        <div className={` ${styles.favouriteMain}`}>
                            {thisFavourite.sub.items.length>0?thisFavourite.sub.items.map((item:any)=>{
                                return (
                                    <div key={item.gridID||item.gridFavFolderID}>
                                        <div className={`flex space-16 pointer ${styles.favouriteItem}`} onClick={()=>favouriteItem(item)} >
                                        <img src={item.thumbHash || favouriteList.baseUrl+item.dataHash} onError={(e:any) => {e.target.onerror = null;e.target.src=require('../../assets/images/'+noImgList[Math.floor(Math.random() * noImgList.length)])}}  alt='' className={`${styles.favouriteImg}`} />
                                            <div className={`flex-1`} style={{width: "0"}}>
                                                <h3 className={`font-16 pb-10 ellipsis-1 ${styles.favouriteTitle}`}>{item.name}</h3>
                                                <div>
                                                    <span className={`font-14 ai-center ${styles.favouriteLot}`}>
                                                        <img src={require('../../assets/images/apps/address-icon-black.png')} alt="icon" className={`mr-5`} />
                                                        {item.location && `${item.location.pageNum},${item.location.grid_y},${item.location.grid_z}`}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }):<NoData />}
                        </div>
                    </div>}
                </div> 
            </div>:
            <ReactTooltip place="top" type="dark" effect="solid" id="fooRef" />
            }
        </div>
    )
} 

// 我的格子
const MyGridsBtn=({onChange,data,skip}:any)=>{
    const {t} = useTranslation();
    const [myGridList,setMyGridList]=useState<any>(data);
    const [logskip,setLogskip] = useState(skip)
    const fooRef = useRef<any>()
    const favouriteItem=(e:any)=>{
        // console.log('favouriteItem',e)
        onChange(e);
    }

    const [dom, setDom] = useState<any>(null);
    const [logData, setLogData] = useState('');
    // const getLogPages=()=>{
    //     setMyGridSkip(myGridSkip+1)
    // }
    // 监听页面滚动
    const handleOnScroll = () => {
        if (dom) {
            const contentScrollTop = dom.scrollTop; //滚动条距离顶部
            const clientHeight = dom.clientHeight; //可视区域
            const scrollHeight = dom.scrollHeight; //滚动条内容的总高度
            if (contentScrollTop + clientHeight >= scrollHeight-20  && myGridList.items.length < myGridList.count) {
                // getLogPages();    // 获取数据的方法
                setLogskip(myGridList.items.length + 1)
            }
        }
    };
    useEffect(()=>{
        if(logskip){
            getLogPages(); 
        }
    },[logskip])
    const getLogPages =async ()=>{
        let dataMise = {
            userName:userName,
            skip: logskip,
            limit: 10
        }
        const res = await getMyGridList(dataMise);
        // console.log('getMyGridList',res)
        if(res.code===0){
            let list = myGridList.items.concat(res.data.items)
            setMyGridList({...res.data,...{items:list}})
        }
    }
    const [showMerge,setShowMerge] = useState(false);
    const gridMergeFn=(e:any)=>{
        // console.log(e)
        if(e){
            getMergeGrid(e)
        }else{
            setShowMerge(false) 
        }
    }

    const [gridMergeData,setGridMergeData] = useState<any>({})
    const getMergeGrid = async (e:any)=>{
        const userInfo:any = JSON.parse(sessionStorage.getItem('userInfo')||'') ;
        console.log(userInfo)
        const timestamp = new Date().getTime().toString(); 
        const temp = `${userName}${timestamp}`;
        const msgKeccak256 =web3.utils.keccak256("\u0019Ethereum Signed Message:\n"+ new Blob([temp]).size + temp);
        const sign = await web3.eth.accounts.sign(web3.utils.utf8ToHex('\x00'+temp),e.privateKey);
        const params = {
            userName: userName,
            page: gridMergeData.pageNum,
            pagey: gridMergeData.y[0],
            pagez: gridMergeData.z[0],
            size: Math.sqrt(gridMergeData.list.length),
            timeStamp: timestamp,
            msgKeccak256: msgKeccak256.substr(2),
            sig: sign.signature.substr(2),
          }
          let result = await mergeGrid(params);
          if(result.code===0){
            simpleFn('已合并成功！', 'success')
            setShowPin(false);
            setShowMerge(false);
            onChange(true)
          }else{
            simpleFn(result.msg, 'error')
          }

    }
    const [showPin,setShowPin] = useState<boolean>(false)
    const changePin = (e:any)=>{
        if(e){
            getMergeGrid(e);
        }else{
            setShowPin(e);
        }
    }
    const changGridMerge=(e:any)=>{
        if(e){
            setShowPin(true);
            setGridMergeData(e);
        }else{
            setShowMerge(false)
        }
    }
    return (
        <>
        <div className={`relative ${styles.myNavBtn}`}>
            <div className={`flex ai-center pointer ${styles.myIcons} ${styles.myGrids}`} data-tip={t('methods.MyGrids')} data-for='fooRefGrids' onClick={myBtnClick}>
                {data && data.count>0 && <span className={`pr-15`}>{data.count}</span>}
            </div>

            
           {userName ? <div className={`${styles.showMyList}`}>
            <div className={` ${styles.MyListTitle} flex jc-space-between`}>
                <h3 className={`font-16`}>
                    {t('methods.MyGrids')}
                </h3>
                <span className={`font-14 col-blue`} onClick={()=>setShowMerge(true)}>我要合并</span>
            </div>
                <div className={` ${styles.favouriteMain}`} ref={(dom) => {
                    setDom(dom);
                }}
           onScrollCapture={() => handleOnScroll()} >
                    {myGridList.items.length > 0 ? myGridList.items.map((item:any,index:number)=>{
                        return (
                            <div key={item.id}>
                                <div className={`flex space-16 pointer ${styles.favouriteItem}`}  onClick={()=>favouriteItem(item)} >
                                    <img src={item.appThumb?item.baseUrl+item.appThumb:item.appImg?item.baseUrl+item.appImg:item.baseUrl+item.dataHash} onError={(e:any) => {e.target.onerror = null;e.target.src=require('../../assets/images/'+noImgList[Math.floor(Math.random() * noImgList.length)])}}  alt='' className={`${styles.favouriteImg}`} />
                                    
                                    <div className={`flex-1`} style={{width: "0"}}>
                                        <h3 className={`font-16 pb-10 ellipsis-1 ${styles.favouriteTitle}`}>{item.appName || item.gridName}</h3>
                                        
                                        <div>
                                            <span className={`font-14 ai-center ${styles.favouriteLot}`}>
                                                <img src={require('../../assets/images/apps/address-icon-black.png')} alt="icon" className={`mr-5`} />
                                                {item.location && `${item.location.pageNum},${item.location.grid_y},${item.location.grid_z}`}
                                            </span>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }):<NoData />}
                </div>
            </div>:
            <ReactTooltip place="top" type="dark" effect="solid" id="fooRefGrids" />
            }
        </div>
        {showMerge && <GridMerge onChange={(e:any)=>changGridMerge(e)} />}
        {showPin && <Pin onChange={(e:any)=>{changePin(e)}} />}
    </>
    )
} 
// 搜索
const SearchGrid = ({onChange}:any)=>{
    const {t} = useTranslation();
    const [showSearchList,setShowSearchList] = useState<boolean>(false);
        const [searchListMain,setSearchListMain] = useState<boolean>(false);
        const [searchList,setSearchList] = useState<any>({count:0,items:[]})
        const searchGrid= async (e:string)=>{
            setSearchListMain(true)
            setShowSearchList(true)
            let dataKey={
                key:e
            }
            const res = await searchGridList(dataKey)
            if(res.code===0){
                setShowSearchList(false)
                setSearchList(res.data)
            }
        }

        const changSearch=(e:any)=>{
            if(e.target.value.length>=2){
                searchGrid(e.target.value)
            }
        }
        const [inTextFocus,setInTextFocus] = useState<boolean>(false);
        const favouriteItem=(e:any)=>{
            onChange(e);
        }
    return (
       
        <div className={`flex-1 ${styles.gridsSearch} ${inTextFocus?styles.inGridsSearch:''}`} >
            <div className={`flex`}>
                <input className={`${styles.gridsSearchInput}`} placeholder={t('methods.AppsSearch')} onFocus={()=>(setInTextFocus(true),searchList.items.length && setSearchListMain(true))}  onChange={(e) =>changSearch(e)} onBlur={()=>(setInTextFocus(false),setTimeout(()=>setSearchListMain(false),300))} />
                
                <i className={` ${styles.gridsSearchIcon}`}></i>
            </div>
            {/* onMouseLeave={searchListMouseleave} onMouseEnter={()=>setSearchListMain(true)} */}
            {searchListMain &&
                <div className={`${styles.searchMain}`} >
                    {showSearchList?
                        <div className={`flex ai-center jc-center`}>

                            <ReactLoading className={`mt-20 mb-20`} type='spin' color='rgb(32, 129, 226)' height={'48px'} width={'48px'} />
                        </div>
                        :
                        <div className={` ${styles.favouriteMain}`}>
                            {searchList.items.length > 0 ? searchList.items.map((item:any,index:number)=>{
                                return (
                                    <div key={item.id}>
                                        <div className={`flex space-16 pointer ${styles.favouriteItem}`}  onClick={()=>favouriteItem(item)} >
                                            <img src={item.appThumb?searchList.baseUrl+item.appThumb:searchList.baseUrl+item.dataHash} onError={(e:any) => {e.target.onerror = null;e.target.src=require('../../assets/images/'+noImgList[Math.floor(Math.random() * noImgList.length)])}}  alt='' className={`${styles.favouriteImg}`} /> 
                                            
                                            <div className={`flex-1`} style={{width: "0"}}>
                                                <h3 className={`font-16 pb-10 ellipsis-1 ${styles.favouriteTitle}`}>{item.appName || item.gridName}</h3>
                                                
                                                <div>
                                                    <span className={`font-14 ai-center ${styles.favouriteLot}`}>
                                                        <img src={require('../../assets/images/apps/address-icon-black.png')} alt="icon" className={`mr-5`} />
                                                        {item.location && `${item.location.pageNum},${item.location.grid_y},${item.location.grid_z}`}
                                                    </span>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }):
                            <div className={`pt-40`}><NoData /></div>
                            }
                        </div>
                    }

                    {/* <div className={`font-14 ${styles.searchMainTip}`}>Press Enter to search all items</div> */}
                </div>
            }   
            
        </div>
    )
}
// 我的历史格子
const MyHistoryBtn = ({onChange,data}:any)=>{
    const {t} = useTranslation();
    const [baseUrl,setBaseUrl] =useState<string>(process.env.BASE_URL+'/ipfs/')
    const [myHistoryGridList,setMyHistoryGridList]=useState<any>(data);
    const favouriteItem=(e:any)=>{
        onChange(e);
    }
    return (
        <div className={`relative ${styles.myNavBtn}`}>
            <div className={`${styles.myIcons} ${styles.myHistory}`} data-tip={t('methods.HistoryGrid')} data-for='fooRefHistory'onClick={myBtnClick}>{t('methods.HistoryGrid')}</div>
            {userName ? <div className={`${styles.showMyList}`}>
                <h3 className={`font-16 ${styles.MyListTitle}`}>{t('methods.HistoryGrid')}</h3>
                <div className={` ${styles.favouriteMain}`}>
                    {myHistoryGridList.length > 0 ? myHistoryGridList.map((item:any,index:number)=>{
                        return (
                            <div key={item.id}>
                                <div className={`flex space-16 pointer ${styles.favouriteItem}`}  onClick={()=>favouriteItem(item)} >
                                    <img src={item.thumbHash || baseUrl+item.metaDataUser.image.split('/')[item.metaDataUser.image.split('/').length-1]} onError={(e:any) => {e.target.onerror = null;e.target.src=require('../../assets/images/'+noImgList[Math.floor(Math.random() * noImgList.length)])}}  alt='' className={`${styles.favouriteImg}`} />
                                    
                                    <div className={`flex-1`} style={{width: "0"}}>
                                        <h3 className={`font-16 pb-10 ellipsis-1 ${styles.favouriteTitle}`}>{item.name || item.metaDataUser.name}</h3>
                                        
                                        <div>
                                            <span className={`font-14 ai-center ${styles.favouriteLot}`}>
                                                <img src={require('../../assets/images/apps/address-icon-black.png')} alt="icon" className={`mr-5`} />
                                                {item.location && `${item.location.pageNum},${item.location.grid_y},${item.location.grid_z}`}
                                            </span>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }):<NoData />}
                </div>
            </div>:
            <ReactTooltip place="top" type="dark" effect="solid" id="fooRefHistory" />
            }
        </div>
    )
}
const Apps = () => {
    //申明语言转换
  const {t} = useTranslation();



    //格子行/列
    let GridYZ = new Array(10);
    for(let i=0;i<GridYZ.length;i++){
        GridYZ[i] = i;
    }
    // // 格子
    let GridArr = new Array(100);
    for(let i=0;i<GridArr.length;i++){
        GridArr[i] = i;
    }
    // console.log(arr1);
    const [baseUrl,setBaseUrl] =useState<string>(process.env.BASE_URL+'/ipfs/')//'https://vp.fanduola.com/ipfs/'
    const [gridList,changGridList] = useState<any[]>([])
    const [queryData, setQueryData] = useState<any>({
        userName: userName,
        pageNum:1,
        gridIndexID:''
        // pageNum: 203,
        // gridIndexID: '62021db940e987321c58cb22'
      })
    const getAppsGridList =async (data:any,fn:any,list:any) => {
        setShowLoding(true)
        // console.log("====================123=================",)
        let indexId = list.filter((item:any)=>item.pageStart<=data.pageNum&&item.pageEnd>=data.pageNum)[0].subset.filter((item:any)=>item.pageStart<=data.pageNum&&item.pageEnd>=data.pageNum)[0].id
        let passData = {
            ...data,
            gridIndexID:indexId
        }


        const res = await getGridList(passData)

        
        if(res.code===0){
            // console.log('success',res);
            setShowLoding(false)
            if(fn){
                fn(res)
            }else{
                setBaseUrl(res.data.baseUrl)
                changGridList(res.data.items)

                setQueryData(data);
            }
            
        }else{
            // console.log('error',res);
        }
    }

    const [indexList,changIndexList] =useState<any[]>([]);
    const sortIndex=(a:any,b:any)=>{
        return a.index-b.index
    }
    const getAppGridIndexList=async ()=>{
        const res = await getGridIndexList({all:"1"});
        if(res.code===0){

            let partList = res.data.filter((item:any)=>item.partIndex && !item.index);


            // let chapterList = res.data.filter((item:any)=> item.partIndex && item.index);
            let newpartList = partList.map((item:any)=>{
                
                
                let partChapterList = res.data.filter((chapterItem:any)=>{
                    // console.log('chapterItem',item.index, chapterItem)
                    return  chapterItem.partIndex && chapterItem.index && item.partIndex===chapterItem.partIndex
                }).sort(sortIndex);
                // console.log('partChapterList',partChapterList)

                let partItem = {
                    ...item,
                    subset:partChapterList
                }
                return partItem
            })
            // console.log("=====================",newpartList,newpartList)
            changIndexList(newpartList)
            setRobotTips(locales==="zh"?newpartList[0].cnDesc:newpartList[0].enDesc)
            // let data = Object.assign({}, queryData, {gridIndexID:newpartList[0].subset[0].id});
            let data = Object.assign({}, queryData, {pageNum:101});

            setQueryData(data)
            getAppsGridList(data,undefined,newpartList);
            // setQueryData(data);
            // console.log('success getAppGridIndexList',newpartList);
        }else{
            // console.log('error getAppGridIndexList',res);
        }
    }

    useEffect(()=>{
        getAppGridIndexList();
        getfavouriteList(undefined,undefined);//获取我的收藏
        // getSalesPageList()
    },[]);
  
    // 书签导航
    const [patrNum,setPatrNum]=useState<number>(0);
    const changAccordion=(e:any,num:number)=>{
        // setShowRobot(true)
        setPatrNum(num);
         // robot talk
        setRobotTips('');
        // setIsShowRobotTips(false);
        setTimeout(()=>{
            setRobotTips(locales==="zh"?e.cnDesc:e.enDesc);
            setIsShowRobotTips(true);
            // setIsScrolle(true);
        },300)
        
    }
    // 书签子导航
    const [chaptNum,setChaptNum] = useState<any>('624fd7eae78edd2400c48050');
    const changChapt=(e:any)=>{
        // setShowRobot(true);
        let chaptData ={
            ...queryData,
            gridIndexID:e.id,
            pageNum:e.pageStart
        }
        let data = Object.assign({}, queryData,chaptData)
        getAppsGridList(data,undefined,indexList)
        setQueryData(data)
        setGridCur(-1);
        setChaptNum(e.id)
        setGridItemCur(undefined);
        // robot talk
        
        setRobotTips('');
        // setIsShowRobotTips(false);
        setTimeout(()=>{
            setRobotTips(locales==="zh"?e.cnDesc:e.enDesc);
            setIsShowRobotTips(true);
            // setIsScrolle(true);
        },300)
    }
    // 格子操作
    const [historyList,getHistoryList] =useState<any>(localStorage.getItem('HistoryList')||'{}')
    const [myHistoryList,getmyHistoryList] =useState<any>([])
    useEffect(()=>{
        let usData = JSON.parse(historyList);
        usData[`${userName}`] && getmyHistoryList(usData[`${userName}`])
    },[])

    const [gridCur,setGridCur] = useState<number>(-1);
    const [gridItemCur,setGridItemCur] = useState<any>(undefined);
    const [gridItemDataCur,setGridItemDataCur] = useState<any>(undefined);
    const onclickGridCur = async(e:any,num:number)=>{
        console.log("1123================",e,num)
        const res = await getGridDetail({gridID:e.id})
        if(res.code===0){

            setGridItemCur(res.data);

            let usData = JSON.parse(historyList);
            let list =[];
            list= usData[`${userName}`]?[e,...usData[`${userName}`].filter((item:any)=>item.id!==e.id)]:[res.data]
            usData[`${userName}`]=list.length>10?list.filter((item,index:number)=>10!==index):list;
            getHistoryList(JSON.stringify(usData));
            getmyHistoryList(list);
            localStorage.setItem('HistoryList',JSON.stringify(usData));
            // setGridCur(num);
            
            setGridCur(res.data.id);
            if(queryData.pageNum===101 && res.data.appLinkUrl){
                accessApp(res.data.appLinkUrl,UserToken)
            }
            
        }
        // console.log('click',e,num,e.metaDataUser.image.split('/')[e.metaDataUser.image.split('/').length-1])
        // let usData = JSON.parse(historyList);
        // let list =[];
        // list= usData[`${userName}`]?[e,...usData[`${userName}`].filter((item:any)=>item.id!==e.id)]:[e]
        // usData[`${userName}`]=list.length>10?list.filter((item,index:number)=>10!==index):list;
        // getHistoryList(JSON.stringify(usData));
        // // !myHistoryList.map((item:any)=>item.id).includes(e.id) &&
        //  getmyHistoryList(list)
        // localStorage.setItem('HistoryList',JSON.stringify(usData))
        // setGridItemCur(e);
        // setGridCur(num);
    }
    const onclickGridNo=(e:object)=>{
        console.log('暂未开发')
    }

    const history = useHistory();
    // 
    const encrypt =(data:any)=>{
        if (typeof data === "object") {
            try {
                // eslint-disable-next-line no-param-reassign
                data = JSON.stringify(data);
            } catch (error) {
                console.log("encrypt error:", error);
            }
        }
        const dataHex = CryptoJS.enc.Utf8.parse(data);
        const encrypted = CryptoJS.AES.encrypt(dataHex, SECRET_KEY, {
            iv: SECRET_KEY,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        return encrypted.ciphertext.toString();
    }


    const accessApp = (url:string,token:any)=>{
        console.log("-============",gridItemCur)
        if(!userName){
            setShowPopup(true);
            return false;
        }
        let pinKey = JSON.parse(pin) || (sessionStorage.getItem('pinKey') && JSON.parse(sessionStorage.getItem('pinKey')||''))||'';
        if(queryData.pageNum===101 &&!pinKey){
            setShowAccessPin(true);
            return false;
        }
        let key = encrypt('s='+encrypt(token)+'&p='+encrypt(pinKey)+'&u='+encrypt(userName))
        setShowLoding(true)
        setTimeout(() => {
            // console.log(queryData.pageNum,key)
            setShowLoding(false)
            queryData.pageNum===101? window.open(url+'?'+key):window.open(url)
        }, 1000);

        
    }

    
    const [showAccessPin,setShowAccessPin] = useState<boolean>(false);
    const [showPopup,setShowPopup] = useState<boolean>(false);
    const popupChange=(e:any)=>{
        console.log(e)
        if(e){
			history.push(`/login`)
        }
        setShowPopup(e)
    }
    const [pin,setPin] = useState<any>(sessionStorage.getItem('pinKey'))
    const changeAccessPin=(e:any,num:string)=>{
        console.log(e,num)
        if(e && !UserToken){
            let then=(res:any)=>{
                setPin(JSON.stringify(num));
                sessionStorage.setItem('pinKey',JSON.stringify(num));
                setUserToken(res.data.socialDataToken)
                setShowAccessPin(false);
                accessApp(gridItemCur.appLinkUrl,res.data.socialDataToken);
            }
            getToken(e,then);
            
        }else if(e && UserToken){
            setPin(JSON.stringify(num));
            sessionStorage.setItem('pinKey',JSON.stringify(num));
    
            setShowAccessPin(false);
            accessApp(gridItemCur.appLinkUrl,UserToken);
        }else{
            setPin('');
            setShowAccessPin(false);
        }
        
    }
    // 我的格子
    const [myGridList,setMyGridList] =useState<any>({count:0,items:[]});
    const [myGridSkip,setMyGridSkip] = useState<any>(0)
    useEffect(()=>{
        getMyGrid();//我的格子
    },[myGridSkip])
    const getMyGrid =async ()=>{
        let data = {
            userName:userName,
            skip: myGridSkip,
            limit: 10
        }
        const res = await getMyGridList(data);
        // console.log('getMyGridList',res)
        if(res.code===0){
            let list = myGridList.items.concat(res.data.items)
            setMyGridList({...res.data,...{items:list}})
        }
    }

    // 收藏的格子
    const [favouriteList,setFavouriteList] =useState<any>({count:0,items:[]});
    const [favouriteGridList,setFavouriteGridList] =useState<any>([])
    var num = favouriteList.count;
    const getfavouriteList = async (id:any,dataList:any)=>{
        let data = {
            userName:userName,
            gridFavFolderID:id,
        }
        const res = await getFavourite(data)
        if(res.code===0){
           if(id){
                num=num+res.data.count;
                let changFavouriteList = {
                    count:num,
                    items:dataList.items.map((item:any)=>{
                        if(item.isFolder&&item.gridFavFolderID === id){
                            item.sub = res.data;

                            let gridProcessList = res.data.items.filter((item: { isFolder: boolean; })=>!item.isFolder).map((item:any)=>item.gridID)
                            let rawData = dataList.items.filter((item: { isFolder: boolean; })=>!item.isFolder).map((item:any)=>item.gridID)
                            setFavouriteGridList([...rawData,...gridProcessList])
                        }
                        return item;
                    })
                 }
                setFavouriteList({...dataList,...changFavouriteList})
           }else{
                num = res.data.count;
                setFavouriteList({...res.data});
                folderItemSub(res.data);
                let gridProcessList = res.data.items.filter((item: { isFolder: boolean; })=>!item.isFolder).map((item:any)=>item.gridID)
                setFavouriteGridList(gridProcessList)
           }
           
        }
        // 
    }
const folderItemSub = (data:any) => {
    data.items.map((item:any)=>{
        if(item.isFolder && !item.sub){
            item.sub={};
            getfavouriteList(item.gridFavFolderID,data)
        }
        return item
    })
}


    const clickCollect=(hasCollect:boolean)=>{
        console.log(123)
        // myBtnClick();
        if(!userName){
            simpleFn('请先登录！', 'error')
            return ;
        }
        setIsShowGridCollect(!isShowGridCollect)
    }
    const [isShowGridCollect,setIsShowGridCollect] = useState(false);
    const handleShowInClick=(e:any)=>{
        // console.log(e.data)
        let folderData = favouriteList;
        switch(e.type){
            case 'addfolder':
                // folderData={
                //     items:[...folderData.items,e.data]
                
                // }
                getfavouriteList(undefined,undefined);
                break;
            case 'delfolder':
                // folderData={
                //     items:[...folderData.items.filter((item:any)=>item.gridFavFolderID!=e.data.gridFavFolderID)]
                // }
                getfavouriteList(undefined,undefined);
                break;
            case 'addgrid':
                // folderData={
                //     items:[...folderData.items,e.data]
                // }
                // setFavouriteGridList([...e.data.gridID])
                getfavouriteList(undefined,undefined);
                setGridItemDataCur(gridItemCur);
                setIsShowGridCollect(!isShowGridCollect)
                break;
            case 'delgrid':
                getfavouriteList(undefined,undefined);
                setGridItemDataCur(gridItemCur);
                setIsShowGridCollect(!isShowGridCollect);
                simpleFn('已取消！', 'success')
                break;
            case 'editfolder':
                getfavouriteList(undefined,undefined);
                break;
            default:
                setGridItemDataCur(gridItemDataCur);
                setIsShowGridCollect(!isShowGridCollect)
                break;
        }
        
        // setFavouriteList({...folderData})

       

        // 
    }
    const changeGridItem =(data:any)=>{

        let snapGridList = gridList.map((item:any)=>{
            if(item.id===gridCur){

                item.appDesc= data.desc || data.appDesc;
                item.appImg=data.imgHash;
                item.appThumb=data.thumbHash;
                item.linkUrl=data.linkUrl;
                item.gridName=data.name;
                item.appName = data.name;
                setGridItemCur({ ...gridItemCur,...item});

                
            }
            return item
        });
        
        changGridList(snapGridList);
    }

    const [UserToken,setUserToken] = useState<any>(sessionStorage.getItem('socialDataToken'));
    const handleInClick=(e:any)=>{
        
        if(UserToken){
            e && isShowPop && changeGridItem(e);
            changeShowPop(!isShowPop);
        }else{
            setShowPin(true)
        }


        if(!isShowPop||showPin){
            document.body.style.overflow = 'hidden';
        }else{
            // getMyGrid();
            setMyGridSkip(0)
            document.body.style.overflow = 'auto';
        }
    }
   
    const EditBtn:React.FC =()=>{
        return (
            <>
                <div className={`${styles.gridItemEdit}`} data-tip={t('methods.Edit')} onClick={handleInClick}>Edit</div>
                <ReactTooltip place="top" type="dark" effect="solid"/>
            </>
        )
    }
    const CollectBtn:React.FC=()=>{
        return (
            <>
                <div className={`${styles.gridItemCollect} ${favouriteGridList.includes(gridItemCur.id)?styles.hasCollect:''}`}  data-tip={`${favouriteGridList.includes(gridItemCur.id)?t('methods.CancelCollection'):t('methods.Collect')}`} onClick={()=>(setGridItemDataCur(gridItemCur),clickCollect(favouriteGridList.includes(gridItemCur.id)))}>Collect</div>
                <ReactTooltip place="top" type="dark" effect="solid"/>
            </>
        )
    }
    const DirectSale:React.FC=()=>{

        const [showBuyGrid,setShowBuyGrid] = useState<boolean>(false);
        const handleSaleBtn=(e:any)=>{
            console.log(e)
            if(e){
                if(e==='ref'){
                    setRefreshData(false);
                    return false;
                }
                orderDetermine(e)
            } else{
                setShowBuyGrid(false) 
            }
        }
        const [showBuy,setShowBuy] = useState<boolean>(false);
	    const [orderNum,setOrderNum] = useState<any>('');

	    const [showPayState,setShowPayState] = useState<boolean>(false);

        const [showOrderPay,setShowOrderPay] = useState(false);
        const [showOrderDetail,setShowOrderDetail] = useState(false);

        const [orderInfo,setOrderInfo] = useState<any>(null)

        const orderDetermine= async (e:any)=>{
            // let userName = sessionStorage.getItem('userName');
            console.log('=================userName',userName,e)
            let orderData={
                fromUserName:e.list[1].userName,
                toUserName:userName,
                destID:e.list[1].destID,
                tokenIDs:e.list.map((item:any)=>item.tokenID).join(','),
                amounts:e.list.map((item:any)=>1).join(',') 
            }
            console.log(orderData)
            const res = await createOrder(orderData)
            console.log("chengg=============",res)
            if(res.code===0){
                setShowBuy(false);
			
                setShowPayState(false);
                setOrderNum(res.data.orderID);
                getOrderDetail(res.data.orderID,1)
            }else if(res.code===8){
                console.log('超出取消5个');
    
                simpleFn('您取消次数已超过限制！', 'error')	
            }else if(res.code===6){
                // console.log('有未支付')
                simpleFn('您有未支付订单，请先完成支付！', 'error')		
            }else if(res.code===10 ||res.code===11){
                simpleFn('您所购买的作品库存不足！', 'error')
            }else if(res.code===12){
                simpleFn('您所购买的作品超过限购！', 'error')
            }else{
                simpleFn(res.msg, 'error')
                console.timeLog(res.msg)
            }
        }
        // const getWXPayOrder=async(orderNum:string)=>{
        //     const res =await wxPagePay({orderID:orderNum})
        //     if(res.code===0){
                
    
        //         setShowOrderPay(false)
        //         setShowPayState(true)
        //         window.open(res.data.url);
                
        //     }
        // }
        // //支付订单
        const getPayOrder =(type:number)=>{
            console.log("============getPayOrder",type==2)
            switch (type) {
                case 1:
                    getZFBPayOrder(orderNum);
                    break;
                case 2:
                    // getWXPayOrder(orderNum);
                    break;
                // default:
                // 	console.log('银行卡支付')
                // 	break;
            }
        }
        const getZFBPayOrder = async (orderNum:string)=>{
            const res = await aliPagePay({orderID:orderNum})
            if(res.code===0){
                
    
                setShowOrderPay(false)
                setShowPayState(true)
                window.open(res.data.url);
                
            }
        }
        // // 订单支付后弹出
        const payOrder=(e:number)=>{
            if(e===1){
                console.log('支付问题？')
                history.push(`/help?type=3`)
            }else if(e===2){
                
                getOrderDetail(orderNum,2);
            }
        }
        const getOrderDetail = async (orderNum:string,n:number)=>{
            console.log('==============123',n)
            const res = await getOrderStatus({orderID:orderNum});
            //payStatus: 1 待支付   2 已支付 3 转移中  4 转移成功  5 转移失败
            console.log('==============123',res)
            if(res.code===0){
                setOrderInfo(res.data)
                switch (n) {
                    case 1:
                        if(res.data.payStatus===5){
                            setShowOrderDetail(true);
                            setShowPayState(false);
                        }else{
                            setShowOrderPay(true)
                        }
                        
                        break;
                    default:
                        // setShowOrderDetail(true);
                        setShowPayState(false);
                        setRefreshData(true);

                        break;
                }
            }else{
    
            }
        }
      
        const confirmOrder =(e:any,n:number)=>{
            
            if(e){
                switch (e) {
                    case 'pay':
    
                        setShowPayState(false);
                        getPayOrder(n)
                        break;
                    default:
                        setShowPopup(true)
                        break;
                }
            }else{
                setShowOrderPay(e)
            }
        }
        // // 查询订单详情
        const confirmOrderDetail=(e:any,n:number)=>{
            console.log("==============confirmOrderDetail",e)
            if(e){
                switch (e) {
                    case 'pay':

                        setShowOrderDetail(false);
                        getPayOrder(n)
                        break;
                    case 'cancel':
                        // getcancelOrder(orderNum,n)
                        setShowPopup(true)
                        break;
                    case 'see':
                        break;
                    default:
    
                        break;
                }
            }else{
                setShowOrderDetail(e);
                // setIsRefresh(true);
                // clearInterval(timer.current)
                // setDataState({
                //     day:'00',
                //     hour:'00',
                //     minute:'00',
                //     second:'00'
                //   })
                // setTimeout(()=>{
                //     getDetail();
                // },500)
            }
        }
        // // 取消订单
        const getcancelOrder = async()=>{
            const res = await cancelOrder({orderID:orderNum})
            if(res.code===0){
                setShowPopup(false);
                setShowOrderPay(false)
                setShowOrderDetail(false);
                simpleFn('订单取消成功！', 'success')
            }
        }
        const [refreshData,setRefreshData] = useState(true)

        return (
            <>
                <div className={`relative ${styles.myNavBtn}`}>
                    <div className={`${styles.myIcons} ${styles.BuyGrid}`} onClick={()=>{
                        if(!userName){
                            simpleFn('请先登录！', 'error')
                            return ;
                        }else{
                            setShowBuyGrid(true)
                        }
                    }}>格子直买</div>
                    {showBuyGrid && <BuyGrid refresh={refreshData}  onChange={(e:any)=>handleSaleBtn(e)} />}
                </div>
                {showOrderPay && <Order itemData={orderInfo} onChange={(e:any,n:number)=>confirmOrder(e,n)} />}
					{showPayState && <Pay onChange={(e:any)=>payOrder(e)} />}
					{showOrderDetail && <OrderDetail itemData={orderInfo} onChange={(e:any,n:number)=>confirmOrderDetail(e,n)} showDetailBtn={true} />}
					{showPopup && <Popup onChange={(e:boolean)=>e?getcancelOrder():setShowPopup(false)} title={`取消订单`} content={<>
					<p className={`mt-20`}>确定取消订单？取消后不可恢复！</p>
					<p className={`font-12 col-red mt-10`}>*24小时内锁单5单未支付，将限制交易24小时</p></>} />}
            </>
        )
    }

    const [showLoding,setShowLoding] =useState<boolean>(false)

    //格子书顶部
    const BookTopNav=({onChange,data}:any)=>{
        //点击收藏 or 历史 or 我的格子
        const handleMyChangeBtn = async(e:any)=>{

            console.log("=112==========================",e)
            // getfavouriteList(e.gridFavFolderID)
            // indexList
            let fn = (item: { pageStart: number; pageEnd: number;subset:object;index:number; },index:number)=>{
                (item.pageStart <= e.location.pageNum && item.pageEnd >= e.location.pageNum && item.subset)?setPatrNum(index):item.pageStart <= e.location.pageNum && item.pageEnd >= e.location.pageNum ? setChaptNum(item.index):null;
                return item.pageStart <= e.location.pageNum && item.pageEnd >= e.location.pageNum
            };
            let chapId = await indexList.filter(fn)[0].subset.filter(fn)[0].id;
            let chaptData ={
                userName:userName,
                gridIndexID:chapId,
                pageNum:e.location.pageNum
            }
            const than = (res:any)=>{
                
                console.log('success',res);

                setQueryData(chaptData);
                setBaseUrl(res.data.baseUrl);
                changGridList(res.data.items);

                setGridItemCur(e);
                // setGridCur((e.location.grid_z-1)*10+(e.location.grid_y-1));
                setGridCur(e.id)
            }
            getAppsGridList(chaptData,than,indexList);
            
            // console.log(patrNum,chaptNum)

        }
        const handleMyCollectBtn = async(e:any)=>{
            console.log("======================",e)
            switch(e.type){
                case 'editCollect':
                    let data={
                        id:e.data.gridID,

                        ...e.data
                    }
                    
                    setGridItemDataCur(data);
                    onChange()
                    // clickCollect(true);

                    break;
             
                default:
                    handleMyChangeBtn(e);
                    break;
            }
        }
        
        return (
            <>
                <Col lg={8} lgOffset={1} className={`flex ai-center space-16 ${styles.bookTopNav}`}>
                    <MyGridsBtn  onChange={(e:any) => handleMyChangeBtn(e)} data={myGridList} skip={myGridSkip} />
                    <SearchGrid  onChange={(e:any) => handleMyChangeBtn(e)}  />
                    <MyCollectBtn onChange={(e:any) => handleMyCollectBtn(e)} data={favouriteList} />
                    {/* <MyHistoryBtn onChange={(e:any) => handleMyChangeBtn(e)} data={myHistoryList}  /> */}
                    <DirectSale   />
                    
                </Col>

            </>
        )
    }



    const GridMain:React.FC =() =>{
        return (
            <>
                {GridArr.map((item,index)=>{
                    return (
                        <div className={`${styles.boardBox}`} key={item} onClick={()=>onclickGridNo(item)}></div>
                    )
                })}
                {gridList.map((item:any,index:number)=>{
                    const styleItemTop = (item.location.grid_z );
                    const styleItemLeft = (item.location.grid_y );
                    return (
                        // backgroundImage: (item.appThumb)?`url(${baseUrl+item.appThumb})`:'none',backgroundSize:'100% 100%',backgroundRepeat:'no-repeat',backgroundPosition:'cover'}
                         <div className={`${styles.boardBoxItem} ${item.status===1?styles.saleStatus0:item.status===2?styles.saleStatus1:styles.saleStatus2} ${item.id===gridCur?styles.gridCur:''}`} style={{top:`calc((${styleItemTop} - 1) * 10%)`,left:`calc((${styleItemLeft} - 1) * 10%)`,width:`calc(10% * ${item.size.width})`,height:`calc(10% * ${item.size.height})`,display:`${item.filter!==1?'block':'none'}`}} key={item.id} onClick={()=>onclickGridCur(item,index)}>
                            {item.appImg && 
                                <LazyLoad className={`${styles.boxImg}`}>
                                    <img src={baseUrl+item.appImg} alt='' />
                                </LazyLoad>
                            }
                           </div>
                    )
                })}

                <div className={`font-12 ${styles.pageTips}`}>
                    <div className={`flex ai-center ${styles.saleStatus}`}><div>未开发</div></div>
                    <div className={`flex ai-center ${styles.saleStatus} ${styles.saleStatus0}`}><div>已开发</div></div>
                    <div className={`flex ai-center ${styles.saleStatus} ${styles.saleStatus1}`}><div>出售中</div></div>
                    <div className={`flex ai-center ${styles.saleStatus} ${styles.saleStatus2}`}><div>已售</div></div>
                </div>
            </>
        )
    }
    const [isShowRobot,setShowRobot]=useState<boolean>(true)
    const showRobotTips=()=>{
        setShowRobot(!isShowRobot)
        setIsShowRobotTips(!isShowRobotTips)
    }

    // 设置滚动
    const [isScrolle, setIsScrolle] = useState(true);
    // 滚动速度，值越小，滚动越快
    const speed = 3000;
    const warper = useRef<any>();
    const childDom = useRef<any>();
    




    // 开始滚动
    useEffect(() => {
      var timer:any;
      if (isScrolle) {
        timer = setInterval(
          () =>(
            //   console.log(warper.current.scrollTop,warper.current.clientHeight,childDom.current.scrollHeight),
           // eslint-disable-next-line no-sequences
           warper.current.scrollTop + warper.current.clientHeight >= childDom.current.scrollHeight?(warper.current.scrollTop = childDom.current.scrollHeight,clearTimeout(timer),setIsScrolle(false))
           :warper.current.scrollTop+=36), speed
        );
      }else{
        setShowRobot(false)
      }
      
      return () => {
        clearTimeout(timer);
        
      };
    }, [isScrolle]);
    const [robotTips,setRobotTips] =useState<string>('')
    const [isShowRobotTips,setIsShowRobotTips] = useState<boolean>(true);
    const [isShowPop,changeShowPop] = useState(false);
    const [showPin,setShowPin] = useState(false);
    const [showMenu,setShowMenu] = useState(false);
    const outputMate = (list:any,type:string)=>{
		let item = list.filter((item:any)=>item.trait_type===type)[0];
		return item?item.value:false
	  }

    const changePin = (e:any)=>{
        console.log("================",e)
        if(e){
            getToken(e,undefined);
        }else{
            setShowPin(e);
        }
    }

    const userInfo = sessionStorage.getItem('userInfo')||'' ;
    const getToken = async (key:any,then:any)=>{
        const userTokendata= web3.utils.keccak256(key.address).substr(2);
        const timestamp = new Date().getTime().toString(); 
        const uTokenValidPeriod = '864000000000';
        const temp = key.address.concat(userTokendata,uTokenValidPeriod,timestamp);
        const msgKeccak256 =web3.utils.keccak256("\u0019Ethereum Signed Message:\n"+ new Blob([temp]).size + temp);
        const sign = await web3.eth.accounts.sign(web3.utils.utf8ToHex('\x00'+temp),key.privateKey);
        let data = {
            userName:key.address,
            userToken: userTokendata,
            tokenValidPeriod: uTokenValidPeriod,
            timeStamp: timestamp,
            sig: sign.signature.substr(2),
            msgKeccak256: msgKeccak256.substr(2),
        }
        const res = await vpCenterLogin(data)
        // console.log("1===================",res)

        if(res.code===0){
            let userInfoData = {
                ...JSON.parse(userInfo),
                ...res.data
            }
            sessionStorage.setItem('userInfo',JSON.stringify(userInfoData));
            sessionStorage.setItem('socialDataToken',res.data.socialDataToken);
            setUserToken(res.data.socialDataToken);
            setShowPin(false);
        
            !then ? changeShowPop(true):then(res);
        }else{
            simpleFn(res.msg,'error')
        }

        
    }
    return (
      <>
      {showPin && <Pin onChange={(e:any)=>{changePin(e)}} />}
      {isShowPop && <GridEdit onChange={(e:any) => handleInClick(e)} dataList={myGridList} data={gridItemCur} baseUrl={baseUrl} />}
      {isShowGridCollect && userName && <GridCollect data={favouriteList} itemData={gridItemDataCur} onChange={(e:any) => handleShowInClick(e)} /> }
      <div className={`${styles.navMore}`} onMouseEnter={()=>setShowMenu(true)} onMouseLeave={()=>setShowMenu(false)}>
            {/*  */}
                {showMenu ? 
                    <Menu  style={{position:'fixed'}} />
                    :
                    <div className={`flex jc-flex-start ai-center pointer ${styles.navMoreBtn}`}>
                        <Col lg={12} xl={10} xlOffset={1} lgOffset={1} className={`flex aj-flex-start ai-center`}>
                            <img src={require('../../assets/images/apps/downappNav.png')} alt="" className={`mr-15`} />{t('methods.More')}
                        </Col>
                    </div>}
            {/*  */}
        </div>
        
      <Col lg={12} xl={10} xlOffset={1} >
        <div className={`bg${primaryColor} ${styles.appsMain}`} style={{minHeight: showMenu?'calc(100vh - 10px)':'calc(100vh - 42px)',top:showMenu?"32px":"0"}}>
        
           <BookTopNav data={favouriteList} onChange={()=>clickCollect(true)} />
           
            {/* 中间内容书本 */}
            <Row>
                {/* {gridItemCur?9:10} */}
                <Col lg={8} >
                    <Row className={``}>
                        {/* 书本 */}
                        <Col lg={10} lgOffset={2} className={``}>
                                    
                            <div className={`${styles.book}`}>

                                {/* 左边书签 */}
                                {/* flex jc-flex-end fd-column  indexNavList*/}
                                <div className={`${styles.accordionContainer}`}>
                                    {indexList.map((item,index)=>{
                                    return(
                                       <div key={index}>
                                       {item.status===1 && 
                                            
                                            <>
                                            {/* Part */} 
                                            <h3 className={`flex ai-center jc-center mt-10 pointer ${styles.accordion} ${patrNum===index?'':styles.noAccordion}`} onClick={()=>changAccordion(item,index)}>{locales==='zh'?item.cnName:item.enName}</h3>
                                            {/* Chap */}
                                            <div className={`flex ai-flex-end fd-column ${styles.accordionContent}`} style={{display:patrNum===index?'flex':'none'}}>
                                                {item.subset.map((subitem:any)=>{
                                                    return (
                                                      
                                                        <div className={`flex ai-center mt-8 pointer ${chaptNum===subitem.id?styles.curChap:''} ${styles.listItem}`} onClick={()=>changChapt(subitem)} key={subitem.id}>{locales==='zh'?subitem.cnName:subitem.enName}</div> 
                                                        
                                                    )
                                                })}
                                            </div>
                                            </>}
                                        </div>
                                    ) 
                                    })}
                                </div> 
                                
                                {/* 中间格子 */}
                                <div className={`${styles.board}`}>
                                    {/* 当前页码 */}
                                    <h3 className={`font-12 text-center ${styles.pageNum}`}>page {queryData.pageNum}</h3>
                                    {/* x轴数字 */}
                                    <div className={`${styles.boardBoxGrid_Y}`}>
                                        {GridYZ.map((item,index)=>{
                                            return (<div className={`flex jc-center ai-center`} key={`x${index}`}>{item+1}</div>)
                                        })}
                                     </div>
                                    {/* y轴数字 */}
                                    <div className={` ${styles.boardBoxGrid_Z}`}>
                                        {GridYZ.map((item,index)=>{
                                            return (<div className={`flex jc-center ai-center`} key={`y${index}`}>{item+1}</div>)
                                        })}
                                    </div>
                                    {/* 构建格子 */}
                                    {/* <GridMain data={gridList}  onChange={(e:any)=>testChange(e)} /> //组件化*/}
                                    <GridMain />
                                </div>
                                <div className={`flex ai-center jc-center ${styles.pagination}`}>
                                    <Pagination simple defaultCurrent={1} current={queryData.pageNum} total={1664} pageSize={1}  className={`${styles.orginPagination}`}  onChange={(current: number, pageSize: number) => {
                                        let data = Object.assign({}, queryData, { pageNum: current })
                                        getAppsGridList(data,undefined,indexList);
                                        setGridCur(-1);
                                        setGridItemCur(undefined);

                                    }} />
                                </div>
                                
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col lg={3}>
                {gridItemCur && <>
                        <div style={{maxWidth:"364px",maxHeight:"364px",width:"100%",}}>
                            <div className={`${styles.minBookGrid}`}>
                                {/* {gridItemCur.appImg || gridItemCur.appThumb?
                                    <img src={gridItemCur.baseUrl+gridItemCur.appImg || gridItemCur.baseUrl+gridItemCur.appThumb} alt=""  className={`${styles.minBoard} ${styles.minBoardImg}`} />
                                :
                                
                                    <div className={`${styles.minBoard}`}>
                                        <GridMain />
                                    </div> 
                                } */}
                                <img src={gridItemCur.appImg?baseUrl+gridItemCur.appImg:baseUrl+gridItemCur.dataHash} alt=""  className={`${styles.minBoard} ${styles.minBoardImg}`} />
                            </div>
                        </div>
                        
                        <div className={`flex jc-space-between ai-flex-start mt-15 pointer ${styles.gridItemTitle}`}>
                            <h3 className={`font-16  ellipsis-2 ${styles.gridItemName}`}>
                                {/* Grid({gridItemCur.location && `${gridItemCur.location.pageNum},${gridItemCur.location.grid_y},${gridItemCur.location.grid_z}`}) */}
                                { gridItemCur.appName || outputMate(gridItemCur.metaDataUser.attributes,'cn_name')}
                            </h3> 
                            <div className={`space-16 pl-10`}>
                                {gridItemCur.userName===userName?<EditBtn />:null}
                                <CollectBtn />
                            </div>
                            
                        </div>



                        <div className={`font-14 mt-10 ${styles.gridItemDesc}`}>
                            

                                {/* <Markdown options={{ wrapper: 'article' }}>
                                  {'# Heck Yes\n\nThis is great!'}
                                </Markdown> */}
                            
                            {gridItemCur.appDesc?
                                <div style={{whiteSpace:'pre-line'}} dangerouslySetInnerHTML={{ __html: gridItemCur.appDesc }}></div>
                            :
                            // 
                            // <Markdown options={{ wrapper: 'aside', forceWrapper: true }}>{outputMate(gridItemCur.metaDataUser.attributes,'cn_description') || gridItemCur.metaDataUser.description}</Markdown>
                            gridItemCur.metaDataUser && <Markdown options={{wrapper: 'article',namedCodesToUnicode: {
                                'ensp': '\u2002',
                                'emsp': '\u2003',
                                'nbsp': '\u00a0'
                            } }}>{outputMate(gridItemCur.metaDataUser.attributes,'cn_description') || gridItemCur.metaDataUser.description}</Markdown> 
                            }
                        </div>                   
                        <div className={`flex ai-center mt-25 font-14 ${styles.gridDescRow}`}>
                            <span className={`flex ai-center mr-10 col-666`}>
                                <img src={require('../../assets/images/apps/user-icon.png')} alt="" className={`mr-10`} />    
                                {t('methods.Owner')} 
                            </span>
                            <span className={`pl-10 ellipsis-1 col-blue`}>
                                {gridItemCur.nickName || gridItemCur.userName==='0xDD8c4ba948D80832f81e1d843cc2AAEF10F0CD2b'?'Verseport':gridItemCur.userName.replace(/(\w{6})\w{30}(\w{6})/, "$1...$2") ||'--'}
                                {/* Not for sale yet */}
                            </span>
                        </div>
                        <div className={`flex ai-center mt-20 font-14 ${styles.gridDescRow}`}>
                            <span className={`flex ai-center mr-10 col-666`}>
                                <img src={require('../../assets/images/apps/address-icon.png')} alt="" className={`mr-10`} />    
                                {t('methods.Location')} 
                            </span>
                            <span className={`pl-10 ellipsis-1`}>
                                {gridItemCur.location && `${gridItemCur.location.pageNum} , ${gridItemCur.location.grid_y} , ${gridItemCur.location.grid_z}`}
                            </span>
                        </div>
                        <div className={`flex ai-center mt-20 font-14 ${styles.gridDescRow}`}>
                            <span className={`flex ai-center mr-10 col-666`}>
                                <img src={require('../../assets/images/apps/size-icon.png')} alt="" className={`mr-10`} />    
                                {t('methods.Size')}   
                            </span>
                            <span className={`pl-10 ellipsis-1`}>
                                {gridItemCur.size && `${gridItemCur.size.width} x ${gridItemCur.size.height}`}
                            </span>
                        </div>
                        { gridItemCur.filter!==2 && <div className={`flex ai-center mt-20 font-14 ${styles.gridDescRow}`}>
                            <span className={`flex ai-center mr-10 col-666`}>
                                <img src={require('../../assets/images/apps/rare-icon.png')} alt="" className={`mr-10`} />    
                                 {t('methods.Rarity')}  
                            </span>
                            <span className={`pl-10 ellipsis-1 ${ gridItemCur.rarity==='N'?styles.rarityN:gridItemCur.rarity==='R'?styles.rarityR:gridItemCur.rarity==='SR'?styles.raritySR:gridItemCur.rarity==='SS'?styles.raritySS:gridItemCur.rarity==='SSS'?styles.raritySSS:null } `} >
                                {gridItemCur.rarity} 
                            </span>
                        </div>}
                        { gridItemCur.appLinkUrl && <button className={`btn blue-btn mt-20 ${styles.gridItemBtn}`} onClick={()=>accessApp(gridItemCur.appLinkUrl,UserToken)}>{t('methods.AccessApp')}</button>}
                        
                        {showAccessPin && <Pin onChange={(e:any,num:string)=>{changeAccessPin(e,num)}} />}
                        { showPopup &&  <Popup onChange={(e:boolean)=>popupChange(e)} title={``} content={<>
                            <p className={`mt-20 `}>您当前帐户暂未登录，登录后可获得更好体验！</p>
                            <p className={`mt-20 `}>立即前往登录？</p>
                        </>} />}

                    </>}
                </Col>
            </Row>

        </div>
      </Col>            
        {isShowRobot ? <div className={`${styles.robotTips}`}>
            <Row>
                <div className={`${styles.robotTipsClose}`} onClick={()=>showRobotTips()}>close</div>
                <Col lg={2} md={3} sm={4} xs={4} className={`${styles.robot}`}>
                    <img src={require('../../assets/images/apps/woman-people.png')}  alt='' className={`${styles.robotImg}`} />
                </Col>
                <Col lg={8} md={7} sm={6} xs={6}>
                    <div className={`${styles.robotTipsMain}`}>
                        <div className={`flex ai-center pb-20 ${styles.tipsTitle}`}>
                            <img src={require('../../assets/images/apps/arrrow-left.png')} alt='' className={`mr-10`} />
                            <span>Verse Lee</span>
                            <img src={require('../../assets/images/apps/arrrow-right.png')} alt='' className={`ml-10`} />
                        </div>

                        <div className={`col-fff ${styles.tipsCentent}`} ref={warper}>
    
                            <div ref={childDom}>
                                {/* {isShowRobotTips && <Texty type={`top`} interval={5}>
                                    {robotTips}
                                </Texty>} */}
                                <Texty type={`top`} interval={5}>
                                    {robotTips}
                                </Texty>
                            </div>
                        </div>
                    </div>
                    
                </Col>
            </Row>
            
        </div>
        :
        <div className={`${styles.unShowRobotTips}`} onClick={()=>showRobotTips()}>
            <img src={require('../../assets/images/apps/mini-robot.png')}  alt='' className={`${styles.robotImg}`} />
            <div className={`${styles.openRobotTips}`}>Open</div>
            {/* <img src={require('../../assets/images/apps/arrow-robotTips.png')}  alt='' className={`${styles.robotImg}`} /> */}
        </div>
        }


        
        {showLoding && <ShowLoadding data={{type:1}} onChange={() => setShowLoding(false)} />}
      </>
    );
  }
  
  export default Apps;



