import React, { useEffect, useState  }from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { getStatsList } from '../../api/public';
import Select from 'react-select';
import QueueAnim from 'rc-queue-anim';

import Pagination from '../../components/Pagination';

import styles from "./index.module.less"
import FormatMsgTime from "../../components/FormatMsgTime"
import FormatMsgNumber from "../../components/FormatMsgNumber"
import Start from "../../components/Stats"
import { useTranslation } from 'react-i18next';
import Qs from 'qs'
// 主题
const themePrimaryColor = 'theme';
const primaryColor =  localStorage.getItem(themePrimaryColor)||'light';

const selectOptions = {

    timeRangeListOptions:[
        {value:"",label:"timeRange"},
        {value:"24H",label:"24H"},
        {value:"7D",label:"7D"},
        {value:"30D",label:"30D"},
        {value:"ALL",label:"ALL"},
    ],
    categoryListOptions:[
      {value:"",label:"Category"},
      {value:"4",label:"Art"},
      {value:"10",label:"Utility"},
      {value:"9",label:"Music"},
      {value:"8",label:"Social"},
      {value:"7",label:"IP"},
      {value:"6",label:"DeFi"},
      {value:"5",label:"Metaverse"},
      {value:"3",label:"Avatar"},
      {value:"2",label:"Collectibles"},
      {value:"1",label:"Game"},
      {value:"98",label:"Followd"},
      {value:"99",label:"Verseport"},
    ],
  
  }
  
  
  const TopSelect: React.FC<{changeQuery: Function}> = (props: any) => {
    return (
      <Row className={`pt-30 ${styles.topSelect}`}>
        
        <Col xs={12} md={8} mdOffset={4} lg={6} lgOffset={6}>
          <Row>
            <Col xs={12} sm={6} className={`mb-28`}>
              {/*  isSearchable={false} */}
              <Select options={selectOptions.categoryListOptions} defaultValue={selectOptions.categoryListOptions[0]} onChange={(e:any) => {
                  props.changeQuery({ category: e.value })
                }} />
            </Col>
            <Col xs={12} sm={6} className={`mb-28`}>
              {/*  isSearchable={false} */}
              <Select options={selectOptions.timeRangeListOptions} defaultValue={selectOptions.timeRangeListOptions[0]} onChange={(e:any) => {
                  props.changeQuery({ timeRange: e.value })
                }} />
            </Col>
         </Row>
          
        </Col>
      </Row >
    )
  }

// const getfilesize=(size:number)=>{
//   if (!size)
//       return 0;

//   var num = 1000; 

//   if (size < num)
//       return size + "B";
//   if (size < Math.pow(num, 2))
//       return (size / num).toFixed(2) + "K"; //kb
//   if (size < Math.pow(num, 3))
//       return (size / Math.pow(num, 2)).toFixed(2) + "M"; //M
//   // if (size < Math.pow(num, 4))
//   //     return (size / Math.pow(num, 3)).toFixed(2) + "G"; //G
//   // return (size / Math.pow(num, 4)).toFixed(2) + "T"; //T
// }

const TableDataCentenr = (props:any)=>{
    return (
        <QueueAnim  delay={100} animConfig={[
          { opacity: [1 ,0], translateX: [0, 200] },
          { opacity: [1, 0], translateX: [0, -200] }
        ]}>
          {props.data.length>0?props.data.map((item:any,index:number)=>{
              return (
                // middle="xs"
                <div  className={`flex ai-center bg${primaryColor} ${styles.tableCentent}`} key={item.id}>
                    <Col style={{width:'50px'}} className={`border-box pl-10`}>{index+1}</Col>
                    <Col xs={2} className={`flex ai-center`}>
                      <img src={item.owner.avatar || 'https://joeschmoe.io/api/v1/random?'+index} alt={item.name} className={`${styles.icon}`} />
                      <div className={`ellipsis-1 ${styles.name}`}>{item.name ||  'Unnamed'}</div>
                    </Col>
                    <Col xs>{item.nickName||'Unnamed'}</Col>
                    <Col xs={2} className={`flex jc-center fd-column`}>
                      {/* {getfilesize(item.lastSale.tokenPrice)} */}
                      <p><FormatMsgNumber data={item.lastSale.tokenPrice}  num={2} /><span className={`col-blue ml-5`}>ETC</span></p>
                      {/* {getfilesize(item.lastSale.usdPrice)} */}
                      <p className={`pt-8`}>$<FormatMsgNumber data={item.lastSale.usdPrice}  num={2} /></p>
                    </Col>
                    <Col xs={2} className={`flex jc-center fd-column`}>
                      <p>{item.highestSale.tokenPrice}<span className={`col-blue ml-5`}>ETC</span></p>
                      <p className={`pt-8`}>{item.highestSale.usdPrice}</p>
                    </Col>
                    <Col style={{width:'10%'}}>{item.sales}</Col>
                    <Col xs><FormatMsgTime data={item.lastDeal} /></Col>
                </div>
                  // <div>{item.id}</div>
              )
          }):null}  
        </QueueAnim>
    )
    
}
const TableDataList = (props:any) =>{
  const { t } = useTranslation();
    return (
        <>
            <Row className={`${styles.tableTitle}`}>
                <Col style={{width:'50px'}} className={`border-box pl-10`}>#</Col>
                <Col xs={2}>NFT</Col>
                <Col xs>{t('methods.Owner')}</Col>
                <Col xs={2}>{t('methods.lastPrice')}(24H)</Col>
          <Col xs={2}>{t('methods.highest')}(24H)</Col>
          <Col style={{ width: '10%' }}>{t('methods.sales')}(24H)</Col>
          <Col xs>{t('methods.lastDeal')}</Col>
            </Row>
            <TableDataCentenr data={props.data.items} />
        </>
    )
}


const Stats =(props:any)=>{
      const urlParams = props.location.search.slice(1);
      const params:any = Qs.parse(urlParams);
      // const [urlData, changUrlData] = useState<object>(params)




      const changeQueryData = (val: object) => {
          let data = Object.assign({}, queryData, val)
          setQueryData(data)
        }
      const [queryData, setQueryData] = useState<object>({
        timeRange: '',
        category: '',
        skip: 0,
        limit: 10
      })

      const [tabData,setTabData]=useState<any>({})

      const [total, setTotal] = useState<number>(params.type || 1);
      const getStatsDataList = async () => {

        const res = await getStatsList(queryData);
        console.log("statsList",res.data.items)
        if (res.code === 0) {
            setTabData(res.data)
            setTotal(res.data.count);
        //   setNFTList(res.data.items);
          
        } else {
          // message.error(res.msg);
        }
      }
    
      // useEffect(() => {
      //   getStatsDataList();
      // }, [queryData])
     
    return (
        <>
            {/* <Col lg={12} xl={10} xlOffset={1}>
                <TopSelect  changeQuery={changeQueryData} />
                <Col xl={12} className={`bg${primaryColor} ${styles.table}`}>
                    {tabData.items?<TableDataList data={tabData} />:null}
                    <Pagination size='lage' total={total} pageSize={10} onChange={(current: number, pageSize: number) => {
                      let data = Object.assign({}, queryData, { skip: (current - 1) * pageSize })
                      setQueryData(data)
                    }} style={{ padding: 28, display: 'flex', justifyContent: 'center' }} />
                </Col>    
                
            </Col> */}
            <div className={`pb-75`}>

             <Start data={{limit:100,type:props.location.state?props.location.state.type:1}} onChange={(e:any)=>{}} />
            </div>
        </>
    )
}
export default Stats