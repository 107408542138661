import React, { useEffect, useRef, useState } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { getMyOrderList,getsevtime,aliPagePay,getOrderStatus,createOrder,wxPagePay,cancelOrder,alipagepayFuel,cancelFuelOrder } from '../../api/public';


import QueueAnim from 'rc-queue-anim';

import FormatMsgTime from "../../components/FormatMsgTime"

import  styles from "./index.module.less";

import LazyLoad from 'react-lazyload';


import { simpleFn } from '../../components/Notification';

import Pay from '../Explore/Detail/Pay';
import Order from '../Explore/Detail/Order';
import OrderDetail from '../Explore/Detail/OrderDetail';
import Popup from '../../components/Popup';

import TimeContent from '../../components/TimeContent';

import Empty from '../../components/Empty';
// import Pagination from '../../components/Pagination';


import RcPagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';

import { t, use } from 'i18next';
// 主题
const themePrimaryColor = 'theme';
const primaryColor =  localStorage.getItem(themePrimaryColor)||'light';

const baseUrl = process.env.BASE_URL+"/ipfs/";


const TableDataCentenr = (props:any)=>{
	console.log('TableDataCentenr=============',props)
	const noImgList = ['noImg1.png', 'noImg2.png', 'noImg3.png'];
	const [orderNum,setOrderNum] = useState<any>('')
	const [showPayState,setShowPayState] = useState<boolean>(false);
	const [orderInfo,setOrderInfo] = useState<any>({})
	const getWXPayOrder=async(orderNum:string)=>{
		const res =await wxPagePay({OrderID:orderNum})
		if(res.code===0){
			setShowOrderPay(false)
			setShowPayState(true)
			window.open(res.data.url);
		}
	}
	//支付订单
	const getPayOrder =(type:any,orderNum:string)=>{
		console.log("============getPayOrder",type)
		switch (type) {
			case 1:
				getZFBPayOrder(orderNum);
				break;
			case 2:
				getWXPayOrder(orderNum);
				break;
			default:
				console.log('银行卡支付')
				break;
		}
	}

	const getZFBPayOrder = async (orderNum:string)=>{
		const res = await aliPagePay({OrderID:orderNum})
		if(res.code===0){
			

			setShowOrderPay(false)
			setShowPayState(true)
			window.open(res.data.URL);
			
		}
	}
	// 订单支付后弹出
	const payOrder=(e:number,id:string)=>{
		if(e===1){
			console.log('支付问题？')
		}else if(e===2){
			getOrderDetail(id);
			props.onChange();
		}
	}
	const getOrderDetail = async (orderNum:string)=>{
		const res = await getOrderStatus({OrderID:orderNum});
		//payStatus: 1 待支付   2 已支付 3 转移中  4 转移成功  5 转移失败
		if(res.code===0){
			setOrderInfo(res.data)
			setShowOrderDetail(true);
			setShowPayState(false);
		}
	}
	const [showOrderPay,setShowOrderPay] = useState(false);
	const [showOrderDetail,setShowOrderDetail] = useState(false);
	
	const [showPopup,setShowPopup] = useState(false)
	// 查询订单详情
	const confirmOrderDetail=(e:any,n:number,id:string)=>{
		console.log("==============confirmOrderDetail",e)
		setOrderNum(id);
		if(e){
			switch (e) {
				case 'pay':

					setShowOrderDetail(false);
          if(orderInfo.mode===50){
            bugFuel(id)
          }else{
            getPayOrder(n,id);
          }
					
					break;
				case 'cancel':
					// getcancelOrder(orderNum,n)
					setShowPopup(true)
          
					break;
				case 'see':
					break;
				default:

					break;
			}
		}else{
			setShowOrderDetail(e)
		}
	}
  // 购买燃料
  const bugFuel= async (e:any)=>{
    const res =await alipagepayFuel({OrderID:e});
    if(res.code===0){
      setShowOrderPay(false)
			setShowPayState(true)
			window.open(res.data.url);
    }
}
	// 取消订单
	const getcancelOrder = async()=>{
    if(orderInfo.mode===50){
      getCancelFuelOrder();
      return;
    }

		const res = await cancelOrder({OrderID:orderNum})
		if(res.code===0){
			setShowPopup(false);
			setShowOrderPay(false)
			setShowOrderDetail(false);
			simpleFn('订单取消成功！', 'success');
			props.onChange()
		}
	}
	// 取消燃料订单
  const getCancelFuelOrder=async()=>{
    const res = await cancelFuelOrder({OrderID:orderNum})
		if(res.code===0){
			setShowPopup(false);
			setShowOrderPay(false)
			setShowOrderDetail(false);
			simpleFn('订单取消成功！', 'success');
			props.onChange()
		}
  }


	const goPay=(e:any)=>{
		console.log("去支付===============",e)
		getOrderDetail(e.OrderID)
	}
  const changeTime=()=>{
    setTimeout(()=>{
      props.onChange()
    },1000)
  }
    return (
        <>
        {props.data.Items && 
          props.data.Items.length > 0 ? props.data.Items.map((item:any,index:number)=>{
          // console.log(item)
          let metaDataSystem: any;
          // if (item.metaDataSystem !== '') {
          //   metaDataSystem = JSON.parse(item.metaDataSystem);
          //   if (item.mode!==50 && metaDataSystem.thumb_url !== undefined) {
          //     metaDataSystem.thumb_hash = metaDataSystem.thumb_url;
          //   }
          // } else {
          //   metaDataSystem = {
          //     "type": '',
          //     "size": '',
          //     "thumb_hash": ''
          //   }
          // }
              return (
              
                <div key={item.OrderID} className={`flex ai-center jc-center ${styles.ownList} ${styles.likeList}`} >
                    <Col>
                      <div className={`text-center col-666 ${styles.ownSort}`}>{index+1}</div>
                    </Col>
                    {/* <Col xs className={`flex ai-center`}>
                     <LazyLoad className={`relative  ml-30 ${styles.likeListImg}`}>
                      {item.mode!==50 ? (metaDataSystem.type !== undefined && metaDataSystem?.type.split('/')[0] === 'image') ? 
                          <img className={`${styles.likeSortImg}`} src={metaDataSystem.thumb_hash == '' ?
                          props.data.baseUrl + item.dataHash :
                            (item.dataHash === '' ?
                              metaDataSystem.thumb_hash :
                              props.data.baseUrl + metaDataSystem.thumb_hash)} onError={(e: any) => { e.target.onerror = null; e.target.src = require('../../assets/images/' + noImgList[Math.floor(Math.random() * noImgList.length)]) }} alt="" /> : 
                              <div>
                                <div className={`flex jc-center ai-center ${styles.mask}`}><img style={{ display: 'inline-block' }} src={require('../../assets/icon/play.png')} alt=''  /></div>
                                <img className={`${styles.likeSortImg}`} src={props.data.baseUrl + metaDataSystem.thumb_hash} onError={(e: any) => { e.target.onerror = null; e.target.src = require('../../assets/images/' + noImgList[Math.floor(Math.random() * noImgList.length)]) }} alt='' />
                            </div>
                          :<img className={`${styles.likeSortImg}`} src={require('../../assets/icon/mine/fuel-icon.gif')} alt="" />}
                        </LazyLoad> 
                    </Col>
                    <Col xs className={``}>
                       <div className={`${styles.medaTips}`}>
                          <h3 className={`font-18`}>{item.mode!==50 ? item.metaDataUser.name:item.transName || '--'}</h3>
                          <p className={`font-14 col-666 mt-10`}>{item.mode!==50?'作品名称':'名称'}</p>
                        </div> 
                    </Col>
                   */}

                    <Col xs={5} className={`flex ai-center`}>
                      {
                        item.Products.map((subitem:any,index:number)=>{
                          // let metaDataSystem: any;
                          // console.log(subitem.metaDataSystem!=null)
                          // if (subitem.metaDataSystem!=null && subitem.metaDataSystem !== '') {
                          //   metaDataSystem = JSON.parse(subitem.metaDataSystem);
                          //   if (item.mode!==50 && metaDataSystem.thumb_url !== undefined) {
                          //     metaDataSystem.thumb_hash = metaDataSystem.thumb_url;
                          //   }
                          // } else {
                          //   metaDataSystem = {
                          //     "type": '',
                          //     "size": '',
                          //     "thumb_hash": ''
                          //   }
                          // }
                          return (
                          <div className={`flex ai-center jc-center fd-column mr-20 ${styles.orderNfts}`} key={subitem.ProductSN}>

                          
                          <LazyLoad className={`relative ${styles.likeListImg}`}>
                            {
                                <div>
                                  {/* <div className={`flex jc-center ai-center ${styles.mask}`}><img style={{ display: 'inline-block' }} src={require('../../assets/icon/play.png')} alt=''  /></div> */}
                                  <img className={`${styles.likeSortImg}`} src={ subitem.ProductType==501?require('../../assets/icon/mine/fuel-icon.gif'):baseUrl+subitem.Thumb_Hash} onError={(e: any) => { e.target.onerror = null; e.target.src = require('../../assets/images/' + noImgList[Math.floor(Math.random() * noImgList.length)]) }} alt='' />
                                </div>
                              }
                              </LazyLoad>
                              <div className={`font-12 text-center ellipsis-1 ${styles.orderNfts}`}>
                                  {subitem.NftName}
                                </div>
                          </div>)
                        })
                      }

                    </Col>
                    <Col xs className={`flex`}>
                        <div className={`${styles.medaTips}`}>
                          <h3 className={`font-18`}>¥ {item.Products[0].Price/100}</h3>
                          <p className={`font-14 col-666 mt-10`}>{item.mode!==50?'作品单价':'单价'}</p>
                        </div>
                    </Col>
                    <Col xs className={`flex`}>
                        <div className={`${styles.medaTips}`}>
                          <h3 className={`font-18`}>{eval(item.Products.map((item:any)=>item.Num).join("+"))}</h3>
                          <p className={`font-14 col-666 mt-10`}>购买数量</p>
                        </div>
                    </Col>
                    <Col xs className={`flex`}>
                        <div className={`${styles.medaTips}`}>
                          <h3 className={`font-18`}>¥ <span className={`col-blue`}>{item.TotalPrice/100}</span></h3>
                          <p className={`font-14 col-666 mt-10`}>订单总计</p>
                        </div>
                    </Col>
                    <Col xs className={`flex`}>
                        <div className={`${styles.medaTips}`}>
                          <h3 className={`font-18`}>
                              {(item.PayStatus===1 || item.PayStatus===2) && '待支付'}
                              {(item.PayStatus===3 || item.PayStatus===8) && '已取消'}
                              {item.PayStatus===4 && '待转移'}
                              {(item.PayStatus===5 || item.PayStatus===7) && '转移中'}
                              {item.PayStatus===6 && '已完成'}
                              {/* {item.payStatus===7 && '转移失败'} */}
                              {/* {item.payStatus===8 && '订单超时'} */}
                          </h3>
                          <p className={`font-14 col-666 mt-10`}>订单状态</p>
                        </div>
                    </Col>

                    
                    
                    <Col xs className={`flex`}>
                       {(item.PayStatus===1 || item.PayStatus===2) ?
                          <div className={`${styles.medaTips}`}>
                           <button className={`btn blue-btn ${styles.payBtn}`} onClick={()=>goPay(item)}>去支付</button>
                           {item.CreateTime && <p className={`font-12 lh-20 col-red mt-10`}>请在 <TimeContent data={item.CreateTime} onChange={()=>changeTime()} /> 
                           内完成支付，逾期视为自愿放弃</p> }
                          </div>
                                    
                          :
                        <div className={`${styles.medaTips}`}>
                          <button className={`btn white-btn ${styles.payBtn}`} onClick={()=>goPay(item)}>查看详情</button>
                        </div>
                        } 
                    </Col>
                   
                </div>
                
              )
            }):
            <Empty
                imageStyle={{
                  width: 480,
                  height: 'auto',
                  display: 'block'
                }}
                description={
                  <p style={{ textAlign: 'center', margin: '24px 0px', fontSize: '24px', color: '#9f9f9f' }}>{t(`methods.noData`)}</p>
                }
                image={require('../../assets/icon/noData.png')}
              />
          
          }


          {/* {showOrderPay && <Order itemData={orderInfo} onChange={(e:any,n:number)=>confirmOrder(e,n)} />} */}
					{showPayState && <Pay data={orderNum} onChange={(e:any,id:string)=>payOrder(e,id)} />}
					{showOrderDetail && <OrderDetail itemData={orderInfo} onChange={(e:any,n:number,id:string)=>confirmOrderDetail(e,n,id)} showDetailBtn={false} />}
					{showPopup && <Popup onChange={(e:boolean)=>e?getcancelOrder():setShowPopup(false)} title={`取消订单`} content={<>
					<p className={`mt-20`}>确定取消订单？取消后不可恢复！</p>
					<p className={`font-12 col-red mt-10`}>*24小时内锁单5单未支付，将限制交易24小时</p></>} />}
        </>
    )
    
}
const OrderList = () => {
      const userName:any = sessionStorage.getItem('userName');
    

      const [dataList,setDataList] = useState<any>({})
      const UserOrder = async ()=>{
// 1 待支付   2 已支付 3 转移中  4 转移成功  5 转移失败
        const res = await getMyOrderList(queryData);
        setTotal(res.data.TotalRecords)
        if(res.code===0){
            console.log(res);
            setDataList(res.data)
        }else{

        }
      }

      const [current, setCurrent] = useState(1);
      const [total, setTotal] = useState<number>(0);
      const Locale = localStorage.getItem('localesLng');
      const LocaleEn_US: object = {
        // Options.jsx
        items_per_page: '/ page',
        jump_to: 'Go to',
        jump_to_confirm: 'confirm',
        page: 'Page',

        // Pagination.jsx
        prev_page: 'Previous Page',
        next_page: 'Next Page',
        prev_5: 'Previous 5 Pages',
        next_5: 'Next 5 Pages',
        prev_3: 'Previous 3 Pages',
        next_3: 'Next 3 Pages',
        page_size: 'Page Size',
      };

      const [queryData, setQueryData] = useState<any>({
        UserName:userName,
        PayStatus:undefined,// 1 待支付   2 已支付 3 转移中  4 转移成功  5 转移失败
        PageNo: 1,
        PageSize: 10
      })
      useEffect(()=>{
        UserOrder()
      },[queryData])

      const [navType,setNavType] = useState(0)
      return (
        <>
            <Col lg={12} xl={10} xlOffset={1} >
                <div className={`${styles.billMain}`}>
                    <div className={`flex ai-center bg${primaryColor}  ${styles.edtailCardTitle}`}>
                        <img src={require(`../../assets/images/nftDetail/icon-nftDetail3.png`)} alt="" />
                        <h3>我的订单</h3>
                    </div>

                    <div className={`bg${primaryColor} mt-20`}>
                        <div>
                      
                          <ul className={`flex ${styles.orderNav}`}>
                            <li className={`pointer ${navType===0?styles.cur:''}`} onClick={()=>{setQueryData({...queryData,PageNo:1,PayStatus:undefined});setNavType(0)}}>全部</li>
                            <li className={`pointer ${navType===1?styles.cur:''}`} onClick={()=>{setQueryData({...queryData,PageNo:1,PayStatus:'1,2'});setNavType(1)}}>未支付</li>
                            <li className={`pointer ${navType===2?styles.cur:''}`} onClick={()=>{setQueryData({...queryData,PageNo:1,PayStatus:'4'});setNavType(2)}}>待转移</li>
                            <li className={`pointer ${navType===4?styles.cur:''}`} onClick={()=>{setQueryData({...queryData,PageNo:1,PayStatus:'6'});setNavType(4)}}>已完成</li>
                            <li className={`pointer ${navType===3?styles.cur:''}`} onClick={()=>{setQueryData({...queryData,PageNo:1,PayStatus:'3,8'});setNavType(3)}}>已取消</li>
                          </ul>
                        </div>
                        <Col xs={12}>
                            <TableDataCentenr data={dataList} onChange={()=>window.location.reload()} />

                            <div className={'flex ai-center jc-center pt-20 pb-40'}>
                              <RcPagination current={current}  total={total} pageSize={10} showPrevNextJumpers={false} locale={Locale === 'en' ? LocaleEn_US : undefined} className={`font-20 text-conter ${styles.orginPagination}`} onChange={(current: number, pageSize: number) => {
                                console.log(current,'current');
                                console.log(pageSize,'pageSize');
                                
                                let data = Object.assign({}, queryData, { PageNo: current });
                                setCurrent(current);
                                setQueryData(data)
                              }}  />
                            </div>
                        </Col>
                    </div>
                </div>
            </Col>

        </>
    
      );

}
export default OrderList;