import React, { CSSProperties, ReactNode } from 'react';

type EmptyProps = {
  image: string;
  description: ReactNode;
  imageStyle: CSSProperties;
}

const Empty: React.FC<EmptyProps> = (props) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', height: '680px', alignItems: 'center'}} >
      <div>
        <img src={props.image} style={props.imageStyle} />
        {props.description}
        {props.children}
      </div>
    </div>
  )
}

export default Empty;