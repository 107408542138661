/* eslint-disable @typescript-eslint/no-redeclare */
/* eslint-disable no-restricted-globals */
import React,{useState,useEffect,}  from 'react';

import { Grid, Row, Col } from 'react-flexbox-grid';

import styles from './index.module.less';

import { simpleFn } from '../../Notification';


import { Link,useHistory } from "react-router-dom";

import { t } from 'i18next';

// api
import { searchtrusteeship,pincodetrusteeship } from '../../../api/public';
// web3
import Web3 from 'web3';

import CryptoJS from "crypto-js";
import { getDecryptKey,getKeccak256,byteLength,getSign,getUtf8ToHex,encrypt,encryptPin } from '../../../assets/js/web3Public';
const SECRET_KEY = CryptoJS.enc.Utf8.parse("f097eae0d2c86d3b");

const web3 = new Web3(Web3.givenProvider);
const ethereumUtil = require('ethereumjs-util')

const Pin=({onChange,error}:any)=>{

    let UserName = sessionStorage.getItem('userName')||'';
    const userInfo = JSON.parse(sessionStorage.getItem('userInfo')||'') ;

    let pinKeyData:any = sessionStorage.getItem('pinKey');
    const [userPIN,changUserPIN] = useState<any>(JSON.parse(pinKeyData)||'');
    const [showTips,setShowTips] = useState<boolean>(false);
    const confirmWalletData= async()=>{

        try{
            if(trusteeship){
                savePinToServe()
            }
            const decryptKey = web3.eth.accounts.decrypt(JSON.parse(userInfo.PrivateKeyCipher), userPIN);
            // console.log("decryptKey=====",decryptKey)
            if(allChecked){
                sessionStorage.setItem('pinKey',JSON.stringify(userPIN))
            }
            onChange(decryptKey,userPIN)
        }catch(err){
            console.log('PIN码错误')
            setShowTips(true)
        }
        if(userPIN.length < 6){
            simpleFn('请填写PIN码，验证身份！','error')
            return false;
        }

        
    }
    
    useEffect(()=>{
        
        getUserIsSavedPin();
        if(pinKeyData){
            changUserPIN(JSON.parse(pinKeyData));
        }
    },[])
    useEffect(()=>{
        if(userPIN && userPIN.length>=6 && pinKeyData){
           
            confirmWalletData();
        }
    },[userPIN])
    const [allChecked, setAllChecked] = useState(false);
    const [checkboxes, setCheckboxes] = useState<any>({
        remember: true
    });
  
    function handleChange(e:any) {
      setCheckboxes({
        ...checkboxes,
        [e.target.id]: e.target.checked
      })
    }
  
    useEffect(() => {
      const result = Object.values(checkboxes).every(v => v);
        
      console.log(result);
      
      
      setAllChecked(result);
    }, [checkboxes]);



    // 查询是否托管加密
    const [trusteeship,setTrusteeship]=useState(true)
    const getUserIsSavedPin=async()=>{
       const res =await searchtrusteeship({userName:UserName})
       if(res.code===0){
        const {data} = res.data
		setTrusteeship(data.trusteeship?false:true)
       }
    }
    const changeRememberPin =(e:any)=>{
        console.log(e)
        setTrusteeship(e.target.checked)
    }
    //托管加密
    //测试加密解密OK
    const encryptPin=(data:string)=>{
        let web3 = new Web3(Web3.givenProvider);
        const msgKeccak256 = web3.utils.keccak256(process.env.COMMON_PIN_KEY).substr(2)
        const SECRET_KEY = CryptoJS.enc.Hex.parse(msgKeccak256);
        const encrypted = CryptoJS.AES.encrypt(data, SECRET_KEY, {
            iv: SECRET_KEY,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        const res = encrypted.ciphertext.toString();
        return res
    }
    const savePinToServe=async()=>{
        let data = {
            userName:UserName,
            pinCode:encryptPin(userPIN)
        }
        const res = await pincodetrusteeship(data)
    }
    return (
        
        <div className={`flex ai-center jc-center ${styles.popUps}`} >
            <div className={`${styles.popBg}`} onClick={() => onChange && onChange(false)}>背景</div>
            <Col xs={10} sm={10} lg={8} xl={6} className={`flex jc-center ai-center fd-column ${styles.popUpsMode}`}>
                <div className={`${styles.popClose}`}  onClick={() => onChange && onChange(false)}>close</div>

                    <h3 className={`${styles.logTitle}`}>确认身份</h3>
                    <Row className={`pt-30 ${styles.logMain}`}>
                        <Col lg={10} lgOffset={1}>
                            <div className={`flex jc-center ai-center font-14 ${styles.warnTips}`}>
                                <img src={require('../../../assets/images/login/tips-icon.png')} alt='' className={`mr-10`} />
                                <span>为了您的账户安全，需要确认身份!</span>
                            </div>
                        </Col>
                        <Col lg={10} lgOffset={1} className={`flex space-16`}>
                           {/* onKeyUp={changPhone}  ${userTips?styles.hasTips:''}*/}
                            <input type='password' placeholder={'请输入PIN码'} value={userPIN}  onChange={e =>{
                                    changUserPIN(e.target.value);
                                    setShowTips(false);
                            }}    className={`${styles.logTex} `}  />
                        </Col>
                        <Col lg={10} lgOffset={1} className={`pt-10 pb-40`}>
                                {showTips ? <div className={`font-14 col-red`}>PIN码错误，请重新输入</div>:
                            <div className={`font-14 col-999`}>6-32位必须包含大小写字母、数字和符号的任意组合</div>}
                        </Col>
                        
                        <Col lg={10} lgOffset={1} className={`pt-40 `}>
                            {/*  onClick={getUserRegister} */}
                            <div className={`mb-15`}>
                                <label className={`col-666 font-14 flex ai-center`}>
                                    <input type={'checkbox'} name="remember"
                                        value={checkboxes.remember}
                                        id="remember"
                                        onChange={handleChange} />
                                    <span className={`ml-5`}>本次登录不再输入PIN码</span>
                                </label>
                            </div>
                            {!trusteeship && <div className={`mb-15`}>
                                <label className={`col-666 font-14 flex ai-center`}>
                                    <input type={'checkbox'} name="rememberPin"
                                        checked={trusteeship}
                                        id="rememberPin"
                                        onChange={changeRememberPin} />
                                    <span className={`ml-5`}>是否托管PIN码到服务器？<Link to={`/help?type=9`} className={`font-14 col-blue`}>《托管协议》</Link></span>
                                </label>
                            </div>}
                            <button className={`btn blue-btn ${styles.logBtn}`} disabled={userPIN.length<6} onClick={()=>confirmWalletData()}>确认</button>
                        </Col>
                        
                    </Row>
            </Col>
        </div>  


        
    )
}
export default Pin;