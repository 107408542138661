
import React,{useState,useEffect}  from 'react';

import { Grid, Row, Col } from 'react-flexbox-grid';

import styles from './index.module.less';

// 切换语言
import { useTranslation } from 'react-i18next';
import LazyLoad from 'react-lazyload';


import { simpleFn } from '../../../../components/Notification';


import wxPay from '../../../../assets/images/explore/wxpay.svg';
import zfbPay from '../../../../assets/images/explore/zfb.svg';
import bankCardPay from '../../../../assets/images/explore/bankCard.svg'


import TimeContent from '../../../../components/TimeContent';


const baseUrl = process.env.BASE_URL+"/ipfs/";

const Exchange =({onChange,itemData}:any)=>{
    console.log("==================itemData",itemData)
    const { t } = useTranslation();
   
    const [amount,changAmount] = useState<any>(1);
 
    
    const changeDaoM = async (e:any)=>{
        console.log('=================',payType)
        
        if(e){
            onChange(e,payType)
        }else{
            onChange(e)
        }

       
    }
    const [payType,setPayType] = useState<number>(1)
    const handleChange = (event:any)=> {
        console.log("================",event.target.value)
        setPayType(event.target.value);
     }
     const formatTimeData=(time:number)=>{
		var date = new Date(time)
            var Y = date.getFullYear() + '-'
            var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
            var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
            var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
            var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
            var s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
            return Y + M + D + h + m + s
	}
    
    return (
        <div className={`flex ai-center jc-center ${styles.popUps}`} >
            {/* onClick={() => onChange && onChange(false)} */}
            <div className={`${styles.popBg}`} >背景</div>
            <Col xs={10} sm={10} lg={8} xl={6} className={`flex jc-center ai-center fd-column ${styles.popUpsMode}`}>
                {/* <div className={`${styles.popClose}`}  onClick={() => onChange && onChange(false)}>close</div> */}
                {(itemData.payStatus===1 || itemData.payStatus===2) ?
                <Row className={`mt-40 ${styles.logTitleMain}`}>
                    <Col lg={12} className={`text-left`}>
                        <h3 className={`font-24`}>确认订单</h3>
                        
                            <div className={`mt-20 font-16`}>
                                <span>订单提交成功,请尽快付款!</span>
                                <span className={`ml-30 col-666`}>提交时间：</span>
                                <span>{formatTimeData(itemData.CreateTime)}</span>
                            </div>
                            <div className={`mt-15 font-16`}>
                                请您在<span className={`col-red text-center ${styles.timeStyle}`} ><TimeContent data={itemData.createTime} onChange={()=>onChange(false)} /></span>内完成支付，逾期视为自愿放弃
                            </div>
                    </Col>
                </Row>:
                <h3 className={`${styles.logTitle}`}>确认订单</h3>
                }
                <Row className={`pt-40 ${styles.logMain}`}>
                   <Col lg={10} lgOffset={1} className={`mb-40`}>
                     



                           {itemData.Products.map((subItem:any,index:any)=>{
                            return (
                                <Row key={subItem.ProductSN}>
                                   <Col md={4}>
                                        <LazyLoad><img src={baseUrl + subItem.Image } alt='' width={120} height={120} /></LazyLoad>
                                    </Col>
                                   <Col md={8} className={`pl-10`}>
                                        <div className={`flex ai-center`}>
                                            <h3 className={`font-18 `}>{subItem.NftName}</h3>
                                        </div>
                                     <div className={`flex ai-center mt-20`}>
                                            <div className={`col-666 font-14 `}>售价</div>
                                            <h3 className={`font-18 ml-15`}>¥ {subItem.Price/100}</h3>
                                        </div>
                                        <div className={`flex ai-center`}>
                                        <div className={`flex ai-center mt-20`}>
                                            <div className={`col-666 font-14`}>数量</div>
                                            <h3 className={`font-18 ml-15`}>{subItem.Num}</h3>
                                        </div>
                                        </div>
                                    </Col>

                                </Row>
                            )
                           })  }
                            <Row>
                            <Col xs={12}>
                                <div className={`flex ai-center mt-40`}>
                                    <div className={`col-666 font-14 `}>支付方式</div>
                                    <div className={`ml-15 space-16 font-14 flex-1`}>
                                        <label className={`flex ai-center`}>
                                            <input type='radio' name='payType' value={1} onClick={handleChange} defaultChecked={payType===1 ? true : false} />
                                            <img src={zfbPay} alt='' className={`ml-5 mr-5 ${styles.payIcon}`} />
                                            支付宝
                                        </label>
                                        {/* <label className={`flex ai-center`}>
                                            <input type='radio' name='payType' value={2} onClick={handleChange} defaultChecked={payType===2 ? true : false}  />
                                            <img src={wxPay} alt='' className={`ml-5 mr-5 ${styles.payIcon}`} />
                                            微信
                                         </label> */}
                                        {/* <label className={`flex ai-center`}>
                                            <input type='radio' name='payType' value={3} onClick={handleChange} defaultChecked={payType===3 ? true : false}  />
                                            <img src={bankCardPay} alt='' className={`ml-5 mr-5 ${styles.payIcon}`} />
                                            银行卡
                                        </label> */}
                                    </div>
                                </div>


                               <div className={`flex ai-center mt-20`}>
                                    <div className={`col-666 font-14 `}>总计</div>
                                    <div className={`ml-15 flex flex-1`}><span className={`col-333 font-16 mr-5`}>¥</span> <h3 className={`font-18 col-blue`}>{itemData.TotalPrice/100}</h3></div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={10} lgOffset={1} className={`pt-40 pb-40 ${styles.modeBtnTop}`}>
                        {/* disabled={amount*unitPrice > modeData.balance} */}

                        <div className={`col-red font-12 pb-15 pl-15`}>注意：您24小时内只能取消5次订单，超出次数将限购24小时。</div>
                        <Row>
                            <Col md={4}>
                                <button className={`${styles.medalBtn} ${styles.medalCancelBtn}`}  onClick={()=>changeDaoM('cancel')}>取消订单</button>
                            </Col>
                            <Col md={8}>
                                <button className={`${styles.medalBtn}`}  onClick={()=>changeDaoM('pay')}>立即支付</button>
                            </Col>
                        </Row>
                        

                        <Row className={` mt-25 ${styles.warnTips}`}>
                            <Col xs={12} className={`flex ai-center font-14`}><img src={require('../../../../assets/images/login/tips-icon.png')} alt='' className={`mr-10`} />购买须知</Col>
                            <Col xs={12} className={`mt-10 font-12 flex-1`}>数字作品为虚拟数字商品，而非实物，一经售出，不支持退换。本商品仅限实名认证为年满18周岁的中国大陆用户购买。数字作品的版权由发行方或原创者拥有，除另行取得版权拥有者书面同意外，用户不得将数字作品用于任何商业用途。请勿对数字作品进行炒作、场外交易、欺诈，或以任何其他非法方式进行使用。</Col>
                        </Row>
                    </Col>
                </Row> 
               
            </Col>
            
        </div>
    )
}
export default Exchange;