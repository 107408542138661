import Web3 from 'web3';
import CryptoJS from "crypto-js";
// // abi文件

// import Config from "../../abi/Config.json";
// import ERC1155Votes from "../../abi/ERC1155Votes.json";
// import VpRightScore from "../../abi/RightScore.json";
// import VpUserAct from "../../abi/UserAct.json";
// import VpUseRScore from "../../abi/UseRScore.json";

// 配置文件
// import config from '../../config/index.js';
// import { getContractAddr } from '../../api/common.js';

// 默认配置获取
// var BatchCfgContInfos = uni.getStorageSync('batchCfgContInfos');



//获取合约地址--获取后默认存储
/* 
name为：
 传入：{name，Distid}；
 输出：{Distid，合约地址}
 输出同时默认记录到本地srorage
 config 2051/51
 
 */

 const SECRET_KEY = CryptoJS.enc.Utf8.parse("f097eae0d2c86d3b");
 //获取字节长度
 const byteLength = (str: string) =>{
	 var length = 0;
	 str.split('').map(function(char: string){
		 if(char.charCodeAt(0)>255) {//字符编码大于255，说明是双字节字符
			 length += 2;
		 }else {
			 length++;
		 }
	 });
	 return length;
 }

/**
 * 加密方法
 * @param data
 * @returns {string}
 */
const encrypt = (data: string)=> {
	if (typeof data === "object") {
		try {
			// eslint-disable-next-line no-param-reassign
			data = JSON.stringify(data);
		} catch (error) {
			console.log("encrypt error:", error);
		}
	}
	const dataHex = CryptoJS.enc.Utf8.parse(data);
	const encrypted = CryptoJS.AES.encrypt(dataHex, SECRET_KEY, {
		iv: SECRET_KEY,
		mode: CryptoJS.mode.CBC,
		padding: CryptoJS.pad.Pkcs7
	});
	return encrypted.ciphertext.toString();
}
const getContractAddress = (obj: { id: any; name: any; })=>{
	// return new Promise((resolve, reject) => {
	// 	getContractAddr({destID:obj.id}).then((res: { data: { contractAddress: any; }; })=>{
	// 		const {contractAddress} = res.data;
	// 		const data ={
	// 			desId:obj.id,
	// 			contractAddr:contractAddress
	// 		}
	// 		uni.setStorageSync(obj.name,data)
	// 		resolve(data)
	// 	})
	// })
}
const getBatchCfgContInfo = (desId: any)=>{
	// let infoData = JSON.parse(BatchCfgContInfos).filter((item: { cId: any; })=>item.cId===desId)[0];
	// return infoData;
}

// 查询获取数据
/* 
输入：{userId,optionId,desId,contractAddr}
	optionId:666,777,888
	contractAddr:区块链地址
输出：链返回数据
 */
const getDesignContractData = (data: { desId: any; userId: any; optionId: any; })=>{
	// const web3 = new Web3(config.ETH_URL)
	// var type,
	// 	desId=data.desId,
	// 	userId = data.userId,
	// 	optionId = data.optionId;
	// let infoData = getBatchCfgContInfo(desId);
	// return new Promise((resolve, reject) => {
	// 	switch (desId){
	// 		//61,62,67
	// 		case config.NFT_CONTRACT_DESTID:
	// 		case config.LOOTBOX_CONTRACT_DESTID:
	// 		case config.STARBEAN_CONTRACT_DESTID:
	// 			type = ERC1155Votes;
	// 			break;
	// 		//70	
	// 		case config.USER_CONTRACT_DESTID:
	// 			type = VpUserAct
	// 			break;
	// 		//609
	// 		case config.RIGHT_SCORE_DESTID:
	// 			type = VpRightScore
	// 			break
	// 		case config.USER_SCORE_DESTID:
	// 			type = VpUseRScore
	// 			break		
	// 		default:
	// 			type = Config
	// 			break;
	// 	}
	// 	let myContract = new web3.eth.Contract(type,infoData.contAddr)
	// 	myContract.methods.balanceOf(userId,optionId).call().then((res: unknown)=>{
	// 		console.log("输出=======123",res)
	// 		resolve(res)
	// 	})
		
	// })
}

/* 
* 密钥解密 私钥
*/
const getDecryptKey = (data: { key: string; pin: any; })=>{
	const web3 = new Web3(Web3.givenProvider);
	const decryptRes = web3.eth.accounts.decrypt(JSON.parse(data.key), data.pin);
	
	return decryptRes;
}
/* 
*keccak256 
*/
const getKeccak256 = (data: any)=>{
	const web3 = new Web3(Web3.givenProvider);
	const keccak256Res = web3.utils.keccak256(data);
	return keccak256Res;
}
/* 
*sign 
*/
const getSign = (data: any,key: any)=>{
	const web3 = new Web3(Web3.givenProvider);
	const signRes = web3.eth.accounts.sign(data,key);
	return signRes;
}
/* 
* utf8ToHex
*/
const getUtf8ToHex = (data: any)=>{
	const web3 = new Web3(Web3.givenProvider);
	const utf8ToHexRes = web3.utils.utf8ToHex(data);
	return utf8ToHexRes;
}
/* 
创建 
 */
const getCreateWeb3 = ()=>{
	
	const web3 = new Web3(Web3.givenProvider);
	const wallet = web3.eth.accounts.create();
	return wallet;
}
/* 
加密 
*/ 
const getEncode = (pin:any)=>{
	let web3 = new Web3(Web3.givenProvider);
	const msgKeccak256 = getKeccak256(process.env.COMMON_PIN_KEY).substr(2)
	const SECRET_KEY = CryptoJS.enc.Hex.parse(msgKeccak256);
	const encrypted = CryptoJS.AES.encrypt(pin, SECRET_KEY, {
		iv: SECRET_KEY,
		mode: CryptoJS.mode.CBC,
		padding: CryptoJS.pad.Pkcs7
	});
	const res = encrypted.ciphertext.toString();
	return res
}

/* 
获取配置 
 */
const getConfig = ()=>{
	// const web3 = new Web3(config.ETH_URL)
   // Vpeth_test_Config_CONT_ID   = "2051"
   //  Vpeth_test_Config_CONT_ADDR = "0x47c9AD1dd96C148E2370870a5a376F4d5953e6FB"
	// let myContract = new web3.eth.Contract(Config, "0x47c9AD1dd96C148E2370870a5a376F4d5953e6FB");
	// return new Promise((resolve, reject) => {
	// 	myContract.methods.getBatchCfgContInfos(true,0,200).call().then((res: unknown)=>{
	// 		BatchCfgContInfos = res;
	// 		uni.setStorageSync('batchCfgContInfos',res)
	// 		resolve(res)
	// 	})
	// })
	
}

/**
 * 需要拿到对应的合约时使用  
 * web3scoket = new Web3(config.ETH_URL)
 * rightScoreContract = new web3scoket.eth.Contract(rightScoreAbi, newValue)
 * @destid 合约对应的destid
 * @contractAddr 对应的合约地址
 */
const initContract = (destid: any,contractAddr: any)=>{
	// const web3 = new Web3(config.ETH_URL)
	// let abi
	// switch (destid){
	// 	case config.USER_CONTRACT_DESTID:
	// 		abi = VpUserAct
	// 		break;
	// 	case config.STARBEAN_CONTRACT_DESTID:
	// 		abi = ERC1155Votes
	// 		break;
	// 	case config.RIGHT_SCORE_DESTID:
	// 		abi = VpRightScore
	// 		break;	
	// 	default:
	// 		break;
	// }
	// return new web3.eth.Contract(abi, contractAddr)
}

/**
 * 加密PIN码 托管PIN码时用到
 */
const encryptPin=(data:any)=>{
	let web3 = new Web3(Web3.givenProvider);
	const msgKeccak256 = web3.utils.keccak256(process.env.COMMON_PIN_KEY).substr(2)
	const SECRET_KEY = CryptoJS.enc.Hex.parse(msgKeccak256);
	const encrypted = CryptoJS.AES.encrypt(data, SECRET_KEY, {
		iv: SECRET_KEY,
		mode: CryptoJS.mode.CBC,
		padding: CryptoJS.pad.Pkcs7
	});
	const res = encrypted.ciphertext.toString();
	return res
}

const isWeb3Addr = (addr: any)=>{
	let web3 = new Web3(Web3.givenProvider);
	// const isAddr = web3.utils.isAddress(addr)
	// return isAddr
}

export  {
	byteLength,
	getContractAddress,
	getDecryptKey,
	getKeccak256,
	getSign,
	getUtf8ToHex,
	getCreateWeb3,
	getEncode,
	getConfig,
	getDesignContractData,
	getBatchCfgContInfo,
	initContract,
	encryptPin,
	isWeb3Addr,
	encrypt,
}