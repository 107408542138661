import React, { useEffect, useRef, useState } from 'react';

import './index.css';
import styles from "./index.module.less";
import Banner from '../../assets/images/about/banner.png';
import { Grid, Row, Col } from 'react-flexbox-grid';
// import { Grid, Row, Col } from 'react-flexbox-grid';

// api
import { helpList,noticeList } from '../../api/public'

import { Link,useHistory,useLocation } from 'react-router-dom';

import Qs from 'qs';

import Collapse, { Panel } from 'rc-collapse';

// 主题
const themePrimaryColor = 'theme';
const primaryColor =  localStorage.getItem(themePrimaryColor)||'light';

const About = (props: any)=>{
    const history = useHistory();
    // 1 ,必读指南 // 2 ，帮助中心 // 3 联系客服 // 4 用户协议 // 5 隐私政策 // 6 关于我们 // 7 当前版本
    const urlParams = props.location.search.slice(1);
	const params = Qs.parse(urlParams);
	const [queryData, changQueryData] = useState<any>(params)
    console.log(queryData.type)
    useEffect(()=>{
        if(queryData.type==='99'){
            getNoticeList();
        }else{
            getCentent();
        }
    },[urlParams])
    const [contentHtml,setCententHtml] = useState<any>('')
    const getCentent= async ()=>{
        const res = await helpList({...queryData})
        if(res.code===0){
            setCententHtml(res.data)
            // window.location.reload();
        }
    }
    const getNoticeList = async ()=>{
        const res = await noticeList({});
        if(res.code===0){
            setCententHtml(res.data)
            // window.location.reload();
        }
    }
    const goUrl = (e:string)=>{
        changQueryData({type:e})
        history.push('/help?type='+e)
    }
    const formatTimeData=(time:number)=>{
		var date = new Date(time)
        var Y = date.getFullYear() + '-'
        var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
        var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
        var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
        var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
        var s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
        return Y + M + D + h + m + s
	}
    return (
        <>
        {/* <Row>
            
                
        </Row>  */}
        <Col xs={12} lg={10} lgOffset={1}>
            {/* <div className={`${styles.bannerWrap}`}>
                <img src={Banner} alt="" className={`${styles.img}`}/>
            </div> */}
            <div className={`bg${primaryColor} flex mt-30 mb-50 ${styles.boxMain}`}>  
                <Col xs={2} className={`${styles.boxMainLeft}`}>
                    <ul className={`mt-20 ${styles.leftNav}`}>
                        <li className={`${queryData.type==='1'?styles.cur:''}`} onClick={()=>goUrl('1')}>必读指南</li>
                        <li className={`${queryData.type==='2'?styles.cur:''}`} onClick={()=>goUrl('2')}>帮助中心</li>
                        <li className={`${queryData.type==='3'?styles.cur:''}`} onClick={()=>goUrl('3')}>联系客服</li>
                        <li className={`${queryData.type==='4'?styles.cur:''}`} onClick={()=>goUrl('4')}>用户协议</li>
                        <li className={`${queryData.type==='5'?styles.cur:''}`} onClick={()=>goUrl('5')}>隐私政策</li>
                        <li className={`${queryData.type==='9'?styles.cur:''}`} onClick={()=>goUrl('9')}>托管协议</li>
                        <li className={`${queryData.type==='8'?styles.cur:''}`} onClick={()=>goUrl('8')}>版本说明</li>
                        <li className={`${queryData.type==='99'?styles.cur:''}`} onClick={()=>goUrl('99')}>平台公告</li>
                        <li className={`${queryData.type==='6'?styles.cur:''}`} onClick={()=>goUrl('6')}>关于我们</li>
                    </ul>

                </Col>
                <Col xs={10}>

                    <div className={`${styles.content}`} >

                        <div className={`mt-10 ${styles.title}`}>
                            {queryData.type==='1' && '必读指南'}
                            {queryData.type==='2' && '帮助中心'}
                            {queryData.type==='3' && '联系客服'}
                            {queryData.type==='4' && '用户协议'}
                            {queryData.type==='5' && '隐私政策'}
                            {queryData.type==='6' && '关于我们'}
                            {queryData.type==='8' && '版本说明'}
                            {queryData.type==='99' && '平台公告'}
                        </div>
                        {queryData.type==='99'?
                            <div className={`mt-30`}>
                                {/* {contentHtml.Items && contentHtml.Items.map((item:any,index:number)=>{
                                    return (
                                        <div className={`mt-30`} key={index}>
                                            <h3 className={`lh-36`}>{item.Title}</h3>
                                            <p  className={`mt-10 col-999 font-14`}>{formatTimeData(item.CreateTime)}</p>
                                            <p className={`mt-20 font-14 lh-32`} dangerouslySetInnerHTML={{__html: item.Content}}></p>
                                        </div>
                                    )
                                })} */}
                                <Collapse className={`${styles.collapseMian}`} accordion={false}>
                                    {contentHtml.Items && contentHtml.Items.map((item:any,index:number)=>{
                                        return (
                                        <Panel 
                                            header={<h3 className={`font-18`}>{item.Title}</h3>}
                                            extra={<span className={`font-14 col-999`}>{formatTimeData(item.CreateTime)}</span>}
                                            key={index}
                                        >
                                            <div className={`${styles.collapseContent} font-14 lh-32`} dangerouslySetInnerHTML={{__html: item.Content}}></div>
                                        </Panel>

                                        )
                                    })}
                                </Collapse>
                            </div>
                            :queryData.type==='3'?
                            <div>
                                <p className={`mt-20 font-14 lh-32`}>客服微信：{contentHtml.WXCode}</p>
                                <p className={`font-14 lh-32`}>客服电话：{contentHtml.ServiceTel}</p>
                            </div>
                            :
                            <div dangerouslySetInnerHTML={{__html: contentHtml.Content}} className={`lh-32 mt-30`}></div>
                        }
                        {/* {contentHtml} */}
                    </div>
                </Col>                        
            </div>  

        </Col>
        </>
    )
}
export default About;