import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { getUserBillStats, getUserBill, unClaimedList,receivePoint,receiveDpointotal,exchangeDao,queryOperStatus,contractInfo } from '../../api/public';

import { useTranslation } from 'react-i18next';
import QueueAnim from 'rc-queue-anim';

import FormatMsgTime from "../../components/FormatMsgTime"

import Exchange from "./Exchange";
import ExAway from "./ExAway";

import ShowLoadding from '../../components/ShowLoadding';

import Pin from '../../components/User/Pin';

import ReadMore from '../Profile/ReadMore';
import  styles from "./index.module.less";

import Empty from '../../components/Empty';
//获取abi
import abi from './abi.json';
import VpUserActAbi from './VpUserAct.json'

// web3
import Web3 from 'web3';


console.log("processprocessprocess===============",process.env)


const starDataHash=process.env.STAR_DATA_HASH;
const starDesId = process.env.USER_DES_ID;

const abiUrl =process.env.ABI_URL;
let abiKeysession:any = sessionStorage.getItem(starDesId || '')
const abiKey = abiKeysession ? JSON.parse(abiKeysession).contractAddress:'';
let userAbiKeysession:any = sessionStorage.getItem(process.env.DES_ID || '')
const userAbiKey =userAbiKeysession?JSON.parse(userAbiKeysession).contractAddress:'';

const web3 = new Web3(abiUrl);

const myContract = new web3.eth.Contract(abi, abiKey); 
const myUserContract = new web3.eth.Contract(VpUserActAbi, userAbiKey); 

// 主题
const themePrimaryColor = 'theme';
const primaryColor =  localStorage.getItem(themePrimaryColor)||'light';

const TableDataCentenr = (props:any)=>{
    return (
        <QueueAnim  delay={100} animConfig={[
          { opacity: [1 ,0], translateX: [0, 200] },
          { opacity: [1, 0], translateX: [0, -200] }
        ]}>
          {props.data.length>0?props.data.map((item:any,index:number)=>{
              return (
                // middle="xs"
                <div  className={`flex ai-center border-box pl-30 pr-30 bg${primaryColor} ${styles.tableCentent}`} key={item.id}>
                    
                    <Col xs={9}>
                        <h3 className={`font-14`}>{item.desc}</h3>
                        <p className={`col-9f mt-10 font-12`}><FormatMsgTime data={item.createTime} /></p>
                    </Col>
                    <Col xs>
                        <h3 className={`${item.points>0?'col-red':'col-green'} font-24 text-right`}>{item.points>0?'+':'-'}{item.points}</h3>
                    </Col>
                </div>
                  // <div>{item.id}</div>
              )
          }):null}  
        </QueueAnim>
    )
    
}
const Bill = () => {
  const { t } = useTranslation();



  


    // 顶部 Tab List
  const topTypeList = [
    {
      id: 1,
      title: '星豆概览',
    },
    {
      id: 2,
      title: "星豆兑换",
    },
    // {
    //   id: 3,
    //   title: "星豆概览"
    // }
  ]

  const [topCurrentID, setTopCurrentID] = useState(1);




      const userName:any = sessionStorage.getItem('userName');

      const getUserInfo:any = sessionStorage.getItem('userInfo');
      let userDataObj = JSON.parse(getUserInfo);

      
      const [integral,setIntegral] = useState<number>(0);

      const [goldMedal,setGoldMedal] = useState<number>(0);
      const [silverMedal,setSilverMedal] = useState<number>(0);
      const [bronzeMedal,setBronzeMedal] = useState<number>(0);
      // 查积分倍数
      const [multiple,setMultiple] = useState<number>(0);
      // 未领取积分
      const [unReceive,setUnReceive] = useState<number>(0);

      const [unGoldMedal,setunGoldMedal] = useState<any>('0');
      const [unSilverMedal,setunSilverMedal] = useState<any>('0');
      const [unBronzeMedal,setunBronzeMedal] = useState<any>('0');



      const getToDayReceive =async () => {
        const res = await receiveDpointotal({});
        console.log('查平台今日已领取积分=================',res)
        let totalReceive = (100000000 - res.data.totalPoint * multiple)>0?(100000000 - res.data.totalPoint*multiple):0
        setUnReceive(totalReceive);
      }
      useLayoutEffect(()=>{
        getToDayReceive()
      },[unReceive])


      const getContract = ()=>{
        myContract.methods.balanceOf(userName,666).call().then((res:any)=>{
          // console.log('积分============',res)
          setIntegral(res);
        })
        myContract.methods.balanceOf(userName,888).call().then((res:any)=>{
          // console.log('金牌============',res)
          setGoldMedal(res)
        })
        myContract.methods.balanceOf(userName,889).call().then((res:any)=>{
          // console.log('银牌============',res)
          setSilverMedal(res)
        })
        myContract.methods.balanceOf(userName,890).call().then((res:any)=>{
          // console.log('铜牌============',res)
          setBronzeMedal(res)
        })
        
        // myContract.methods.getPointSpeedUp().call().then((res:any)=>{
        //   // console.log('倍数============',res)
        //   setMultiple(res)
        // })

        myContract.methods.totalSupply(888).call().then((res:any)=>{
          let totalReceive = (10000 - res)>0?(10000 - res):0
          setunGoldMedal(totalReceive+'');
        })
        myContract.methods.totalSupply(889).call().then((res:any)=>{
          let totalReceive = (1000000 - res)>0?(1000000 - res):0
          setunSilverMedal(totalReceive+'');
        })
        myContract.methods.totalSupply(890).call().then((res:any)=>{
          let totalReceive = (100000000 - res)>0?(100000000 - res):0
          setunBronzeMedal(totalReceive+'');
        })

      }
      const [billStats,setBillStats] = useState<any>({
        balance:0,
        earn:0
      });
      const UserBillStats= async ()=>{

        const res = await getUserBillStats({userName:userName});

        if(res.code===0){
            console.log(res);
            setBillStats(res.data)
        }else{

        }
      }
      const [percent,setPercent] =useState<number>(0.00);
      const [percentLine,setPercentLine] =useState<any>([0,0]);
      const [levelPercent,setLevelPercent] = useState<number>(0)
      useLayoutEffect(()=>{
        let total = goldMedal*500000+silverMedal*5000+bronzeMedal*50+integral
        let level = Math.floor(Math.sqrt(total/100))
        let delta1 = 100 * Math.pow(level+1,2)-100*Math.pow(level,2)
        let delta2:number = total-100 * Math.pow(level,2)
        let percentData = parseFloat(((delta2/delta1)*100).toFixed(2)) 
        setPercent(percentData)
        setLevelPercent(level)

      },[integral,goldMedal,silverMedal,bronzeMedal])
      const [billList,setBillList] = useState<any[]>([]);
      const UserBill = async ()=>{

        const res = await getUserBill({userName:userName});

        if(res.code===0){
            console.log(res);
            setBillList(res.data.items)
        }else{

        }
      }
      useEffect(()=>{
        UserBill()
      },[])
      const [receiveBillList,setReceiveBillList] = useState<any[]>([])
      const getUserUnClaimedList = async ()=>{

        const res = await unClaimedList({userName:userName});

        if(res.code===0){
            console.log("未领取积分列表",res);
           
            setReceiveBillList(res.data.items)
        }
      }
      const receivetFn = async()=>{
        if(bCanClaim){
          setShowPin(true)
        }
        
      }
      const receivePointFn = async (e:any)=>{
        // const decryptKey = web3.eth.accounts.decrypt(JSON.parse(userDataObj.privateKey), e);
        
        const decryptKey = e;
        const timestamp = new Date().getTime().toString();
        const datahash=starDataHash;//"QmWHU9wQzywACFyNKRTsv6iT9zA3AazMoQBT6dEe6nMp6v";
        const destId=starDesId;//'2067';
        const temp = userName.concat(destId, datahash, 666, pointBase,timestamp);
        const msgKeccak256 = web3.utils.keccak256("\u0019Ethereum Signed Message:\n"+ new Blob([temp]).size + temp);
        const sign =  web3.eth.accounts.sign(web3.utils.utf8ToHex('\x00'+temp),decryptKey.privateKey);
        let data={
          userName:userName,
          destID:destId,
          point: pointBase,
          timeStamp:timestamp,
          msgKeccak256:msgKeccak256.substr(2),
          sig:sign.signature.substr(2)
        }
        const res = await receivePoint(data)
        if(res.code===0){
          console.log("领取积分成功",res);
          let operObjList=new Array()
          operObjList[0]=res.data.outData
          setOperObj(operObjList);
          QueryOperStatus(operObjList);
          setShowLoadding(true);

          let actsArr = userActs;
              actsArr[2]=[];
          setUserActs(actsArr);
          setLeftPoint(leftPoint * 1 - (pointBase * 1 * pointSpeedUp*1));
          setLeftClaimTime(leftClaimTime * 1 - 1);
          setBCanClaim(false);
        }
      }
      const [daoType,setDaoType]=useState<string>('888')
      const changeDao = async(type:string)=>{
        console.log('type========================',type)
        setDaoType(type)
        setShowExchange(true)
      }
      

      useLayoutEffect(()=>{
        getContract();
        getUserContract();
        getUserUnClaimedList();
      },[abiKey,userAbiKey])
      const tipsList =[
        {
          title:'更低的藏品交易费'
        },
        {
          title:'更大的星豆兑换折扣'
        },
        {
          title:'提升藏品空投概率'
        },
        {
          title:'获得等级达人成就'
        },
        {
          title:'获得社区更多的投票权'
        },
        {
          title:'未来期待更多特权'
        }
      ]
      const [showExchange,setShowExchange] = useState<boolean>(false);
      const [showPin,setShowPin] = useState<boolean>(false);
      const [changeDaoMedal,setChangeDaoMedal] = useState(false)
      const [buyAmount,setBuyAmount] = useState<number>(1);
      const changeEX =(e:any)=>{
        if(e){
          setShowPin(true);
          setChangeDaoMedal(true);
        }
        setShowExchange(false);
      }
      const changePin=(e:any)=>{
       if(e && !changeDaoMedal){
        // changeDaoPop(e)
        setShowPin(false)
        receivePointFn(e)
       }else if(e && changeDaoMedal){
        changeDaoPop(e)
        // setShowPin(false)
       }else{
        setShowPin(e)
       }
        
      }
      const changeDaoPop = async (e:any)=>{
        let daoHash = daoType==='888'?process.env.GOLD_MEDAL_DATA_HASH:daoType==='889'?process.env.SILVER_MEDAL_DATA_HASH:process.env.BRONZE_MEDAL_DATA_HASH;
        let destIDType=process.env.USER_DES_ID;
        // const decryptKey = web3.eth.accounts.decrypt(JSON.parse(userDataObj.privateKey), e);
        const timestamp = new Date().getTime().toString();
        const temp = userName.concat(destIDType,daoHash,daoType,buyAmount,timestamp);
        const msgKeccak256 = web3.utils.keccak256("\u0019Ethereum Signed Message:\n"+ new Blob([temp]).size + temp);
        const sign =  web3.eth.accounts.sign(web3.utils.utf8ToHex('\x00'+temp),e.privateKey);
        let data={
          userName:userName,
          destID:destIDType,
          amount:buyAmount,
          tokenID:daoType,
          dataHash:daoHash,
          timeStamp:timestamp,
          msgKeccak256:msgKeccak256.substr(2),
          sig:sign.signature.substr(2)
        }
        const res = await exchangeDao(data);
        
        if(res.code===0){
          setShowPin(false);
          setChangeDaoMedal(false);
          let operObjList=new Array()
          operObjList[0]=res.data.outData
          setOperObj(operObjList);
          QueryOperStatus(operObjList)
          setShowLoadding(true)
        }
      }
      const countDiscoun=(num:number)=>{
        const countIntegral = ( (goldMedal * 500000) + (silverMedal*5000) + (bronzeMedal*50) ) + integral*1 - num;
        if(200000<=countIntegral && countIntegral<400000){
          return 9;
        }else if(400000<=countIntegral && countIntegral<600000){
          return 8;
        }else if(600000<=countIntegral && countIntegral<800000){
          return 7;
        }else if(800000<=countIntegral && countIntegral<1000000){
          return 6;
        }else if(1000000<=countIntegral){
          return 5;
        }
      }
      const ExchangeMain =()=>{
        return (<div className={`${styles.curentMain}`}>
        <div className={`flex ai-center ${styles.daoTips}`}>
          <div className={`flex-1  col-fff font-14 lh-24 pr-20 `}>道牌是一种基于社区驱动发行的数字作品，拥有道牌可以参与提案的投票，并可以叠加到星豆中让用户拥有更高的用户等级，从而获得更高的等级权限；也可以叠加到星豆获得星豆兑换的折扣和交易手续费的折扣。</div>
          <div className={`font-30 col-fff`}>道牌 DAO Medal</div>
        </div>

        <Row className={`mt-30`}>
          <Col md={4} className={`relative ${styles.daoMedalMain}`}>
            <div className={`${styles.daoMedal} ${styles.daoGMedal}`}>
                <p className={`font-18 col-fff mt-25 text-center`}>金牌</p>
                <div className={`flex jc-center`}>
                  <img src={require('../../assets/images/bill/convert/G.png')} width={106} className={`mt-15`} />
                </div>
                <div className={`flex jc-center mt-30`}>
                    <div className={`text-center flex-1 ${styles.tipsbox}`}>
                      <p className={`font-14 col-666`}>发行总量</p>
                      <p className={`font-24 col-blue mt-20`}>10000</p>
                    </div>
                    <div className={`text-center flex-1 ${styles.tipsbox}`}>
                      <p className={`font-14 col-666`}>需星豆/枚</p>
                      <p className={`font-24 col-blue mt-20`}>100万</p>
                    </div>
                    <div className={`text-center flex-1 ${styles.tipsbox}`}>
                      <p className={`font-14 col-666`}>投票权重</p>
                      <p className={`font-24 col-blue mt-20`}>x10000</p>
                    </div>
                </div>
                
                <div className={`text-center mt-50 pb-30`}>
                  <p className={`font-14 col-666 mb-10`}>（剩余{unGoldMedal.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}）</p>
                  <button className={`${styles.medalBtn} ${integral<1000000?styles.noMedalBtn:''}`} disabled={integral<1000000} onClick={()=>{
                      changeDao('888')
                    }}>
                      立即兑换
                    {countDiscoun(1000000) && integral>1000000 &&  <div className={`flex fd-column ai-conter jc-center ${styles.medalBtnDiscount}`}>
                        <p className={`font-16`}>享受</p>
                        <p className={`font-22 pt-5`}>{countDiscoun(1000000)} 折</p>
                      </div>}
                    </button>
                </div>
            </div>
          </Col>
          <Col md={4} className={`relative ${styles.daoMedalMain}`}>
            <div className={`${styles.daoMedal} ${styles.daoSMedal}`}>
                <p className={`font-18 col-fff mt-25 text-center`}>银牌</p>
                <div className={`flex jc-center`}>
                    
                  <img src={require('../../assets/images/bill/convert/S.png')} width={106} className={`mt-15`} alt='' />
                </div>
                <div className={`flex jc-center mt-30`}>
                    <div className={`text-center flex-1 ${styles.tipsbox}`}>
                      <p className={`font-14 col-666`}>发行总量</p>
                      <p className={`font-24 col-blue mt-20`}>100万</p>
                    </div>
                    <div className={`text-center flex-1 ${styles.tipsbox}`}>
                      <p className={`font-14 col-666`}>需星豆/枚</p>
                      <p className={`font-24 col-blue mt-20`}>10,000</p>
                    </div>
                    <div className={`text-center flex-1 ${styles.tipsbox}`}>
                      <p className={`font-14 col-666`}>投票权重</p>
                      <p className={`font-24 col-blue mt-20`}>x100</p>
                    </div>
                </div>
                <div className={`text-center mt-50 pb-30`}>
                  <p className={`font-14 col-666 mb-10`}>（剩余{unSilverMedal.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}）</p>
                    <button className={`${styles.medalBtn}  ${integral<10000?styles.noMedalBtn:''}`} disabled={integral<10000} onClick={()=>{
                      changeDao('889')
                    }}>立即兑换
                      {countDiscoun(10000) && <div className={`flex fd-column ai-conter jc-center ${styles.medalBtnDiscount}`}>
                        <p className={`font-16`}>享受</p>
                        <p className={`font-22 pt-5`}>{countDiscoun(10000)} 折</p>
                      </div>}
                    </button>
                </div>
            </div>
          </Col>
          <Col md={4} className={`relative ${styles.daoMedalMain}`}>
            <div className={`${styles.daoMedal} ${styles.daoCMedal}`}>
                <p className={`font-18 col-fff mt-25 text-center`}>铜牌</p>
                <div className={`flex jc-center`}>
                  <img src={require('../../assets/images/bill/convert/C.png')} width={106} className={`mt-15`} />
                  {/* <img src={require('../../assets/icon/dao/c.png')} className={`mt-15`} width={'160px'} /> */}
                </div>
                <div className={`flex jc-center mt-30`}>
                    <div className={`text-center flex-1 ${styles.tipsbox}`}>
                      <p className={`font-14 col-666`}>发行总量</p>
                      <p className={`font-24 col-blue mt-20`}>1亿</p>
                    </div>
                    <div className={`text-center flex-1 ${styles.tipsbox}`}>
                      <p className={`font-14 col-666`}>需星豆/枚</p>
                      <p className={`font-24 col-blue mt-20`}>100</p>
                    </div>
                    <div className={`text-center flex-1 ${styles.tipsbox}`}>
                      <p className={`font-14 col-666`}>投票权重</p>
                      <p className={`font-24 col-blue mt-20`}>x1</p>
                    </div>
                </div>
                <div className={`text-center mt-50 pb-30`}>
                  <p className={`font-14 col-666 mb-10`}>（剩余{unBronzeMedal.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}）</p>
                    <button className={`${styles.medalBtn} ${integral<100?styles.noMedalBtn:''}`}  disabled={integral<100} onClick={()=>{
                      changeDao('890')
                    }}>立即兑换
                      {countDiscoun(100) && <div className={`flex fd-column ai-conter jc-center ${styles.medalBtnDiscount}`}>
                        <p className={`font-16`}>享受</p>
                        <p className={`font-22 pt-5`}>{countDiscoun(100)} 折</p>
                      </div>}
                    </button>
                </div>
            </div>

          </Col>
        </Row>

    </div>)
  }



  
  // const [isShow, setIsShow] = useState(false)
  const [showLoadding, setShowLoadding] = useState(false)    
  const [operObj, setOperObj] = useState<any>([]);
  const handleShowLoadding = () => {
    setShowLoadding(!showLoadding);
    // if (!showLoadding) {
    //   document.body.style.overflow = 'hidden';
    // } else {
    //   document.body.style.overflow = 'auto';
    // }
  }
  const [polling, setPolling] = useState(false);
  const [loadingType, setLoadingType] = useState(1);
  // let intervalHandle = useRef<any>();
  let intervalHandle:any = null;

  const QueryOperStatus = async (val: any) => {
    let DataOperUUID: any = val.map((item:any)=>item.DataOperUUID);
   
    console.log(DataOperUUID)
   
    
    intervalHandle = setInterval(async () => {
      const res = await queryOperStatus({ "operUUID": DataOperUUID.join(',') });
      let temp = true;
      for (let i = 0; i < res.data.items.length; i++) {
        if (res.data.items[i].operStatus === 2) {
          clearInterval(intervalHandle);
          getContract();
          temp = false;
          setTimeout(()=>{
            setShowLoadding(false);
          },2000)
        } else {
          temp = true;
        }
      }
      setLoadingType(res.data.items[0].operStatus);
      setPolling(temp);
    }, 3000);

  }

  useEffect(() => {

    if (polling) {
      QueryOperStatus(operObj);
    } else {

      clearInterval(intervalHandle);
      setTimeout(()=>{
        setLoadingType(1);
        // setShowLoadding(false);
      },1000)
      
    }
  }, [polling]);




  
  const [dataState,setDataState]  = useState<any>({
    day: 0,
    hour: 0,
    minute: 0,
    second: 0
  })
  let timer:any = null;
  const countFun = () => {
      timer = setInterval(() => {
        Minutes()
      }, 1000);
  }
  
  const changeTime = (number:any) =>{
    let hour = Math.floor(number/1000/60/60)
    let minute = Math.floor((number - hour*60*60*1000)/1000/60)
    let second = Math.floor((number - hour*60*60*1000 - minute*60*1000)/1000)
    // return a+"时"+b+"分"+c+"秒"
    setDataState({
      hour: hour < 10 ? "0" + hour : hour,
      minute: minute < 10 ? "0" + minute : minute,
      second: second < 10 ? "0" + second : second
    })
  }
  const Minutes = () => {
    let remainTime = (+new Date((new Date().toLocaleString().split(' ')[0]) + ' 00:00:00')) + 864000000000 - new Date().getTime()
    changeTime(remainTime)
  };
  // useEffect(()=>{
  //   countFun()
  // },[])

  const [actInfo,setActInfo] =useState<any>([])
  const [userActs,setUserActs] = useState<any>()
  const getUserContract = ()=>{
    // console.log("===============getUserContract=================",myUserContract.methods.getBatchActInfo())
    myUserContract.methods.getBatchActInfo().call().then((res:any)=>{
      console.log('getBatchAchInfo============',res[0])
      setActInfo([...res[0]])
    })
  }
  let actInfoData:any = sessionStorage.getItem('userActInfo')
  const [userActInfo,setUserActInfo] =useState(JSON.parse(actInfoData) || []);


  // 获取合约行为List
  const infoList =()=>{
    if(actInfoData){
      return false;
    }
    var inlist:any=[];
     actInfo.map((item:any)=>{
        var infoItem={};
        myUserContract.methods.getOneActInfo(item).call().then((res:any)=>{
          console.log("getOneActInfo================123",res)
          inlist.push({...res,7:item})
          infoItem={...res,7:item}
        })
      
        return infoItem
    });
    setShowLoadding(true)
    setTimeout(()=>{
      console.log('inlist=========',inlist)
      
        sessionStorage.setItem('userActInfo',JSON.stringify(inlist));
        setUserActInfo(inlist);
    
      
    },3000)
  }
  const barterItem=(e:any)=>{
    // console.log(userActInfo,e)
    let actItems = userActInfo.filter((item:any)=>{
      return item[7]===e
    })

    return (actItems[0])
  }
  const [bCanClaim,setBCanClaim] =useState<boolean>(false);
  const [hasClaimed,setHasClaimed] =useState<string>('0');
  const [leftClaimTime,setLeftClaimTime] =useState<any>('0');
  const [leftPoint,setLeftPoint] =useState<any>('0');
  const [maxPointInOneIntv,setMaxPointInOneIntv] =useState<string>('0');
  const [pointBase,setPointBase] =useState<any>('0');
  const [pointSpeedUp,setPointSpeedUp] =useState<any>(0);
  useLayoutEffect(()=>{
    if(userActInfo.length>0){
      setShowLoadding(true)
      myUserContract.methods.getUserActs(userName).call().then((res:any)=>{
        console.log('getUserActs============234',res )
        setShowLoadding(false);
        setBCanClaim(res[0].bCanClaim);
        setHasClaimed(res[0].hasClaimed);
        setLeftClaimTime(res[0].leftClaimTime);
        setLeftPoint(res[0].leftPoint);
        setMaxPointInOneIntv(res[0].maxPointInOneIntv);
        setPointBase(res[0].pointBase);
        setPointSpeedUp(res[0].pointSpeedUp);
        setUserActs(res);
      })
    }
  },[userActInfo])

  useLayoutEffect(()=>{
    infoList()
  },[actInfo])

  const [showExAway,setShowExAway] = useState<boolean>(false)
  
  const changeExAway=()=>{
    getContract();
    getUserContract();
    getUserUnClaimedList();
    setShowExAway(false)
  }
      return (
    
        <>
            <Col lg={12} xl={10} xlOffset={1} >
                {/* <Row className={`bg${primaryColor} mt-30 ml-0 mr-0 ${styles.billTop}`}>
                  <Col lg={8} className={`flex ${styles.billTopLeft}`}>
                    <div className={`text-center pl-40`}>
                        <img src={userDataObj.avatar && userDataObj.baseUrl ?(userDataObj.baseUrl+userDataObj.avatar):require('../../assets/images/menu/user-icon.png')} className={`${styles.userAvatar}`}  alt={`user`} />
                        <div className={`pt-5f`}>{userDataObj.nickName || 'Unnamed'}</div>
                    </div>
                    <Row className={`flex-1 pl-30`}>
                          <Col md={4} className={`${styles.billLeftBox}`}>
                            <div className={`flex ai-center jc-center ${styles.medalBox}`}>
                              <div className={'pr-20'}>
                                <p className={`font-18`}>金牌</p>
                                <p className={`col-blue font-30 pt-10`}>{goldMedal}</p>
                              </div>
                              <img src={require('../../assets/images/bill/convert/G.png')} alt='' />
                            </div>
                          </Col>
                          <Col md={4} className={`${styles.billLeftBox}`}>
                            <div className={`flex ai-center jc-center ${styles.medalBox}`}>
                              <div className={'pr-20'}>
                                <p className={`font-18`}>银牌</p>
                                <p className={`col-blue font-30 pt-10`}>{silverMedal}</p>
                              </div>
                             
                              <img src={require('../../assets/images/bill/convert/S.png')} />
                            </div>
                          </Col>
                          <Col md={4} className={`${styles.billLeftBox}`}>
                            <div className={`flex ai-center jc-center ${styles.medalBox}`}>
                              <div className={'pr-20'}>
                                <p className={`font-18`}>铜牌</p>
                                <p className={`col-blue font-30 pt-10`}>{bronzeMedal}</p>
                              </div>

                              <img src={require('../../assets/images/bill/convert/C.png')} />

                            </div>
                          </Col>
                    </Row>
                  </Col>
                  <Col lg={4} className={`${styles.billTopRight}`}>
                    <div className={`flex ai-center jc-space-between`}>
                        <div className={`flex ai-center jc-flex-start`}>
                          <img src={require('../../assets/images/bill/logo.png')} alt='' />
                          <div className={`font-18 ml-10`}>星豆</div>
                          <div className={`font-30 ml-10 col-blue`}>{integral}</div>
                        </div>
                        <button className={`${styles.cardbtn}`} onClick={()=>setShowExAway(true)}>转赠</button>
                    </div>
                    <div>
                      <div className={`${styles.levelTitle} font-16 col-blue mt-30`}>Lv {levelPercent}</div>
                      <div className={`${styles.levelBar} mt-20`}>
                          <div className={`${styles.levelBarLine}`} style={{width:(percent).toString().split('.')[1]+'%'}}></div>
                          <div className={`${styles.levelBarTips}`} style={{left: `calc(${(percent).toString().split('.')[1]}% - 18px)`}}>{parseInt((percent).toString().split('.')[1]) || 0} %</div>
                      </div>
                    </div>
                  </Col>
                </Row> */}
                <div className={`mt-30 bg${primaryColor} ${styles.billMain}`}>
               
                    <ul className={`${styles.topTab}`}>
                      {
                        topTypeList.map((item) => {
                          return (
                            <li className={`${item.id == topCurrentID ? styles.active : ''}`} key={item.id} onClick={() => {
                            setTopCurrentID(item.id)
                            }}>
                              <div className={`flex jc-center ai-cernter`}>
                                {item.title}
                              </div>
                            </li>
                          )
                        })
                      }
                    </ul>
                 
                
                  {topCurrentID===1?
                     <div className={`${styles.curentMain}`}>
                      
                       <div className={``}><span className={`font-18 mr-20`}>等级特权</span><span className={`col-999`}>星豆越多，等级越高，享有更多特权</span></div>
                       <div className={`flex jc-space-between mt-30`}>
                         {tipsList.map((item,index)=>{
                           return (
                            <div className={`text-center`} key={index}>
                              <img src={require('../../assets/images/bill/tips-'+index+'.png')} className={`${styles.tipsImg}`} alt="" />
                              <p className={`mt-10`}>{item.title}</p>
                            </div>
                            )
                         })}
                       </div>
                       <div className={`mt-50 flex ai-center `}>
                          <div  className={`text-left flex-1`}>
                             <span className={`font-18 mr-20`}>领取星豆</span>
                             <span className={`col-999`}>通过日常行为，赚取更多星豆</span>
                          </div>
                          {/* {unReceive} */}
                          <div>今日平台剩余 <span className={`col-blue font-24`}>{(leftPoint+'').replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}</span> 星豆</div>
                        </div>
                       
                        {userActs && userActs[2].length>0?
                            <>
                              <Row className={`mt-30 ${styles.receiveBillList}`}>
                                  {userActs &&
                                    userActs[2].map((item:any,index:number)=>{
                                      return (
                                        <Col lg={3} md={4} xs={6} key={index}>
                                          {barterItem(item) && 
                                            <div className={`flex jc-space-between mb-16 ${styles.receiveBox}`}>
                                              <div className={`flex-1 ${styles.receiveBoxTips}`}>
                                                <p className={`ellipsis-2 font-14`}>{barterItem(item)[0]} * {userActs[3][index]}</p>
                                                <p className={`col-blue font-14 ${styles.receiveNum}`}>星豆 +{((barterItem(item)[2] * userActs[3][index] * 1 )+'').replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}</p>
                                              </div>
                                              <img src={require(barterItem(item)[7]==='1'||barterItem(item)[7]==='2'||barterItem(item)[7]==='3'||barterItem(item)[7]==='4'?'../../assets/images/bill/pic1.png':barterItem(item)[7]==='2011'?'../../assets/images/bill/pic3.png':barterItem(item)[7]==='2031'?'../../assets/images/bill/pic2.png':'../../assets/images/bill/pic4.png')} alt="" />
                                            </div>
                                          
                                          }
                                        </Col>
                                        
                                      
                                      )
                                    })
                                  }
                                    {/* {receiveBillList.map((item,index)=>{
                                      return (
                                        <Col lg={3} md={4} xs={6} key={item.id}>
                                          <div className={`flex jc-space-between mb-16 ${styles.receiveBox}`}>
                                            <div className={`flex-1 ${styles.receiveBoxTips}`}>
                                              <p className={`ellipsis-2 font-14`}>{
                                                item.cmd===1?'每日登录':
                                                item.cmd===2?'连续7日登录':
                                                item.cmd===5?'有效评论':
                                                item.cmd===9?'用户注册':
                                                item.cmd===10?'实名认证':
                                                item.cmd===11?'首次登录':''
                                              }</p>
                                              <p className={`col-blue font-14 ${styles.receiveNum}`}>星豆 +{item.point}</p>
                                            </div>
                                            <img src={require('../../assets/images/bill/pic1.png')} alt="" />
                                          </div>
                                        </Col>
                                      )
                                    })} */}
                              </Row>
                            </>
                      :<Empty
                      imageStyle={{
                        width: 480,
                        height: 'auto',
                        display: 'block'
                      }}
                      description={
                        <p style={{ textAlign: 'center', margin: '24px 0px', fontSize: '24px', color: '#9f9f9f' }}>{t(`methods.noData`)}</p>
                      }
                      image={require('../../assets/icon/noData.png')}
                    />}
{/* bCanClaim */}
                    <div className={`flex jc-center mt-50`}>

                      <div className={`${styles.receiveBtn} pointer ${bCanClaim?'':styles.noReceivetFn}`}  onClick={receivetFn}>
                            <img src={require('../../assets/images/bill/btnImg.png')} alt='' />
                            <div className={`flex fd-column jc-center ${styles.receiveBtnTips}`}>
                              <h4 className={`font-24 col-fff`}>全部领取</h4>
                              {/* multiple */}
                              <p className={`font-14 col-fff mt-5`}>当前可享受星豆<span className={`${styles.receiveBtnMultiple}`}>x{pointSpeedUp}</span>加速</p>
                            </div>
                      </div>
                    </div>

                        {/* <div className={`flex jc-center mt-40 col-666 lh-50`}>
                          距离下一次领取时间还有
                          <span className={`${styles.timeBox}`}>{dataState.hour}</span>时 
                          <span className={`${styles.timeBox}`}>{dataState.minute}</span>分
                          <span className={`${styles.timeBox}`}>{dataState.second}</span>秒
                        </div> */}
                         <div className={`flex jc-center mt-40 col-666 lh-50`}>
                          今日可领取次数还有 <span className={`${styles.timeBox}`}>{leftClaimTime}</span>次
                        </div>
                     </div>
                  :topCurrentID===2?
                      <ExchangeMain />
                  :
                  <div className={`${styles.curentMain}`}>
                    <Row>
                        <Col xs={12}>
                            <TableDataCentenr data={billList} />
                        </Col>
                    </Row>
                  </div>
                  } 
                </div>
                
            </Col>
            <ReadMore />
            {showExAway && <ExAway modeData={{dataHash:starDataHash,destID:starDesId,balance:integral,goldMedal:goldMedal,silverMedal:silverMedal,bronzeMedal:bronzeMedal,}} onChange={()=>changeExAway()} />}
            {showExchange && <Exchange onChange={(e:any,num:number)=>{changeEX(e);setBuyAmount(num)}} modeData={{type:daoType,balance:integral,goldMedal:goldMedal,silverMedal:silverMedal,bronzeMedal:bronzeMedal}} />}
            {showPin && <Pin onChange={(e:any)=>{changePin(e)}} />}
            {showLoadding && <ShowLoadding data={{type:loadingType}} onChange={() => handleShowLoadding()} />}
        </>
    
      );

}
export default Bill;