import React from 'react';
import styles from './index.module.less';
import ReadMore from '../Profile/ReadMore';
import Carousel from './Carousel';
import { Row, Col } from 'react-flexbox-grid';
import MedalList from './MedalList';

const Achievements = () => {
  return (
    <div>
      <Row style={{ padding: '29px' }}>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className={`${styles.achvContent}`}>
            <Carousel />
            <MedalList />
          </div>
          
        </Col>
      </Row>
      <ReadMore />
    </div>
  )
}

export default Achievements;