/* eslint-disable array-callback-return */
/* eslint-disable no-lone-blocks */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { useHistory } from "react-router-dom";
import Qs from 'qs';
import Select from 'react-select';
import LazyLoad from 'react-lazyload';
import { collectListByParent,collectDeail } from '../../../api/public'
import Pagination from '../../../components/Pagination';
import styles from "./index.module.less"
import errorIconImg from '../../assets/images/noHeadImg.png'

import Empty from '../../../components/Empty';


import BannerAnim, { Element } from 'rc-banner-anim';

// 切换语言
import { useTranslation } from 'react-i18next';
import { simpleFn } from '../../../components/Notification/index';

// 主题
const themePrimaryColor = 'theme';
const primaryColor =  localStorage.getItem(themePrimaryColor)||'light';
const noImgList = ['noImg1.png', 'noImg2.png', 'noImg3.png'];


const baseUrl = process.env.BASE_URL+"/ipfs/";
const BrandSort: React.FC<{ data: object }> = (props: any) => {
  const {t} = useTranslation();

  const history = useHistory();

  const urlParams = props.location.search.slice(1);
	const params = Qs.parse(urlParams);
	const [queryData, changQueryData] = useState<any>(params)


  console.log("queryData123================",queryData, props.location.search)

  const [brandList,setBrandList] =useState<any>({items:[]});

  const getBrandList = async ()=>{
    const res = await collectListByParent({
      BrandId:queryData.did,
      PageNo:1,
      PageSize:20
    })
    console.log("1111111",res)
    if(res.code===0){
      setBrandList(res.data)
    }
  }
  const outputMate = (list:any,type:string)=>{
    let item = list.filter((item:any)=>item.trait_type===type)[0]
    return item?item.value:false
  }
  const goDetail=(e:string)=>{
    history.push('/brand/sort/detail?id='+e)
  }
  const [sortDetail,setSortDetail] =useState<any>({})
  const getSortDetail = async()=>{
    const res = await collectDeail({brandId:queryData.did})
    if(res.code===0){
      setSortDetail(res.data)
    }
  }
  useEffect(()=>{
    getSortDetail();
    getBrandList();
  },[])
  return (
    <>
     <div className={`flex ai-center jc-center ${styles.sortBanner}`}>

          <BannerAnim prefixCls={`${styles.sortBannerMain}`} autoPlay thumb={false} arrow={false} autoPlaySpeed={3000} type={`across`}>
                <Element 
                      prefixCls={``}
                      key={sortDetail.banner}
                    >
                  <LazyLoad>
                  <img src={baseUrl + sortDetail.banner} alt='' onError={(e: any) => { e.target.onerror = null; e.target.src = require('../../../assets/images/noImg1.png') }}/>
                  </LazyLoad>
                </Element>
          </BannerAnim>
      </div>
       {sortDetail && 
       <div className={`bg${primaryColor} ${styles.bodyCentent} pb-60 relative pt-60`}>
          <div className={`${styles.brandImg}`}>
            {<LazyLoad>
              <img src={baseUrl + sortDetail.logo} alt=''  onError={(e: any) => { e.target.onerror = null; e.target.src = require('../../../assets/images/noImg3.png') }} />
            </LazyLoad>  }
          </div>
          
          <h3 className={`mt-30 font-30 text-center`}>{sortDetail.name}</h3>
          <Col xs={4} xsOffset={4} className={`mt-30`}>
            <div className={`flex jc-center text-center pt-20 pb-20 ${styles.sortCount}`}>
              <Col xs={6} className={`${styles.sortCountLeft}`}>
                <h3 className={`font-30`}>{sortDetail.onSale}</h3>
                <p className={`col-666 font-16 pt-10 flex ai-center jc-center`}><img src = {require('../../../assets/images/brand/sort-icon.png')} alt="" className={`mr-10`} />在售</p>
              </Col>
              <Col xs={6}>
                <h3 className={`font-30`}>{sortDetail.issued}</h3>
                <p className={`col-666 font-16 pt-10 flex ai-center jc-center`}><img src = {require('../../../assets/images/brand/NFR-icon.png')} alt="" className={`mr-10`} />发行</p>
              </Col>
            </div>
          </Col>
          <Col lg={6} lgOffset={3} className={`mt-30`}>
              <p className={`font-14 col-666 text-center lh-24`}>{sortDetail.desc}</p>
          </Col>
      </div> }





      <Col lg={10} lgOffset={1} className={`pt-60 pb-60 text-center`}>

     {/* <Row className={`${styles.exploreRow}`}>
      {JSON.stringify(brandList.items[0]) }
        
        </Row>  */}
      
        <h3>其他信息，请到《移动端》查看</h3>
      </Col>
    </>
  );
}

export default BrandSort;


