import Web3 from 'web3';
import React, { useState } from 'react';
import styles from './index.module.less';
import Hash from 'ipfs-only-hash';
import { uploadfile } from '../../../api/public';
// let ipfsKey="Verseport IPFS";

let ipfsKey="GkMrWfnKM2oL7zZ";
const web3 = new Web3(window.ethereum);

interface Props { 
  extraStyle: string;
  onChange: (url: string) => void;
} 

const UploadImg = ({extraStyle, onChange}: Props) => {
  const [fileItem, setFileItem] = useState<any>();
  const hiddenFileInput = React.useRef<any>(null);

  const calcuFileIpfsHash = (file: any) => {
    return new Promise((resolve, reject) => {
      let fileReaderObj = new FileReader();
      fileReaderObj.onload = function (e: any) {
        let DataStr: any = fileReaderObj.result;
        let bytes = new Uint8Array(DataStr);
        resolve(bytes)
      }
      fileReaderObj.readAsArrayBuffer(file)
    })
  }

  const uploadClick = () => {
    hiddenFileInput.current.click();
  };
  const handleFileChange = async (event: any) => {
    if (event.target.files.length !== 0) {
      setFileItem(event.target.files[0])
      // const fileBytes = await calcuFileIpfsHash(event.target.files[0]);
      // const ipfsHash = await Hash.of(fileBytes);
      UploadFile(event.target.files[0]);
    }
  };

  const UploadFile = async (val: any) => {
    const TimeStamp = Math.round(+new Date());
    const strSeriesConnMsg = web3.utils.keccak256(ipfsKey).substr(2) + TimeStamp;
    const MsgKeccak256 = web3.utils.keccak256("\u0019Ethereum Signed Message:\n" + new Blob([strSeriesConnMsg]).size + strSeriesConnMsg);
    const formData: any = new FormData();
    
    formData.append("size", 350);
    formData.append("thumbnail", 1);
    formData.append("file", val);
    formData.append("msgKeccak256", MsgKeccak256.substr(2));
    formData.append("timeStamp", TimeStamp);

    const res = await uploadfile(formData);
    if (res.code == 0) {
      console.log(res);
      onChange(res.data.baseUrl + res.data.dataHash);
      console.log("文件上传成功")
    } else {
      console.log("文件上传失败")
    }
  }



  return (
    <div className={`flex ai-center jc-center ${styles.logoModel} ${extraStyle}`} onClick={uploadClick}>
      <input type='file' ref={hiddenFileInput} onChange={handleFileChange}/>
      {fileItem == undefined ? <img className={`${styles.placeHolder}`} src={require('../../../assets/icon/image.png')} alt=''/> : 
        <img className={`${styles.uploadImg}`} src={ URL.createObjectURL(fileItem)} alt='' />
      }
    </div>
  )
}

export default UploadImg;