/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';

import { Grid, Row, Col } from 'react-flexbox-grid';
import Qs from 'qs'

import { getNFTDetail, getNFTTradelist, getCommentList, setCommentCreate, queryOperStatus, transferDataAsset, transferonenft, likeNFT, newGetNFTDetail,getsevtime,aliPagePay,getOrderStatus,createOrder,wxPagePay,cancelOrder,transferOneLootBox,getNFTNewDetail,getOwnerNFT,vpCenterLogin,  getProductDetail,getContInfoAddr} from '../../../api/public';

import Markdown from 'markdown-to-jsx';

import LazyLoad from 'react-lazyload';

import { PhotoProvider, PhotoView } from 'react-photo-view';

import Select from 'react-select';
import QueueAnim from 'rc-queue-anim';

import FormatMsgNumber from "../../../components/FormatMsgNumber";
import FormatMsgData from "../../../components/FormatMsgData";
import FormatMsgTime from "../../../components/FormatMsgTime"

import styles from "./index.module.less"

import { simpleFn } from '../../../components/Notification/index';

import 'react-photo-view/dist/react-photo-view.css';


import { ReactEmojiEditor } from '../../../components/emotor';
// import { ReactEmojiEditor } from 'react-emotor'
import { getEmojiImgUrl } from '../../../components/emotor/emojiList';

import ShowLoadding from '../../../components/ShowLoadding';

// web3
import Web3 from 'web3';
import { Link,useHistory } from "react-router-dom";
import ReactLoading from 'react-loading';
import Share from '../Share/Share';
import { t, use } from 'i18next';
import CreateModal from '../CreateModal/CreateModal';
import ReactTooltip from 'react-tooltip';
import Sell from '../Sell/index';

import timeIcon from '../../../assets/images/explore/time.svg';

import Buy from './Buy';
import Pay from './Pay';
import Order from './Order';
import OrderDetail from './OrderDetail';
import Popup from '../../../components/Popup'

import GetToken from './GetToken';

import Pin from '../../../components/User/Pin';

import { get } from '../../../axios/axios-methods';

const web3 = new Web3(Web3.givenProvider);
// 主题
const themePrimaryColor = 'theme';
const primaryColor = localStorage.getItem(themePrimaryColor) || 'light';

const baseUrl = process.env.BASE_URL+"/ipfs/";

// 转赠弹窗
const TransferModal = ({ onChange, data, itemData, getDataFun }: any) => {
	const history = useHistory();
	let intervalHandle = useRef<any>();

	const [fromAdd, setFromAdd] = useState<string>();
	const [transferAmount, setTransferAmount] = useState(1);
	const [checkResult, setCheckResult] = useState<boolean>(true)
	const [transferStatus, setTransferStatus] = useState(0);

	const QueryOperStatus = async (val: any) => {
		intervalHandle.current = setInterval(async () => {
			const res = await queryOperStatus({ "operUUID": val });
			for (let i = 0; i < res.data.items.length; i++) {
				setTransferStatus(res.data.items[i].operStatus)
				if (res.data.items[i].operStatus === 2) {
					setTimeout(()=>{
						setShowLoadding(false);
						clearInterval(intervalHandle.current)
						onChange()
					  },1000)
					
				  } else {
					setShowLoadding(true);
					
				  }
				// 
			}
		}, 3000);

	}


	useEffect(() => {
		console.log(data)
		if (transferStatus !== 1) {
			clearInterval(intervalHandle.current)
		}

	}, [transferStatus])

	const TransferNFT = async () => {
		setShowGetToken(true)
	}

	const evaluatePINAsset = async (e:any) => {
		const FromUserName = sessionStorage.getItem('userName');
		const DataHash = itemData.dataHash;
        const timestamp = new Date().getTime().toString(); 
		const TokenID = itemData.tokenID;
		const DestIDs = itemData.destID;
		const Amount = transferAmount;
		// 
        const temp =  `${FromUserName}${fromAdd}${DestIDs}${TokenID}${Amount}${timestamp}`;
        const msgKeccak256 =web3.utils.keccak256("\u0019Ethereum Signed Message:\n"+ new Blob([temp]).size + temp);
        const sign = await web3.eth.accounts.sign(web3.utils.utf8ToHex('\x00'+temp),e.privateKey);
		const params = {
			fromUserName: FromUserName,
			toUserName: fromAdd,//fromAdd,
			destIDs: DestIDs+'',
			// dataHash: DataHash,
			tokenID: TokenID,
			amount: Amount,
			timeStamp: timestamp,
			msgKeccak256: msgKeccak256.substr(2),
			sig: sign.signature.substr(2),
		}
		const result = await transferOneLootBox(params);
		if (result.code == 0) {
			setTransferStatus(1);
			setShowLoadding(true)
			QueryOperStatus(result.data[0].DataOperUUIDTransfer);
		} else {
			console.log('出错了', result.code, result.msg)
		}
	}
	const evaluate=(e:any)=>{
		itemData.nftType === 1 || itemData.nftType === 2 || itemData.nftType === 3 ||itemData.nftType === 4  ? evaluatePIN(e) : evaluatePINAsset(e)
	}
	const evaluatePIN= async (e:any)=>{
		// console.log(e)
		const FromUserName = sessionStorage.getItem('userName');
		const DataHash = itemData.dataHash;
        const timestamp = new Date().getTime().toString(); 
		const TokenID = itemData.tokenID;
		const DestIDs = itemData.destID;
		const Amount = transferAmount;
		// 
        const temp =  `${FromUserName}${fromAdd}${DestIDs}${TokenID}${Amount}${timestamp}`;
        const msgKeccak256 =web3.utils.keccak256("\u0019Ethereum Signed Message:\n"+ new Blob([temp]).size + temp);
        const sign = await web3.eth.accounts.sign(web3.utils.utf8ToHex('\x00'+temp),e.privateKey);
		const params = {
			fromUserName: FromUserName,
			toUserName: fromAdd,//fromAdd,
			destIDs: DestIDs+'',
			dataHash: DataHash,
			tokenID: TokenID,
			amount: Amount,
			timeStamp: timestamp,
			msgKeccak256: msgKeccak256.substr(2),
			sig: sign.signature.substr(2),
		}
		const result = await transferonenft(params);
		if (result.code == 0) {
			setTransferStatus(1);
			setShowLoadding(true)
			QueryOperStatus(result.data[0].DataOperUUID);
		} else {
			console.log('出错了', result.code, result.msg)
		}

	}
	const [showGetToken,setShowGetToken] = useState<boolean>(false);
	const [showLoadding, setShowLoadding] = useState<boolean>(false)
	const handleShowLoadding = () => {
		setShowLoadding(!showLoadding);
	}
	return (
		<>
			{showLoadding? 
			<ShowLoadding data={{type:transferStatus}} onChange={() => handleShowLoadding()} />:
			 <div className={`flex ai-center jc-center ${styles.popUps}`} >
				<div className={`${styles.popBg}`} onClick={() => onChange && onChange(false)}>背景</div>
				<Col xs={10} sm={10} lg={8} xl={6} className={`flex jc-center ai-center fd-column ${styles.popUpsMode}`}>
					<div className={`${styles.popClose}`}  onClick={() => onChange && onChange(false)}>close</div>

					{showGetToken ? <GetToken onChange={(e:any)=> evaluate(e)} /> :
					<>
						<h3 className={`${styles.logTitle}`}>{t('methods.transfer')}</h3>
						<Row className={`pt-30 ${styles.logMain}`}>
							<Col lg={10} lgOffset={1}>
								<div className={`flex jc-center ai-center font-14 ${styles.warnTips}`}>
									<img src={require('../../../assets/images/login/tips-icon.png')} alt='' className={`mr-10`} />
									<span>为了您的账户安全，需要确认好转赠接收身份地址!</span>
								</div>
							</Col>
							<Col lg={10} lgOffset={1}>
								{transferStatus == 0 &&
									<>
										<p className={`pt-20`}>{t('methods.transferAddress')}</p>
										<input className={`mt-20 ${styles.addMetaInput}`} placeholder="e.g. 0x1ed3... or destination.eth" onChange={async (e) => {
											const temp = await web3.utils.isAddress(e.target.value);
											setCheckResult(temp)
											setFromAdd(e.target.value);
										}} />
										
										<div className={`font-14 lh-24 mb-15`} style={{height:'24px'}}>
											{(fromAdd == '' && fromAdd !== undefined) ? <span style={{ color: 'red' }}>{t('methods.requiredFields')}</span> : ''}
											{(fromAdd != '' && fromAdd !== undefined && !checkResult) ? <span style={{ color: 'red' }}>{t('methods.InvalidAddress')}</span> : ''}
											{(checkResult && fromAdd !== undefined) ? <span style={{ color: '#333333' }}>{t('methods.transferError')}</span> : ''}
										</div>
									</>
								}
								{transferStatus == 1 &&
									<div className={`flex fd-column ai-center`}>
										<div><ReactLoading type='spin' color='rgb(32, 129, 226)' height={'48px'} width={'48px'} /></div>
										<div style={{ marginTop: '24px' }}>{t('methods.transferring')}</div>
									</div>
								}
								{transferStatus == 2 &&
									<div className={`flex  fd-column ai-center`}>
										<div>
											<LazyLoad className={``}>
												<img className={`${styles.resultImg}`} src={itemData.img_url} alt="" />
											</LazyLoad>
										</div>
										<div style={{ marginTop: '24px' }}>{t('methods.transferSucc')}</div>
									</div>
								}
								{transferStatus == 3 &&
									<div className={`flex  fd-column ai-center`}>
										<div>
											<LazyLoad className={``}>
												<img className={`${styles.resultImg}`} src={itemData.img_url} alt="" />
											</LazyLoad>
										</div>
										<div style={{ marginTop: '24px', color: "" }}>{t('methods.transferFailed')}</div>
									</div>
								}



							 
								<div className={`pb-40`}>
									<p>{t('methods.transfer') + t('methods.amount')}</p>
									<input type="number" min={1} max={itemData.ownerAmount} className={`mt-20 ${styles.addMetaInput}`} placeholder={t('methods.transfer') + t('methods.amount')} onChange={async (e: any) => {
										if ((e.target.value - 0) >= (itemData.amount - 0)) {
											setTransferAmount(itemData.amount);
										} else {
											setTransferAmount(e.target.value);
										}
									}} defaultValue={transferAmount} disabled={(itemData.nftType === 5 || itemData.nftType === 6)} /> 
								</div>
							</Col>
							
							<Col lg={10} lgOffset={1} className={`pb-60`}>
								{transferStatus !== 2 ? 
								<button className={`btn blue-btn ${styles.btn}`} disabled={transferStatus != 0 || checkResult == false || fromAdd == '' || fromAdd == undefined} onClick={(e) => {
									// { itemData.nftType == 2 ? TransferNFT() : TransferDataAsset() }
									TransferNFT();
									e.preventDefault();
								}}>{t('methods.transfer')}</button> :
									<button className={`btn blue-btn ${styles.btn}`} onClick={(e) => {
										history.push(`/profile`);
										e.preventDefault();
									}}>{t('methods.backProfile')}</button>}
							</Col>
							
						</Row>
					</>
				}
				</Col>
			</div> }
		</>
			
	)
}


const TableDataCentenr = (props: any) => {
	return (
		<QueueAnim delay={100} animConfig={[
			{ opacity: [1, 0], translateX: [0, 200] },
			{ opacity: [1, 0], translateX: [0, -200] }
		]}>
			{props.data.length > 0 ? props.data.map((item: any, index: number) => {
				//   console.log('list',item)
				return (
					// middle="xs"
					<div className={`flex ai-center font-14 bg${primaryColor} ${styles.tableCentent}`} key={item.id}>
						{/* ${styles.actionTitle} */}
						<Col xs={1} className={`flex ai-center`}>
							<img src={require('../../../assets/images/nftDetail/icon-nftDetail-' + item.event + '.png')} className={`mr-5 ${styles.actionImg}`} alt='' />
							{item.event}
						</Col>
						<Col xs={2} className={`flex ai-center jc-flex-end`}>
							<div className={`text-right font-14`}>
								<h3><FormatMsgNumber data={item.tokenPrice} num={2} /><span className={`ml-5 col-blue`}>{item.blockchain}</span></h3>
								<p className={`pt-8 col-9f`}>$ <FormatMsgNumber data={item.usdPrice} num={2} /></p>
							</div>
						</Col>
						<Col style={{ width: '10%', paddingRight: '20px' }} className={`text-right`}>{item.quantity}</Col>
						<Col xs={2} className={`flex jc-center fd-column`}>
							<p>{item.from.replace(/(\w{8})\w{28}(\w{6})/, "$1......$2")}</p>
						</Col>
						<Col xs={2} className={`flex jc-center fd-column`}>
							<p>{item.to.replace(/(\w{8})\w{28}(\w{6})/, "$1......$2")}</p>
						</Col>
						<Col style={{ width: '10%' }} className={`text-right`}><FormatMsgNumber data={item.gasFee} num={4} /> {item.blockchain}</Col>
						<Col xs className={`text-right col-blue`}><FormatMsgTime data={item.time} /></Col>
					</div>
					// <div>{item.id}</div>
				)
			}) : null}
		</QueueAnim>
	)

}
const TableDataList = (props: any) => {
	// console.log(12333,props.data.items)
	return (
		<>
			<Row className={`pb-15 font-14 ${styles.tableTitle}`}>
				<Col xs={1} className={`pl-15`}>{t('methods.action')}</Col>
				<Col xs={2} className={`text-right`}>{t('methods.price')}</Col>
				<Col style={{ width: '10%', paddingRight: '20px' }} className={`text-right`}>{t('methods.quantity')}</Col>
				<Col xs={2} className={`text-right`}>{t('methods.from')}</Col>
				<Col xs={2} className={`text-right`}>{t('methods.to')}</Col>
				<Col style={{ width: '10%' }} className={`text-right`}>{t('methods.gas')}</Col>
				<Col xs className={`text-right`}>{t('methods.date')}</Col>
			</Row>
			<TableDataCentenr data={props.data.items} />
		</>
	)
}
// emoji展示组件，将含有html标签和Unicode编码的文本转化为html标签
const ReactEmojiShow = (props: any) => {
	// console.log(props)
	const {
		content = '',
	} = props;
	let contentHtml = parseEmojiStr(content).replaceAll('\n', '<br>');

	return <div className={`showEmotorMsg`} dangerouslySetInnerHTML={{ __html: contentHtml }}></div>
}

// 字符串中utf16的emoji表情转img标签(utf16转unicode)
const unicodeToutf16 = (str: any) => {
	// 检测img标签转换为utf16字
	let img_re = new RegExp("<img.*?>", 'g');
	// 去除正则断言写法，兼容Safari浏览器
	str = str.replace(img_re, function (img_str: any) {
		img_str = '0x' + img_str.split('/')[5].split('_')[1].split('.')[0];
		return String.fromCodePoint(img_str);
	});
	return str;
}
const parseEmojiStr = (str: any) => {
	//检测utf16emoji表情 转换为实体字符以供后台存储
	// var patt = /[\ud800-\udbff][\udc00-\udfff]/g;
	var patt = new RegExp("[\ud800-\udbff][\udc00-\udfff]", 'g');
	str = str.replace(patt, function (char: any) {
		var H, L, code;
		if (char.length === 2) {
			H = char.charCodeAt(0); // 取高8位
			L = char.charCodeAt(1); // 取低8位
			code = (H - 0xd800) * 0x400 + 0x10000 + L - 0xdc00; // utf16 -> unicode

			let imgUrl: any = getEmojiImgUrl(code.toString(16));
			let imgHtml = `<img alt='' src='${imgUrl}' />`;
			imgHtml = unicodeToutf16(imgHtml)
			return imgHtml;
		} else {
			return char;
		}
	});
	return str;
}

const DetailComment: React.FC<any> = (props: any) => {
	const [commentList, setCommentList] = useState<any>({ count: 0, items: [] })
	const [skip, setSkip] = useState<number>(0)
	const params: any = props.data;
	const detailData = props.detail;

	const getCommentDataList = async (params: { children?: React.ReactNode; destID?: any; tokenID?: any; }, type: string | undefined) => {
		console.log(type)
		const res = await getCommentList({
			// contextId: params.id,
			// contextType: params.type,
			destID: params.destID,
			tokenID: params.tokenID,
			skip: type === 'Comment' ? 0 : skip,
			limit: type === 'Comment' ? 1 : 3
		})
		if (res.code === 0) {
			let data = {
				count: res.data.count,
				items: type === 'Comment' ? [...res.data.items, ...commentList.items] : [...commentList.items, ...res.data.items]
			}
			props.change1(res.data.count);
			// console.log("============21++++++]]============",data)
			setCommentList({ ...data })
		}
	}
	useEffect(() => {
		// console.log(props)
		getCommentDataList(detailData, undefined);
	}, [skip])

	// const [commentMsg, setCommentMsg] = useState('')
	const contentOnChange = (content: any) => {
		// setCommentMsg(content)
		postComment(content);
	}

	const getUserInfo: any = sessionStorage.getItem('userInfo')
	let [userDataObj, setUserDataObj] = useState<any>(JSON.parse(getUserInfo))//getUserInfo?JSON.parse(getUserInfo):null;

	const web3 = new Web3(Web3.givenProvider);

	//获得字符长度
	const getLength = (val: string) => {
		var str = new String(val);
		var bytesCount = 0;
		for (var i = 0, n = str.length; i < n; i++) {
			var c = str.charCodeAt(i);
			if ((c >= 0x0001 && c <= 0x007e) || (0xff60 <= c && c <= 0xff9f)) {
				bytesCount += 1;
			} else {
				bytesCount += 2;
			}
		}
		return bytesCount;
	}


	const postComment = async (commentMsg: string) => {
		// setCommentCreate
		const timestamp = new Date().getTime().toString();
		const socialDataToken = sessionStorage.getItem('socialDataToken');

		console.log(socialDataToken, sessionStorage.getItem('userInfo'))
		if (!(socialDataToken && userDataObj)) {
			simpleFn('请先登录！', 'error')
			return;
		}

		if (commentMsg.length == 0) {
			simpleFn('请输入评论内容！', 'error')
			return;
		}
		const temp = userDataObj.userName.concat(socialDataToken, timestamp);
		const msgKeccak256 = web3.utils.keccak256("\u0019Ethereum Signed Message:\n" + getLength(temp) + temp).substr(2);

		let data = {
			userName: userDataObj.userName,
			destID: detailData.destID,
			tokenID: detailData.tokenID,
			text: commentMsg,
			timeStamp: timestamp,
			msgKeccak256: msgKeccak256,
		}

		const res = await setCommentCreate(data)
		if (res.code === 0) {
			console.log(res)

			simpleFn('添加评论成功！', 'success')
			// setCommentMsg('');
			getCommentDataList(detailData, 'Comment');
		}

	}
	return (
		<Col id="comment" lg={12} className={`mt-20`}>
			<div className={`bg${primaryColor} ${styles.edtailCard}`}>
				<div className={`${styles.edtailCardCentent}`}>
					<Row>
						<Col className={`pr-5`}>
							<img alt="" src={userDataObj && userDataObj.avatar && userDataObj.baseUrl ? (userDataObj.baseUrl + userDataObj.avatar) : require('../../../assets/images/menu/user-icon.png')} className={`userAvatar`} />
						</Col>
						<Col xs className={`${styles.edtailCardDesc}`}>
							<ReactEmojiEditor onChange={(content: any) => contentOnChange(content)} placeholder={t('methods.writeCommentg')} />
							{/* <button className={`btn blue-btn mt-15 ${styles.edtailBtn}`} onClick={postComment}>{t('methods.submit')}</button> */}
						</Col>
					</Row>

					<div className={`pt-20`}>
						{commentList.items.map((item: any) => {
							return (
								<Row className={`pt-15 pb-20`} key={item.id}>
									<Col className={`pr-5`}>
										<img alt="" src={item && item.userInfo.avatar ? item.baseURL + item.userInfo.avatar : require('../../../assets/images/menu/user-icon.png')} className={`userAvatar`} />
									</Col>
									<Col xs className={``}>
										<h3 className={`font-16`}>{item && (item.userInfo.nickName || item.userInfo.userName)}</h3>
										<div className={`pt-8 font-14 col-9f`}><ReactEmojiShow content={item.text} /></div>
									</Col>
								</Row>
							)
						})}
						{commentList.count > commentList.items.length && <div className={`text-center pt-15`}>
							<span className={`col-blue font-14 pointer`} onClick={() => setSkip(commentList.items.length)}>{t('methods.More')}...</span>
						</div>}

					</div>
				</div>
			</div>
		</Col>
	)
}

const Activity: React.FC<{ data: object }> = (queryData: any) => {
	console.log("queryData", queryData)
	const [tabData, setTabData] = useState<any>({})
	const [total, setTotal] = useState<number>(0);
	const getTradelist = async (data: object) => {

		const res = await getNFTTradelist({ ...data, ...{ limit: 5 } });
		// console.log("statsList",res.data.items)
		if (res.code === 0) {
			setTabData(res.data)
			setTotal(res.data.count);

		} else {
			// message.error(res.msg);
		}
	}
	const selectOptions = {
		activityListOptions: [
			{ value: "", label: t('methods.all') },
			{ value: "Mint", label: t('methods.mint') },
			{ value: "Sale", label: t('methods.sale') },
			{ value: "Transfer", label: t('methods.transfer') },
			{ value: "Offer", label: t('methods.offer') }
		],
	}
	const changeQuery = (e: any) => {
		console.log(e)
		let data = {
			...queryData.data,
			...e
		}
		getTradelist(data);
	}
	// useEffect(() => {
	// 	getTradelist(queryData.data);
	// }, [])
	return (<>
		<Col>
			<div className={`mt-20 bg${primaryColor} ${styles.edtailCard}`}>
				<div className={`flex ai-center jc-space-between ${styles.edtailCardTitle}`}>
					<Col xs className={`flex ai-center`}>
						<img src={require(`../../../assets/images/nftDetail/icon-nftDetail12.png`)} alt="" />
						<h3>{t('methods.Activity')}</h3>
					</Col>
					<Col xs={6} sm={4} md={3} lg={2}>
						<Select options={selectOptions.activityListOptions} defaultValue={selectOptions.activityListOptions[0]} onChange={(e: any) => { changeQuery({ event: e.value }) }} />
					</Col>

				</div>
				<div className={`${styles.edtailCardCentent}`}>

					{tabData.items ? <TableDataList data={tabData} /> : null}
				</div>
			</div>
		</Col>
	</>)
}



const Detail = (props: any) => {
	const history = useHistory();
	const web3 = new Web3(window.ethereum);
	const urlParams = props.location.search.slice(1);
	const params = Qs.parse(urlParams);
	const [queryData, changQueryData] = useState<object>(params)
	const [detailData, changDetailData] = useState<any>()
	const [isRefresh, setIsRefresh] = useState(false);
	const [isShow, setIsShow] = useState(false);
	const [isCreate, setIsCreate] = useState(props.location.state?.isCreate);


	const [properties, setProperties] = useState<any>([])
	const [levels, setLevels] = useState<any>([]);
	const [stats, setStats] = useState<any>([]);

	const [showProper, setShowProper] = useState(false);
	const [showLevels, setShowLevels] = useState(false);
	const [showStats, setShowStats] = useState(false);
	const getUserInfo: any = sessionStorage.getItem('userInfo')
	const [userDataObj, setUserDataObj] = useState<any>(JSON.parse(getUserInfo))//getUserInfo?JSON.parse(getUserInfo):null;
	// const [isCreate, setIsCreate] = useState(params?.isCreate == '1');

	const [mediaStatus, setMediaStatus] = useState('paused');
	const [showSell, setShowSell] = useState(false);
	const [sellInfo, setSellInfo] = useState<any>();
	const [LikeNum,setLikeNum] = useState(0)
	const getDetail = async (e: any) => {
		let params: any = { ...queryData }
		params.userName = userDataObj?.userName;
		// const res1 = await getNFTDetail({
		// 	type: params.type,
		// 	id: params.id,
		// 	userName: params.userName
		// });
		// const res = await newGetNFTDetail({ id: params.id, userName: params.userName });
		const res = await newGetNFTDetail({ TokenID: e.TokenID, DestID:e.DestID,UserName: params.userName });

		if (res.code === 0) {
			params.userName && getMyOwnerNFT(res.data.destID,res.data.tokenID)
			//   console.log(res)
			res.data.metaDataSystem = JSON.parse(res.data.metaDataSystem)
			console.log("new", res)
			setIsRefresh(false)
			changDetailData(res.data);
			// setLikeNum(res.data.stats.likes)
			let propertiesTemp = [];
			let levelsTemp = [];
			let statsTemp = [];
			for (let i = 0; i < res.data.metaDataUser.attributes.length; i++) {
				if (res.data.metaDataUser.attributes[i].max_value == null && res.data.metaDataUser.attributes[i].display_type == '') {

					propertiesTemp.push(res.data.metaDataUser.attributes[i]);
					setProperties(propertiesTemp);
				} else if (res.data.metaDataUser.attributes[i].max_value !== null && res.data.metaDataUser.attributes[i].display_type == '') {
					statsTemp.push(res.data.metaDataUser.attributes[i]);
					setStats(statsTemp);
				} else if(res.data.metaDataUser.attributes[i].trait_type!=='cn_description' && res.data.metaDataUser.attributes[i].trait_type!=='cn_name') {
					levelsTemp.push(res.data.metaDataUser.attributes[i]);
					setLevels(levelsTemp);
				}
			}
			getServerTime(res.data.markets.startDate,res.data.markets.endDate)
		} else {
			// message.error(res.msg);
		}
	}

	const [ownerAmount,setOwnerAmount]=useState(0);
	const [likeStatus,setLikeStatus]=useState(0)
	const getMyOwnerNFT=async(did:any,tid:any)=>{
		let params: any = { ...queryData }
			params.userName = userDataObj?.userName;
		let myData={
			userName: params.userName,
			destID:did,
			tokenID:tid
		}
		const res = await getOwnerNFT(myData)
		if(res.code===0){
			setOwnerAmount(res.data.ownerAmount)
			setLikeStatus(res.data.likeStatus)
		}
	}

	useEffect(() => {
		// getDetail();
		getProductDetailData();
	}, [])
	const getProductDetailData=async ()=>{


		let params: any = { ...queryData }
		params.userName = userDataObj?.userName;

		const res = await getProductDetail({UserName:params.userName,ProductSN:params.id})
		if(res.code===0){
			changDetailData(res.data);
			// getDetail(res.data);
			getHashData(res.data.MetaDataHash)
			let time = res.now
			setNowTime(time);
			time < res.data.StartTime?countFun(res.data.StartTime,res.now):countFun(res.data.EndTime,res.now);
			getContInfoAddrData(res.data.DestID)
		}
	}

	const getHashData =async (hash: string) => {
		const res = await get('/ipfs/'+hash);
		console.log(res)
		let propertiesTemp = [];
		let levelsTemp = [];
		let statsTemp = [];
		for (let i = 0; i < res.attributes.length; i++) {
			if (res.attributes[i].max_value == null && res.attributes[i].display_type == '') {

				propertiesTemp.push(res.attributes[i]);
				setProperties(propertiesTemp);
			} else if (res.attributes[i].max_value !== null && res.attributes[i].display_type == '') {
				statsTemp.push(res.attributes[i]);
				setStats(statsTemp);
			} else if(res.attributes[i].trait_type!=='cn_description' && res.attributes[i].trait_type!=='cn_name') {
				levelsTemp.push(res.attributes[i]);
				setLevels(levelsTemp);
			}
		}
	}
	const [contractAddress,setContractAddress] = useState('')
	const getContInfoAddrData = async(did:string)=>{
		const res = await getContInfoAddr({DestID:did})
		console.log(res)
		if(res.code===0){
			setContractAddress(res.data.ContAddr)
		}
	}


	const handleInPro = () => {
		setIsShow(!isShow);
		setIsRefresh(true);
		clearInterval(timer.current)
		setDataState({
			day:'00',
			hour:'00',
			minute:'00',
			second:'00'
			})
		setTimeout(()=>{
			// getDetail();
		},500)
		if (!isShow) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'auto';
		}
	}

	const SellModelHandle = () => {
		setShowSell(!showSell);
		if (!showSell) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'auto';
		}
		console.log(sellInfo)
	}


	const CreateModalHandle = () => {
		props.history.replace({
			...props.location,
			state: undefined,
		});
		setIsCreate(!isCreate);
		console.log(isCreate)
		if (!isCreate) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'auto';
		}
	}


	const copyText = (value: any) => {
		const textarea = document.createElement('textarea')
		textarea.readOnly = true
		textarea.style.position = 'absolute'
		textarea.style.left = '-9999px'
		textarea.value = value
		document.body.appendChild(textarea)
		// 选中值并复制
		textarea.select()
		textarea.setSelectionRange(0, textarea.value.length)
		const copyStatus = document.execCommand('Copy')
		document.body.removeChild(textarea)
		if (copyStatus) {
			simpleFn(t('methods.copySucc'), 'success');
		} else {
			simpleFn(t('methods.copyError'), 'error');
		}
	}

	const scrollToAnchor = (id: any) => {
		let temp: any = document.getElementById(id)
		temp.scrollIntoView(false);
	}
	const [showSocialDataToken,setShowSocialDataToken] = useState(false)
	const LikeNft = async () => {
		const timestamp = new Date().getTime().toString();
		const socialDataToken = sessionStorage.getItem('socialDataToken');

		console.log(socialDataToken, sessionStorage.getItem('userInfo'))
		if (!userDataObj) {
			simpleFn('请先登录！', 'error')
			return;
		}else if(!socialDataToken){
			setShowSocialDataToken(true)
		} else {

			const strSeriesConnMsg = `${userDataObj.userName}${socialDataToken}${timestamp}`;
			const MsgKecak256 = web3.utils.keccak256(`\u0019Ethereum Signed Message:\n${new Blob([strSeriesConnMsg]).size}${strSeriesConnMsg}`);
			const params = {
				userName: userDataObj.userName,
				tokenID: detailData.tokenID,
				// contextId: props.location.state.id,
				// contextType: props.location.state.type,
				like: likeStatus == 0 ? 1 : 0,
				timeStamp: timestamp,
				msgKeccak256: MsgKecak256.substr(2),
				destID: detailData.destID,
			}
			// console.log(props.location.state)
			const res = await likeNFT(params);
			if (res.code == 0) {
				console.log('like 成功')
				// getDetail();
				// setLikeNum(likeStatus === 0 ? (LikeNum + 1) : (LikeNum - 1)) //== 0 ? 1 : 0
			} else {
				console.log('like 失败')
			}
			console.log('like result', res)
		}
	}
	const getLoginToken=async(e:any)=>{
		console.log(e)
		if(e){
			const userToken= web3.utils.keccak256(e.address).substr(2);
			const timestamp = new Date().getTime().toString(); 
			const uTokenValidPeriod = '864000000000';
			const temp = e.address.concat(userToken,uTokenValidPeriod,timestamp);
			const msgKeccak256 =web3.utils.keccak256("\u0019Ethereum Signed Message:\n"+ new Blob([temp]).size + temp);
			const sign = await web3.eth.accounts.sign(web3.utils.utf8ToHex('\x00'+temp),e.privateKey);
			let data = {
				userName:e.address,
				userToken: userToken,
				tokenValidPeriod: uTokenValidPeriod,
				timeStamp: timestamp,
				sig: sign.signature.substr(2),
				msgKeccak256: msgKeccak256.substr(2),
			}
			const res = await vpCenterLogin(data)
			console.log("1===================",res)
	
			if(res.code===0){
				let userInfoData = {
					
					...res.data
				}
				sessionStorage.setItem('userInfo',JSON.stringify(userInfoData));
				sessionStorage.setItem('socialDataToken',res.data.socialDataToken);
				LikeNft()
			}
			
		}
		setShowSocialDataToken(false)
	}
	const changeQuantity = (val: any) => {
		let temp = { ...detailData };
		detailData.stats.comments = val
		changDetailData(temp)
	}

	/** 微信分享二维码 */
	// const handleQrCode = () => {
	// 	setShowQrCode(!showQrCode);
	// 	if (!showQrCode) {
	// 		document.body.style.overflow = 'hidden';
	// 	} else {
	// 		document.body.style.overflow = 'auto';
	// 	}
	// }
	const getServerTime= async (startTime:number,endTime:number)=>{
		const res =await getsevtime({})
		if(res.code===0){
			let time = res.data.time
			setNowTime(time);
			time < startTime?countFun(startTime,res.data.time):countFun(endTime,res.data.time)
		}
	}
	const formatTimeData=(time:number)=>{
		var date = new Date(time)
            var Y = date.getFullYear() + '-'
            var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
            var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
            var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
            var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
            var s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
            return Y + M + D + h + m + s
	}
	const [dataState,setDataState]  = useState<any>({
		day: '00',
		hour: '00',
		minute: '00',
		second: '00'
	  })
	const [nowTime,setNowTime]= useState<number>(0)
	let timer:any = useRef<any>();
	const countFun = (endTime:number,startTime:number) => {


		clearInterval(timer.current)

		timer.current = setInterval(()=>{
			let sysSecond:number = endTime - startTime;//计算剩余的毫秒数
			// console.log(sysSecond);
			let day = Math.floor(sysSecond / 1000 / 60 / 60 / 24);//计算剩余的天数
			let hour = Math.floor(sysSecond / 1000  / 60 / 60 % 24);//计算剩余的小时数
			let minute = Math.floor(sysSecond / 1000  / 60 % 60);//计算剩余的分钟数
			let second = Math.floor(sysSecond / 1000  % 60);//计算剩余的秒数

			if(sysSecond>0){
				// console.log(day + "天" + hour + "时" + minute + "分" + second + "秒");
				setDataState({
					day:day < 10 ? "0" + day : day,
					hour: hour < 10 ? "0" + hour : hour,
					minute: minute < 10 ? "0" + minute : minute,
					second: second < 10 ? "0" + second : second
				  })
				startTime = startTime + 1000;
			}else if(sysSecond<0){
				setDataState({
					day:'00',
					hour:'00',
					minute:'00',
					second:'00'
				  })
				  clearInterval(timer.current)
			}
		},1000);


	}
	
	const [showBuy,setShowBuy] = useState<boolean>(false);
	const [orderNum,setOrderNum] = useState<any>('')
	const buyOrder=(e:any,num:number)=>{
		console.log('=================',e,num)
		if(e){
			orderDetermine(num)
		}else{
			setShowBuy(e)
		}
	}
	const [showPayState,setShowPayState] = useState<boolean>(false);
	const orderDetermine= async (e:number)=>{
		let userName = sessionStorage.getItem('userName');
		console.log('=================userName',userName)
		let orderData={
			UserName:userName,
			Products:[
				{
					ProductSN:detailData.ProductSN,
					Num:e
				}
			]
			
		}
		// 
		const res = await createOrder(orderData)
		if(res.code===0){
			setShowBuy(false);
			
			setShowPayState(false);
			setOrderNum(res.data.OrderID);
			getOrderDetail(res.data.OrderID,1)
		}else if(res.code===6){
			console.log('超出取消5个');

			simpleFn('您取消次数已超过限制！', 'error')	
		}else if(res.code===4){
			// console.log('有未支付')
			simpleFn('您有未支付订单，请先完成支付！', 'error')		
		}else if(res.code===10 ||res.code===11){
			simpleFn('您所购买的作品库存不足！', 'error')
		}else if(res.code===12){
			simpleFn('您所购买的作品超过限购！', 'error')
		}else{
			simpleFn(res.msg, 'error')
			console.timeLog(res.msg)
		}
	}

	const [orderInfo,setOrderInfo] = useState<any>({})
	const getWXPayOrder=async(orderNum:string)=>{
		const res =await wxPagePay({orderID:orderNum})
		if(res.code===0){
			

			setShowOrderPay(false)
			setShowPayState(true)
			window.open(res.data.url);
			
		}
	}
	//支付订单
	const getPayOrder =(type:number)=>{
		console.log("============getPayOrder",type==2)
		switch (type) {
			case 1:
				getZFBPayOrder(orderNum);
				break;
			case 2:
				getWXPayOrder(orderNum);
				break;
			// default:
			// 	console.log('银行卡支付')
			// 	break;
		}
	}

	const getZFBPayOrder = async (orderNum:string)=>{
		const res = await aliPagePay({OrderID:orderNum})
		if(res.code===0){
			

			setShowOrderPay(false)
			setShowPayState(true)
			window.open(res.data.URL);
			
		}
	}
	// 订单支付后弹出
	const payOrder=(e:number)=>{
		if(e===1){
			console.log('支付问题？')
			history.push(`/help?type=3`)
		}else if(e===2){
			getOrderDetail(orderNum,2);
		}
	}
	const getOrderDetail = async (orderNum:string,n:number)=>{
		console.log('==============123',orderNum)
		const res = await getOrderStatus({OrderID:orderNum});
		//payStatus: 1 待支付   2 已支付 3 转移中  4 转移成功  5 转移失败

		console.log("payStatus==============",res)
		if(res.code===0){
			setOrderInfo(res.data)
			switch (n) {
				case 1:
					if(res.data.payStatus===5){
						setShowOrderDetail(true);
						setShowPayState(false);
					}else{
						setShowOrderPay(true)
					}
					
					break;
				default:
					setShowOrderDetail(true);
					setShowPayState(false);
					break;
			}
		}else{

		}
	}
	const [showOrderPay,setShowOrderPay] = useState(false);
	const [showOrderDetail,setShowOrderDetail] = useState(false);
	const confirmOrder =(e:any,n:number)=>{
		
		if(e){
			switch (e) {
				case 'pay':

					setShowPayState(false);
					getPayOrder(n)
					break;
				default:
					setShowPopup(true)
					break;
			}
		}else{
			setShowOrderPay(e)
		}
	}
	const [showPopup,setShowPopup] = useState(false)
	// 查询订单详情
	const confirmOrderDetail=(e:any,n:number)=>{
		console.log("==============confirmOrderDetail",e)
		if(e){
			switch (e) {
				case 'pay':

					setShowOrderDetail(false);
					getPayOrder(n)
					break;
				case 'cancel':
					// getcancelOrder(orderNum,n)
					setShowPopup(true)
					break;
				case 'see':
					break;
				default:

					break;
			}
		}else{
			setShowOrderDetail(e);
			setIsRefresh(true);
			clearInterval(timer.current)
			setDataState({
				day:'00',
				hour:'00',
				minute:'00',
				second:'00'
			  })
			setTimeout(()=>{
				// getDetail();
			},500)
		}
	}
	// 取消订单
	const getcancelOrder = async()=>{
		const res = await cancelOrder({orderID:orderNum})
		if(res.code===0){
			setShowPopup(false);
			setShowOrderPay(false)
			setShowOrderDetail(false);
			simpleFn('订单取消成功！', 'success')
		}
	}
	// 
	const outputMate = (list:any,type:string)=>{
		let item = list.filter((item:any)=>item.trait_type===type)[0];
		return item?item.value:false
	  }

	const changeLandIdentify=(item:any)=>{
		// 
		let landIdentify:any = { //正式环境的格子等级登记号
			N: '鲁作登字-2022-F-00767236',
			R: '鲁作登字-2022-F-00767234',
			SR: '鲁作登字-2022-F-00767237',
			SS: '鲁作登字-2022-F-00767235',
			SSS: '鲁作登字-2022-F-00767238'
		   }

		if(item.metaDataUser.name.indexOf('Grid')>-1){
			let type=outputMate(item.metaDataUser.attributes,"Rarity")
			return landIdentify[type]
		}

		console.log("============eeeee",item)


	}

	return (
		<>
		<div style={{ minHeight: '680px' }}>
			{detailData && <Col lg={12} xl={10} xlOffset={1} className={`${styles.edtailMain}`}>
				<Row>
					<Col lg={4}>
						{/* 左边模块1 */}
						{/* {(detailData.metaDataSystem.type == "" || detailData.metaDataSystem.type.split('/')[0] == "video") ?
							<div className={`flex ${styles.mainCtn}`}>
								<PhotoProvider maskOpacity={0.8}>
									<PhotoView
										width={800}
										height={400}
										render={({ scale, attrs }: any) => {
											const width = attrs.style.width;
											const offset = (width - 600) / 600;
											const childScale = scale === 1 ? scale + offset : 1 + offset;
											return (
												<video style={{ width: '800px', height: '400px', transform: `scale(${childScale})` }} autoPlay src={detailData.baseUrl + detailData.dataHash} controls loop preload="auto"></video>
											)
										}}>
										<div style={{ width: '100%' }}>
											<div className={`flex jc-center ai-center ${styles.mask}`}><img style={{ display: 'inline-block' }} src={require('../../../assets/icon/play.png')} alt='' /></div>
											<img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={detailData.baseUrl + detailData.metaDataSystem.thumb_hash} onError={(e: any) => { e.target.onerror = null; e.target.src = require('../../../assets/images/noImg1.png')}} alt='' />
										</div>
									</PhotoView>
								</PhotoProvider>

							</div>
							: ""}

						{detailData.metaDataSystem.type.split('/')[0] == "audio" ?
							<div className={`flex ${styles.mainCtn}`}>
								<PhotoProvider maskOpacity={0.8}>
									<PhotoView
										width={800}
										height={400}
										render={({ scale, attrs }: any) => {
											const width = attrs.style.width;
											const offset = (width - 600) / 600;
											const childScale = scale === 1 ? scale + offset : 1 + offset;
											return (
												<div style={{ width: '800px', height: '400px', transform: `scale(${childScale})` }}>
													<div style={{ width: '100%' }}>
														<div className={`flex jc-center ai-center`} style={{
															position: 'absolute',
															inset: '0px',
															width: '100%',
															zIndex: 1,
															transition: 'opacity 0.1s ease-in 0s',
															opacity: 1,
														}}><img style={{ display: 'inline-block', width: '100%', height: '100%', objectFit: 'contain', }} src={require('../../../assets/images/create/top.png')} alt='' /></div>
														<img className={`${styles.musicImg}`} style={{
															width: '50%',
															height: '50%',
															display: 'block',
															margin: '0 auto',
															objectFit: 'contain',
															animationPlayState: mediaStatus
														}} src={require('../../../assets/images/create/bottom.png')} alt='' />
													</div>

													<audio autoPlay src={detailData.img_url} style={{ width: '100%' }} controls loop preload="auto" onPlay={() => { setMediaStatus('running') }} onPause={() => { setMediaStatus('paused') }}></audio>
												</div>
											)
										}}>
										<div style={{ width: '100%' }}>
											<div className={`flex jc-center ai-center ${styles.mask}`}><img style={{ display: 'inline-block' }} src={require('../../../assets/icon/play.png')} alt='' /></div>
											<img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={detailData.baseUrl + detailData.metaDataSystem.thumb_hash} onError={(e: any) => { e.target.onerror = null; e.target.src = require('../../../assets/images/noImg1.png')}} alt='' />

										</div>
									</PhotoView>
								</PhotoProvider>

							</div>
							: ""} */}
						{/* {detailData.metaDataSystem.type.split('/')[0] == "image" ?
							<div className={`pointer ${styles.detailImg}`}>
								<LazyLoad className={``}>
									<PhotoProvider maskOpacity={0.8}>
										<PhotoView src={detailData.baseUrl + detailData.dataHash}>
											<img src={detailData.baseUrl + detailData.dataHash} alt="" />
										</PhotoView>
									</PhotoProvider>
								</LazyLoad>
							</div> : ""} */}


							<div className={`pointer ${styles.detailImg}`}>
								<LazyLoad className={``}>
									<PhotoProvider maskOpacity={0.8}>
										<PhotoView src={baseUrl + detailData.DataHash}>
											<img src={baseUrl + detailData.DataHash} alt="" />
										</PhotoView>
									</PhotoProvider>
								</LazyLoad>
							</div> 
						
						{/* 左边模块2 */}
						{properties.length > 0 && <div className={`mt-20 bg${primaryColor} ${styles.edtailCard}`}>
							<div className={`flex ai-center jc-space-between ${styles.edtailCardTitle}`} onClick={() => { setShowProper(!showProper) }}>
								<div className={`flex`}>
									<img src={require(`../../../assets/images/nftDetail/icon-nftDetail1.png`)} alt='' />
									<h3>作品属性</h3>
								</div>
								<div><img style={{ transform: showProper ? 'rotate(180deg)' : 'rotate(0deg)' }} src={require(`../../../assets/icon/arrow.png`)} alt='' /></div>
							</div>
							{showProper ? <Row className={`${styles.edtailCardCentent}`} style={{ paddingBottom: '8px' }}>
								{properties.map((item: any) => {
									return (
										<Col xs={6} key={item.trait_type}>
											<div className={`${styles.edtailCardBox}`}>
												<p className={`ellipsis-1 col-blue`}>{item.trait_type}</p>
												<h3 className={`mt-10`}>{item.value}</h3>
											</div>
										</Col>
									)
								})}
							</Row> : ''}
						</div>}

						{levels.length > 0 && <div className={`mt-20 bg${primaryColor} ${styles.edtailCard}`}>
							<div className={`flex ai-center jc-space-between ${styles.edtailCardTitle}`} onClick={() => { setShowLevels(!showLevels) }}>
								<div className={`flex`}>
									<img src={require(`../../../assets/icon/LevelsD.png`)} alt='' />
									<h3>等级属性</h3>
								</div>
								<div><img style={{ transform: showLevels ? 'rotate(180deg)' : 'rotate(0deg)' }} src={require(`../../../assets/icon/arrow.png`)} alt='' /></div>
							</div>
							{showLevels ? <div>
								{levels.map((item: any, index: any) => {
									return (
										<div className={`${styles.statsItem}`} key={index}>
											<div className={`${styles.numTrait}`}>
												<div className={`${styles.traitType}`}>{item.trait_type}</div>
												<div className={`${styles.value}`}>{item.value} of {item.max_value}</div>
											</div>
											<div className={`${styles.bar}`}>
												<div className={`${styles.barFill}`} style={{ width: `${(item.value / item.max_value * 100).toFixed(0)}%` }}></div>
											</div>
										</div>
									)
								})}
							</div> : ""}
						</div>}

						{/* {stats.length > 0 && <div className={`mt-20 bg${primaryColor} ${styles.edtailCard}`}>
							<div className={`flex ai-center jc-space-between ${styles.edtailCardTitle}`} onClick={() => { setShowStats(!showStats) }}>
								<div className={`flex`}>
									<img src={require(`../../../assets/icon/StatsD.png`)} alt='' />
									<h3>统计属性</h3>
								</div>
								<div><img style={{ transform: showStats ? 'rotate(180deg)' : 'rotate(0deg)' }} src={require(`../../../assets/icon/arrow.png`)} alt='' /></div>
							</div>
							{showStats ? <div>
								{stats.map((item: any, index: any) => {
									return (
										<div className={`${styles.statsItem}`} key={index}>
											<div className={`${styles.numTrait}`}>
												<div className={`${styles.traitType}`}>{item.trait_type}</div>
												<div className={`${styles.value}`}>{item.value} of {item.max_value}</div>
											</div>
										</div>
									)
								})}
							</div> : ""}
						</div>} */}
						
						{/* 左边模块4 */}
						<div className={`mt-20 bg${primaryColor} ${styles.edtailCard}`}>
							<div className={`flex ai-center ${styles.edtailCardTitle}`}>
								<img src={require(`../../../assets/images/nftDetail/icon-nftDetail3.png`)} alt='' />
								<h3>{t('methods.details')}</h3>
							</div>
							<div className={`${styles.edtailCardCentent}`}>
								<div className={`flex ai-center ${styles.detailsCardTips}`}>
									<div className={`${styles.detailsCardTipsTitle}`}>作品哈希</div>
									<span className={`flex-1 text-right border-box pl-20`}>{detailData.DataHash.replace(/(\w{6})\w{34}(\w{6})/, "$1...$2")}</span>
									<div data-tip data-for='copyToken' className={`ml-10 ${styles.copyIconDetail}`} onClick={() => {
										copyText(detailData.DataHash)
									}}>
										<img alt='' src={require('../../../assets/icon/copy.png')} />
									</div>
								</div>
								<ReactTooltip effect='solid' id='copyToken' type='info' >
									<span>copy</span>
								</ReactTooltip>
								 <div className={`flex ai-center ${styles.detailsCardTips}`}>
									<div className={`${styles.detailsCardTipsTitle}`}>作品ID</div>
									<a className={`flex-1 col-blue text-right border-box pl-20`} target='_blank' href={`https://scan.verseport.com/token/`+contractAddress+`/instance/`+detailData.TokenID+`/token-transfers`}>{detailData.TokenID.replace(/(\w{6})\w{64}(\w{6})/, "$1...$2")}</a>
									<div data-tip data-for='copy' className={`ml-10 ${styles.copyIconDetail}`} onClick={() => {
										copyText(detailData.TokenID)
									}}>
										<img alt='' src={require('../../../assets/icon/copy.png')} />
									</div>
								</div>
								<ReactTooltip effect='solid' id='copy' type='info' >
									<span>copy</span>
								</ReactTooltip>
								<div className={`flex ai-center ${styles.detailsCardTips}`}>
									<div className={`${styles.detailsCardTipsTitle}`}>{t('methods.address')}</div>
									<span className={`flex-1 text-right border-box pl-20`}>{contractAddress.replace(/(\w{6})\w{30}(\w{6})/, "$1...$2")}</span>
									<div data-tip data-for='copyAddress' className={`ml-10 ${styles.copyIconDetail}`} onClick={() => {
										copyText(contractAddress)
									}}>
										<img alt='' src={require('../../../assets/icon/copy.png')} />
									</div>
								</div>
								<ReactTooltip effect='solid' id='copyAddress' type='info' >
									<span>copy</span>
								</ReactTooltip>
								<div className={`flex ai-center ${styles.detailsCardTips}`}>
									<div className={`${styles.detailsCardTipsTitle}`}>{t('methods.blockchain')}</div>
									<a className={`flex-1 col-blue text-right border-box pl-20`} target='_blank' href={`https://scan.verseport.com/`}>Verseport对象网络</a>
								</div>
							
								{/*	<div className={`flex ai-center ${styles.detailsCardTips}`}>
										<div className={`${styles.detailsCardTipsTitle}`}>作品登记号</div>
										<span className={`flex-1 text-right border-box pl-20 lh-20 font-12`}>
											{detailData.markets.opusesNum || changeLandIdentify(detailData) || '--'}
										</span>



									</div> */}
									
							</div>
						</div>
					</Col>
					<Col lg={8}>
						{/* 右边模块1 */}
						<div className={`bg${primaryColor} ${styles.edtailCentent}`}>
							<Row>
								<Col lg={8}>
									{/* <h3 className={`font-16`}><Link className={`col-333`} to={`/brand/sort/detail?id=`+detailData.collectInfo.collectID}>{detailData.collectInfo.metaDataUser === null ? "Verseport" : detailData.collectInfo.metaDataUser.name}</Link></h3> */}
									<h3 className={`font-36 pt-30`}>{detailData.Name}</h3>
								</Col>
								<Col lg={4} className={`flex jc-flex-end`}>
									<div className={`pointer ml-20 mr-10 ${styles.topBtn}`} onClick={() => {
										setIsRefresh(true);
										window.setTimeout(() => {
											window.location.reload()
										}, 1000)
									}}>
										<img className={`${isRefresh ? styles.btnImg : ''}`} src={require(`../../../assets/images/nftDetail/icon-nftDetail9.png`)} alt="" />
										<p>{t('methods.refresh')}</p>
									</div>
									{/* 赠送 */}
									{ownerAmount>0 && detailData.filter!==1 && detailData.filter!==2 && <div className={`pointer ml-20 mr-10 ${styles.topBtn}`} onClick={() => {
										setIsShow(true)
									}}>
										<img src={require(`../../../assets/images/nftDetail/icon-nftDetail20.png`)} alt="" />
										<p>{t('methods.transfer')}</p>
									</div>}
									{/* 分享 */}
									<div className={`pointer ml-20 mr-10 ${styles.topBtn}`} data-tip data-for='enrich'>
										<img src={require(`../../../assets/images/nftDetail/icon-nftDetail10.png`)} alt="" />
										<p>{t('methods.share')}</p>
										<Share data={detailData} />
									</div>
									
								</Col>
							</Row>
							<div className={`flex ai-center pt-15`}>
								{/* <div className={`flex ai-center ${styles.counts}`}>
									<img src={require(`../../../assets/images/nftDetail/icon-nftDetail5.png`)} alt="" />
									{detailData.stats.views || 0} 查看
								</div> */}
								
								<div className={`flex ai-center pointer ${styles.counts}`} onClick={() => LikeNft()}>
									<img src={require(likeStatus == 1 ? "../../../assets/images/nftDetail/icon-nftDetail7.on.png" : '../../../assets/images/nftDetail/icon-nftDetail7.png')} alt="" />
									{detailData.LikeCount} 喜爱
								</div>
							</div>

							{detailData.Status && ( detailData.Status!==3 || detailData.Status!==0 )  ?
							<>
								<Row className={`pt-35`}>
									<Col lg={9}>
									{ nowTime < detailData.EndTime ?

									nowTime  < detailData.StartTime ?
									<>
										<div className={`col-666 flex ai-center lh-24`}>
											<img src={timeIcon} width={24} height={24} alt='' className={`mr-5`} />
											距开售
										</div>
										<div className={`flex ai-flex-end mt-20 font-24 ${styles.marketData}`}>
											<h3 className={`col-blue`}>{dataState.day}</h3><span className={`col-666 font-14`}>天</span>
											<h3 className={`col-blue`}>{dataState.hour}</h3><span className={`col-666 font-14`}>时</span>
											<h3 className={`col-blue`}>{dataState.minute}</h3><span className={`col-666 font-14`}>分</span>
											<h3 className={`col-blue`}>{dataState.second}</h3><span className={`col-666 font-14`}>秒</span>
										</div>
									</>:
									<>
										<div className={`col-666 flex ai-center lh-24`}>
											<img src={timeIcon} width={24} height={24} alt='' className={`mr-5`} />
											距结束
										</div>
										<div className={`flex ai-flex-end mt-20 font-24 ${styles.marketData}`}>
											<h3>{dataState.day}</h3><span className={`col-666 font-14`}>天</span>
											<h3>{dataState.hour}</h3><span className={`col-666 font-14`}>时</span>
											<h3>{dataState.minute}</h3><span className={`col-666 font-14`}>分</span>
											<h3>{dataState.second}</h3><span className={`col-666 font-14`}>秒</span>
										</div>
									</>:
									<>
										<div className={`col-666 flex ai-center lh-24`}>
											<img src={timeIcon} width={24} height={24} alt='' className={`mr-5`} />
											距结束
										</div>
										<div className={`flex ai-flex-end mt-20 font-24 ${styles.marketData}`}>
											活动已结束
										</div>
									</>
									}
										
									</Col>
									<Col lg={3} className={`text-right`}>
										<div className={``}>售价</div>
										<div className={`pt-20 font-24`}>
											
											<span className={`col-blue ml-5`}>¥ {detailData.Price/100}</span>
										</div>
									</Col>
								</Row>
								<Row className={`mt-40`} middle="lg" >
									<Col lg={8}>
										<div className={`flex `}>
										{nowTime  < detailData.EndTime && <div className={`${styles.detailData}`}>
												<p>剩余</p>
												
												<h3 className={`mt-20`}>{detailData.LeftAmount}</h3>
											</div>}
											<div className={`${styles.detailData}`}>
												<p>发行总量</p>
												<h3 className={`mt-20`}>{detailData.TotalAmount}</h3>
											</div>
										</div>
									</Col>
									<Col lg={4} className={`text-right flex jc-flex-end`}>
										{nowTime  > detailData.StartTime && nowTime  < detailData.EndTime ?
										<button className={`btn blue-btn ${styles.buyBtn}`} disabled={detailData.Status === 2 || nowTime >= detailData.EndTime} onClick={() => {
											setShowBuy(true);
										}}>{detailData.Status === 2?'已售罄':`立即购买`}</button>
										:<button className={`btn ${styles.noBuyBtn}`} disabled={true}>立即购买</button>
									}
									</Col>
								</Row>
							</>:
								<Row className={`pt-35`}>
								<Col lg={12}>
									<div className={`${styles.detailDesc}`}>
										{detailData.Desc}
									</div> 
								</Col>
							</Row>
						}
						</div>
						{/* 模块2 */}
						{/* <div className={`mt-20 bg${primaryColor} ${styles.edtailCentent}`}>
							<Row middle="lg" >
								<Col md={3} sm={6}>
									<div className={`${styles.detailData}`} style={{ width: '100%' }}>
										<p>{t('methods.highest')}</p>
										
										<h3 className={`mt-20`}>tokenPrice symbol</h3>

									</div>
									<div className={`mt-60 ${styles.detailData}`} style={{ width: '100%' }}>
										<p>{t('methods.lowest')}</p>
										
										<h3 className={`mt-20`}>tokenPrice symbol</h3>

									</div>
								</Col>
								<Col md={3} sm={6}>
									<div className={`${styles.detailData}`} style={{ width: '100%' }}>
										<p>{t('methods.7DSales')}</p>
										<h3 className={`mt-20`}>sales7d</h3>

									</div>
									<div className={`mt-60 ${styles.detailData}`} style={{ width: '100%' }}>
										<p>{t('methods.TotalSales')}</p>
										<h3 className={`mt-20`}>salesTotal</h3>

									</div>
								</Col>
								<Col md={3} sm={6}>
									<div className={`${styles.detailData}`} style={{ width: '100%' }}>
										<p>{t('methods.PastOwners')}</p>
										<h3 className={`mt-20`}>pastOwners</h3>

									</div>
									<div className={`mt-60 ${styles.detailData}`} style={{ width: '100%' }}>
										<p>{t('methods.BirthDate')}</p>
										<h3 className={`mt-20`}><FormatMsgData data={"createTime"} /></h3>

									</div>
								</Col>
								<Col lg={3} sm={6}>
									<div className={`${styles.detailData}`} style={{ width: '100%' }}>
										<p>{t('methods.cHoldTime')}</p>
										<h3 className={`mt-20`}>~currentHoldingDays<span className={`ml-5 col-blue`}>days</span></h3>

									</div>
									<div className={`mt-60 ${styles.detailData}`} style={{ width: '100%' }}>
										<p>{t('methods.lHoldTime')}</p>
										<h3 className={`mt-20`}>~longestHoldingDays<span className={`ml-5 col-blue`}>days</span></h3>

									</div>
								</Col>
							</Row>
						</div> */}
						 {detailData.Status && (detailData.Status!==3 || detailData.Status!==0 || detailData.Status!==null)  &&
						<div className={`mt-20 bg${primaryColor} ${styles.edtailCard}`}>
							<div className={`flex ai-center ${styles.edtailCardTitle}`}>
								<img src={require(`../../../assets/images/nftDetail/icon-nftDetail2.png`)} />
								<h3>作品描述</h3>
							</div>
							<div className={`${styles.edtailCardCentent}`}>
								<div className={`${styles.edtailCardDesc}`}>
									{detailData.Desc}
								</div>
							</div>
						</div> }
						{/* {
							 detailData.collectInfo.metaDataUser && 
							<div className={`mt-20 bg${primaryColor} ${styles.edtailCard}`}>
								<div className={`flex ai-center ${styles.edtailCardTitle}`}>
									<img src={require(`../../../assets/images/nftDetail/icon-nftDetail2.png`)} />
									<h3>{(outputMate(detailData.collectInfo.metaDataUser.attributes,'cn_name') || detailData.collectInfo.metaDataUser.name)} 系列</h3>
								</div>
								<div className={`${styles.edtailCardCentent}`}>
									<div className={`${styles.edtailCardDesc}`}>
										<Markdown options={{
											namedCodesToUnicode: {
												'ensp': '\u2002',
												'emsp': '\u2003',
												'nbsp': '\u00a0'
											}
										}}>{outputMate(detailData.collectInfo.metaDataUser.attributes,'cn_description') || detailData.collectInfo.metaDataUser.description}</Markdown>
									</div>
								</div>
							</div>
						} */}


						
						{/* 底部数据 */}
						{/* <Activity data={queryData} /> */}





					</Col>
					{/* 模块评论 */}
				 { showSocialDataToken &&
						<Pin onChange={(e:any)=>{getLoginToken(e)}} />
					}

					{isShow && <TransferModal data={params} itemData={detailData} getDataFun={getDetail} onChange={() => handleInPro()} />}
					{isCreate == true ? <CreateModal itemData={detailData} onChange={() => CreateModalHandle()} /> : ''}
					{showSell ? <Sell changeSellInfo={setSellInfo} onChange={() => SellModelHandle()} itemData={detailData} /> : ''}
				
					{showBuy && <Buy itemData={detailData} onChange={(e:any,num:number)=>buyOrder(e,num)} />}
					{showOrderPay && <Order itemData={orderInfo} onChange={(e:any,n:number)=>confirmOrder(e,n)} />}
					{showPayState && <Pay onChange={(e:any)=>payOrder(e)} />}
					{showOrderDetail && <OrderDetail itemData={orderInfo} onChange={(e:any,n:number)=>confirmOrderDetail(e,n)} showDetailBtn={true} />}
					{showPopup && <Popup onChange={(e:boolean)=>e?getcancelOrder():setShowPopup(false)} title={`取消订单`} content={<>
					<p className={`mt-20`}>确定取消订单？取消后不可恢复！</p>
					<p className={`font-12 col-red mt-10`}>*24小时内锁单5单未支付，将限制交易24小时</p></>} />} 	
				</Row>
			</Col>}
			
		</div>
		
		</>
	)
}

export default Detail;