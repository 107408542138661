/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-restricted-globals */
/* eslint-disable react/style-prop-object */
/* eslint-disable react-hooks/rules-of-hooks */

import React,{useState,useEffect}  from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import { Link,useHistory,useLocation } from 'react-router-dom';
import Qs from 'qs';

// 切换语言
import { useTranslation } from 'react-i18next';

// 主题
import ThemeColor from '../Theme/ThemeColor'
// 样式
import styles from  "./index.module.less"

import LazyLoad from 'react-lazyload';
// api
import { top100List,top100Weight,likeSortList,nftNumList,inviteRanking } from '../../api/public'

import Empty from '../../components/Empty'

// const { t, i18n} = useTranslation();
type Props = {
    data:any;
}
const TopHundred = ({data,onChange}:any) =>{
    const {t} = useTranslation();

    console.log("================123",data)



    const history = useHistory();
    const noImgList = ['noImg1.png', 'noImg2.png', 'noImg3.png']
    const baseUrl = process.env.BASE_URL+"/ipfs/";//"https://vp.fanduola.com/ipfs/";
    // Tab List
    const topTypeList = [
      {
        id: 1,
        title: '作品',
      },
      {
        id: 2,
        title: "藏家",
      },
      {
        id: 5,
        title: "邀请"
      },
      {
        id: 3,
        title: "星豆"
      },
      {
        id: 4,
        title: "道牌与权重"
      },
      
    ]
    const [topCurrentID, setTopCurrentID] = useState(data.type || 1);



    const [integralDataList, setIntegralDataList] = useState<any>({})
    const getRpcData = async (e:string)=>{
      const res = await top100List({Limit:data.limit});
      if(res.code===0){
        console.log("星豆==================",res)

        let jsonData ={
          ...res.data
        }
       e==='666' && setIntegralDataList(jsonData)
      }
    }
    const [weightsDataList,setWeightsDataList] = useState<any>({})
    const [weightType,setWeightType] = useState<number>(1);
    const [weightTypeData,setWeightTypeData] = useState([
      {
        id:1,
        title:'权重',
        type:''
      },
      {
        id:2,
        title:'金牌',
        type:'888'
      },
      {
        id:3,
        title:'银牌',
        type:'889'
      },{
        id:4,
        title:'铜牌',
        type:'890'
      }
  ])

  const goDetail = (e: any) => {
    const type = 2;
    const nftid = e.nftID;
    // history.push(`/explore/detail?type=${type}&id=${nftid}`, { type, nftid })
  }
    const getWeights = async()=>{
      const res = await top100Weight({Limit:data.limit})
      if(res.code===0){
        let jsonData ={
          ...res.data
        }
        setWeightsDataList(jsonData)
      }
    }
    const [LikeSortDataList,setLikeSortDataList]=useState<any>({});
    const [getNFTListLoding,setGetNFTListLoding] = useState<boolean>(false);
    const getLikeSortList =async () => {


      const res = await likeSortList({Limit:data.limit});
      console.log("likeNft===",res.data)
      if(res.code===0){
        let datalist={
          //...res.data,

          items:res.data//res.data.items.slice(0,data.limit)
        }
        setLikeSortDataList(datalist)
      }
      getNftNumList();
      getRpcData('666');
    }
    const [NftDataList,setNftDataList]=useState<any>({})
    const getNftNumList=async ()=>{
      const res = await nftNumList({Limit:data.limit});
      if(res.code===0){
        let jsonData ={
          ...res.data,
          Items:res.data.Items//res.data.items.slice(0,data.limit)
        }
        setNftDataList(jsonData)
      }
    }
    useEffect(()=>{
      getLikeSortList();
      getWeights();
      getInviteRanking();
    },[])
    const [InviteRank,setInviteRank] = useState({Items:[]})
    const getInviteRanking=async () => {
      const res = await inviteRanking({Limit:data.limit});
      console.log("邀请===========",res)
      if(res.code===0){
        setInviteRank(res.data)
      }
    }

    const outputMate = (list:any,type:string)=>{
      let item = list.filter((item:any)=>item.trait_type===type)[0];
      return item?item.value:false
      }




  return (
    <div className={`pt-40`}>
      <Col md={10} mdOffset={1} >
        <div  className={`mt-40`}>
          <p className={`font-32 col-333 pl-15`}>排行榜</p>
        </div>
        <div className={`flex mt-30 pb-20`}>
          {/* topCurrentID===4?8:12 */}
            <Col lg={12}>
              <ul className={`${styles.topTab} text-left`}>
                {
                  topTypeList.map((item) => {
                    return (
                      <li className={`${item.id == topCurrentID ? styles.active : ''}`} key={item.id} onClick={() => {
                      setTopCurrentID(item.id);
                      onChange(item.id)
                      }}>
                        <div className={`flex jc-center ai-cernter`}>
                          {item.title}
                        </div>
                      </li>
                    )
                  })
                }
              </ul>
            </Col>
            {/* {topCurrentID===4 && <Col lg={4} className={`relative flex jc-center`}>
                <div className={`${styles.currentTabs}`}>
                    {weightTypeData.map((item)=>{return (<span key={item.id} className={`${item.id === weightType?styles.curTabs:''}`} onClick={()=>(setWeightType(item.id))}>{item.title}</span>)})}
                </div>
              
            </Col>} */}
        </div>

        {/* 内容 */}
        <div className={`mt-10 ${styles.topHundredMain}`}>
        {topCurrentID===1 &&
        <>
        { LikeSortDataList.items && LikeSortDataList.items.length > 0  ? LikeSortDataList.items.map((item:any,index:number)=>{
          let metaDataSystem: any;
          // if (item.metaDataSystem !== '') {
          //   metaDataSystem = JSON.parse(item.metaDataSystem);
          //   if (metaDataSystem.thumb_url !== undefined) {
          //     metaDataSystem.thumb_hash = metaDataSystem.thumb_url;
          //   }
          // } else {
          //   metaDataSystem = {
          //     "type": '',
          //     "size": '',
          //     "thumb_hash": ''
          //   }
          // }
              return (
                <div key={item.TokenID} className={`flex ai-center jc-center ${styles.ownList} ${styles.likeList}`} onClick={()=>{goDetail(item)}}>
                    <Col>
                      <div className={`text-center col-666 ${styles.ownSort}`}>{index+1}</div>
                    </Col>
                    <Col xs className={`flex ai-center`}>
                      <LazyLoad className={`relative  ml-30 ${styles.likeListImg}`}>
                          {/* {(metaDataSystem.type !== undefined && metaDataSystem?.type.split('/')[0] === 'image') ?  */}
                          <img className={`${styles.likeSortImg}`} src={ process.env.BASE_URL+'/ipfs/'+item.DataHash} onError={(e: any) => { e.target.onerror = null; e.target.src = require('../../assets/images/' + noImgList[Math.floor(Math.random() * noImgList.length)]) }} alt="" /> {/* : 
                            <div>
                              <div className={`flex jc-center ai-center ${styles.mask}`}><img style={{ display: 'inline-block' }} src={require('../../assets/icon/play.png')} alt=''  /></div>
                              <img className={`${styles.likeSortImg}`} src={LikeSortDataList.baseUrl + metaDataSystem.thumb_hash} onError={(e: any) => { e.target.onerror = null; e.target.src = require('../../assets/images/' + noImgList[Math.floor(Math.random() * noImgList.length)]) }} alt='' />
                            </div> */}
                          
                        </LazyLoad>
                    </Col>
                    <Col xs={3} className={``}>
                      <div className={`${styles.medaTips}`}>
                          <h3 className={`font-18`}>{item.Name}</h3>
                          <p className={`font-14 col-666 mt-10`}>作品名称</p>
                        </div>
                    </Col>
                    {/* <Col xs={3}  className={`flex`}>
                        <div className={`${styles.medaTips}`}>
                          <h3 className={`font-18`}>{item.pmetaDataUser?item.pmetaDataUser.name:'--'}</h3>
                          <p className={`font-14 col-666 mt-10`}>所属品牌馆</p>
                        </div>
                    </Col>
                    <Col xs={2} className={`flex`}>
                        <div className={`${styles.medaTips}`}>
                          <h3 className={`font-18`}>{item.smetaDataUser?item.smetaDataUser.name:'--'}</h3>
                          <p className={`font-14 col-666 mt-10`}>所属系列</p>
                        </div>
                    </Col> */}
                    {/* <Col xs className={`flex`}>
                        <div className={`${styles.medaTips}`}>
                          <h3 className={`font-18`}>{ metaDataSystem?.type.split('/')[0]}</h3>
                          <p className={`font-14 col-666 mt-10`}>作品类型</p>
                        </div>
                    </Col> */}
                    <Col xs className={`flex`}>
                        <div className={`${styles.medaTips}`}>
                          <h3 className={`font-18`}>{item.LikeCount.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}</h3>
                          <p className={`font-14 col-666 mt-10`}>喜欢数</p>
                        </div>
                        {/* <img src={require("../../assets/images/nftDetail/icon-nftDetail7.on.png")} alt="" className={`ml-10 ${styles.medalImg}`}  /> */}
                    </Col>
                    
                </div>
              )
            }):<Empty
            imageStyle={{
              width: 480,
              height: 'auto',
              display: 'block'
            }}
            description={
              <p style={{ textAlign: 'center', margin: '24px 0px', fontSize: '24px', color: '#9f9f9f' }}>{t(`methods.noData`)}</p>
            }
            image={require('../../assets/icon/noData.png')}
          />
          
          }
        </>
        }
        {topCurrentID===2 &&
          <div className={`pl-10 pr-10`}>
            {NftDataList.Items && NftDataList.Items.length>0? NftDataList.Items.map((item:any,index:number)=>{
              return (
                <div key={item.UserName} className={`flex ai-center jc-center ${styles.ownList}  ${styles.likeList}`}>
                    <Col>
                      <div className={`text-center col-666 ${styles.ownSort}`}>{index+1}</div>
                    </Col>
                    <Col xs={5} className={`flex ai-center`}>
                      <LazyLoad className={`relative ml-30 ${styles.likeListImg}`}>
                        <img src={`${item.Avatar? process.env.BASE_URL+'/ipfs/'+item.Avatar:require('../../assets/images/menu/user-icon.png')}`} className={`${styles.ownIcon}`} alt={`${item.NickName || 'Unnamed'}`} />
                      </LazyLoad>
                      <span className={`ml-20 font-18 ellipsis-1`}>{item.NickName || 'Unnamed'}</span>
                    </Col>
                    <Col xs className={`flex`}>
                        <div className={`${styles.medaTips}`}>
                          
                          <h3 className={`font-18`}>{/* {item.totalAmount.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")} */}**</h3>
                          <p className={`font-14 col-666 mt-10`}>收藏数字作品</p>
                        </div>
                       
                    </Col>
                </div>
              )
            }):<Empty
            imageStyle={{
              width: 480,
              height: 'auto',
              display: 'block'
            }}
            description={
              <p style={{ textAlign: 'center', margin: '24px 0px', fontSize: '24px', color: '#9f9f9f' }}>{t(`methods.noData`)}</p>
            }
            image={require('../../assets/icon/noData.png')}
          />}
          </div>
        }
        {topCurrentID===3 &&
          <div className={`pl-10 pr-10`}>
            {integralDataList.items && integralDataList.items.length>0 ? integralDataList.items.map((item:any,index:number)=>{
              return (
                <div key={item.UserName} className={`flex ai-center jc-center ${styles.ownList} ${styles.likeList}`}>
                    <Col>
                      <div className={`text-center col-666 ${styles.ownSort}`}>{index+1}</div>
                    </Col>
                    <Col xs={5} className={`flex ai-center`}>
                      
                      <LazyLoad className={`relative ml-30 ${styles.likeListImg}`}>
                        <img src={`${item.avatar?baseUrl+item.avatar:require('../../assets/images/menu/user-icon.png')}`} className={`${styles.ownIcon}`} alt={`${item.nickName || 'Unnamed'}`} />
                      </LazyLoad>
                      <span className={`ml-20 font-18 ellipsis-1`}>{item.nickname || item.username.replace(/(\w{6})\w{32}(\w{4})/, "$1...$2")}</span>
                    </Col>
                    <Col xs className={`flex`}>
                      <div className={`${styles.medaTips}`}>
                          <h3 className={`font-18`}>{item.amount.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}</h3>
                          <p className={`font-14 col-666 mt-10`}>拥有星豆</p>
                        </div>
                        <img src={require("../../assets/images/bill/logo.png")} alt="" className={`ml-10 ${styles.medalOwnImg}`}  />
                    </Col>
                </div>
              )
            }):<Empty
            imageStyle={{
              width: 480,
              height: 'auto',
              display: 'block'
            }}
            description={
              <p style={{ textAlign: 'center', margin: '24px 0px', fontSize: '24px', color: '#9f9f9f' }}>{t(`methods.noData`)}</p>
            }
            image={require('../../assets/icon/noData.png')}
          />}
          </div>
        }
        {topCurrentID===4 &&
          <div className={`pl-10 pr-10`}>
            {weightsDataList.items && weightsDataList.items.map((item:any,index:number)=>{
              return (
                <div key={item.UserName} className={`flex ai-center jc-center ${styles.ownList} ${styles.likeList}`}>
                    <Col>
                      <div className={`text-center col-666 ${styles.ownSort}`}>{index+1}</div>
                    </Col>
                    <Col xs={3} className={`flex ai-center`}>
                      <LazyLoad className={`relative ml-30 ${styles.likeListImg}`}>
                        <img src={`${item.avatar?baseUrl+item.avatar:require('../../assets/images/menu/user-icon.png')}`} className={`${styles.ownIcon}`} alt={`${item.nickName || 'Unnamed'}`} />
                      </LazyLoad>
                      <span className={`ml-20 font-18`} style={{width:'100px'}}>{item.nickName || item.username.replace(/(\w{6})\w{32}(\w{4})/, "$1...$2")}</span>
                    </Col>
                    <Col xs className={``}>
                        <h3 className={`font-18`}>{item.weight.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}</h3>
                        <p className={`font-14 col-666 mt-10`}>权重</p>
                    </Col>
                    <Col xs className={`flex`}>
                        <div className={`${styles.medaTips}`}>
                          <h3 className={`font-18`}>{item.gold_amount.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}</h3>
                          <p className={`font-14 col-666 mt-10`}>金牌</p>
                        </div>
                        <img src={require('../../assets/images/bill/G.png')} alt='' className={`ml-10 ${styles.medalImg}`} />
                    </Col>
                    <Col xs className={`flex`}>
                        <div className={`${styles.medaTips}`}>
                          <h3 className={`font-18`}>{item.silv_amount.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}</h3>
                          <p className={`font-14 col-666 mt-10`}>银牌</p>
                        </div>
                        <img src={require('../../assets/images/bill/S.png')} alt='' className={`ml-10 ${styles.medalImg}`} />
                    </Col>
                    <Col xs className={`flex`}>
                        <div className={`${styles.medaTips}`}>
                          <h3 className={`font-18`}>{item.bron_amount.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}</h3>
                          <p className={`font-14 col-666 mt-10`}>铜牌</p>
                        </div>
                        <img src={require('../../assets/images/bill/C.png')} alt='' className={`ml-10 ${styles.medalImg}`} />
                    </Col>
                </div>
              )
            })}
          </div>
        }
        {topCurrentID===5 &&
          <div className={`pl-10 pr-10`}>
            {InviteRank.Items && InviteRank.Items.length>0? InviteRank.Items.map((item:any,index:number)=>{
              return (
                <div key={item.UserName} className={`flex ai-center jc-center ${styles.ownList}  ${styles.likeList}`}>
                    <Col>
                      <div className={`text-center col-666 ${styles.ownSort}`}>{index+1}</div>
                    </Col>
                    <Col xs={5} className={`flex ai-center`}>
                      <LazyLoad className={`relative ml-30 ${styles.likeListImg}`}>
                        <img src={`${item.Avatar? process.env.BASE_URL+'/ipfs/'+item.Avatar:require('../../assets/images/menu/user-icon.png')}`} className={`${styles.ownIcon}`} alt={`${item.NickName || 'Unnamed'}`} />
                      </LazyLoad>
                      <span className={`ml-20 font-18 ellipsis-1`}>{item.NickName || 'Unnamed'}</span>
                    </Col>
                    <Col xs className={`flex`}>
                        <div className={`${styles.medaTips}`}>
                          
                          <h3 className={`font-18`}>{item.TotalCount}</h3>
                          <p className={`font-14 col-666 mt-10`}>邀请数</p>
                        </div>
                       
                    </Col>
                </div>
              )
            }):<Empty
            imageStyle={{
              width: 480,
              height: 'auto',
              display: 'block'
            }}
            description={
              <p style={{ textAlign: 'center', margin: '24px 0px', fontSize: '24px', color: '#9f9f9f' }}>{t(`methods.noData`)}</p>
            }
            image={require('../../assets/icon/noData.png')}
          />}
          </div>
        }
      </div>

      
      </Col>
    </div>
  )
}
export default TopHundred;