
import React,{useState,useEffect}  from 'react';

import { Grid, Row, Col } from 'react-flexbox-grid';

import styles from './index.module.less';

// 切换语言
import { useTranslation } from 'react-i18next';

// api
import { exchangeDao } from '../../../api/public';

import { simpleFn } from '../../../components/Notification';

// web3
// import detectEthereumProvider from '@metamask/detect-provider';
import Web3 from 'web3';
import { blob } from 'node:stream/consumers';

const Exchange =({onChange,modeData}:any)=>{
    // console.log("==================",modeData)
    const { t } = useTranslation();
    const userName:any = sessionStorage.getItem('userName');

    const userInfo = JSON.parse(sessionStorage.getItem('userInfo')||'') ;
    const [amount,changAmount] = useState<any>(1);
    const [unitPrice,setUnitPrice] = useState<number>(1000000);
    const [daoSign,setDaoSign] =  useState<string>('金牌')
    useEffect(()=>{
        let num = modeData.type==='888'?1000000:modeData.type==='889'?10000:100;
        setUnitPrice(num);
        countDiscoun(num,amount);
        let dao = modeData.type==='888'?'金牌':modeData.type==='889'?'银牌':'铜牌';
        setDaoSign(dao)
    },[])
    const changeDaoM = async ()=>{
        onChange('click',amount); 
    }
    const [discount,setDiscount] = useState<number>(10)
    const countDiscoun=(num:number,e:any)=>{
        const countIntegral =  ( modeData.goldMedal*500000 + modeData.silverMedal*5000 + modeData.bronzeMedal*50 ) + modeData.balance*1 - num * (e*1);
        if(0<countIntegral && countIntegral<200000){
            setDiscount(10)
        }else if(200000<=countIntegral && countIntegral<400000){
            setDiscount(9);
        }else if(400000<=countIntegral && countIntegral<600000){
            setDiscount(8);
        }else if(600000<=countIntegral && countIntegral<800000){
            setDiscount(7);
        }else if(800000<=countIntegral && countIntegral<1000000){
            setDiscount(6);
        }else if(1000000<=countIntegral){
            setDiscount(5);
        }
        // switch(true){
        //     case countIntegral<200000:
        //         setDiscount(10)
        //         break
        //     case 200000<=countIntegral && countIntegral<400000:
        //         setDiscount(9);
        //         break
        //     case 400000<=countIntegral && countIntegral<600000:
        //         setDiscount(8);
        //         break
        //     case 600000<=countIntegral && countIntegral<800000:
        //         setDiscount(7);
        //         break
        //     case 800000<=countIntegral && countIntegral<1000000:
        //         setDiscount(6);
        //         break
        //     default:setDiscount(5);
        // }
      }
   
    return (
        <div className={`flex ai-center jc-center ${styles.popUps}`} >
            <div className={`${styles.popBg}`} onClick={() => onChange && onChange(false)}>背景</div>
            <Col xs={10} sm={10} lg={8} xl={6} className={`flex jc-center ai-center fd-column ${styles.popUpsMode}`}>
                <div className={`${styles.popClose}`}  onClick={() => onChange && onChange(false)}>close</div>
                <h3 className={`${styles.logTitle} pt-0`}>兑换道牌</h3>
                <Row className={`pt-40 ${styles.logMain}`}>
                    <Col lg={10} lgOffset={1} className={`mb-40`}>
                        <Row>
                            <Col md={4}>
                                <img width={'100%'} src={modeData.type ==='888'?(process.env.BASE_URL+'/ipfs/'+process.env.GOLD_MEDAL_DATA_HASH):modeData.type=='889'?(process.env.BASE_URL+'/ipfs/'+process.env.SILVER_MEDAL_DATA_HASH):(process.env.BASE_URL+'/ipfs/'+process.env.BRONZE_MEDAL_DATA_HASH)} alt='' />
                            </Col>
                            <Col md={8} className={`pl-10`}>
                                <div className={`flex ai-center`}>
                                    <div className={`col-666 font-14 `}>道牌种类</div>
                                    <h3 className={`font-18 ml-15 `}>{daoSign}</h3>
                                </div>
                                <div className={`flex ai-center mt-20`}>
                                    <div className={`col-666 font-14 `}>道牌单价</div>
                                    <h3 className={`font-18 ml-15 `}>{(unitPrice+'').replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}</h3>
                                </div>
                                <div className={`flex ai-center`}>
                                    <input type='number' placeholder={'输入需要兑换的道牌数量'}  className={`${styles.logTex} `} value={amount}  onChange={e =>{
                                        changAmount(e.target.value);
                                        countDiscoun(unitPrice,e.target.value);
                                    }}  min={1} />
                                </div>
                                <div className={`flex ai-center mt-20`}>
                                    <div className={`col-666 font-14 `}>总需星豆</div>
                                    <h3 className={`font-18 ml-15 `}>{((amount*unitPrice)+'').replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}</h3>
                                </div>
                                <div className={`flex ai-center mt-20`}>
                                    <div className={`col-666 font-14 `}>折后所需</div>
                                    <h3 className={`font-18 ml-15 col-blue`}>{((amount*unitPrice*(discount/10))+'').replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}</h3>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={10} lgOffset={1} className={`pt-40 ${styles.modeBtnTop}`}>
                        <button className={`${styles.medalBtn}`} disabled={amount*unitPrice > modeData.balance} onClick={changeDaoM}>确认兑换
                        {discount<10 && <div className={`flex fd-column ai-conter jc-center ${styles.medalBtnDiscount}`}>
                            <p className={`font-16`}>享受</p>
                            <p className={`font-22 pt-5`}>{discount} 折</p>
                        </div>}
                        {/* {countDiscoun(100) && <div className={`flex fd-column ai-conter jc-center ${styles.medalBtnDiscount}`}>
                        <p className={`font-16`}>享受</p>
                        <p className={`font-22 pt-5`}>{countDiscoun(100)} 折</p>
                      </div>} */}
                        </button>
                    </Col>
                </Row>
               
            </Col>
            
        </div>
    )
}
export default Exchange;