import React, { useEffect, useRef, useState } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { myTransList } from '../../api/public';


import QueueAnim from 'rc-queue-anim';

import FormatMsgTime from "../../components/FormatMsgTime"

import  styles from "./index.module.less";

import LazyLoad from 'react-lazyload';


import { simpleFn } from '../../components/Notification';

import Pay from '../Explore/Detail/Pay';
import Order from '../Explore/Detail/Order';
import OrderDetail from '../Explore/Detail/OrderDetail';
import Popup from '../../components/Popup';

import TimeContent from '../../components/TimeContent';

import Empty from '../../components/Empty';
// import Pagination from '../../components/Pagination';


import RcPagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';

import { t, use } from 'i18next';
// 主题
const themePrimaryColor = 'theme';
const primaryColor =  localStorage.getItem(themePrimaryColor)||'light';

const baseUrl = process.env.BASE_URL+"/ipfs/";

const TableDataCentenr = (props:any)=>{
	console.log('TableDataCentenr=============',props)
	const noImgList = ['noImg1.png', 'noImg2.png', 'noImg3.png'];

  const outputMate = (list:any,type:string)=>{
		let item = list.filter((item:any)=>item.trait_type===type)[0];
		return item?item.value:false
	}
  const formatTimeData=(time:number)=>{
    var date = new Date(time)
            var Y = date.getFullYear() + '-'
            var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
            var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
            var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
            var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
            var s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
            return Y + M + D + h + m + s
  }
    return (
        <>
        {props.data && 
          props.data.length > 0 ? props.data.map((item:any,index:number)=>{
          // console.log(item)
          // let metaDataSystem: any;
          // if (item.metaDataSystem !== '') {
          //   metaDataSystem = JSON.parse(item.metaDataSystem);
          //   if (metaDataSystem.thumb_url !== undefined) {
          //     metaDataSystem.thumb_hash = metaDataSystem.thumb_url;
          //   }
          // } else {
          //   metaDataSystem = {
          //     "type": '',
          //     "size": '',
          //     "thumb_hash": ''
          //   }
          // }
              return (
              
                <div key={item.TransferID} className={`flex ai-center jc-center ${styles.ownList} ${styles.likeList}`} >
                    <Col>
                      <div className={`text-center col-666 ${styles.ownSort}`}>{index+1}</div>
                    </Col>
                    {/* <Col xs className={`flex ai-center`}>
                     <LazyLoad className={`relative  ml-30 ${styles.likeListImg}`}>
                      {(metaDataSystem.type !== undefined && metaDataSystem?.type.split('/')[0] === 'image') ? 
                          <img className={`${styles.likeSortImg}`} src={metaDataSystem.thumb_hash == '' ?
                          props.data.baseUrl + item.dataHash :
                            (item.dataHash === '' ?
                              metaDataSystem.thumb_hash :
                              props.data.baseUrl + metaDataSystem.thumb_hash)} onError={(e: any) => { e.target.onerror = null; e.target.src = require('../../assets/images/' + noImgList[Math.floor(Math.random() * noImgList.length)]) }} alt="" /> : 
                              <div>
                                <div className={`flex jc-center ai-center ${styles.mask}`}><img style={{ display: 'inline-block' }} src={require('../../assets/icon/play.png')} alt=''  /></div>
                                <img className={`${styles.likeSortImg}`} src={props.data.baseUrl + metaDataSystem.thumb_hash} onError={(e: any) => { e.target.onerror = null; e.target.src = require('../../assets/images/' + noImgList[Math.floor(Math.random() * noImgList.length)]) }} alt='' />
                            </div>
                          }
                        </LazyLoad>
                    </Col>
                    <Col xs className={``}>
                      <div className={`${styles.medaTips}`}>
                          <h3 className={`font-18`}>{outputMate(item.metaDataUser.attributes,'cn_name')||item.metaDataUser.name}</h3>
                          <p className={`font-14 col-666 mt-10`}>作品名称</p>
                        </div>
                    </Col> */}
                   <Col xs={5} className={`flex ai-center`}>
                      {
                        item.BatchOperDetails.map((subitem:any,index:number)=>{
                          // let metaDataSystem: any;
                          // console.log(subitem.metaDataSystem!=null)
                          // if (subitem.metaDataSystem!=null && subitem.metaDataSystem !== '') {
                          //   metaDataSystem = JSON.parse(subitem.metaDataSystem);
                          //   if (item.mode!==50 && metaDataSystem.thumb_url !== undefined) {
                          //     metaDataSystem.thumb_hash = metaDataSystem.thumb_url;
                          //   }
                          // } else {
                          //   metaDataSystem = {
                          //     "type": '',
                          //     "size": '',
                          //     "thumb_hash": ''
                          //   }
                          // }
                          return (
                          <div className={`flex ai-center jc-center fd-column mr-20 ${styles.orderNfts}`} key={subitem.tokenID}>

                          
                          <LazyLoad className={`relative ${styles.likeListImg}`}>
                            {
                            
                                <div>
                                    <div className={`flex jc-center ai-center ${styles.mask}`}><img style={{ display: 'inline-block' }} src={require('../../assets/icon/play.png')} alt=''  /></div>
                                    <img className={`${styles.likeSortImg}`} src={baseUrl + subitem.DataHash} onError={(e: any) => { e.target.onerror = null; e.target.src = require('../../assets/images/' + noImgList[Math.floor(Math.random() * noImgList.length)]) }} alt='' />
                                </div>
                              }
                              </LazyLoad>
                              <div className={`font-12 text-center ellipsis-1 ${styles.orderNfts}`}>
                                  {subitem.Name}
                                </div>
                          </div>)
                        })
                      }

                    </Col>
                    <Col xs className={`flex`}>
                        <div className={`${styles.medaTips}`}>
                          <h3 className={`font-18`}>{eval(item.BatchOperDetails.map((item:any)=>item.Amount).join("+"))}</h3>
                          <p className={`font-14 col-666 mt-10`}>数量</p>
                        </div>
                    </Col>
                  
                    
                    <Col xs className={`flex`}>
                        <div className={`${styles.medaTips}`}>
                          <h3 className={`font-14`}>{formatTimeData(item.CreateTime)}</h3>
                          <p className={`font-14 col-666 mt-10`}>转赠时间</p>
                        </div>
                    </Col>
                   <Col xs={3} className={`flex`}>
                        <div className={`${styles.medaTips}`}>
                          <h3 className={`font-14`} style={{wordBreak:'break-all'}}>{props.type===0?item.ToUserName:item.FromUserName}</h3>
                          <p className={`font-14 col-666 mt-10`}>{props.type===0?'受赠者':'转赠者'}</p>
                        </div>
                    </Col>
                     
                   
                </div>
                
              )
            }):
            <Empty
                imageStyle={{
                  width: 480,
                  height: 'auto',
                  display: 'block'
                }}
                description={
                  <p style={{ textAlign: 'center', margin: '24px 0px', fontSize: '24px', color: '#9f9f9f' }}>{t(`methods.noData`)}</p>
                }
                image={require('../../assets/icon/noData.png')}
              />
          
          }


     
				
        </>
    )
    
}
const OrderList = () => {
      const userName:any = sessionStorage.getItem('userName');
    

      const [dataList,setDataList] = useState<any>({})
      const UserTransList = async ()=>{

        const res = await myTransList(queryData);

        if(res.code===0){
            console.log(res);
            setDataList(res.data)
        }else{

        }
      }

      const [current, setCurrent] = useState(1);
      const [total, setTotal] = useState<number>(0);
      const Locale = localStorage.getItem('localesLng');
      const LocaleEn_US: object = {
        // Options.jsx
        items_per_page: '/ page',
        jump_to: 'Go to',
        jump_to_confirm: 'confirm',
        page: 'Page',

        // Pagination.jsx
        prev_page: 'Previous Page',
        next_page: 'Next Page',
        prev_5: 'Previous 5 Pages',
        next_5: 'Next 5 Pages',
        prev_3: 'Previous 3 Pages',
        next_3: 'Next 3 Pages',
        page_size: 'Page Size',
      };
      const [queryData, setQueryData] = useState<any>({
        UserName:userName,
        Cmd:1,// 1 表示我的转移记录， 其他 别人转我的记录。
        PageNo: 1,
        PageSize: 10
      })
      useEffect(()=>{
        UserTransList()
      },[queryData])

      const [navType,setNavType] = useState(0)
      return (
        <>
            <Col lg={12} xl={10} xlOffset={1} >
                <div className={`${styles.billMain}`}>
                    <div className={`flex ai-center bg${primaryColor}  ${styles.edtailCardTitle}`}>
                        <img src={require(`../../assets/images/nftDetail/icon-nftDetail3.png`)} alt="" />
                        <h3>转赠记录</h3>
                    </div>

                    <div className={`bg${primaryColor} mt-20`}>
                        <div>
                      
                          <ul className={`flex ${styles.orderNav}`}>
                            <li className={`pointer ${navType===0?styles.cur:''}`} onClick={()=>{setQueryData({...queryData,PageNo:1,Cmd:1});setCurrent(1);setNavType(0)}}>我的转赠</li>
                            <li className={`pointer ${navType===1?styles.cur:''}`} onClick={()=>{setQueryData({...queryData,PageNo:1,Cmd:2});setCurrent(1);setNavType(1)}}>我的受赠</li>
                          </ul>
                        </div>
                        <Col xs={12}>
                            <TableDataCentenr data={dataList} type={navType} onChange={()=>window.location.reload() } />

                            <div className={'flex ai-center jc-center pt-20 pb-40'}>
                              <RcPagination current={current}  total={dataList.count} pageSize={10} showPrevNextJumpers={false} locale={Locale === 'en' ? LocaleEn_US : undefined} className={`font-20 text-conter ${styles.orginPagination}`} onChange={(current: number, pageSize: number) => {
                                let data = Object.assign({}, queryData, { PageNo: (current - 1) * pageSize });
                                setCurrent(current);
                                setQueryData(data)
                              }}  />
                            </div>
                        </Col>
                    </div>
                </div>
            </Col>

        </>
    
      );

}
export default OrderList;