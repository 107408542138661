import { get,post } from '../axios/axios-methods';

// 首页获取数据
export const getNewFeed = async (params:any) => await get('/v1/home/newsfeed', {params: params});

// 钱包登录
export const metaLogin = async (params:any) => await post('/v1/user/signin', params);

// 用户密码登录
export const userLogin =async (params:any) => await post('/v1/user/weblogin',params);

// 用户手机号码注册 
export const regbyPhone =async (params:any) => await post('/v1/user/reg',params);

// 获取后台登录

export const vpCenterLogin =async (params:any) => await post('/v1/user/username_login',params);

// 获取手机验证码
export const getSmsCode =async (params:any) => await get('/v1/user/get_veri_code', {params: params});

// 校验验证码
export const checkSmsCode =async (params:any) => await post('/v1/user/check_veri_code',params);


//查询用户是否已注册
export const changeUser =async (params:any) => await get('/v1/user/whether_reg', {params: params});

//新登录手机号码登录
export const vplogin =async (params:any) => await post('/v1/user/phone_login',params);
// 用户上传文件保存系统
export const uploadfileIDCard =async (params:any) => await post('/v1/user/uploadfile',params);
// 用户提交认证
export const uploadUserDoc =async (params:any) => await post('/v1/user/realname_auth',params);
// 创建身份
export const createWallet =async (params:any) => await post('/v1/user/create_id_address',params);

//获取未领取积分
export const unClaimedList =async (params:any) => await get('/v1/point/unclaimedlist',{params: params});

//领取积分
export const receivePoint =async (params:any) => await post('/v1/point/receivepoint',params);

// 平台今日已领取积分
export const receiveDpointotal =async (params:any) => await get('/v1/point/receivedpointotal',{params: params});
// 兑换道牌
export const exchangeDao =async (params:any) => await post('/v1/point/exchangedao',params);


// 获取Explore NET列表
export const getNFTList = async(params: any) => await get('/v1/nft/items', {params: params});

// 获取NFT详情
export const getNFTDetail = async(params: any) => await get('/v1/nft/item/detail', {params: params});

export const newGetNFTDetail = async(params: any) => await get('/v1/nft/detail', {params: params});

// NFT交易记录
export const getNFTTradelist = async (params: any) => await get('/v1/nft/tradelist', {params: params});


// 统计数据
export const getStatsList = async (params: any) => await get('/v1/nft/ranking', {params: params});

// 获取格子列表
export const getGridList  = async (params: any) => await get('/v1/grid/list', {params: params});
// 格子详情
export const getGridDetail   = async (params: any) => await get('/v1/grid/detail', {params: params});




// 搜索格子
export const searchGridList  = async (params: any) => await get('/v1/grid/searchgrid', {params: params});

// 获取格子索引列表
export const getGridIndexList  = async (params: any) => await get('/v1/grid/indexlist', {params: params});

// 修改用户格子参数
export const gridSetData = async (params: any) => await post('/v1/grid/param/update',params);

// 我的格子 
export const getMyGridList  = async (params: any) => await get('/v1/grid/get_mylist', {params: params});

//查询收藏列表 
export const getFavourite = async (params: any) => await get('/v1/grid/getfavourite',{params: params});
// 新增收藏格子
export const addFavourite = async (params: any) => await post('/v1/grid/addfavourite',params);
// 新增收藏夹
export const addFavouriteFolder = async (params: any) => await post('/v1/grid/addfavouritefolder',params);
// 删除收藏夹 
export const delFavouriteFolder = async (params: any) => await post('/v1/grid/delfavouritefolder',params);
// 编辑收藏夹
export const updateFavouriteFolder = async (params: any) => await post('/v1/grid/updatefavouritefolder',params);

//修改格子
export const updateGrid = async (params: any) => await post('/v1/grid/updategrid',params);


// 查询用户是否用户该Hash
export const ownerofhash = async (params: any) => await get('/v1/user/ownerofhash', { params: params });

// 查询该文件是否已经上传
export const hasfilehash = async (params: any) => await get('/api/ipfs/hasfilehash', { params: params });

// 上传文件至IPFS
export const uploadfile = async (params: any) => await post('/api/ipfs/uploadfile', params, {timeout: 300000}); 

// 上传MetaData至IPFS
export const uploaddata = async (params: any) => await post('/api/ipfs/uploaddata', params); 

// 创建数据资产
export const createAssets = async (params: any) => await post('/v1/nft/create', params);

// 创建数据资产(proxy、owner)
export const createAssetsByProxy = async (params: any) => await post('/v1/nft/createbyproxy', params);

// 铸造数据资产
export const castNFT = async (params: any) => await post('/v1/nft/cast', params);

// 铸造 prize 
export const castPrizeNFT = async (params: any) => await post('/v1/nft/castprize', params);

// 铸造盲盒
export const castLootBox = async (params: any) => await post ('/v1/nft/castlootbox', params);

// 获取用户信息
export const getUserInfo = async (params: any) => await get('/v1/user/get_info', { params: params });

// 上传profile图片
export const uploaProfileImg = async (params: any) => await post('/api/ipfs', params); 




// 更新profile数据
// export const updateProfile = async (params: any) => await post('/v1/user/profile', params);
export const updateProfile = async (params: any) => await post('/v1/user/update_info', params);

// 获取用户NFT、数据资产列表
export const getUserNFTData = async (params: any) => await get('/v1/user/datanfts', {params: params});

// 我的积分账单
export const getUserBill = async (params: any) => await get('/v1/user/bill', {params: params});

//账单信息统计
export const getUserBillStats = async (params: any) => await get('/v1/user/bill/stats', {params: params});

// nft评论
export const getCommentList = async (params: any) => await get('/v1/nft/comment/list', {params: params});

// 新增评论 
export const setCommentCreate = async (params: any) => await post('/v1/nft/comment/create', params);

// 查询操作状态
export const queryOperStatus = async (params: any) => await get('/v1/grid/nftfromlootbox', {params: params});

// 转移数据资产
export const transferDataAsset = async (params: any) => await post('/v1/linkdata/transferdata', params);

// 转移 batch NFT
export const transferbatchnft = async (params: any) => await post('/v1/nft/transferbatchnft', params);

// 转移 单个 NFT
export const transferonenft = async (params: any) => await post('/v1/nft/transfer_one_nft', params);

// 创建 Collection
export const createCollection = async (params: any) => await post('/v1/linkdata/createcollect', params);

// 删除 Collection
export const deleteCollection = async (params: any) => await post('/v1/linkdata/deletecollect', params)

// 获取 VP Collection
export const getVPCollection = async () => await get('/v1/linkdata/vpcollectlist');

// 获取用户 Collection 列表
export const getUserCollectionList = async (params: any) => await get('/v1/linkdata/collectlist', {params: params});

// Like NFT
export const likeNFT = async (params: any) => await post ('/v1/nft/like', params);

// 售卖 
export const shelvesNFT = async (params: any) => await post('/v1/nft/nftshelves', params);

// 获取集合下面的NFT
export const nftlistbycollect = async (params: any) =>  await get('/v1/linkdata/nftlistbycollect', {params: params}); 

// 分享 NFT
export const shareNFT = async (params: any) => await get('/v1/nft/nftshare', {params: params});



//品牌馆列表
export const topCollectList= async (params: any) => await get('/v1/brand/list', {params: params});

//品牌系列
export const collectListByParent= async (params: any) => await get('/v1/brand/pro_list', {params: params});

//品牌馆详情
export const collectDeail= async (params: any) => await get('/v1/brand/detail', {params: params});
//系列详情
export const seriesDeail= async (params: any) => await get('/v1/series/detail', {params: params});



//查询前100
export const rpc = async (params: any) => await post('https:/web3.verseport.com/rpc', params);

export const top100List= async (params: any) => await get('/v1/rank/get_bean', {params: params});
export const top100Weight= async (params: any) => await get('/v1/rank/get_dao_power', {params: params});

// 潜力作品
export const likeSortList= async (params: any) => await get('/v1/user/like_ranking', {params: params});
// 收藏家排名
export const nftNumList= async (params: any) => await get('/v1/user/nftowner_ranking', {params: params});

//首页推荐商品
export const recommendList= async (params: any) => await get('/v1/app/get_recommendlist', {params: params});

//推荐格子/轮播图
export const loopic= async (params: any) => await get('/v1/app/loopic', {params: params});

// 获取服务器时间
export const getsevtime= async (params: any) => await get('/v1/sys/getsevtime', {params: params});

//查询订单
export const getOrderStatus= async (params: any) => await get('/v1/order/detail', {params: params});
// 生成订单
export const createOrder = async (params: any) => await post('/v1/order/create_order', params);
// 支付宝支付
export const aliPagePay = async (params: any) => await post('/v1/order/ali_webpay', params);
//微信支付
export const wxPagePay = async (params: any) => await post('/v1/order/wxwebpay', params);

//订单列表
export const getMyOrderList= async (params: any) => await get('/v1/order/get_list', {params: params});
// 取消订单 
export const cancelOrder= async (params: any) => await post('/v1/order/cancel_order',params);


// 盲盒转赠
export const transferOneLootBox= async (params: any) => await post('/v1/nft/transfer_lootbox',params);

// 查询合约地址
export const contractInfo= async (params: any) => await get('/v1/linkdata/contractinfo', {params: params});

// 查询文本
export const helpList= async (params: any) => await get('/v1/user/helplist', {params: params});

// 我的nft列表
export const userNFTList= async (params: any) => await get('/v1/user/nftlist', {params: params});

// 开启盲盒
export const lootBoxOpen = async (params: any) => await post('/v1/lootbox/unpack',params);

// 我喜爱
export const userLiked = async (params: any) => await get('/v1/user/liked',{params: params});

// 重置密码
export const userResetpwd = async (params: any) => await post('/v1/user/resetpwd',params);

// 公告
export const noticeList = async (params: any) => await get('/v1/sys/get_noticelist',{params: params});

// nft详情
export const getNFTNewDetail = async(params: any) => await get('/v1/nft/newdetail', {params: params});


// 商品详情
export const getProductDetail = async(params: any) => await get('/v1/product/detail', {params: params});

// nft详情其他参数
export const getOwnerNFT = async(params: any) => await get('/v1/nft/ownernft', {params: params});

// 查询等级
export const getMyNFTSum = async(params: any) => await get('/v1/user/mynftsum', {params: params});
// 通过等级查询列表
export const getMyNFTlist = async(params: any) => await get('/v1/user/mynftlist', {params: params});

//我的道牌
export const getPropList = async(params: any) => await get('/v1/user/proplist', {params: params});

//我的盲盒
export const getLootBoxList = async(params: any) => await get('/v1/grid/lootboxlist', {params: params});

//燃料套餐 
export const generalsheLvesList = async(params: any) => await get('/v1/product/fuel_list', {params: params});

// 购买燃料次数
export const alipagepayFuel = async (params: any) => await post('/v1/order/ali_webpay',params);

// 燃料次数
export const myTransrdList = async(params: any) => await get('/v1/user/get_transfertimes', {params: params});

// 创建燃料订单
export const createFuelOrder = async (params: any) => await post('/v1/order/create_order',params);

// 取消燃料订单 
export const cancelFuelOrder = async (params: any) => await post('/v1/order/cancel_order',params);

// 我的转赠记录
export const myTransList = async(params: any) => await get('/v1/user/get_transfer_list', {params: params});

// 我拥有格子对应页数
export const myPageCount = async(params: any) => await get('/v1/grid/pagecount', {params: params});
// 合并格子
export const mergeGrid = async (params: any) => await post('/v1/grid/merge',params);

// 拆分格子/v1/grid/unmerge

// 转赠合并的格子
export const transferBatchGrid = async (params: any) => await post('/v1/nft/transferbatchgrid',params);

// 正在售卖的页码
export const salesPageList = async(params: any) => await get('/v1/grid/saleslist', {params: params});
// 当前页码可售卖
export const salesPageListItem = async(params: any) => await get('/v1/grid/salespage', {params: params});

//系列列表
export const nftmarketbycollect = async(params: any) => await get('/v1/product/list', {params: params});



//查询用户是否已经托管PIN码
export const searchtrusteeship = async(params: any) => await get('/v1/user/search_trusteeship', {params: params});

//托管PIN码到服务器
export const pincodetrusteeship = async (params: any) => await post('/v1/user/set_trusteeship',params);

// 资产统计
export const getAssetSum = async(params: any) => await get('/v1/user/get_asset_sum', {params: params});
// 我的资产
export const getAssetList = async(params: any) => await get('/v1/user/get_asset_list', {params: params});

// 获取合约地址
export const getContInfoAddr = async(params: any) => await get('/v1/sys/get_cont_info', {params: params});

//邀请排行榜
export const inviteRanking = async(params: any) => await get('/v1/user/invite_ranking', {params: params});

//合约配置
export const getContInfo = async(params: any) => await get('/v1/nft/get_cont_info', {params: params});

