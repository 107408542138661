/* eslint-disable no-unreachable */
import instance from './axios';
// import { v4 as uuidv4 } from 'uuid';

const request = async function (option:any) {
  const { headers = {} } = option;
  Object.assign(headers, {
    // "Content-Type": 'application/json; charset=utf-8'
  });
  const datas = await instance(option);
  return datas;
};

// get 请求
export const get = async function (url:string, config?: any) {
  const timestamp = new Date().getTime();
  if (/.*\?.*$/.test(url)) {
    url += '&t=' + timestamp;
  } else {
    url += '?t=' + timestamp;
  }
  const datas = await request(
    Object.assign(
      {
        url
      },
      config
    )
  );
  switch(datas.data.code){
    case 9974:
        // sessionStorage.clear();
        // window.location.href='/';
        // return datas.data;
       break;
    default:
      return datas.data;
  }
  
};

// post 请求
export const post = async function (url:string, data:any, config?:any) {
  const datas = await request(
    Object.assign(
      {
        url,
        method: 'post',
        data: data
      },
      config
    )
  );
  switch(datas.data.code){
    case 9974:
        // sessionStorage.clear();
        // window.location.href='/';
        // return datas.data;
       break;
    default:
      return datas.data;
  }
};