import Web3 from 'web3';
import { useEffect, useState } from 'react';
import UploadImg from './UploadImg';
import styles from './index.module.less';
import { Col } from 'react-flexbox-grid';
import { useTranslation } from 'react-i18next';
import Select, { components } from 'react-select';
import { createCollection, getUserCollectionList, getVPCollection } from '../../api/public'

const web3 = new Web3(window.ethereum);


const { Option } = components;
const IconOption = (props: any) => {
  return (
    <Option {...props}>
      <div style={{ display: 'flex' }}>
        <img src={require('../../assets/icon/' + props.data.icon)} style={{ width: 36, height: 36, borderRadius: '50%' }} />
        <div style={{ marginLeft: 16 }}>
          <span className={`${styles.metaDataType}`}>{props.data.label}</span>
          <p style={{ margin: 0, lineHeight: '24px' }}>{props.data.desc}</p>
        </div>
      </div>
    </Option>
  )
}

const CollOption = (props: any) => {
  return (
    <Option {...props}>
      <div style={{ display: 'flex' }}>
        <img src={props.data.icon} style={{ width: 36, height: 36, borderRadius: '50%' }} />
        <div style={{ marginLeft: 16 }}>
          <span className={`${styles.metaDataType}`} style={{lineHeight: '36px'}}>{props.data.label}</span>
          {/* <p style={{ margin: 0, lineHeight: '24px' }}>{props.data.desc}</p> */}
        </div>
      </div>
    </Option>
  )
}

const CollectionList = () => {
  // vp 顶层 Collection List
  const [collList, setCollList] = useState([]);
  const [selectOption, setSelectOption] = useState<any>([]);
  const [VPCollList, setVPCollection] = useState([]);
  const [metaDataSystem, setMetaDataSystem] = useState<any>({})
  const [hasCreatorEarn, setHashCreateEarn] = useState(false)
  const [collectionInfo, setCollectionInfo] = useState(
    {
      "name": "",
      "image": "",
      "background_color": "",
      "animation_url": "",
      "youtube_url": "",
      "description": "",
      "external_link": "",
      "attributes": [
        {
          "display_type": "",
          "trait_type": "category",
          "value": "99",
          "max_value": null
        },
        {
          "display_type": "number",
          "trait_type": "creator_earnings",
          "value": 0,
          "max_value": 10
        },
        {
          "display_type": "",
          "trait_type": "fee_recipient",
          "value": "",
          "max_value": null
        },
        {
          "display_type": "number",
          "trait_type": "level",
          "value": 2,
          "max_value": null
        },
        {
          "display_type": "",
          "trait_type": "parent_level",
          "value": "",
          "max_value": null
        },
        {
          "display_type": "",
          "trait_type": "editors",
          "value": [],
          "max_value": null
        },
        {
          "display_type": "",
          "trait_type": "chain",
          "value": "2011",
          "max_value": null
        },
        {
          "display_type": "",
          "trait_type": "contract_address",
          "value": "",
          "max_value": null
        },
        {
          "display_type": "",
          "trait_type": "owner",
          "value": sessionStorage.getItem('userName'),
          "max_value": null
        },
        {
          "display_type": "",
          "trait_type": "url",
          "value": '',
          "max_value": null
        },
        {
          "display_type": "",
          "trait_type": "logo_image_url",
          "value": '',
          "max_value": null
        },
        {
          "display_type": "",
          "trait_type": "featured_image_url",
          "value": '',
          "max_value": null
        },
        {
          "display_type": "",
          "trait_type": "banner_image_url",
          "value": '',
          "max_value": null
        }
      ]
    }
  )

  const { t } = useTranslation();

  const ChainOptions: any = {
    // { value: "61", label: "Poriot", icon: "poriot.png" },
    "mumbai": { value: "20712", label: "Mumbai", icon: "polygon.png", desc: t('methods.mumbaiDesc') },
    "rinkeby": { value: "2011", label: "Rinkeby", icon: "ethereum.png", desc: t('methods.rinkebyDesc') },
  };

  // 0x7597db9fc3B8fF164bd5455d6A214148A54727F3
  // 14c123233a2a773d590a8439fdd7f7fbf21a232332b94618ace3ad6021af72fa
  useEffect(() => {
    GetVPCollList();
    GetUserCollList();
  }, [])

  const GetUserCollList = async () => {
    const UserName = sessionStorage.getItem('userName');
    const res = await getUserCollectionList({ userName: UserName })
    let temOption: any = []
    for (let i = 0; i < res.data.items.length; i++) {
      res.data.items[i].metaDataSystem = JSON.parse(res.data.items[i].metaDataSystem);
      temOption.push({
        "value": res.data.items[i].dataChainUUID,
        "label": res.data.items[i].metaDataUser.name,
        "icon": res.data.items[i].metaDataUser.attributes[10].value,
        "level": res.data.items[i].metaDataUser.attributes[3].value
      })
    }
    setSelectOption(temOption);
    setCollList(res.data.items);
  }

  const GetVPCollList = async () => {
    const res: any = await getVPCollection();
    for (let i = 0; i < res.data.items.length; i++) {
      res.data.items[i] = { ...res.data.items[i], ...ChainOptions[`${res.data.items[i].linkName}`] }
    }
    console.log(res.data.items);
    setVPCollection(res.data.items)

  }

  const CreateCollection = async () => {
    const UserName = sessionStorage.getItem('userName');
    const TimeStamp = Math.round(+new Date());
    const DataString = JSON.stringify({ "name": collectionInfo.name });
    const MetaDataSystem = JSON.stringify(metaDataSystem);
    const MetaDataUser = JSON.stringify(collectionInfo);
    const SocialDataToken = sessionStorage.getItem('socialDataToken');

    const strSeriesConnMsg = `${UserName}${SocialDataToken}${TimeStamp}`;
    const MsgKecak256 = web3.utils.keccak256(`\u0019Ethereum Signed Message:\n${new Blob([strSeriesConnMsg]).size}${strSeriesConnMsg
      }`);

    const res = await createCollection({
      "userName": UserName,
      "name": collectionInfo.name,
      "metaDataSystem": MetaDataSystem,
      "metaDataUser": MetaDataUser,
      "dataString": DataString,
      "timeStamp": TimeStamp,
      "msgKeccak256": MsgKecak256.substr(2)
    });

    if (res.code == 0) {
      console.log("集合创建成功")
    } else {
      console.log("集合创建失败")
    }
  }

  const setLogoImgUrl = (url: string) => {
    let temp = { ...collectionInfo };
    temp.attributes[10].value = url;
    setCollectionInfo(temp);
  }

  const setFeatureImgUrl = (url: string) => {
    let temp = { ...collectionInfo };
    temp.attributes[11].value = url;
    setCollectionInfo(temp);
  }

  const setBannerImgUrl = (url: string) => {
    let temp = { ...collectionInfo };
    temp.attributes[12].value = url;
    setCollectionInfo(temp);
  }

  const categoryDefVal = t('methods.categoryDataList', { returnObjects: true })
  const categoryListOptions = [...categoryDefVal]


  return (
    <Col xs={12} sm={10} md={10} lg={8} smOffset={1} mdOffset={1} lgOffset={2}>
      <div className={`flex fd-column ${styles.settings}`}>
        <h1 className={`${styles.title}`}>{t('methods.createCollection')}</h1>
        <div>
          <p><span style={{ color: 'rgb(235, 87, 87)' }}>*</span>{t('methods.requiredFields')}</p>

          {/* logo 图 */}
          <div className={`${styles.required} ${styles.field}`}>
            <label>{t('methods.collLogoTitle')}</label>
            <p>{t('methods.collLogoDesc')}</p>
            <UploadImg extraStyle={`${styles.logoImg}`} onChange={setLogoImgUrl} />
          </div>

          {/* Featured image */}
          <div className={`${styles.field}`}>
            <label>{t('methods.collFeaTitle')}</label>
            <p>{t('methods.collFeaDesc')}</p>
            <UploadImg extraStyle={`${styles.featureImg}`} onChange={setFeatureImgUrl} />
          </div>

          {/* Banner image */}
          <div className={`${styles.field}`}>
            <label>{t('methods.collBannerTitle')}</label>
            <p>{t('methods.collBannerDesc')}</p>
            <UploadImg extraStyle={`${styles.bannerImg}`} onChange={setBannerImgUrl} />
          </div>

          {/* Name */}
          <div className={`${styles.required} ${styles.field}`} style={{ marginBottom: '24px' }}>
            <label>{t('methods.name')}</label>
            <input type="text" name="Item-name" className={`${styles.inputItem}`} placeholder={t('methods.itemName')} onChange={(e) => {
              let temp = { ...collectionInfo };
              temp.name = e.target.value;
              setCollectionInfo(temp);
            }} />
          </div>

          {/* URL */}
          <div className={`${styles.field}`}>
            <label>URL</label>
            <p>{t('methods.urlDesc')}</p>
            <input type="text" name="Item-name" className={`${styles.inputItem}`} placeholder={t('methods.itemName')} onChange={(e) => {
              let temp = { ...collectionInfo };
              temp.attributes[9].value = e.target.value;
              setCollectionInfo(temp);
            }} />
          </div>

          {/* External link */}
          <div className={`${styles.field}`}>
            <label>{t('methods.extLink')}</label>
            <p>{t('methods.extLinkDesc')}</p>
            <input className={`${styles.inputItem}`} type="text" name="External-link" placeholder="https://yousite.io/item/123" onChange={(e) => {
              let temp = { ...collectionInfo };
              temp.external_link = e.target.value;
              setCollectionInfo(temp);
            }} />
          </div>

          {/* description */}
          <div className={`${styles.field}`}>
            <label>{t('methods.description')}</label>
            <p>{t('methods.descriptionDesc')}</p>
            <textarea className={`${styles.textAreaItem}`} onChange={(e) => {
              let temp = { ...collectionInfo };
              temp.description = e.target.value;
              setCollectionInfo(temp);
            }} />
          </div>

          {/* Select category */}
          <div className={`${styles.field}`}>
            <label>{t("methods.category")}</label>
            <p>{t("methods.categoryDesc")}</p>
            <Select defaultValue={categoryListOptions[0]} options={categoryListOptions} onChange={(e: any) => {
              let temp = { ...collectionInfo };
              temp.attributes[0].value = e.value;
              setCollectionInfo(temp);
            }} />
          </div>

          {/* Select parent_level */}
          <div className={`${styles.field}`}>
            <label>{t("methods.parentLevel")}</label>
            <Select defaultValue={selectOption[0]} options={selectOption} components={{ Option: CollOption }} onChange={(e: any) => {
              console.log(e)
              let temp = { ...collectionInfo };
              temp.attributes[4].value = e.value;
              temp.attributes[3].value = e.level + 1;
              setMetaDataSystem({
                id: e.dataChainUUID,
                vpCollectName: e.label
              })
              setCollectionInfo(temp);
            }} />
          </div>

          {/* Select Blockchain */}
          <div className={`${styles.field}`} style={{ marginTop: 18 }}>
            <label>{t('methods.chain')}</label>
            <Select
              defaultValue={VPCollList[0]}
              onChange={(e: any) => {
                console.log(e)
                setMetaDataSystem({
                  id: e.id,
                  vpCollectName: e.vpCollectName
                })
                let temp = { ...collectionInfo };
                // temp.attributes[4].value = e.vpCollectName;
                temp.attributes[6].value = e.value;
                setCollectionInfo(temp);
              }}
              options={VPCollList}
              components={{ Option: IconOption }}
            />
          </div>

          {/* Creator Earnings */}
          <div className={`${styles.field}`}>
            <label>{t('methods.earnings')}</label>
            <p>{t('methods.earningsDesc')}</p>
            <p>Percentage fee</p>
            <input className={`${styles.inputItem}`} type="text" name="External-link" placeholder="e.g.2.5" onChange={(e: any) => {
              if (e.target.value == '') {
                setHashCreateEarn(false);
              } else {
                setHashCreateEarn(true);
              }
              let temp = { ...collectionInfo };
              temp.attributes[1].value = e.target.value - 0;
              setCollectionInfo(temp);
            }} />
          </div>

          {hasCreatorEarn == true ?
            <div className={`${styles.required} ${styles.field}`} style={{ marginBottom: '24px' }}>
              <label>{t('methods.payoutAddress')}</label>
              <input type="text" name="Item-name" className={`${styles.inputItem}`} placeholder="Please enter an address, e.g. 0x1ed3... or destination.eth" onChange={(e: any) => {
                let temp = { ...collectionInfo };
                temp.attributes[2].value = e.target.value;
                setCollectionInfo(temp);
              }} />
            </div> : ''}

          <button className={`${styles.createBtn}`} onClick={(e) => {
            e.preventDefault()
            console.log(collectionInfo)
            console.log(metaDataSystem)
            CreateCollection();
          }}>{t('methods.create')}</button>
        </div>
      </div>
    </Col>
  );
}
export default CollectionList;