
import React,{useState,useEffect,useRef}  from 'react';

import { Grid, Row, Col } from 'react-flexbox-grid';

import styles from './index.module.less';

// 切换语言
import { useTranslation } from 'react-i18next';

// api
import { transferonenft,queryOperStatus } from '../../../api/public';

import { simpleFn } from '../../../components/Notification';

import Pin from '../../../components/User/Pin';
import ShowLoadding from '../../../components/ShowLoadding';
// web3
// import detectEthereumProvider from '@metamask/detect-provider';
import Web3 from 'web3';
import { blob } from 'node:stream/consumers';


const web3 = new Web3(Web3.givenProvider);
const Exchange =({onChange,modeData}:any)=>{
    // console.log("==================",modeData)
    const { t } = useTranslation();
    const userName:any = sessionStorage.getItem('userName');

    const userInfo = JSON.parse(sessionStorage.getItem('userInfo')||'') ;
    const [amount,changAmount] = useState<any>(100);
    const [unitPrice,setUnitPrice] = useState<number>(1000000);
    const [daoSign,setDaoSign] =  useState<string>('金牌')
    useEffect(()=>{
        // let num = modeData.type==='888'?1000000:modeData.type==='889'?10000:100;
        // setUnitPrice(num);
        // countDiscoun(num,amount);
        // let dao = modeData.type==='888'?'金牌':modeData.type==='889'?'银牌':'铜牌';
        // setDaoSign(dao)
    },[])
    const changeDaoM = async ()=>{

        console.log('===========222222',modeData.balance)
        if(amount > modeData.balance*1 ){
            simpleFn('转赠星豆不得超过所拥有数量！','error')
            return false;
        }else{
            setShowPin(true)
        }

       
    }
    const [discount,setDiscount] = useState<number>(10)
    const [showPin,setShowPin] = useState<boolean>(false)
    const evaluatePIN= async (e:any)=>{
        console.log(e)
		const FromUserName = sessionStorage.getItem('userName');
		const DataHash = modeData.dataHash;
        const timestamp = new Date().getTime(); 
		const TokenID = '666';
		const DestIDs = modeData.destID*1;
		const Amount = amount*1;
		// 
        const temp =  `${FromUserName}${fromAdd}${DestIDs}${TokenID}${Amount}${timestamp}`;
        const msgKeccak256 =web3.utils.keccak256("\u0019Ethereum Signed Message:\n"+ new Blob([temp]).size + temp);
        const sign = await web3.eth.accounts.sign(web3.utils.utf8ToHex('\x00'+temp),e.privateKey);
		const params = {
            FromUserName: FromUserName,
			ToUserName: fromAdd,//fromAdd,
			DestID: DestIDs,
			TokenID: TokenID,
			Amount: Amount,
			TimeStamp: timestamp,
			MsgKeccak256: msgKeccak256.substr(2),
			Sig: sign.signature.substr(2),
		}
		const result = await transferonenft(params);
		if (result.code == 0) {
			setTransferStatus(1);
			setShowLoadding(true)
			QueryOperStatus(result.data.DataOperUUID);

		}else if(result.code===14){
            simpleFn('转赠次数不足', 'error'); 
        }else{
        simpleFn(result.msg, 'error'); 
        }

	}
    let intervalHandle = useRef<any>();

	const [showLoadding, setShowLoadding] = useState<boolean>(false);
    const QueryOperStatus = async (val: any) => {
		// intervalHandle.current = setInterval(async () => {
		// 	const res = await queryOperStatus({ "operUUID": val });
		// 	for (let i = 0; i < res.data.items.length; i++) {
		// 		setTransferStatus(res.data.items[i].operStatus)
		// 		if (res.data.items[i].operStatus === 2) {
		// 			setTimeout(()=>{
		// 				// setShowLoadding(false);
		// 				clearInterval(intervalHandle.current)
		// 				onChange()
		// 			  },1000)
					
		// 		  } else {
		// 			setShowLoadding(true);
		// 		  }
		// 		// 
		// 	}
		// }, 3000);
        setShowLoadding(true);
        onChange();
        // setTimeout(()=>{
        // setShowLoadding(false)
        // onChange()
        // },5000)

	}
    const [fee,setFee]=useState(2);
    const [isDisabled,setIsDisabled] = useState<boolean>(false)
    useEffect(()=>{
        changeFee(amount);
        setIsDisabled((amount>modeData.balance));
    },[amount])
    const changePin=(e:any)=>{
        console.log("==============11111",e)
        if(e){
            evaluatePIN(e)
        }else{
            onChange(false);
        }
    }
    const [transferStatus, setTransferStatus] = useState(0);
    const [fromAdd, setFromAdd] = useState<string>();

	const [checkResult, setCheckResult] = useState<boolean>(true);
    const changeFee=(num:number)=>{
        const countIntegral =  ( modeData.goldMedal*500000 + modeData.silverMedal*5000 + modeData.bronzeMedal*50 ) + modeData.balance*1;
        switch(true){
            case countIntegral<200000:
                setFee(2)
                break
            case 200000<=countIntegral && countIntegral<400000:
                setFee(1.8)
                break
            case 400000<=countIntegral && countIntegral<600000:
                setFee(1.6)
                break
            case 600000<=countIntegral && countIntegral<800000:
                setFee(1.4)
                break
            case 800000<=countIntegral && countIntegral<1000000:
                setFee(1.2)
                break
            default:setFee(1)
        }


      }

      const handleShowLoadding = () => {
		setShowLoadding(!showLoadding);
	}
    return (
        <>
        {showLoadding?<ShowLoadding  data={{type:transferStatus}} onChange={() => handleShowLoadding()} />:
            showPin ? <Pin onChange={(e:any)=>{changePin(e)}} /> :
                <div className={`flex ai-center jc-center ${styles.popUps}`} >
                    <div className={`${styles.popBg}`} onClick={() => onChange && onChange(false)}>背景</div>
                    <Col xs={10} sm={10} lg={8} xl={6} className={`flex jc-center ai-center fd-column ${styles.popUpsMode}`}>
                        <div className={`${styles.popClose}`}  onClick={() => onChange && onChange(false)}>close</div>
                        <h3 className={`${styles.logTitle}`}>星豆转赠</h3>
                        <Row className={`pt-40 ${styles.logMain}`}>
                            <Col lg={10} lgOffset={1}>
                                <div className={`flex jc-center ai-center font-14 ${styles.warnTips}`}>
                                    <img src={require('../../../assets/images/login/tips-icon.png')} alt='' className={`mr-10`} />
                                    <span>为了您的账户安全，需要确认好转赠接收身份地址!<br />赠送成功后，若未及时收到，请在30分钟后再次查看!</span>
                                </div>
                            </Col>
                            <Col lg={10} lgOffset={1} className={`mt-20 mb-40`}>
                            <div className={`flex ai-center`}>
                                <div className={`col-666 font-14 `}>拥有星豆</div>
                                <h3 className={`font-18 ml-15 `}>{modeData.balance}</h3>
                            </div>
                            {transferStatus == 0 &&
                                <>
                                    <p className={`pt-20 col-666 font-14 `}>{t('methods.transferAddress')}</p>
                                    <input className={`mt-10 ${styles.addMetaInput}`} placeholder="e.g. 0x1ed3... or destination.eth" onChange={async (e) => {
                                        const temp = await web3.utils.isAddress(e.target.value);
                                        setCheckResult(temp)
                                        setFromAdd(e.target.value);
                                    }} />
                                    
                                    <div className={`font-14 lh-24`} style={{height:'24px'}}>
                                    
                                        {(fromAdd == '' && fromAdd !== undefined) ? <span style={{ color: 'red' }}>{t('methods.requiredFields')}</span> : ''}
                                        {(fromAdd != '' && fromAdd !== undefined && !checkResult) ? <span style={{ color: 'red' }}>{t('methods.InvalidAddress')}</span> : ''}
                                        {(checkResult && fromAdd !== undefined) ? <span style={{ color: '#333333' }}>{t('methods.transferError')}</span> : ''}
                                    </div>
                                </>
                            }
                            <div className={``}>
                                <p className={`col-666 font-14 `}>转赠数量</p>
                                <input type='number' placeholder={'输入转赠数量'}  className={`mt-10 ${styles.addMetaInput} `} defaultValue={amount}  onChange={e =>{
                                    changAmount(e.target.value);
                                }}  min={100} max={modeData.balance} disabled={100 > modeData.balance} />
                                 <div className={`font-14 lh-24 col-666 `} style={{height:'24px'}}>
                                    {100 > amount ?<span style={{ color: 'red' }}>转赠星豆不得少于100</span>:
                                    <span className={`font-14`}>{`转赠规则：单笔>=100，手续费为转赠数量的2%，最少1%`}</span>}
                                </div>
                            </div>
                            <div className={`flex ai-center mt-20`}>
                                <div className={`col-666 font-14 `}>手续费</div>
                                <h3 className={`font-18 ml-15 `}>{fee} %</h3>
                            </div>
                            <div className={`flex ai-center mt-20`}>
                                <div className={`col-666 font-14 `}>到账星豆</div>
                                {/* 向下区政 */}
                                <h3 className={`font-18 ml-15 col-blue`}>{Math.floor(amount - (amount*(fee/100)))}</h3>
                            </div>
                            
                            </Col>
                            <Col lg={10} lgOffset={1} className={`pt-40 pb-40 ${styles.modeBtnTop}`}>
                                {/* disabled={ isDisabled }  */}
                                <button className={`${styles.medalBtn}`} disabled={!fromAdd || (!fromAdd && !checkResult)|| (checkResult && !fromAdd) || !checkResult} onClick={changeDaoM}>确认转赠</button>
                            </Col>
                        </Row>
                    
                    </Col>
                    
                </div>
                
            }
        </>
    )
}
export default Exchange;