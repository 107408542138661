import React , { useEffect, useRef, useState  } from 'react';
import { Route, Redirect, useHistory,useLocation,useParams } from 'react-router-dom';
// 底部
import Footer from '../Footer'; 


// 顶部导航
import Menu from '../TopMenu';

function AuthRoute(props:any):any {
  // console.log(useHistory());
  // console.log(useLocation());
  // console.log(useParams()); 
  // console.log('low',props)
  useEffect(()=>{
    if (document.body.scrollTop || document.documentElement.scrollTop > 0) {
      window.scrollTo(0, 0)
    }
  },[props.path])

  let UserName = sessionStorage.getItem('userName');
  let UserToken = sessionStorage.getItem('socialDataToken');
  
  let AuthStatus:any = sessionStorage.getItem('AuthStatus');

  const animals = ['/apps']; 

   console.log("==========1111",AuthStatus==0)
  // 如果用户有权限，就渲染对应的路由
  if (props && ((props.role === 'user' && UserName && UserToken && AuthStatus!=0 ) || props.role === 'all')) {
    return (
      <>

        { ! animals.includes(props.path) && <Menu />}
        {/* <Menu /> */}
        <Route {...props}/>
        { ! animals.includes(props.path) && <Footer />}
      </>
    )
      
  } else {
    // 如果没有权限，返回配置的默认路由
    return <Redirect to={props.backUrl} />
  }
}

export default AuthRoute;