/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState,useLayoutEffect} from 'react';
import { getUserInfo, getUserNFTData,userNFTList,lootBoxOpen,queryOperStatus,transferOneLootBox,transferonenft,userLiked,getMyNFTSum,getMyNFTlist,getPropList,getLootBoxList,transferBatchGrid,getAssetList,getAssetSum,getContInfo, newGetNFTDetail } from '../../api/public';
import styles from "./index.module.less";
import errorIconImg from '../../assets/images/noHeadImg.png'
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-flexbox-grid';
import LazyLoad from 'react-lazyload';
import Pagination from '../../components/Pagination';
import { simpleFn } from '../../components/Notification';
import ReactTooltip from 'react-tooltip';
import ReadMore from './ReadMore';
import Empty from '../../components/Empty';
import NFTDetail from './NFTDetail';
import ShowLoadding from '../../components/ShowLoadding';

import Pin from '../../components/User/Pin';
import OpenDetail from './OpenDetail'

import RcPagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';


import ReactLoading from 'react-loading';
import { Link,useHistory } from "react-router-dom";

import GetToken from '../Explore/Detail/GetToken'

import { t, use } from 'i18next';

import ExAway from "../Bill/ExAway";
//获取abi
import abi from '../Bill/abi.json';
import ConfigAbi from './Config.json';
// web3
import Web3 from 'web3';

import '../../assets/js/protocol.js';
import '../../assets/js/starx-wsclient.js';

var abiKeyData:any =[];
const web3 = new Web3(Web3.givenProvider);

// 主题
const themePrimaryColor = 'theme';
const primaryColor =  localStorage.getItem(themePrimaryColor)||'light';


const baseUrl = process.env.BASE_URL+"/ipfs/";





// nft详情弹窗
const NFTDetailsModal = ({ onChange, topCur, itemData }: any) => {

  console.log("================",topCur,itemData)
  const copyText = (value: any) => {
		const textarea = document.createElement('textarea')
		textarea.readOnly = true
		textarea.style.position = 'absolute'
		textarea.style.left = '-9999px'
		textarea.value = value
		document.body.appendChild(textarea)
		// 选中值并复制
		textarea.select()
		textarea.setSelectionRange(0, textarea.value.length)
		const copyStatus = document.execCommand('Copy')
		document.body.removeChild(textarea)
		if (copyStatus) {
			simpleFn(t('methods.copySucc'), 'success');
		} else {
			simpleFn(t('methods.copyError'), 'error');
		}
	}
  
    return (
      <>
     
         <div className={`flex ai-center jc-center ${styles.popUps}`}>
          <div className={`${styles.popBg}`} onClick={() => onChange && onChange(false)}>背景</div>
          <Col xs={10} sm={10} lg={8} xl={6} className={`flex jc-center ai-center fd-column ${styles.popUpsMode}`} style={{height:'750px'}}>
            <div className={`${styles.popClose}`}  onClick={() => onChange && onChange(false)}>close</div>
           
                  <Row style={{width:'100%'}}  className={`mt-20`}>
                    
                    <Col xs={5} >
                      <div className={`${styles.album}`}>
                        <img src={baseUrl+ itemData.image_hash} alt='' key={itemData.image_hash} className={`${styles.albumImg}`} onError={(e: any) => { e.target.onerror = null; e.target.src = require('../../assets/images/noImg1.png') }}/>
                      </div>
                    </Col>
                    
                    <Col xs={7} className={`pl-40`}>
                      <div  className={`flex ai-center`}>
                        <h3 className={`font-30 flex-1`}>{itemData.name}</h3>
                        {/* <span className={`font-14 col-666`}>编号：52874</span> */}
                      </div>
                      <div className={`flex ai-center font-14 col-666 mt-25 pb-35 ${styles.albumTips}`}>
                            <div className={`flex ai-center`}>
                              <img src={require('../../assets/images/img_yongyou.png')} alt='' style={{width:'20px'}} className={`mr-10`} />
                              拥有：{itemData.balance}
                            </div>
                            <div className={`flex ai-center ml-20`}>
                              <img src={require(itemData.is_like ? "../../assets/images/img_xihuan2.png" : '../../assets/images/img_xihuan.png')} alt=""  style={{width:'20px'}} className={`mr-10`} />
                              {itemData.like_count}人喜欢
                            </div>
                            <div className={`flex-1 text-right`} style={{minWidth:topCur===6?'50%':'auto'}}>
                            {topCur===6 && <button className={`${styles.cardbtn} ${styles.cardbtnw} mr-20`}  onClick={()=>onChange({type:'open',data:itemData})} >开盲盒</button>}
                              <button  className={`${styles.cardbtn}`} onClick={()=>onChange({type:'sale',data:itemData})}>转赠</button>
                              {/*  <button  className={`${styles.cardbtn}`}  onClick={() => {
                                        setIsShow(true);
                                        setDetailData(item)
                                      }}>我要寄售</button> */}
                            </div>
                        </div>
                        <div className={`${styles.albumDesc}`}>
                           {itemData.image_hash && <div className={`flex ai-center jc-space-between col-666 font-14`}>
                              <span>作品哈希</span>
                              <div className={`flex ai-center ${styles.detailsCardTips}`}>
                                <span className={`flex-1 text-right border-box pl-20`}>{itemData.image_hash.substring(0, 5) + '...' + itemData.image_hash.substring(itemData.image_hash.length - 5, itemData.image_hash.length)}</span>
                                <div data-tip data-for='image_hash' className={`ml-10 ${styles.copyIconDetail}`} onClick={() => {
                                  copyText(itemData.image_hash)
                                }}>
                                  <img alt='' src={require('../../assets/images/img_fuzhi.png')} />
                                </div>
                              </div>
                              <ReactTooltip effect='solid' id='image_hash' type='info' >
                                <span>copy</span>
                              </ReactTooltip>
                            </div>}
                            {itemData.TokenID && <div className={`flex ai-center jc-space-between col-666 font-14 mt-20`}>
                              <span>作品ID</span>
                              <div className={`flex ai-center ${styles.detailsCardTips}`}>
                                <span className={`flex-1 text-right border-box pl-20`}>{itemData.TokenID.length>10?itemData.TokenID.substring(0, 5) + '...' + itemData.TokenID.substring(itemData.TokenID.length - 5, itemData.TokenID.length):itemData.TokenID}</span>
                                <div data-tip data-for='TokenID' className={`ml-10 ${styles.copyIconDetail}`} onClick={() => {
                                  copyText(itemData.TokenID)
                                }}>
                                  <img alt='' src={require('../../assets/images/img_fuzhi.png')} />
                                </div>
                              </div>
                              <ReactTooltip effect='solid' id='TokenID' type='info' >
                                <span>copy</span>
                              </ReactTooltip>
                            </div> }
                            {itemData.cont_addr && <div className={`flex ai-center jc-space-between col-666 font-14 mt-20`}>
                              <span>合约地址</span>
                              <div className={`flex ai-center ${styles.detailsCardTips}`}>
                                <span className={`flex-1 text-right border-box pl-20`}>{itemData.cont_addr.substring(0, 5) + '...' + itemData.cont_addr.substring(itemData.cont_addr.length - 5, itemData.cont_addr.length)}</span>
                                <div data-tip data-for='cont_addr' className={`ml-10 ${styles.copyIconDetail}`} onClick={() => {
                                  copyText(itemData.cont_addr)
                                }}>
                                  <img alt='' src={require('../../assets/images/img_fuzhi.png')} />
                                </div>
                              </div>
                              <ReactTooltip effect='solid' id='cont_addr' type='info' >
                                <span>copy</span>
                              </ReactTooltip>
                            </div> }
                            <div className={`flex ai-center jc-space-between col-666 font-14 mt-20`}>
                              <span>认证网络</span>
                              <div className={`flex ai-center ${styles.detailsCardTips}`}>
                                <a className={`flex-1 col-blue text-right border-box pl-20`} target='_blank' href={`https://scan.verseport.com/`}>Verseport对象网络</a>
                              </div>
                            </div>
                        </div>
                    </Col>

                    <Col xs={12}>
                      <h3 className={`mt-25`}>作品描述</h3>
                      <div className={`${styles.albumDesc} lh-24 col-666 font-14`}>
                        {itemData.desc}
                      </div>
                    </Col>


                  </Row>

             
            
          </Col>
        </div> 
      </>
        
    )
  }


// 转赠弹窗
const TransferModal = ({ onChange, data, itemData }: any) => {

// console.log("================",data,itemData)
// 建立 webstockt 连接
// console.log(starx)
// starx.init({ host: config.WS_URL, 
//   path: '/' }, function (data) {
//   starx.request("system.login", JSON.parse(newValue),
//   function (res) {
//     console.log(JSON.stringify(res));
//     if (res.code == 0) {

//     } else {
      
//     }
//   });
// })
	const history = useHistory();
	let intervalHandle = useRef<any>();

	const [fromAdd, setFromAdd] = useState<string>();
	const [transferAmount, setTransferAmount] = useState(1);
	const [checkResult, setCheckResult] = useState<boolean>(true)
	const [transferStatus, setTransferStatus] = useState(0);

	const QueryOperStatus = async (val: any) => {
		// intervalHandle.current = setInterval(async () => {
		// 	const res = await queryOperStatus({ "operUUID": val });
		// 	for (let i = 0; i < res.data.items.length; i++) {
		// 		setTransferStatus(res.data.items[i].operStatus)
		// 		if (res.data.items[i].operStatus === 2) {
    //       setShowGetToken(false);
		// 			setTimeout(()=>{
		// 				setShowLoadding(false);
		// 				clearInterval(intervalHandle.current)
            
		// 				onChange()
		// 			},1000)
					
		// 		  } else {
		// 			setShowLoadding(true);
					
		// 		  }
		// 		// 
		// 	}
		// }, 3000);
    setTransferStatus(1)
    setShowLoadding(true);
    setTimeout(()=>{
      setShowLoadding(false)
      
      setTransferStatus(0)
      onChange()
    },5000)
	}


	useEffect(() => {
		console.log(data)
		if (transferStatus !== 1) {
			clearInterval(intervalHandle.current)
		}
		// window.setTimeout(() => {
		// 	history.push(`/profile`);
		// }, 2000)
	}, [transferStatus])

	const TransferNFT = async () => {
		setShowGetToken(true)
	}

	const evaluatePINAsset = async (e:any) => {
		const FromUserName = sessionStorage.getItem('userName');
		// const DataHash = itemData.dataHash;
        const timestamp = new Date().getTime(); 
		const TokenID = itemData.TokenID;
		const DestIDs = [itemData.DestID*1];
		const Amount = transferAmount;
		// 
        const temp =  `${FromUserName}${fromAdd}${DestIDs}${TokenID}${Amount}${timestamp}`;
        const msgKeccak256 =web3.utils.keccak256("\u0019Ethereum Signed Message:\n"+ new Blob([temp]).size + temp);
        const sign = await web3.eth.accounts.sign(web3.utils.utf8ToHex('\x00'+temp),e.privateKey);
		const params = {
			FromUserName: FromUserName,
			ToUserName: fromAdd,//fromAdd,
			DestIDs: DestIDs,
			// dataHash: DataHash,
			TokenID: TokenID,
			Amount: Amount,
			TimeStamp: timestamp,
			MsgKeccak256: msgKeccak256.substr(2),
			Sig: sign.signature.substr(2),
		}
		const result = await transferOneLootBox(params);
		if (result.code == 0) {
			// setTransferStatus(1);
			// setShowLoadding(true)
			QueryOperStatus(result.data.DataOperUUIDTransfer);
		}else if(result.code===14){
      simpleFn('转赠次数不足', 'error'); 
    }else{
      simpleFn(result.msg, 'error'); 
    }
	}
	const evaluate=(e:any)=>{

    console.log(itemData)

    if(showGetToken){
      itemData.NftType === 13 || itemData.NftType === 14 || itemData.NftType === 41 ? evaluatePIN(e) : evaluatePINAsset(e)
    }
		
	}
  
	const evaluatePIN= async (e:any)=>{
		console.log("===================",itemData,e)
		const FromUserName = sessionStorage.getItem('userName');
		// const DataHash = itemData.dataHash;
    const timestamp = new Date().getTime(); 
		const TokenID = itemData.TokenID;
		const DestIDs = itemData.DestID*1;
		const Amount = transferAmount;
		// 
    const temp =  `${FromUserName}${fromAdd}${DestIDs}${TokenID}${Amount}${timestamp}`;
    const msgKeccak256 =web3.utils.keccak256("\u0019Ethereum Signed Message:\n"+ new Blob([temp]).size + temp);
    const sign = await web3.eth.accounts.sign(web3.utils.utf8ToHex('\x00'+temp),e.privateKey);
    console.log(itemData.size,"=====================")
		const params = {
			FromUserName: FromUserName,
			ToUserName: fromAdd,//fromAdd,
			DestID: DestIDs,
			// dataHash: DataHash,
			TokenID: TokenID,
			Amount: Amount,
			TimeStamp: timestamp,
			MsgKeccak256: msgKeccak256.substr(2),
			Sig: sign.signature.substr(2),
      // size:itemData.size?itemData.size.width:undefined,
      // page:outputMate(itemData.metaDataUser.attributes,'Page X'),
      // pagey:outputMate(itemData.metaDataUser.attributes,'Grid Y'),
      // pagez:outputMate(itemData.metaDataUser.attributes,'Grid Z')
		}
    console.log(params)


		const result = await transferonenft(params);
		if (result.code == 0) {
			// setTransferStatus(1);
			QueryOperStatus(result.data.DataOperUUID);
		}else if(result.code===14){
      simpleFn('转赠次数不足', 'error'); 
    }else{
      simpleFn(result.msg, 'error'); 
    }

	}
	const [showGetToken,setShowGetToken] = useState<boolean>(false);
	const [showLoadding, setShowLoadding] = useState<boolean>(false)
	const handleShowLoadding = () => {
		setShowLoadding(!showLoadding);
	}
  const outputMate = (list:any,type:string)=>{
		let item = list.filter((item:any)=>item.trait_type===type)[0];
		return item?item.value:false
	}




	return (
		<>
			{showLoadding? 
			<ShowLoadding data={{type:transferStatus}} onChange={() => handleShowLoadding()} />:
			 <div className={`flex ai-center jc-center ${styles.popUps}`} >
				<div className={`${styles.popBg}`} onClick={() => onChange && onChange(false)}>背景</div>
				<Col xs={10} sm={10} lg={8} xl={6} className={`flex jc-center ai-center fd-column ${styles.popUpsMode}`}>
					<div className={`${styles.popClose}`}  onClick={() => onChange && onChange(false)}>close</div>

					{showGetToken ? <GetToken onChange={(e:any)=> evaluate(e)} /> :
					<>
						<h3 className={`${styles.logTitle}`}>{t('methods.transfer')}</h3>
						<Row className={`pt-30 ${styles.logMain}`}>
							<Col lg={10} lgOffset={1}>
								<div className={`flex jc-center ai-center font-14 ${styles.warnTips}`}>
									<img src={require('../../assets/images/login/tips-icon.png')} alt='' className={`mr-10`} />
									<span>为了您的账户安全，需要确认好转赠接收身份地址!</span>
								</div>
							</Col>
							<Col lg={10} lgOffset={1} className={`pt-20`}>

                <Row>
                  <Col md={4}>
                      <img width={'100%'} src={process.env.BASE_URL+'/ipfs/'+itemData.Image} alt='' />
                  </Col>
                  <Col md={8} className={`pl-10`}>
                      <div className={`flex ai-center`}>
                          <div className={`col-666 font-14 `}>转赠{itemData.NftType===13?'道牌':'作品'}</div>
                        <h3 className={`font-16 ml-15`}>{ itemData.NftName || '--'}</h3>
                      </div>
                      <div className={`flex ai-center mt-20`}>
                          <div className={`col-666 font-14`}>拥有数量</div>
                          <h3 className={`font-18 ml-15`}>{itemData.Amount}</h3>
                      </div>
                      <div className={`mt-20`}>
                       {transferStatus == 0 &&
                          <>
                            <div className={`col-666 font-14 `}>接收地址</div>
                            <input className={`mt-10 ${styles.addMetaInput}`} placeholder="e.g. 0x1ed3... or destination.eth" onChange={async (e) => {
                              const temp = await web3.utils.isAddress(e.target.value);
                              setCheckResult(temp)
                              setFromAdd(e.target.value);
                            }} />
                            
                            <div className={`font-14 lh-24`} style={{height:'24px'}}>
                              {(fromAdd == '' && fromAdd !== undefined) ? <span style={{ color: 'red' }}>{t('methods.requiredFields')}</span> : ''}
                              {(fromAdd != '' && fromAdd !== undefined && !checkResult) ? <span style={{ color: 'red' }}>{t('methods.InvalidAddress')}</span> : ''}
                              {(checkResult && fromAdd !== undefined) ? <span style={{ color: '#FF9E33' }}>{t('methods.transferError')}</span> : ''}
                            </div>
                          </>
                        }
                        {transferStatus == 1 &&
                          <div className={`flex fd-column ai-center`}>
                            <div><ReactLoading type='spin' color='rgb(32, 129, 226)' height={'48px'} width={'48px'} /></div>
                            <div style={{ marginTop: '24px' }}>{t('methods.transferring')}</div>
                          </div>
                        }
                        {transferStatus == 2 &&
                          <div className={`flex fd-column ai-center`}>
                            <div>
                              <LazyLoad className={``}>
                                <img className={`${styles.resultImg}`} src={itemData.img_url} alt="" />
                              </LazyLoad>
                            </div>
                            <div style={{ marginTop: '24px' }}>{t('methods.transferSucc')}</div>
                          </div>
                        }
                        {transferStatus == 3 &&
                          <div className={`flex  fd-column ai-center`}>
                            <div>
                              <LazyLoad className={``}>
                                <img className={`${styles.resultImg}`} src={itemData.img_url} alt="" />
                              </LazyLoad>
                            </div>
                            <div style={{ marginTop: '24px'}}>{t('methods.transferFailed')}</div>
                          </div>
                        } 
                      </div>
                     <div className={`mt-10`}>
                          <div className={`col-666 font-14 `}>{t('methods.transfer') + t('methods.amount')}</div>

                          <input type="number" min={1} max={itemData.Amount} className={`mt-10 ${styles.addMetaInput}`} placeholder={t('methods.transfer') + t('methods.amount')} onChange={async (e: any) => {
                            setTransferAmount(e.target.value);
                          }} defaultValue={transferAmount} /> 
                          <div className={`font-14 lh-24`} style={{height:'24px'}}>
                              {transferAmount*1 > itemData.Amount*1 && <span style={{ color: 'red' }}>转赠数量不得大于拥有数量</span>}
                            </div>
                      </div>
                  </Col>
                </Row>
							</Col>
							
							<Col lg={10} lgOffset={1} className={`pb-60 mt-40 pt-40 ${styles.modeBtnTop}`}>
								{transferStatus !== 2 ? 
								<button className={`btn blue-btn ${styles.btn}`} disabled={transferStatus != 0 || checkResult == false || fromAdd == '' || fromAdd == undefined || transferAmount*1 > itemData.amount*1 || !transferAmount} onClick={(e) => {
									// { itemData.nftType !== 13 ? TransferNFT() : TransferDataAsset() }
									TransferNFT();
									e.preventDefault();
								}}>{t('methods.transfer')}</button> :
									<button className={`btn blue-btn ${styles.btn}`} onClick={(e) => {
										history.push(`/profile`);
										e.preventDefault();
									}}>{t('methods.backProfile')}</button>}
							</Col>
							
						</Row>
					</>
				}
				</Col>
			</div> }
		</>
			
	)
}

//我的资产
const Assets = ({ toChange, data } :any) => {
  const UserInfoDataMain = data;
  
  console.log("data----------",toChange,data,UserInfoDataMain)
  const starDataHash=process.env.STAR_DATA_HASH;
  const starDesId = process.env.USER_DES_ID;

  const abiUrl =process.env.ABI_URL;

  // let abiKeysession:any = sessionStorage.getItem(starDesId || '')
  // const abiKey = JSON.parse(abiKeysession).contractAddress;
  // let userAbiKeysession:any = sessionStorage.getItem(process.env.DES_ID || '')
  // const userAbiKey =JSON.parse(userAbiKeysession).contractAddress;

  

  const web3 = new Web3(abiUrl);
  const abiItem = (list:any,id:number)=>{
		let item = list.filter((item:any)=>item.cId===id)[0];
		return item
	}
  //获取合约地址
  const getContInfoFun = async()=>{
    console.log("INIT_CONTRATC_ID",process.env.INIT_CONTRATC_ID)
    // return new Promise<void>((resolve, reject) => {
        const res =await getContInfo({DestID:process.env.INIT_CONTRATC_ID})
        // console.log("获取合约地址====",res)
        return res;
    // })
  }


  // 获取合约地址
  const getAbiDataConfig = ()=>{

    getContInfoFun()
    .then((res:any)=>{

      // console.log("获取合约地址abi",res.data.cont_addr);
      const	initContract = new web3.eth.Contract(ConfigAbi, res.data.cont_addr);

      initContract.methods.getBatchCfgContInfos(true,0,200).call().then((res: any)=>{
        // console.log('linkabi=========',res);
        abiKeyData = JSON.parse(res);
        getContract();
      })

    })

    

    
  }
  useEffect(()=>{
    getAbiDataConfig();
    
  },[])

  // const myContract = new web3.eth.Contract(abi, abiKey); 

  const userName:any = sessionStorage.getItem('userName');
  const getUserInfo:any = sessionStorage.getItem('userInfo');
  let userDataObj = JSON.parse(getUserInfo);


  const [showExAway,setShowExAway] = useState<boolean>(false)
  
  const [integral,setIntegral] = useState<number>(0);

  const [goldMedal,setGoldMedal] = useState<number>(0);
  const [silverMedal,setSilverMedal] = useState<number>(0);
  const [bronzeMedal,setBronzeMedal] = useState<number>(0);




  

  const getContract = ()=>{
    
    console.log("getContract==============",abiKeyData,) 
    const myContract = new web3.eth.Contract(abi, abiItem(abiKeyData,67).contAddr); 
    myContract.methods.balanceOf(userName,666).call().then((res:any)=>{
      console.log('积分============',res)
      setIntegral(res*1);
    })
    myContract.methods.balanceOf(userName,888).call().then((res:any)=>{
      // console.log('金牌============',res)
      setGoldMedal(res*1)
    })
    myContract.methods.balanceOf(userName,889).call().then((res:any)=>{
      // console.log('银牌============',res)
      setSilverMedal(res*1)
    })
    myContract.methods.balanceOf(userName,890).call().then((res:any)=>{
      // console.log('铜牌============',res)
      setBronzeMedal(res*1)
    })
  }
  const [percent,setPercent] =useState<number>(0.00);
  const [levelPercent,setLevelPercent] = useState<number>(0)
  useLayoutEffect(()=>{
    let total = (goldMedal*500000)+(silverMedal*5000)+(bronzeMedal*50)+integral;
    let level = Math.floor(Math.sqrt(total/100))
    let delta1 = 100 * Math.pow(level+1,2)-100*Math.pow(level,2)
    let delta2:number = total-100 * Math.pow(level,2)
    let percentData = parseFloat(((delta2/delta1)*100).toFixed(2)) 
    setPercent(percentData)
    setLevelPercent(level)

  },[integral,goldMedal,silverMedal,bronzeMedal])

  // useEffect(()=>{
  //     getContract();
  // },[abiKey])
  // useEffect(()=>{
  //   if(toChange){
  //     getContract()
  //   }
  // },[toChange])
  const changeExAway=()=>{
    console.log(1)
    setTimeout(()=>{
      console.log(12)
      // getContract();
      const myContract = new web3.eth.Contract(abi, abiItem(abiKeyData,67).contAddr); 
      myContract.methods.balanceOf(userName,666).call().then((res:any)=>{
        console.log('积分============',res)
        setIntegral(res*1);
        
        setShowExAway(false);
      })
            
    },5000)

  }
  return (
    <>
    <Row className={`bg${primaryColor} mt-30 ml-0 mr-0 ${styles.billTop}`}>
      <Col lg={8} className={`flex ${styles.billTopLeft}`}>
        {/* <div className={`text-center pl-40`}>
            <img src={userDataObj.avatar && userDataObj.baseUrl ?(userDataObj.baseUrl+userDataObj.avatar):require('../../assets/images/menu/user-icon.png')} className={`${styles.userAvatar}`}  alt={`user`} />
            <div className={`pt-5f`}>{userDataObj.nickName || 'Unnamed'}</div>
        </div> */}
        {/*  pl-30 */}
        <Row className={`flex-1`}>
              <Col md={4} className={`${styles.billLeftBox}`}>
                <div className={`flex ai-center jc-center ${styles.medalBox}`}>
                  <div className={'pr-20'}>
                    <p className={`font-18`}>金牌</p>
                    <p className={`col-blue font-30 pt-10`}>{goldMedal}</p>
                  </div>
                  <img src={require('../../assets/images/bill/convert/G.png')} alt='' />
                </div>
              </Col>
              <Col md={4} className={`${styles.billLeftBox}`}>
                <div className={`flex ai-center jc-center ${styles.medalBox}`}>
                  <div className={'pr-20'}>
                    <p className={`font-18`}>银牌</p>
                    <p className={`col-blue font-30 pt-10`}>{silverMedal}</p>
                  </div>
                  
                  <img src={require('../../assets/images/bill/convert/S.png')} />
                </div>
              </Col>
              <Col md={4} className={`${styles.billLeftBox}`}>
                <div className={`flex ai-center jc-center ${styles.medalBox}`}>
                  <div className={'pr-20'}>
                    <p className={`font-18`}>铜牌</p>
                    <p className={`col-blue font-30 pt-10`}>{bronzeMedal}</p>
                  </div>

                  <img src={require('../../assets/images/bill/convert/C.png')} />

                </div>
              </Col>
        </Row>
      </Col>
      <Col lg={4} className={`${styles.billTopRight}`}>
        <div className={`flex ai-center jc-space-between`}>
            <div className={`flex ai-center jc-flex-start`}>
              <img src={require('../../assets/images/bill/logo.png')} alt='' />
              <div className={`font-18 ml-10`}>星豆</div>
              {UserInfoDataMain.Bean &&<div className={`font-30 ml-10 col-blue`}>{UserInfoDataMain.Bean.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}</div>}
            </div>
            <button className={`${styles.cardbtn}`} onClick={()=>setShowExAway(true)}>转赠</button>
        </div>
        <div>
          <div className={`${styles.levelTitle} font-16 col-blue mt-30`}>Lv {levelPercent}</div>
          <div className={`${styles.levelBar} mt-20`}>
              <div className={`${styles.levelBarLine}`} style={{width:(percent).toString().split('.')[1]+'%'}}></div>
              <div className={`${styles.levelBarTips}`} style={{left: `calc(${(percent).toString().split('.')[1]}% - 18px)`}}>{parseInt((percent).toString().split('.')[1]) || 0} %</div>
          </div>
        </div>
      </Col>
    </Row>

    {showExAway && <ExAway modeData={{dataHash:starDataHash,destID:starDesId,balance:integral,goldMedal:goldMedal,silverMedal:silverMedal,bronzeMedal:bronzeMedal,}} onChange={()=>changeExAway()} />}
  </>
  )
}


const Profile = (props: any) => {
  const [topCurrentID, setTopCurrentID] = useState(6);
  const [bottomCurrentID, setBottomCurrentID] = useState(1);
  const [pageData, setPageData] = useState({ PageNo: 1, PageSize: 12 });
  const [dataList, setDataList] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [isHover, setIsHover] = useState(0);
  const noImgList = ['noImg1.png', 'noImg2.png', 'noImg3.png'];
  const [userInfo, setUserInfo] = useState<any>({
    avatar: "",
    baseUrl: "",
    cover: "",
    dataChainUUID: "",
    nickName: "",
    userName: "",
  });
  const [medalList, setMedalList] = useState<any>([]);

  // 顶部 Tab List
  const topTypeList = [
    {
    id:6,
    icon:'Blind.png',
    title:'Blind',
  },
    {
      id: 14,
      icon: 'NFR.png',
      title: 'NFR',
    },
    
    {
      id:13,
      icon:'digital.png',
      title:'DAOMEDAL',
    },
    {
      id: 21,
      icon: 'Right.png',
      title: "Right"
    },
    {
      id: 31,
      icon: 'AchMedal.png',
      title: "AchMedal"
    },
    {
      id: 41,
      icon: 'following.png',
      title: "tool"
    },
    {
      id: 99,
      icon: 'Digital Assets.png',
      title: "Others",
    },
  ]

  const assetsTAb = [
    {
      id: 1,
      icon: 'collected.png',
      title: 'Collected',
    },
    // {
    //   id: 2,
    //   icon: 'created.png',
    //   title: "Created",
    // },
    {
      id: 3,
      icon: 'favorited.png',
      title: "Favorited",
    },
    // {
    //   id: 4,
    //   icon: 'activity.png',
    //   title: "Activity",
    // },
    // {
    //   id: 5,
    //   icon: 'offers.png',
    //   title: "Offers",
    // }
  ]

  const followTAb = [
    {
      id: 1,
      icon: 'followed.png',
      title: 'Followed'
    },
    {
      id: 2,
      icon: 'fans.png',
      title: "Fans"
    },
  ]

  const [current, setCurrent] = useState(1);
  const Locale = localStorage.getItem('i18nextLng');
  const LocaleEn_US: object = {
    // Options.jsx
    items_per_page: '/ page',
    jump_to: 'Go to',
    jump_to_confirm: 'confirm',
    page: 'Page',

    // Pagination.jsx
    prev_page: 'Previous Page',
    next_page: 'Next Page',
    prev_5: 'Previous 5 Pages',
    next_5: 'Next 5 Pages',
    prev_3: 'Previous 3 Pages',
    next_3: 'Next 3 Pages',
    page_size: 'Page Size',
  };

  const { t } = useTranslation();

  useEffect(() => {
    getUserInfoDataMain()
    // GetUserNFTDataInfo(topCurrentID)
    // console.log("返回数据=============",GetUserNFTDataInfo(6));
  }, []);

  const [UserInfoDataMain,setUserInfoDataMain] = useState<any>({KindInfo:{LootBox:0,Grid:0,DaoMedal:0,Prop:0}});
//获取用户资产表
const getUserInfoDataMain =async ()=>{

  const UserData:any = JSON.parse(sessionStorage.getItem('userInfo')||'')
  const res = await getAssetSum({ UserName: UserData.UserName});
  console.log("getUserInfoDataMain=========",res)
  setUserInfoDataMain(res.data)

}

const [isGetUserNFTDataInfo,setIsGetUserNFTDataInfo] = useState(false)
  // 获取用户资产信息
  const GetUserNFTDataInfo = async (e: number | undefined) => {
    if(isGetUserNFTDataInfo){
      return ;
    }
    setIsGetUserNFTDataInfo(true)
    const UserData:any = JSON.parse(sessionStorage.getItem('userInfo')||'')
    // console.log(UserData)
    setUserInfo(UserData);
    
    const res = await getAssetList({ UserName: UserData.UserName,TType:e,Rarity:myRarity,...pageData});
    if(res.code===0){
      console.log("获取用户资产信息==========",res)
      
      setIsGetUserNFTDataInfo(false);
      setDataList(res.data.Items);
    }
  }
  //获取nft详情
  const getNFTDetailFn = async(e:any)=>{
    let data = {
      TokenID:e.TokenID,
      DestID:e.DestID,
      
      UserName: sessionStorage.getItem('userName'),
    }
    const res = await newGetNFTDetail(data);
    return res;
  }
  const [showNFTDetailsModal,setShowNFTDetailsModal] = useState(false);
  const [NFTDetailsData,setNFTDetailsData] = useState({})
  const goDetail = (e: any) => {
    // if(e.filter===2 ||e.filter===1){
    //   return false;
    // }
    // const type = topCurrentID;
    // const id = e.nftID;
    // props.history.push(`/explore/detail?type=${type}&id=${id}`, { type, id })

    console.log(e)
    getNFTDetailFn(e).then((res)=>{
      console.log("goDetail============",res,)
      setShowNFTDetailsModal(true)
      setNFTDetailsData({...e,...res.data})
    })
    

  }
  // const [baseUrl,setBaseUrl] = useState<string>('')
  // 获取用户 NFT 数据
  const GetUserNFTData = async () => {
    // if (topCurrentID <= 2 && bottomCurrentID <= 2) {
      const params = {
        userName: sessionStorage.getItem('userName'),
        viewUserName: sessionStorage.getItem('userName'),
        // skip: pageData.skip,
        // limit: pageData.limit,
        contextType: topCurrentID,
        filter: bottomCurrentID,
        mode:topCurrentID===3?3:1
      }
      // const res = await getUserNFTData(params);
      const res = await userNFTList(params);
      if (res.code == 0) {
        for (let i = 0; i < res.data.items.length; i++) {
          let tempMetaDataSystem = JSON.parse(res.data.items[i].metaDataSystem);
          if (tempMetaDataSystem !== null) {
            let fileType = tempMetaDataSystem.type !== undefined ? tempMetaDataSystem.type.split('/')[0] : 'video';
            if (fileType == 'video') {
              let videoImg: any = await getVideoPosterByFrame(process.env.BASE_URL+'/ipfs/' + res.data.items[i].dataString, 1)
              tempMetaDataSystem.thumb_hash  = videoImg.posterUrl;
              console.log(tempMetaDataSystem)
              res.data.items[i].metaDataSystem = JSON.stringify(tempMetaDataSystem);
            }
          }
        }
        setDataList(res.data.items);
        setTotal(res.data.count);
        // setBaseUrl(res.data.baseUrl)
      } else {
        console.log('出错了, 错误代码: ', res.code, res.msg);
      }
    // } else {
    //   setDataList([]);
    // }
  }
  const GetUserNFTLikeData = async () => {
      const params = {
        userName: sessionStorage.getItem('userName'),
        // skip: pageData.skip,
        // limit: pageData.limit,
        type:1
      }
      const res = await userLiked(params);
      if (res.code == 0) {
        for (let i = 0; i < res.data.items.length; i++) {
          let tempMetaDataSystem = JSON.parse(res.data.items[i].metaDataSystem);
          if (tempMetaDataSystem !== null) {
            let fileType = tempMetaDataSystem.type !== undefined ? tempMetaDataSystem.type.split('/')[0] : 'video';
            if (fileType == 'video') {
              let videoImg: any = await getVideoPosterByFrame(res.data.baseUrl + res.data.items[i].dataString, 1)
              tempMetaDataSystem.thumb_hash  = videoImg.posterUrl;
              console.log(tempMetaDataSystem)
              res.data.items[i].metaDataSystem = JSON.stringify(tempMetaDataSystem);
            }
          }
        }
        setDataList(res.data.items);
        setTotal(res.data.count);
        // setBaseUrl(res.data.baseUrl)
      } else {
        console.log('出错了, 错误代码: ', res.code, res.msg);
      }
   
  }
  // 获取视频基本信息
  const getVideoBasicInfo = (videoSrc: any) => {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video')
      video.src = videoSrc
      // 视频一定要添加预加载
      video.preload = 'auto'
      // 视频一定要同源或者必须允许跨域
      video.crossOrigin = 'Anonymous'
      // 监听：异常
      video.addEventListener('error', error => {
        reject(error)
      })
      // 监听：加载完成基本信息,设置要播放的时常
      video.addEventListener('loadedmetadata', () => {
        const videoInfo = {
          video,
          width: video.videoWidth,
          height: video.videoHeight,
          duration: video.duration
        }
        resolve(videoInfo)
      })
    })
  }

  // 获取视频当前帧图像信息与饱和度
  const getVideoPosterInfo = (videoInfo: any) => {
    return new Promise(resolve => {
      const { video, width, height } = videoInfo
      video.addEventListener('canplay', () => {
        const canvas = document.createElement('canvas')
        canvas.width = width
        canvas.height = height
        const ctx: any = canvas.getContext('2d')
        // 将视频对象直接绘入canvas
        ctx.drawImage(video, 0, 0, width, height)
        // 获取图像的整体平均饱和度
        const posterUrl = canvas.toDataURL('image/jpg')
        resolve({ posterUrl })
      })
    })
  }

  // 根据视频地址与播放时长获取图片信息与图片平均饱和度
  const getVideoPosterByFrame = async (videoSrc: any, targetTime: any) => {
    const videoInfo: any = await getVideoBasicInfo(videoSrc);
    const { video, duration } = videoInfo;
    video.currentTime = targetTime;
    return await getVideoPosterInfo(videoInfo);
  }

  const copyText = (value: any) => {
    const textarea = document.createElement('textarea')
    textarea.readOnly = true
    textarea.style.position = 'absolute'
    textarea.style.left = '-9999px'
    textarea.value = value
    document.body.appendChild(textarea)
    // 选中值并复制
    textarea.select()
    textarea.setSelectionRange(0, textarea.value.length)
    const copyStatus = document.execCommand('Copy')
    document.body.removeChild(textarea)
    if (copyStatus) {
      simpleFn(t('methods.copySucc'), 'success');
    } else {
      simpleFn(t('methods.copyError'), 'error');
    }
  }

  const [showOpenBox,setShowOpenBox] = useState<boolean>(false)


  const getLootBoxOpen =(e:any)=>{
    console.log(e)
    setNFTInfo(e);
    setShowOpenBox(true);
  }
  const [NFTInfo,setNFTInfo] = useState<any>({});
  const [openAmount,setOpenAmount] = useState<number>(1)
  const confirmOpenBoxDetail=(e:any)=>{
    if(e){
      setShowPin(true) 
    }
    setShowOpenBox(false);
    setOpenAmount(1)
  }
  const getOpenBox =async (e:any)=>{
    console.log("盲盒1",e,NFTInfo)
    const userName =  e.address;
    const destID:any = process.env.LOOTBOX_DES_ID;
    const tokenID = NFTInfo.TokenID;
    const timestamp = new Date().getTime(); 
    const temp =`${userName}${destID}${tokenID}${openAmount}${timestamp}`;
    const msgKeccak256 = web3.utils.keccak256("\u0019Ethereum Signed Message:\n"+ new Blob([temp]).size + temp).substr(2);
    const sign =  web3.eth.accounts.sign(web3.utils.utf8ToHex('\x00'+temp),e.privateKey).signature.substr(2);


    const tmpObj={
      username: userName,
      dest_id: destID * 1,
      loot_box_id:tokenID,
      amount:openAmount * 1,
      timestamp:timestamp,
      msg_keccak256:msgKeccak256,
      sig:sign
    };
    console.log(tmpObj)
    const then=(res: any)=>{
      console.log(res);
      setShowPin(false);
      setShowLoadding(true);
      if(res.code!==0){
        simpleFn(res.msg, 'error'); 
      }
    }
    (window as any).starx.request("transit.splitflow.NewUnpackLootBox",tmpObj,then);
    // const res = await lootBoxOpen(data)
    // if(res.code===0){
    //   let operObjList=res.data;
    //   setOperObj(operObjList);
    //   QueryOperStatus(operObjList);

    //   setShowPin(false);
    //   setShowLoadding(true);
    // }
    // else if(res.code===14){
    //   simpleFn('转赠次数不足', 'error'); 
    // }else{
    //   simpleFn(res.msg, 'error'); 
    // }
  }
  const [showLoadding, setShowLoadding] = useState<boolean>(false)
	const handleShowLoadding = () => {
		setShowLoadding(!showLoadding);
	}
  const [showPin,setShowPin] = useState<boolean>(false);
  const handleOnMessage=(e: any)=>{
    console.log("ws~回调",e)
    switch (e.msg_type) {
      case "NewUnpackLootBox":
        setShowLoadding(false);
        setOpenData(e.prizes);
        setShowOpenDetail(true);
        
        
        getUserInfoDataMain()
        GetUserNFTDataInfo(topCurrentID);
        break;
    
      default:
        break;
    }
  }
  const changePin=(e:any)=>{
    console.log('================122223=======',e)
    if(e){
      // getOpenBox(e)
        const UserName:any = sessionStorage.getItem('userName');
        const timestamp = +new Date();
        const SocialDataToken:any= sessionStorage.getItem("socialDataToken");
        const temp = UserName + SocialDataToken + timestamp
        const msgKeccak256 = web3.utils.keccak256("\u0019Ethereum Signed Message:\n" + new Blob([temp]).size + temp).substr(2);
        let tmpObj = {
            username: UserName,
            timestamp,
            msg_keccak256: msgKeccak256
        }

        var join =  (res:any)=> {
            console.log("ws初始化登陆完成～",res);
            if(res.code === 0) {
                // this.DecryptKey = web3.eth.accounts.decrypt(JSON.parse(res.data.private_key), UserData.p);
                // this.UserDataInScene= res.data
                getOpenBox(e);
                
                (window as any).starx.on('onMsgSplit', handleOnMessage);
            }
        };
        (window as any).starx.request("system.login",tmpObj,join);
      // (window as any).starx.init({host: process.env.WS_URL,port: 3250, path: '/'}, function () {
      //   console.log("ws初始化成功initialized");
      //   (window as any).starx.request("system.login",tmpObj,join);
      // })
    }else{
      setShowPin(e) 
    }
  }

  const [polling, setPolling] = useState(false);
  const [loadingType, setLoadingType] = useState(1);
  // let intervalHandle = useRef<any>();
  let intervalHandle:any = null;
 
  const [operObj, setOperObj] = useState<any>([]);
  const QueryOperStatus = async (val: any) => {
    // setLoadingType(2);
    setLoadingType(1);
    setShowLoadding(true);
    setTimeout(()=>{
      setShowLoadding(false)
      GetUserNFTDataInfo(topCurrentID)
    },8000)
    // let DataOperUUID: any = val.map((item:any)=>item.DataOperUUIDPrize);
   
    // console.log(DataOperUUID)
   
    
    // intervalHandle = setInterval(async () => {
    //   const res = await queryOperStatus({ "operUUID": DataOperUUID.join(',') });
    //   let temp = true;
    //   for (let i = 0; i < res.data.items.length; i++) {
    //     if (res.data.items[i].operStatus === 2) {
    //       clearInterval(intervalHandle);
    //       setOpenData(res.data);
    //       setTimeout(()=>{
    //         setShowOpenDetail(true);
            
    //         getMyLootBoxList();
    //       },1000)

    //       temp = false;
    //     } else {
    //       temp = true;
    //     }
    //   }
    //   setLoadingType(res.data.items[0].operStatus);
    //   setPolling(temp);
    // }, 3000);
// 
  }
  // useEffect(() => {
  //   console.log("===========================",polling,operObj)
    
  //   if (polling) {
  //     QueryOperStatus(operObj);
      
  //   } else {

  //     clearInterval(intervalHandle);
  //     setTimeout(()=>{
  //       setLoadingType(1);
  //       setShowLoadding(false);
        
  //       // GetUserNFTData();
  //     },1000)
      
  //   }
  // }, [polling]);

  // 
  const [myNftNavSum,setMyNftNavSum] = useState<any>([{
    rarity:'N'
  },
  {
    rarity:'R'
  },
  {
    rarity:'SR'
  },
  {
    rarity:'SS'
  },
  {
    rarity:'SSS'
  }]);
  const [myRarity,setMyRarity] = useState<any>('')
  const getMyNFTSumList = async()=>{
    // const UserName = sessionStorage.getItem('userName');
    // const res = await getMyNFTSum({ userName: UserName,})
    // if(res.code===0){
    //   setMyNftNavSum(res.data)
    // }

    let data =[
      {
        rarity:'N'
      },
      {
        rarity:'R'
      },
      {
        rarity:'SR'
      },
      {
        rarity:'SS'
      },
      {
        rarity:'SSS'
      }
    ]
    setMyNftNavSum(data)
  }
const getMyNFTlistData = async()=>{
  const UserName = sessionStorage.getItem('userName');
  const params={
    userName: UserName,
    // skip: pageData.skip,
    // limit: pageData.limit,
    rarity: myRarity,
    // searchKey: 
  }
  const res = await getMyNFTlist(params)
  if (res.code == 0) {
    for (let i = 0; i < res.data.items.length; i++) {
      let tempMetaDataSystem = JSON.parse(res.data.items[i].metaDataSystem);
      if (tempMetaDataSystem !== null) {
        let fileType = tempMetaDataSystem.type !== undefined ? tempMetaDataSystem.type.split('/')[0] : 'video';
        if (fileType == 'video') {
          let videoImg: any = await getVideoPosterByFrame(res.data.baseUrl + res.data.items[i].dataString, 1)
          tempMetaDataSystem.thumb_hash  = videoImg.posterUrl;
          console.log(tempMetaDataSystem)
          res.data.items[i].metaDataSystem = JSON.stringify(tempMetaDataSystem);
        }
      }
    }
    setDataList(res.data.items);
    setTotal(res.data.count);
    // setBaseUrl(res.data.baseUrl)
  } else {
    console.log('出错了, 错误代码: ', res.code, res.msg);
  }
}
useEffect(() => {
  GetUserNFTDataInfo(topCurrentID)
}, [topCurrentID,myRarity,pageData]);
// useEffect(()=>{
//   if(myRarity!==''){
//     console.log(12342222225)
//     getMyNFTlistData()
//   }else{
//     console.log(12345)
//     GetUserNFTData();
//   }
// },[myRarity,bottomCurrentID])
 // 页码、顶部 Tab、侧边 Tab 发生改变时重新获取用户 NFT及数据资产数据
// useEffect(()=>{
//   getMyNFTSumList();
// },[])
  const [showOpenDetail,setShowOpenDetail] = useState(false);
  const [openData,setOpenData] = useState({})


	const [isShow, setIsShow] = useState(false);
	const [isRefresh, setIsRefresh] = useState(false);
  const [assetsChange,setAssetsChange] = useState(false);
  const handleInPro = () => {
		setIsShow(!isShow);
		setIsRefresh(true);
    
    setAssetsChange(true);
    setTimeout(()=>{
      // getMyNFTSumList();
      // getMyPropList();
      setAssetsChange(false);
      GetUserNFTDataInfo(topCurrentID);
      // GetUserNFTData();
      // getMyLootBoxList();
    },1000)
		if (!isShow) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'auto';
		}
	}
  const [detailData,setDetailData] = useState<any>();
  const showCN=(e:string)=>{
    return e==='SSS'?'传奇':e==='SS'?'史诗':e==='SR'?'经典':e==='R'?'稀有':'普通'
  }
  const outputMate = (list:any,type:string)=>{
		let item = list.filter((item:any)=>item.trait_type===type)[0];
		return item?item.value:false
	}
  // useEffect(()=>{
  //   if(topCurrentID===4){
  //     getMyPropList()
  //   }else if(topCurrentID===5){
  //     getMyLootBoxList()
  //   }
  // },[topCurrentID]);
  const [dataDaoList,setDataDaoList] = useState<any>({count:0,items:[]})
  const getMyPropList = async()=>{
    const res = await getPropList({userName:sessionStorage.getItem('userName')})
    if(res.code===0){
      setDataDaoList(res.data)
    }
  }
  const[dataLootBoxList,setDataLootBoxList] = useState<any>({count:0,items:[]})
  const getMyLootBoxList = async()=>{
    const params = {
      userName: sessionStorage.getItem('userName'),
      viewUserName: sessionStorage.getItem('userName'),
      // skip: pageData.skip,
      // limit: pageData.limit,
      contextType: topCurrentID,
      filter: bottomCurrentID,
      mode:2
    }
    const res = await userNFTList(params);
    if(res.code===0){
      setDataLootBoxList(res.data)
    }
  }


  return (
    <>
      <div className={`${styles.medal}`}>
        <div className={`${styles.top}`} style={{ backgroundImage: `url(${userInfo.baseUrl + userInfo.cover})` }}>
          <ul className={`flex fw-wrap ${styles.medalUl}`}>
            {medalList.map((item: any, index: any) => {
              if (item.status == 2) {
                return (
                  <li className={`${styles.hex}`}>
                    <div className={`${styles.hexIn}`}>
                      <a className={`${styles.hexLink}`} href="#">
                        {item.image != '' ? <img src="https://hbimg.huabanimg.com/2c21c37f9098e34fc9181a0efa90e56f2cc4a3373ba12-Jjnrca_fw658/format/webp" alt="" /> : ""}
                      </a>
                    </div>
                  </li>
                )
              } else if (item.status == 1) {
                return (
                  <li className={`${styles.hex}`} onMouseEnter={() => { setIsHover(1) }} onMouseLeave={() => { setIsHover(0) }}>
                    <div className={`${styles.hexIn}`}>
                      {/* <a className={`${styles.hexLink} ${(item.status == 1 && isHover == 1) ? styles.linkHover : ''}`} href="#"> */}
                      <a className={`${styles.hexLink}`} href="#">
                        <img style={{ width: '55%', height: '55%', top: '20%', objectFit: 'contain' }} src={require('../../assets/images/profile/lock-icon.png')} alt="" />
                      </a>
                    </div>
                  </li>
                )
              } else {
                return (
                  <li className={`${styles.hex}`}>
                    <div className={`${styles.hexIn}`}>
                      <a className={`${styles.hexLink}`} href="#">
                        <img style={{ width: '55%', height: '55%', top: '20%', objectFit: 'contain' }} src={require('../../assets/images/profile/lock-icon.png')} alt="" />
                      </a>
                    </div>
                  </li>
                )
              }
            })}
          </ul>
        </div>


      </div>
      <div style={{ display: 'flex', flexDirection: 'column', alignContent: 'center', marginTop: '-60px' }}>
        <div style={{ display: 'flex', justifyContent: 'center' }}><img style={{ width: '120px', height: '120px', objectFit: 'cover', borderRadius: '50%' }} src={baseUrl + userInfo.Avatar} onError={(e: any) => { e.target.onerror = null; e.target.src = errorIconImg }} />
        </div>
        <div className={`font-18 mt-20 ${styles.nickName}`}>
          <p className={`pb-10`}>{userInfo.NickName || 'Unnamed'}</p>
          <span data-tip data-for='copy' onClick={() => {
            copyText(userInfo.UserName);
          }}>{userInfo.UserName}</span>
        </div>
      </div>
      <Col lg={12} xl={10} xlOffset={1} >
        <Assets toChange={assetsChange} data ={UserInfoDataMain} />

        <div className={`mt-20 ${styles.userAsset}`}>

          <ul className={`${styles.topTab}`}>
            {
              topTypeList.map((item) => {
                // console.log('item=====',item)
                return (
                  <li className={`${item.id == topCurrentID ? styles.active : ''}`} key={item.id} onClick={() => {
                    setDataList([]);
                    setTopCurrentID(item.id);
                    setBottomCurrentID(1);
                    setMyRarity('');
                    let data = Object.assign({}, pageData, {  PageNo: 1});
                        setCurrent(1);
                        setPageData(data);
                    // setTotal((topCurrentID==6?UserInfoDataMain.LootBox:topCurrentID==14?UserInfoDataMain.Grid:topCurrentID==13?UserInfoDataMain.DaoMedal:UserInfoDataMain.Prop)*1)
                  }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                      <img src={require(`../../assets/icon/${(topCurrentID == item.id ? item.title + '-active' : item.title) + '.png'}`)} style={{ width: '20px', height: '20px' }} alt='' />
                      <span style={{ marginLeft: '8px', textAlign: 'center', lineHeight: '20px' }}>{t(`methods.${item.title}`)}（{(item.id===6?UserInfoDataMain.KindInfo.LootBox:item.id===14?UserInfoDataMain.KindInfo.Grid:item.id===13?UserInfoDataMain.KindInfo.DaoMedal:item.id===41?UserInfoDataMain.KindInfo.Prop:item.id===99?UserInfoDataMain.KindInfo.Other:item.id===21?UserInfoDataMain.KindInfo.Right:UserInfoDataMain.KindInfo.AchMedal)*1}）</span>
                    </div>
                  </li>
                )
              })
            }
          </ul>
          {topCurrentID===14 &&
          <Row style={{ padding: '29px' }}>
            <Col xs={12}>            
                  {topCurrentID===14 && <Row className={`mb-10`}>
                      <Col xs={2}>
                        <div className={`mb-10 pointer ${styles.rarityNav} ${myRarity===''?styles.rarityNavCur:''}`} onClick={()=>{setMyRarity('');
                        let data = Object.assign({}, pageData, { PageNo:1});
                        setPageData(data);}}>
                          全部
                        </div>
                      </Col>
                      {
                        myNftNavSum.map((item:any)=>{
                          console.log(item)
                          return (
                            <Col  xs={4} md={3} key={item.rarity} >
                              <div className={`mb-10 pointer ${styles.rarityNav}  ${item.rarity==='SSS'?styles.rarityNavSSS:item.rarity==='SS'?styles.rarityNavSS:item.rarity==='SR'?styles.rarityNavSR:item.rarity==='R'?styles.rarityNavR:styles.rarityNavN} ${myRarity===item.rarity?styles.rarityNavCur:''}`} onClick={()=>{setMyRarity(item.rarity);
                                  let data = Object.assign({}, pageData, { PageNo:1});
                                  setPageData(data);}}>
                                {item.rarity}（{showCN(item.rarity)}）
                              </div>
                            </Col>
                          )
                        })
                      }
                  </Row>}
                  {(dataList.length > 0) ?
                  <>
                  <Row className={`${styles.exploreRow}`}>
                    {dataList.length > 0 ? dataList.map((item, index) => {
                      return (
                        <Col xl={3} lg={4} md={6} sm={6} xs={6} className={`pointer`} key={item.nftID}>
                          <div className={`${styles.card}`}>
                            <div className={`relative`} onClick={() => goDetail(item)}>
                              <LazyLoad className={`${styles.image}`}>
                              <img className={`${styles.childImg}`} src={baseUrl + item.Image} onError={(e: any) => { e.target.onerror = null; e.target.src = require('../../assets/images/noImg1.png') }}  alt="" />
                              </LazyLoad>
                            </div>
                          

                          <Row between="xs" className={`${styles.cardTips}`} onClick={() => goDetail(item)}>
                              <Col xs={12} className={`flex ai-center`} >
                              <div className={`${styles.nftName} ellipsis-1 text-left`}>
                                  {item.NftName}
                                  
                                </div>
                              </Col>
                            </Row>


                            {bottomCurrentID===1?
                            <Row around="xs" className={`${styles.cardTips}`}>
                              <Col xs={item.NftType ===5 || item.NftType===6 ? 4:6} className={`${styles.cardBottom} ${styles.divider}`}>
                                
                                <span>拥有</span>
                                <p>{item.Amount || '--'}</p>
                              </Col>
                            <Col xs={item.NftType===5 || item.NftType===6 ? 4:6} className={`${styles.cardBottom} 
                              ${item.NftType===5||item.NftType===6 ? styles.divider:''}`}>
                                {/*  <span>Offers</span>
                                <p>{item.stats.offers}</p>
                                */}
                                {item.filter===1?
                                <div className={`font-14`}>已经合并</div>:
                                <button  className={`${styles.cardbtn}`}  onClick={() => {
                                        setIsShow(true);
                                        setDetailData(item)
                                }}>转赠</button>
                                  }
                              </Col>
                            </Row>
                            :<></>}
                          </div>
                          
                        </Col>)
                    }) : <></>
                    }
                    
                  </Row>
                {UserInfoDataMain.KindInfo.Grid>pageData.PageSize && <div className={'flex ai-center jc-center pt-20 pb-40'}>
                    <RcPagination total={UserInfoDataMain.KindInfo.Grid} pageSize={pageData.PageSize} current={current} locale={Locale === 'en' ? LocaleEn_US : undefined} className={`font-20 text-conter ${styles.orginPagination}`} onChange={(current: number, pageSize: number) => {
                        let data = Object.assign({}, pageData, {  PageNo: current});
                        setCurrent(current);
                        setPageData(data);
                      }}  />
                    </div>}
              </> :
                <Empty
                  imageStyle={{
                    width: 480,
                    height: 'auto',
                    display: 'block'
                  }}
                  description={
                    <p style={{ textAlign: 'center', margin: '24px 0px', fontSize: '24px', color: '#9f9f9f' }}>{t(`methods.noData`)}</p>
                  }
                  image={require('../../assets/icon/noData.png')}
                />
              }
              </Col>
          </Row>
        }

        {topCurrentID===41 &&
          <Row style={{ padding: '29px' }}>
              <Col xs={12}> 


                  
                  {(dataList.length > 0) ?
                  <>
                  <Row className={`${styles.exploreRow}`}>
                    {dataList.length > 0 ? dataList.map((item, index) => {
                     
                      return (
                        <Col xl={3} lg={4} md={6} sm={6} xs={6} className={`pointer`} key={item.nftID}>
                          <div className={`${styles.card}`}>
                            <div className={`relative`} onClick={() => goDetail(item)}>
                              <LazyLoad className={`${styles.image}`}>
                              <img className={`${styles.childImg}`} src={baseUrl + item.Image} onError={(e: any) => { e.target.onerror = null; e.target.src = require('../../assets/images/noImg1.png') }}  alt="" />
                              </LazyLoad>
                            </div>
                          

                          <Row between="xs" className={`${styles.cardTips}`} onClick={() => goDetail(item)}>
                              <Col xs={12} className={`flex ai-center`} >
                              {item.metaDataUser && <div className={`${styles.nftName} ellipsis-1 text-left`}>
                                  {item.NftName || '--'}
                                  
                                </div>} 
                              </Col>
                            </Row>


                            {bottomCurrentID===1?
                            <Row around="xs" className={`${styles.cardTips}`}>
                              <Col xs={item.NftType===5 || item.NftType===6 ? 4:6} className={`${styles.cardBottom} ${styles.divider}`}>
                                
                                <span>拥有</span>
                                <p>{item.amount || '--'}</p>
                              </Col>
                            <Col xs={item.NftType===5 || item.NftType===6 ? 4:6} className={`${styles.cardBottom} 
                              ${item.NftType===5||item.NftType===6 ? styles.divider:''}`}>
                              
                                <button  className={`${styles.cardbtn}`}  onClick={() => {
                                        setIsShow(true);
                                        setDetailData(item)
                                      }} disabled>转赠</button>
                              </Col>
                            </Row>
                            :<></>}
                          </div>
                          
                        </Col>)
                    }) : <></>
                    }
                    
                  </Row>
                  
                {UserInfoDataMain.KindInfo.Prop>pageData.PageSize && <div className={'flex ai-center jc-center pt-20 pb-40'}>
                    <RcPagination total={UserInfoDataMain.KindInfo.Prop} pageSize={pageData.PageSize} current={current} locale={Locale === 'en' ? LocaleEn_US : undefined} className={`font-20 text-conter ${styles.orginPagination}`} onChange={(current: number, pageSize: number) => {
                        let data = Object.assign({}, pageData, { PageNo: current});
                        setCurrent(current);
                        setPageData(data);
                      }}  />
                    </div>}
              </> :
                <Empty
                  imageStyle={{
                    width: 480,
                    height: 'auto',
                    display: 'block'
                  }}
                  description={
                    <p style={{ textAlign: 'center', margin: '24px 0px', fontSize: '24px', color: '#9f9f9f' }}>{t(`methods.noData`)}</p>
                  }
                  image={require('../../assets/icon/noData.png')}
                />
              }
              </Col>
          </Row>
        }

        {topCurrentID===99 &&
          <Row style={{ padding: '29px' }}>
              <Col xs={12}> 


                  
                  {(dataList.length > 0) ?
                  <>
                  <Row className={`${styles.exploreRow}`}>
                    {dataList.length > 0 ? dataList.map((item, index) => {
                     
                      return (
                        <Col xl={3} lg={4} md={6} sm={6} xs={6} className={`pointer`} key={item.nftID}>
                          <div className={`${styles.card}`}>
                            <div className={`relative`} onClick={() => goDetail(item)}>
                              <LazyLoad className={`${styles.image}`}>
                              <img className={`${styles.childImg}`} src={baseUrl + item.Image} onError={(e: any) => { e.target.onerror = null; e.target.src = require('../../assets/images/noImg1.png') }}  alt="" />
                               
                              </LazyLoad>
                              
                            </div>
                          

                          <Row between="xs" className={`${styles.cardTips}`} onClick={() => goDetail(item)}>
                              <Col xs={12} className={`flex ai-center`} >
                              {item.metaDataUser && <div className={`${styles.nftName} ellipsis-1 text-left`}>
                                  {item.NftName || '--'}
                                  
                                </div>} 
                              </Col>
                            </Row>


                            {bottomCurrentID===1?
                            <Row around="xs" className={`${styles.cardTips}`}>
                              <Col xs={item.NftType===5 || item.NftType===6 ? 4:6} className={`${styles.cardBottom} ${styles.divider}`}>
                                
                                <span>拥有</span>
                                <p>{item.amount || '--'}</p>
                              </Col>
                            <Col xs={item.NftType===5 || item.NftType===6 ? 4:6} className={`${styles.cardBottom} 
                              ${item.NftType===5||item.NftType===6 ? styles.divider:''}`}>
                              
                                <button  className={`${styles.cardbtn}`}  onClick={() => {
                                        setIsShow(true);
                                        setDetailData(item)
                                      }} disabled>转赠</button>
                              </Col>
                            </Row>
                            :<></>}
                          </div>
                          
                        </Col>)
                    }) : <></>
                    }
                    
                  </Row>
                  
                {UserInfoDataMain.KindInfo.Other>pageData.PageSize && <div className={'flex ai-center jc-center pt-20 pb-40'}>
                    <RcPagination total={UserInfoDataMain.KindInfo.Other} pageSize={pageData.PageSize} current={current} locale={Locale === 'en' ? LocaleEn_US : undefined} className={`font-20 text-conter ${styles.orginPagination}`} onChange={(current: number, pageSize: number) => {
                        let data = Object.assign({}, pageData, { PageNo: current});
                        setCurrent(current);
                        setPageData(data);
                      }}  />
                    </div>}
                </> :
                <Empty
                  imageStyle={{
                    width: 480,
                    height: 'auto',
                    display: 'block'
                  }}
                  description={
                    <p style={{ textAlign: 'center', margin: '24px 0px', fontSize: '24px', color: '#9f9f9f' }}>{t(`methods.noData`)}</p>
                  }
                  image={require('../../assets/icon/noData.png')}
                />
              }
              </Col>
          </Row>
        }

        {topCurrentID===13 &&
          <Row style={{ padding: '29px' }}>
              <Col xs={12}> 
                  
                  {(dataList.length > 0) ?
                  <>
                  <Row className={`${styles.exploreRow}`}>
                    {dataList.length > 0 ? dataList.map((item:any, index:number) => {
            
                      return (
                        <Col xl={3} lg={4} md={6} sm={6} xs={6} className={`pointer`} key={item.nftID}>
                          <div className={`${styles.card}`}>
                            <div onClick={() => goDetail(item)}>
                              <LazyLoad className={`${styles.image}`}>
                              <img className={`${styles.childImg}`} src={baseUrl + item.Image} onError={(e: any) => { e.target.onerror = null; e.target.src = require('../../assets/images/noImg1.png') }}  alt="" />
                              </LazyLoad>
                            </div>
                          

                          <Row between="xs" className={`${styles.cardTips}`} onClick={() => goDetail(item)}>
                              <Col xs={12} className={`flex ai-center`} >
                              {item.metaDataUser && <div className={`${styles.nftName} ellipsis-1 text-left`}>
                                  { item.NftName || '--'}
                                  
                                </div>} 
                              </Col>
                            </Row>


                            {bottomCurrentID===1?
                            <Row around="xs" className={`${styles.cardTips}`}>
                              <Col xs={item.NftType===5 || item.NftType===6 ? 4:6} className={`${styles.cardBottom} ${styles.divider}`}>
                                <span>拥有</span>
                                <p>{item.Amount || '--'}</p>
                              </Col>
                            <Col xs={item.NftType===5 || item.NftType===6 ? 4:6} className={`${styles.cardBottom} 
                              ${item.NftType===5||item.NftType===6 ? styles.divider:''}`}>
                                <button  className={`${styles.cardbtn}`}  onClick={() => {
                                        setIsShow(true);
                                        setDetailData(item)
                                      }}>转赠</button>
                              </Col>
                            </Row>
                            :<></>}
                          </div>
                          
                        </Col>)
                    }) : <></>
                    }
                    
                  </Row>
                  
                {UserInfoDataMain.KindInfo.DaoMedal>pageData.PageSize && <div className={'flex ai-center jc-center pt-20 pb-40'}>
                                <RcPagination total={UserInfoDataMain.KindInfo.DaoMedal} pageSize={pageData.PageSize} current={current} locale={Locale === 'en' ? LocaleEn_US : undefined} className={`font-20 text-conter ${styles.orginPagination}`} onChange={(current: number, pageSize: number) => {
                        let data = Object.assign({}, pageData, { PageNo: current });
                        setCurrent(current);
                        setPageData(data);
                      }}  />
                    </div>}
              </> :
                <Empty
                  imageStyle={{
                    width: 480,
                    height: 'auto',
                    display: 'block'
                  }}
                  description={
                    <p style={{ textAlign: 'center', margin: '24px 0px', fontSize: '24px', color: '#9f9f9f' }}>{t(`methods.noData`)}</p>
                  }
                  image={require('../../assets/icon/noData.png')}
                />
              }
              </Col>
          </Row>
        }
        {
          topCurrentID===6 &&
          <Row style={{ padding: '29px' }}>
              <Col xs={12}>                 
                  {(dataList.length > 0) ?
                  <>
                  <Row className={`${styles.exploreRow}`}>
                    {dataList.length > 0 ? dataList.map((item:any, index:number) => {
                      return (
                        <Col xl={3} lg={4} md={6} sm={6} xs={6} className={`pointer`} key={item.TokenID}>
                          <div className={`${styles.card}`}>
                           <div onClick={() => goDetail(item)}>
                                <LazyLoad className={`${styles.image}`}>
                                <img className={`${styles.childImg}`} src={baseUrl + item.Image} onError={(e: any) => { e.target.onerror = null; e.target.src = require('../../assets/images/noImg1.png') }}  alt="" />
                              </LazyLoad>
                            </div>
                          

                          <Row between="xs" className={`${styles.cardTips}`} onClick={() => goDetail(item)}>
                              <Col xs={12} className={`flex ai-center`} >
                                <div className={`${styles.nftName} ellipsis-1 text-left`}>
                                  {item.NftName }
                                  
                                </div>
                              </Col>
                            </Row>


                            {bottomCurrentID===1?
                            <Row around="xs" className={`${styles.cardTips}`}>
                              <Col xs={item.NftType===5 || item.NftType===6 ? 4:6} className={`${styles.cardBottom} ${styles.divider}`}>
                                <span>拥有</span>
                                <p>{item.Amount}</p>
                              </Col>
                            <Col xs={item.NftType===5 || item.NftType===6 ? 4:6} className={`${styles.cardBottom} 
                              ${item.NftType===5||item.NftType===6 ? styles.divider:''}`}>
                    
                                <button  className={`${styles.cardbtn}`}  onClick={() => {
                                        setIsShow(true);
                                        setDetailData(item)
                                      }}>转赠</button>
                              </Col>
                              {(item.NftType===5 || item.NftType===6) &&
                                <Col xs={4} className={`${styles.cardBottom}`}>
                           
                                  <button className={`${styles.cardbtn} ${styles.cardbtnw}`} onClick={()=>getLootBoxOpen(item)}>开盲盒</button>
                                </Col>
                              }
                            </Row>
                            :<></>}
                          </div>
                          
                        </Col>)
                    }) : <></>
                    }
                    
                  </Row>
                  
                {UserInfoDataMain.KindInfo.LootBox>pageData.PageSize && <div className={'flex ai-center jc-center pt-20 pb-40'}>
                                <RcPagination total={UserInfoDataMain.KindInfo.LootBox} pageSize={pageData.PageSize} current={current} locale={Locale === 'en' ? LocaleEn_US : undefined} className={`font-20 text-conter ${styles.orginPagination}`} onChange={(current: number, pageSize: number) => {
                        let data = Object.assign({}, pageData, { PageNo: current });
                        setCurrent(current);
                        setPageData(data);
                      }}  />
                    </div>}
              </> :
                <Empty
                  imageStyle={{
                    width: 480,
                    height: 'auto',
                    display: 'block'
                  }}
                  description={
                    <p style={{ textAlign: 'center', margin: '24px 0px', fontSize: '24px', color: '#9f9f9f' }}>{t(`methods.noData`)}</p>
                  }
                  image={require('../../assets/icon/noData.png')}
                />
              }
              </Col>
          </Row>
        }
 {
          topCurrentID===21 &&
          <Row style={{ padding: '29px' }}>
              <Col xs={12}>                 
                  {(dataList.length > 0) ?
                  <>
                  <Row className={`${styles.exploreRow}`}>
                    {dataList.length > 0 ? dataList.map((item:any, index:number) => {
                      return (
                        <Col xl={3} lg={4} md={6} sm={6} xs={6} className={`pointer`} key={item.TokenID}>
                          <div className={`${styles.card}`}>
                           <div onClick={() => goDetail(item)}>
                                <LazyLoad className={`${styles.image}`}>
                                <img className={`${styles.childImg}`} src={baseUrl + item.Image} onError={(e: any) => { e.target.onerror = null; e.target.src = require('../../assets/images/noImg1.png') }}  alt="" />
                              </LazyLoad>
                            </div>
                          

                          <Row between="xs" className={`${styles.cardTips}`} onClick={() => goDetail(item)}>
                              <Col xs={12} className={`flex ai-center`} >
                                <div className={`${styles.nftName} ellipsis-1 text-left`}>
                                  {item.NftName }
                                  
                                </div>
                              </Col>
                            </Row>


                            {bottomCurrentID===1?
                            <Row around="xs" className={`${styles.cardTips}`}>
                              <Col xs={item.NftType===5 || item.NftType===6 ? 4:6} className={`${styles.cardBottom} ${styles.divider}`}>
                                <span>拥有</span>
                                <p>{item.Amount}</p>
                              </Col>
                            <Col xs={item.NftType===5 || item.NftType===6 ? 4:6} className={`${styles.cardBottom} 
                              ${item.NftType===5||item.NftType===6 ? styles.divider:''}`}>
                    
                                <button  className={`${styles.cardbtn}`}  onClick={() => {
                                        setIsShow(true);
                                        setDetailData(item)
                                      }}>转赠</button>
                              </Col>
                             
                            </Row>
                            :<></>}
                          </div>
                          
                        </Col>)
                    }) : <></>
                    }
                    
                  </Row>
                  
                {UserInfoDataMain.KindInfo.Right>pageData.PageSize && <div className={'flex ai-center jc-center pt-20 pb-40'}>
                                <RcPagination total={UserInfoDataMain.KindInfo.Right} pageSize={pageData.PageSize} current={current} locale={Locale === 'en' ? LocaleEn_US : undefined} className={`font-20 text-conter ${styles.orginPagination}`} onChange={(current: number, pageSize: number) => {
                        let data = Object.assign({}, pageData, { PageNo: current });
                        setCurrent(current);
                        setPageData(data);
                      }}  />
                    </div>}
              </> :
                <Empty
                  imageStyle={{
                    width: 480,
                    height: 'auto',
                    display: 'block'
                  }}
                  description={
                    <p style={{ textAlign: 'center', margin: '24px 0px', fontSize: '24px', color: '#9f9f9f' }}>{t(`methods.noData`)}</p>
                  }
                  image={require('../../assets/icon/noData.png')}
                />
              }
            </Col>
          </Row>
        } 
        {
          topCurrentID===31 &&
          <Row style={{ padding: '29px' }}>
              <Col xs={12}>                
                  {(dataList.length > 0) ?
                  <>
                  <Row className={`${styles.exploreRow}`}>
                    {dataList.length > 0 ? dataList.map((item:any, index:number) => {
                      return (
                        <Col xl={3} lg={4} md={6} sm={6} xs={6} className={`pointer`} key={item.TokenID}>
                          <div className={`${styles.card}`}>
                           <div onClick={() => goDetail(item)}>
                                <LazyLoad className={`${styles.image}`}>
                                <img className={`${styles.childImg}`} src={baseUrl + item.Image} onError={(e: any) => { e.target.onerror = null; e.target.src = require('../../assets/images/noImg1.png') }}  alt="" />
                              </LazyLoad>
                            </div>
                          

                          <Row between="xs" className={`${styles.cardTips}`} onClick={() => goDetail(item)}>
                              <Col xs={12} className={`flex ai-center`} >
                                <div className={`${styles.nftName} ellipsis-1 text-left`}>
                                  {item.NftName }
                                  
                                </div>
                              </Col>
                            </Row>


                            {bottomCurrentID===1?
                            <Row around="xs" className={`${styles.cardTips}`}>
                              <Col xs={item.NftType===5 || item.NftType===6 ? 4:6} className={`${styles.cardBottom} ${styles.divider}`}>
                                <span>拥有</span>
                                <p>{item.Amount}</p>
                              </Col>
                            <Col xs={item.NftType===5 || item.NftType===6 ? 4:6} className={`${styles.cardBottom} 
                              ${item.NftType===5||item.NftType===6 ? styles.divider:''}`}>
                    
                                <button  className={`${styles.cardbtn}`}  onClick={() => {
                                        setIsShow(true);
                                        setDetailData(item)
                                      }}>转赠</button>
                              </Col>
                            </Row>
                            :<></>}
                          </div>
                          
                        </Col>)
                    }) : <></>
                    }
                    
                  </Row>
                  
                {UserInfoDataMain.KindInfo.AchMedal>pageData.PageSize && <div className={'flex ai-center jc-center pt-20 pb-40'}>
                                <RcPagination total={UserInfoDataMain.KindInfo.AchMedal} pageSize={pageData.PageSize} current={current} locale={Locale === 'en' ? LocaleEn_US : undefined} className={`font-20 text-conter ${styles.orginPagination}`} onChange={(current: number, pageSize: number) => {
                        let data = Object.assign({}, pageData, { PageNo: current });
                        setCurrent(current);
                        setPageData(data);
                      }}  />
                    </div>}
              </> :
                <Empty
                  imageStyle={{
                    width: 480,
                    height: 'auto',
                    display: 'block'
                  }}
                  description={
                    <p style={{ textAlign: 'center', margin: '24px 0px', fontSize: '24px', color: '#9f9f9f' }}>{t(`methods.noData`)}</p>
                  }
                  image={require('../../assets/icon/noData.png')}
                />
              }
              </Col>
          </Row>
        }


        </div>
      </Col>
      <ReadMore />
      <ReactTooltip effect='solid' id='copy' type='info' >
        <span>copy</span>
      </ReactTooltip>
      {showOpenBox && <NFTDetail itemData={NFTInfo} ipfsUrl={baseUrl} onChange={(e:any,n:number)=>{confirmOpenBoxDetail(e);setOpenAmount(n)}} showDetailBtn={true}  />}
      {showPin && <Pin onChange={(e:any)=>{changePin(e)}} />}
      {showLoadding && <ShowLoadding data={{type:loadingType}} onChange={() => handleShowLoadding()} />}
      {showOpenDetail && <OpenDetail itemData={openData} onChange={() => {setShowOpenDetail(false);GetUserNFTData();}} />}

      {isShow && <TransferModal data={{nftType:detailData.NftType,id:detailData.NftID}} itemData={detailData} onChange={() => handleInPro()} />}
      {/*  */}
      {showNFTDetailsModal && <NFTDetailsModal itemData={NFTDetailsData} topCur={topCurrentID} onChange={(e: any) =>(setShowNFTDetailsModal(false),e.type==='sale'?(setIsShow(true),setDetailData(e.data)):getLootBoxOpen(e.data))  } /> }
      
    </>
  );
}
export default Profile;