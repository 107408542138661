
import React,{useState,useEffect}  from 'react';

import { Grid, Row, Col } from 'react-flexbox-grid';

import styles from './index.module.less';

// 切换语言
import { useTranslation } from 'react-i18next';
import LazyLoad from 'react-lazyload';


import { simpleFn } from '../../../../components/Notification';


import wxPay from '../../../../assets/images/explore/wxpay.svg';
import zfbPay from '../../../../assets/images/explore/zfb.svg';
import bankCardPay from '../../../../assets/images/explore/bankCard.svg'


const baseUrl = process.env.BASE_URL+"/ipfs/";

const Exchange =({onChange,itemData}:any)=>{
    // console.log("==================itemData",itemData)
    const { t } = useTranslation();
    const [amount,changAmount] = useState<any>(1);
    const changeDaoM = async ()=>{
        onChange('click',amount)
    }
    const numMulti = (num1:number, num2:number)=>{
        var baseNum = 0;
        try {
            baseNum += num1.toString().split(".")[1].length;
        } catch (e) {
        }
        try {
            baseNum += num2.toString().split(".")[1].length;
        } catch (e) {
        }
        return Number(num1.toString().replace(".", "")) * Number(num2.toString().replace(".", "")) / Math.pow(10, baseNum);
    };
    return (
        <div className={`flex ai-center jc-center ${styles.popUps}`} >
            <div className={`${styles.popBg}`} onClick={() => onChange && onChange(false)}>背景</div>
            <Col xs={10} sm={10} lg={8} xl={6} className={`flex jc-center ai-center fd-column ${styles.popUpsMode}`}>
                <div className={`${styles.popClose}`}  onClick={() => onChange && onChange(false)}>close</div>
                <h3 className={`${styles.logTitle}`}>提交订单</h3>
                <Row className={`pt-40 ${styles.logMain}`}>
                   <Col lg={10} lgOffset={1} className={`mb-40`}>
                       <Row>
                            <Col md={4}>
                                <LazyLoad><img src={baseUrl + itemData.DataHash } alt='' width={120} height={120} /></LazyLoad>
                            </Col>
                            <Col md={8} className={`pl-10`}>
                                <div className={`flex ai-center`}>
                                    <h3 className={`font-18 `}>{itemData.Name}</h3>
                                </div>
                               <div className={`flex ai-center mt-20`}>
                                    <div className={`col-666 font-14 `}>售价</div>
                                    <h3 className={`font-18 ml-15`}>¥ {itemData.Price/100}</h3>
                                </div>
                                <div className={`flex ai-center mt-20`}>
                                    <div className={`col-666 font-14 `}>库存</div>
                                    <h3 className={`font-18 ml-15`}>{itemData.LeftAmount}</h3>
                                </div>
                                <div className={`flex ai-center mt-20`}>
                                    <div className={`col-666 font-14`}>数量</div>
                                    <input type='number' placeholder={'输入购买数量'}  className={`flex-1 ml-15 ${styles.logTex} `} value={amount}  onChange={e =>{
                                        changAmount(e.target.value);
                                        // countDiscoun(unitPrice,e.target.value);
                                    }}  min={1} max={(itemData.LeftAmount)>itemData.BuyLimitAmount?itemData.BuyLimitAmount:itemData.LeftAmount} />
                                    <div className={`pl-20 font-14 col-666`}>限购 {itemData.BuyLimitAmount} 件</div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={10} lgOffset={1} className={`pb-40 ${styles.modeBtnTop}`}>
                        <div className={`flex ai-center mt-30 pb-40`}>
                            <div className={`col-666 font-14 `}>总计</div>
                            <div className={`ml-15 flex flex-1`}><span className={`col-333 font-16 mr-5`}>¥</span> <h3 className={`font-18 col-blue`}>{numMulti(amount,itemData.Price/100)}</h3></div>
                        </div>
                        
                        <div className={`col-red font-12 pb-15 pl-15`}>注意：您24小时内只能取消5次订单，超出次数将限购24小时。</div>
                        <button className={`${styles.medalBtn}`}  onClick={changeDaoM}>提交订单</button>

                        <Row className={` mt-25 ${styles.warnTips}`}>
                            <Col xs={12} className={`flex ai-center font-14`}><img src={require('../../../../assets/images/login/tips-icon.png')} alt='' className={`mr-10`} />购买须知</Col>
                            <Col xs={12} className={`mt-10 font-12 flex-1`}>数字作品为虚拟数字商品，而非实物，一经售出，不支持退换。本商品仅限实名认证为年满18周岁的中国大陆用户购买。数字作品的版权由发行方或原创者拥有，除另行取得版权拥有者书面同意外，用户不得将数字作品用于任何商业用途。请勿对数字作品进行炒作、场外交易、欺诈，或以任何其他非法方式进行使用。</Col>
                        </Row>
                    </Col>
                </Row> 
               
            </Col>
            
        </div>
    )
}
export default Exchange;