import React,{useState,useEffect}  from 'react';

import { Grid, Row, Col } from 'react-flexbox-grid';

import styles from './index.module.less';

import { simpleFn } from '../../Notification';

// web3
import Web3 from 'web3';

import { t } from 'i18next';

// api
import { userResetpwd,getSmsCode,vplogin } from '../../../api/public';
import { setTimeout } from 'timers';


import {getKeccak256,byteLength,} from '../../../assets/js/web3Public';
const web3 = new Web3(Web3.givenProvider);


const Register=({onChange}:any)=>{
    const [userPhone,changUserPhone] = useState('');
    const [userPwd,changUserPwd] = useState('');
    const [userTips,changUserTips] =useState('');
    const [userPwdTips,changUserPwdTips] =useState('');

    const [phoneCode,setPhoneCode] = useState('');
    const [padText,setPadText] = useState('');
    const [padTextAgain,setPadTextAgain] = useState('');

    const [fromTips,setFromTips] = useState('');

    const [inviter,setInviter] = useState('')

    const getUserRegister = async ()=>{
        console.log(userPhone,padText,phoneCode);
       const MsgKeccak256 = getKeccak256("\u0019Ethereum Signed Message:\n" + byteLength(padText) + padText).substr(2)
        let data={
            Phone:userPhone,
            VeriCode:phoneCode,
            PassWord:MsgKeccak256
        }
        const res = await userResetpwd(data)
        console.log('=================',res)
        if(res.code===0){
            simpleFn('修改成功！','success');

            phoneLogin();
        }else{
            setFromTips(res.msg)
        }


    }



    

    const phoneLogin = async ()=>{
  
        const timestamp = new Date().getTime().toString();
        const uTokenValidPeriod='864000000000';
        const MsgKeccak256 = getKeccak256("\u0019Ethereum Signed Message:\n" + byteLength(padText) + padText).substr(2)
        const data ={

            Phone: userPhone,
            PassWord:MsgKeccak256,
            UTokenValidPeriod: uTokenValidPeriod,
            TimeStamp: timestamp,
            
        }
        const res = await vplogin(data)
        if(res.code===0){
            sessionStorage.setItem('userInfo',JSON.stringify(res.data))

            sessionStorage.setItem('AuthStatus',res.data.AuthStatus)
            
            sessionStorage.setItem('userName',res.data.UserName);
            sessionStorage.setItem('userMobile',res.data.Phone);
            onChange();
        }
        console.log("=========================login",res)
    }
    const [getCodeTips,setGetCodeTips] = useState<string>('获取验证码');
    const [getCodeBtnClick,setGetCodeBtnClick] = useState<boolean>(true);

    const changPhone=()=>{
        console.log("=========================",userPhone)
        const phoneReg:RegExp = /^((1[0-9]{1}[0-9]{1})+\d{8})$/; 
        if(!phoneReg.exec(userPhone)){
            changUserTips('Please enter the correct phone number!')
            setGetCodeBtnClick(true)
            return false;
        }
        changUserTips('')
        setGetCodeBtnClick(false)
    }
    const [isGetCode,setIsGetCode]=useState<boolean>(false);
    // 
    const countdownBtn=()=>{
        let timer: any = null;
        const Countdown = 60;
       if (!timer) {
         let countdown = Countdown;
         timer = setInterval(() => {
           if (countdown > 0 && countdown <= 60) {
             countdown--;
             if (countdown !== 0) {
                setGetCodeBtnClick(true);
                setIsGetCode(true)
               setGetCodeTips("重新发送(" + countdown + ")");
             } else {
               clearInterval(timer);
               setGetCodeTips("获取验证码");
               countdown = 60;
               timer = null;
               setGetCodeBtnClick(false);
               setIsGetCode(false)
             }
           }
         }, 1000);
       }
    }
    const getCode= async()=>{
        const phoneReg:RegExp = /^((1[0-9]{1}[0-9]{1})+\d{8})$/; 
        if(!phoneReg.exec(userPhone)){
            changUserTips('Please enter the correct phone number!')
            setGetCodeBtnClick(false)
            return false;
        }
        // let num = 60;
        // let code = setInterval(()=>{
        //     setGetCodeTips(num+' S')
        // },1000)
        const TimeStamp = +new Date()
        const temp = ''+userPhone+TimeStamp
        const MsgKeccak256 = getKeccak256("\u0019Ethereum Signed Message:\n" + byteLength(temp) + temp).substr(2)

        const res = await getSmsCode({Phone:userPhone,TimeStamp,MsgKeccak256})
        // if(res.code===0){

        // }

        switch(res.code){
            case 0:
                simpleFn('发送成功，请留意！','success')
                countdownBtn();
            break;
            default:
            
            simpleFn('服务器出错,请稍后再试！','error')
            // console.log('服务器出错')

        }
    }
    const padChecking=()=>{
        
        // console.log("padChecking==============================",padText,padTextAgain)
        if(padText!==padTextAgain){
            changUserPwdTips('两次密码不一致！！！');
        }else{
            changUserPwdTips('')
        }
    }

    return (
        <>
            <h3 className={`${styles.logTitle}`}>忘记密码</h3>
            <Row className={`pt-30 pb-60 ${styles.logMain}`}>
                <Col lg={8} lgOffset={2} className={`flex space-16`}>
                    <span className={`font-18 mt-20`}>+86</span>
                    <input type='text' placeholder={'请输入您的手机号码'}  onChange={e =>{
                            changUserPhone(e.target.value);
                    }}  onKeyUp={changPhone}  className={`${styles.logTex}  ${userTips?styles.hasTips:''}`}  />
                </Col>
                <Col lg={8} lgOffset={2} className={`flex space-16`}>
                    <input type='text' placeholder={'请输入手机验证码'}  onChange={e =>{
                        setPhoneCode(e.target.value)    
                    }} value={phoneCode} className={`flex-1 ${styles.logTex} `}  />
                        {/* disabled={getCodeBtnClick} */}
                    <button className={`btn blue-btn ${styles.getCodeBtn} ${isGetCode?styles.getCodeClick:''}`}  onClick={getCode} >{getCodeTips}</button>
                </Col>



                <Col lg={8} lgOffset={2}>
                    <input type='password' placeholder={'请设置登录密码'} value={padText}  onChange={e =>{
                        console.log(e.target.value);
                            setPadText(e.target.value);
                    }} onKeyUp={padChecking} className={`${styles.logTex} ${userPwdTips?styles.hasTips:''}`}  />
                </Col>
                <Col lg={8} lgOffset={2}>
                    <input type='password' placeholder={'请再次确认登录密码'} value={padTextAgain}  onChange={e =>{
                        console.log(e.target.value);
                            setPadTextAgain(e.target.value);
                    }} onKeyUp={padChecking} className={`${styles.logTex} ${userPwdTips?styles.hasTips:''}`}  />
                </Col>
                
                <Col lg={8} lgOffset={2} className={`flex ai-center jc-center fd-column ${styles.fromTipsBox}`}>
                    {userTips?<p className={`${styles.fromTips}`}>{userTips}</p>:null}
                    {userPwdTips?<p className={`${styles.fromTips}`}>{userPwdTips}</p>:null}
                    {fromTips?<p className={`${styles.fromTips}`}>{fromTips}</p>:null}
                </Col>
                <Col lg={8} lgOffset={2}>
                    <button className={`btn blue-btn ${styles.logBtn}`} disabled={userPhone==='' || padText==='' || phoneCode===''} onClick={getUserRegister}>确定并登录</button>
                </Col>
            </Row>
        </>  
    )
}
export default Register;