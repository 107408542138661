
import React,{useState,useEffect}  from 'react';

import { Grid, Row, Col } from 'react-flexbox-grid';

import styles from '../User/Login/Login.module.less';

// 切换语言
import { useTranslation } from 'react-i18next';

import ReactLoading from 'react-loading';

const ShowLoadding = ({ onChange, data }: any) => {
  
    const { t } = useTranslation();

    return (
      <div className={`flex ai-center jc-center ${styles.popUps}`} >
        {/*  onClick={() => onChange && onChange(false)} */}
        <div className={`${styles.popBg}`}>背景</div>
        <Col xs={8} sm={8} lg={6} xl={4} className={`flex jc-center ai-center fd-column ${styles.popUpsMode}`} style={{minHeight:'160px'}}>
          <div className={`lh-24 font-24 mt-20 mb-30`}>{data.type === 1? '正在上链，请稍等...':data.type === 2?'上链成功...':data.type === 3?'上链失败，请稍后重试！':'上链异常，请稍后重试！'}</div>
  
              <div className={`flex fd-column ai-center pb-12`}>
                {data.type === 1 ? <ReactLoading type='spin' color='#123456' height={'48px'} width={'48px'} /> : 
                data.type === 2 ? <img src={require(`../../assets/icon/success.png`)} style={{ width: 48, height: 48, marginRight: '8px' }} alt='' /> : data.type === 3 ? <img src={require(`../../assets/icon/error.png`)} style={{ width: 48, height: 48, marginRight: '8px' }} alt='' /> : ''}
              </div>
        </Col>
      </div>
    )
  }
export default ShowLoadding;