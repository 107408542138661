/* eslint-disable @typescript-eslint/no-redeclare */
/* eslint-disable no-restricted-globals */
import React,{useState,useEffect,}  from 'react';

import { Grid, Row, Col } from 'react-flexbox-grid';

import styles from './index.module.less';

import { simpleFn } from '../../../../components/Notification';



import { t } from 'i18next';

// api
import { vpCenterLogin } from '../../../../api/public';
import { setTimeout } from 'timers';

import { useHistory } from "react-router-dom";

import ReactTooltip from 'react-tooltip';

// web3
import Web3 from 'web3';

import CryptoJS from "crypto-js";


import { getDecryptKey,getKeccak256,byteLength,getSign,getUtf8ToHex,encrypt,encryptPin } from '../../../../assets/js/web3Public';
const web3 = new Web3(Web3.givenProvider);
const ethereumUtil = require('ethereumjs-util')

const SECRET_KEY = CryptoJS.enc.Utf8.parse("f097eae0d2c86d3b");
const GetToken=({onChange}:any)=>{

    /**
 * 解密方法
 * @param data
 * @returns {string}
 */
const decrypt=(data: string)=> {
	const encryptedHexStr = CryptoJS.enc.Hex.parse(data);
	const str = CryptoJS.enc.Base64.stringify(encryptedHexStr);
	const decrypt = CryptoJS.AES.decrypt(str, SECRET_KEY, {
		iv: SECRET_KEY,
		mode: CryptoJS.mode.CBC,
		padding: CryptoJS.pad.Pkcs7
	});
	const decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
	return decryptedStr.toString();
}

    let UserName = sessionStorage.getItem('userName')||'';
    const userInfo = JSON.parse(sessionStorage.getItem('userInfo')||'') ;
    let pinKeyData:any = sessionStorage.getItem('pinKey');
    const [userPIN,changUserPIN] = useState<any>(JSON.parse(pinKeyData)||'');
    const [showTips,setShowTips] = useState<boolean>(false)
    const confirmWalletData= async()=>{
        try{
            const decryptKey = getDecryptKey({key:userInfo.PrivateKeyCipher,pin:userPIN});
            console.log(decryptKey)
            if(allChecked){
                sessionStorage.setItem('pinKey',JSON.stringify(userPIN))
              }
            onChange(decryptKey)
            // getToken(UserName,decryptKey);
        }catch(err){
            console.log('PIN码错误')
            setShowTips(true)
        }
    
    }
    
    useEffect(()=>{
        
        if(pinKeyData){
            changUserPIN(JSON.parse(pinKeyData));
        }
    },[])
    useEffect(()=>{
        if(userPIN && userPIN.length>=6 && pinKeyData){
            confirmWalletData()
        }
    },[userPIN])
    const [allChecked, setAllChecked] = useState(false);
    const [checkboxes, setCheckboxes] = useState<any>({
        remember: true
    });
  
    function handleChange(e:any) {
      setCheckboxes({
        ...checkboxes,
        [e.target.id]: e.target.checked
      })
    }
  
    useEffect(() => {
      const result = Object.values(checkboxes).every(v => v);
      setAllChecked(result);
    }, [checkboxes]);
    return (
        <>
        <h3 className={`${styles.logTitle}`}>确认身份</h3>
                    <Row className={`pt-30 ${styles.logMain}`}>
                        <Col lg={10} lgOffset={1}>
                            <div className={`flex jc-center ai-center font-14 ${styles.warnTips}`}>
                                <img src={require('../../../../assets/images/login/tips-icon.png')} alt='' className={`mr-10`} />
                                <span>为了您的账户安全，需要确认身份!</span>
                            </div>
                        </Col>
                        <Col lg={10} lgOffset={1} className={`flex space-16`}>
                           {/* onKeyUp={changPhone}  ${userTips?styles.hasTips:''}*/}
                            <input type='password' placeholder={'请输入PIN码'} value={userPIN}  onChange={(e:any)=>{
                                    changUserPIN(e.target.value)
                                    setShowTips(false)
                            }}    className={`mt-20 ${styles.logTex} `}  />
                        </Col>
                        <Col lg={10} lgOffset={1} className={`pt-10 pb-40`}>
                            {showTips ? <div className={`font-14 col-red`}>PIN码错误，请重新输入</div>:
                            <div className={`font-14 col-999`}>6-32位必须包含大小写字母、数字和符号的任意组合</div>}
                        </Col>
                        
                        <Col lg={10} lgOffset={1} className={`pt-40 pb-40`}>
                            {/*  onClick={getUserRegister} */}
                            <div className={`mb-15`}>
                                <label className={`col-666 font-14 flex ai-center`}>
                                    <input type={'checkbox'} name="remember"
                        value={checkboxes.remember}
                        id="remember"
                        onChange={handleChange} />
                                    <span className={`ml-5`}>本次登录不再输入PIN码</span>
                                </label>
                            </div>
                            <button className={`btn blue-btn ${styles.logBtn}`} disabled={userPIN.length<6} onClick={()=>confirmWalletData()}>确认</button>
                        </Col>
                        
                    </Row>
                
           
        </>  
    )
}
export default GetToken;