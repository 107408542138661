/* eslint-disable react-hooks/rules-of-hooks */
import React , { useEffect, useState }  from 'react';

import { Link,useHistory } from 'react-router-dom';

// api
import { getNewFeed,topCollectList,rpc,likeSortList,nftNumList,recommendList,loopic,newGetNFTDetail } from '../../api/public'

// 切换语言
import {  useTranslation } from 'react-i18next';

// 文字动画
// import Texty from 'rc-texty';
import QueueAnim from 'rc-queue-anim';

import BannerAnim, { Element } from 'rc-banner-anim';

import { Grid, Row, Col } from 'react-flexbox-grid';

// 样式
import styles from  "./index.module.less"

import LazyLoad from 'react-lazyload';
//图片
// eslint-disable-next-line import/first
import IntegralIcon from '../../assets/images/img/integral-icon.png';
// 
import FormatMsgTime from "../../components/FormatMsgTime";
import Stats from '../../components/Stats';


import Empty from '../../components/Empty';

import errorIconImg from '../../assets/images/noHeadImg.png'


import CryptoJS from "crypto-js";
const SECRET_KEY = CryptoJS.enc.Utf8.parse("f097eae0d2c86d3b");
// 主题
const themePrimaryColor = 'theme';
const primaryColor =  localStorage.getItem(themePrimaryColor)||'light';

const baseUrl = process.env.BASE_URL+"/ipfs/";
//banner
const Banner:React.FC =()=>{

  const history = useHistory();
  //申明语言转换
  const {t} = useTranslation();
  // 特卖
  const getLoopic = async()=>{
    const res = await loopic({type:101})
    if(res.code===0){

      console.log('123========',JSON.parse(res.data.content))
      let nftID =JSON.parse(res.data.content)[0].nftID
      getSaleNft(nftID)
    }
    // 
  }
  const noImgList = ['noImg1.png', 'noImg2.png', 'noImg3.png'];
  const [bannerNFTList,setBannerNFTList] = useState<any>([])
  const [changSaleNft,setChangSaleNft] = useState<boolean>(false)
  const getSaleNft = async(id:string)=>{
    console.log(id)
    const res = await newGetNFTDetail({id: id})
    if(res.code===0){
      console.log('1234========',res)
      let nftList = new Array()
      nftList[0] = {...res.data,...{nftID:id,type:1}}
      setBannerNFTList([...nftList]);
    }
    
    setChangSaleNft(true);
  }
  const getRecommendList = async()=>{
    const res = await recommendList({})
    if(res.code===0){

      console.log('12345========',res)
      setBannerNFTList([...bannerNFTList,...res.data]);
      
    }
  }
  useEffect(()=>{
    // getLoopic();
    getRecommendList();
  },[])
  // useEffect(()=>{
  //   if(changSaleNft){
  //     getRecommendList();
  //   }
  // },[changSaleNft])
  const goDetail = (e: any) => {
    console.log(1234,e)
    const type = 2;
    // const id = e.id;
    const nftid = e.ProductID;
    // &nftid=${nftid}  , nftid
    history.push(`/explore/detail?id=${nftid}`, { type, nftid })
  }
  const goApps=()=>{

    const socialDataToken = sessionStorage.getItem('socialDataToken')
    const s = encrypt(socialDataToken)
    const p = sessionStorage.getItem('p');
    const UserName = sessionStorage.getItem('userName');
    const u = encrypt(UserName);
    const t = encrypt(new Date().getTime().toString());
    const isUni = encrypt('1');
    const ep = encrypt(`s=${s}&p=${p}&u=${u}&uni=${isUni}&t=${t}`)
    
    window.open(process.env.BASE_URL +'/hall/star/?'+ep);
}
// 加密
const encrypt = (data: any)=> {
    if (typeof data === "object") {
        try {
            // eslint-disable-next-line no-param-reassign
            data = JSON.stringify(data);
        } catch (error) {
            console.log("encrypt error:", error);
        }
    }
    const dataHex = CryptoJS.enc.Utf8.parse(data);
    const encrypted = CryptoJS.AES.encrypt(dataHex, SECRET_KEY, {
        iv: SECRET_KEY,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.ciphertext.toString();
}
  return (
    <div className={`flex ai-center ${styles.banner}`}>
        <Col md={10} mdOffset={1} lg={8} lgOffset={2}  className='bannerTips'>
          <Row>
            <Col md={7}>
              <QueueAnim delay={100} animConfig={[
                  { opacity: [1, 0], translateY: [0, 200] },
                  { opacity: [1, 0], translateY: [0, -200] }
                ]}>
                  <div className={`${styles.bannerTitle} pt-60`} dangerouslySetInnerHTML={{__html:`${t('methods.bannerTitle')}`}} key="title" />
                  <div className={`${styles.bannerSubTitle}  pt-35`} dangerouslySetInnerHTML={{__html:`${t('methods.bannerSubTitle')}`}} key="subtitle" />
                  <div className={`pt-35 pb-30 space-24`} key="btn">
                    
                    <Link className={`btn blue-btn pointer`} to={`/brand`}>{t('methods.explore')}</Link>
                    <div className={`btn white-btn pointer`} onClick={()=>goApps()}>探索</div>  
                  </div>
                </QueueAnim>
              </Col>
              {console.log("1222===============",bannerNFTList)}
              {bannerNFTList.length>0 && <Col md={5} className={`flex ai-center jc-center`}>
                 
                   

                      <BannerAnim prefixCls={`${styles.hotMain}`} autoPlay arrow={false} autoPlaySpeed={3000} type={`acrossOverlay`}>

                      {bannerNFTList.map((item:any,index:number)=>{

                      let metaDataSystem: any;
                      if (item.Meta !== '') {
                        metaDataSystem = JSON.parse(item.Meta);
                        
                      } else {
                        metaDataSystem = {
                          "Image": '',
                          "Thumb_Hash": ''
                        }
                      }
                      console.log(item.Meta)
                       return <Element 
                          prefixCls={`${styles.cardMain}`}
                          key={index}
                        >
                          <div className={`${styles.card}`} onClick={()=>goDetail(item)}>
                            <div className={`${styles.cardType} ${styles.cardTypeRed}`}>荐</div>
                            <div className={`${styles.cardImg}`}>
                              <LazyLoad className={`${styles.image}`}>
                                <img className={`${styles.trueImg}`} src={baseUrl + metaDataSystem.Thumb_Hash} onError={(e: any) => { e.target.onerror = null; e.target.src = require('../../assets/images/' + noImgList[Math.floor(Math.random() * noImgList.length)]) }} alt="" /> 
                              </LazyLoad>
                            </div>
                            <Row between="xs" className={`${styles.cardTips}`}>
                              <Col xs className={`ellipsis-1 text-left ${styles.title}`}>
                                {item.Name}
                              </Col>
                            </Row>
                             <Row between="xs" className={`mt-0 ${styles.cardTips}`}>
                                <Col xs  className={`col-red text-left ${styles.price}`}>
                                  ¥ <span className={`font-24`}>{item.Price/100}</span>
                                </Col>
                                <Col xs className={`text-right`}>
                                  <p className={`col-999 font-14`}>剩余/总量</p>
                                  <p className={`mt-10 pb-10 font-14`}>{item.LeftAmount}/{item.TotalAmount}</p>
                                </Col>
                            </Row>
                          </div>
               
                           
                        </Element>
                        })}
                         
                        
                      </BannerAnim>



              

              </Col>}
            </Row>
        </Col>
    </div>
  )
}

// 品牌
const Brand = ()=>{
  

  const history = useHistory();
  const [brandList,setBrandList] =useState<any>([]);

  const getBrandList = async ()=>{
    const res = await topCollectList({})
    console.log(res)
    if(res.code===0){
      setBrandList(res.data)
    }
  }
  const outputMate = (list:any,type:string)=>{
    let item = list.filter((item:any)=>item.trait_type===type)[0]
    return item?item.value:false
  }
  const goSort=(e:string)=>{
    history.push('/brand/sort?did='+e)
  }
  useEffect(()=>{
    getBrandList()
  },[])
  const goBarnd=()=>{
    history.push('/brand')
  }
  return (
    <div className={`${styles.bodyCentent} ${styles.brandBg}`}>
      <Col md={10} mdOffset={1} >
        <div  className={`mt-40 flex ai-flex-end`}>
          <p className={`font-32 col-333 pl-15`}>人气品牌馆</p>
          <span className={`col-blue font-16 ml-40`} onClick={goBarnd}>{'查看更多 >'}</span>
        </div>
        <div className={`flex mt-10 pb-20 ${styles.brandList}`}>
            {brandList.map((item:any)=>{
              return (
              <div className={`mb-30 mt-30 pl-15 pr-15 ${styles.brandListItem}`} key={item.sceneId}>
                <div className={`pointer ${styles.brandMain}`} onClick={()=>goSort(item.sceneId)}>
                    <div className={`${styles.brandItem}`}>
                        <LazyLoad>
                          <img src={baseUrl+ item.banner} alt='' key={item} className={`${styles.brandItemBanner}`} onError={(e: any) => { e.target.onerror = null; e.target.src = require('../../assets/images/noImg1.png') }}/>
                        </LazyLoad>
                        <div className={`${styles.brandItemHeading}`}>
                        <LazyLoad>
                          <img src={ baseUrl + item.logo} alt=''  onError={(e: any) => { e.target.onerror = null; e.target.src = require('../../assets/images/noImg3.png') }} />
                        </LazyLoad>
                          <h3 className={`pt-35 font-16`}>{item.name}</h3>
                          <Row className={`pt-15 col-blue font-14`}>
                          <Col xs={6} className={`${styles.itemLeft}`}>在售： {item.onSale}</Col>
                            <Col xs={6}>发行 {item.issued} </Col>
                          </Row>
                        </div>
                        <div className={`font-14 col-666 pt-30 text-center ellipsis-3 lh-20`}>{item.desc}</div>
                    </div>
                </div>
              </div>)})
              } 
        </div>
      </Col>
    </div>
  )
}
// 排行
const TopHundred=()=>{
  const history = useHistory();
  const goStats = (e: any) => {
    history.push({pathname:'/stats',state:{type:statType}})
  }
  const [statType,setStatType] = useState(1)
  return (
    <div className={`bg${primaryColor} `} >
      <Stats data={{limit:10}} onChange={(e:any)=>{setStatType(e)}} />
      <div className={`flex jc-center mt-40 pb-75`}>
        <button className={`btn blue-btn pointer`} onClick={goStats}>查看全部</button>
      </div>
    </div>
  )
}

// 说明
const Legend =()=>{
  return (
    <>
      <div className={`${styles.LegendCentent}`}>
          
        <Col md={10} mdOffset={1} className={`pt-75 pb-75 ${styles.LegendMain}`}>
        <div  className={`mt-60 mb-40 flex ai-flex-end`}>
          <p className={`font-32 col-333 pl-15`}>核心技术</p>
        </div>
          <Row>
              <Col md={4} className={`pl-15 pr-15`}>
                <div className={`text-left ${styles.legendBox}`}>
                    <div className={`flex jc-flex-start pt-10 pb-40 ${styles.legendImg}`}>
                      <img src={require('../../assets/images/home/bg/mode-icon.png')} alt=''  height={90}  />
                    </div>
                    <p className={`font-20`}>数据资产价值模型</p>
                    <p className={`font-16 mt-15 col-666 lh-24 ${styles.legendTips}`}>{`数据资产化 > 资产藏品化 > 藏品对象化 > 对象价值化`}</p>
                    {/* <div className={`mt-20`}><span className={`col-blue font-16`}>{`查看详情 >`}</span></div> */}
                </div>
              </Col>
              <Col md={4} className={`pl-15 pr-15`}>
                <div className={`text-left ${styles.legendBox}`}>
                <div className={`flex jc-flex-start pt-10 pb-40 ${styles.legendImg}`}>
                      <img src={require('../../assets/images/home/bg/net-icon.png')} alt='' height={90} />
                  </div>
                  <p className={`font-20`}>对象网络</p>
                  <p className={`font-16 mt-15 col-666 lh-24 ${styles.legendTips}`}>{`为Web3应用和数据资产价值模型提供底层基础设施支撑。`}</p>
                  {/* <div className={`mt-20`}><span className={`col-blue font-16`}>{`查看详情 >`}</span></div> */}
                    
                </div>
              </Col>
              <Col md={4} className={`pl-15 pr-15`}>
                <div className={`text-left ${styles.legendBox}`}>
                  <div className={`flex jc-flex-start pt-10 pb-40 ${styles.legendImg}`}>
                    <img src={require('../../assets/images/home/bg/web3-icon.png')} alt='' height={90} />
                  </div>
                  <p className={`font-20`}>Web3 API</p>
                  <p className={`font-16 mt-15 col-666 lh-24 ${styles.legendTips}`}>{`Verseport开放数据资产价值模型的Web3 API。`}</p>
                  {/* <div className={`mt-20`}><span className={`col-blue font-16`}>{`查看详情 >`}</span></div> */}
                </div>
              </Col>
              {/* <Col md={12} className={`pl-15 pr-15 mt-30`}>
                <div className={`${styles.legendBox}`}>1</div>
              </Col> */}
          </Row>
          
        </Col>
      </div>
    </>
  )
}


// 标题
const DomTitle = ({bgtitle,title}:any) => {
  return (
    <div className={`${styles.domTitle}`}>
      <div className={`${styles.modelTitle} modelTitle${primaryColor}`}>{bgtitle}</div>
      <div className={`${styles.bodyTitle} `}>{title}</div>
    </div>
  )
}

const ModelData: React.FC = () => {
  //申明语言转换
  const {t} = useTranslation();
  // mode
  const ModelDataList:any[] = t('methods.modelDataList', { returnObjects: true })
  return (
    <>
      <Col xs={10} xsOffset={1} className={`${styles.bodyCentent}`}>
          <DomTitle bgtitle="MODEL" title={`${t('methods.modeTitle')}`} />
          <Row className={`${styles.modelMain}`}  key="modeDom">
          {
              ModelDataList.map((item, index) => {
                return (  
                  <Col xs={12} md={6} lg={6} xl={3} className={`${styles.modelbox}`} key={index}>
                    <img src={require(`../../assets/images/img/icon0${index+1}.png`)} alt="" />
                    <h4>{item.title}</h4>
                    <div>{item.content}</div>
                  </Col>
                )  
              })
            }
          </Row>
      </Col>
    </>
  );
};

// feed
const update_form=(text:string)=>{
	var update_str="";
	var tmp_num_str="";
		for(let i=0;i<text.length;i++){
		  const inter_char:any=text.charAt(i);
      if(!isNaN(inter_char)){
      //为数字时
        if(tmp_num_str===""){
        //增加样式
          tmp_num_str=`<img src=${IntegralIcon} class=${styles.bidIcon} /><span class=${styles.number}>`+inter_char;
        }else{
          tmp_num_str=tmp_num_str+inter_char;
        }
      }else{
      //闭合样式
        if(tmp_num_str!==""){
          tmp_num_str=tmp_num_str+"</span>"+inter_char;
          update_str=update_str+tmp_num_str;
          tmp_num_str="";
        }else{
          update_str=update_str+inter_char;
        }
      }
		}
		return update_str;
	}

const FeedDataList = ( {dataList}:any)=>{
  return  (
    
    <Col xs={12} sm={10} md={8} lg={8} smOffset={1} mdOffset={2} lgOffset={2}>
      { dataList.map((item: any,index: any)=>{ 
          return (
           <Row className={`flex ai-center jc-space-between bg${primaryColor} ${styles.feedBox}`} key={index}>
             <Col xs={3}  sm={3} lg={3} className={`flex ai-center`}>
               <img src={`${item.userInfo.avatar?require('../../assets/images/noHeadImg.png'):'https://joeschmoe.io/api/v1/random?'+index}`} className={`${styles.icon}`} alt={`${item.userInfo.nickName || 'Unnamed'}`} />
               <span className={`${styles.name}`}>{item.userInfo.nickName || 'Unnamed'}</span>
             </Col>
             <Col xs={9} sm={9} lg={6}  className={`flex ai-center `}>
                <div className={`flex ai-center ${styles.points}`} dangerouslySetInnerHTML={{__html:`${update_form(item.desc)}`}}></div>
             </Col>
             <Col xs={12} sm={12} lg={3} className={`${styles.time}`}>
              <FormatMsgTime data={item.createTime} />  
             </Col> 
           </Row>
          )
        })
      } 
    </Col>
  )
}
const FeedData: React.FC = () => {
  //申明语言转换
  const {t} = useTranslation();
  const [newfeedData, setNewfeedData] = useState<any[]>([]);
  useEffect(()=>{
    getFeedList();
  }, [])
  const getFeedList = async () => {
    const res = await getNewFeed({limit:10});
    if(res.code === 0){
      setNewfeedData([...res.data.items]);
    }else {
      // message.error(res.msg);
    }
  }

  return (
    <div className={`bg${primaryColor} ${styles.bodyCentent}`} >
      <DomTitle bgtitle="FEED" title={`${t('methods.feedTitle')}`} />
      <FeedDataList dataList={ newfeedData } />  
    </div>
  )
}
/* CATEGORY */ 
const Category : React.FC = () => {
  //申明语言转换
  const {t} = useTranslation();
  const CategoryDataList:any[] = t('methods.categoryDataList', { returnObjects: true })

  return (
    <div className={`bg${primaryColor} ${styles.bodyCentent}`}>
      {/* <DomTitle bgtitle="CATEGORY" title={`${t('methods.CategoryTitle')}`} /> */}
      <Col xs={12} lg={10} lgOffset={1} className={`pt-30`}>
        <Row className={`${styles.categoryMain}`}>
          {CategoryDataList.map((item:any,index:number)=>{
            return (
              <Col xs={6} sm={4} md={3} lg={3} xl={2} key={index} className={` pt-15 pb-15 pl-15 pr-15`}>
                <Link className={`text-center ${styles.categoryBox}`} to={`/explore?id=`+item.value}>
                  <img src={`${require('../../assets/images/home/'+item.img)}`} className={`${styles.HomeCategoryIcon}`} alt={item.title} /> 
                  <div className={`flex ai-center jc-center pt-15 ${styles.categoryTips}`}>{item.label}</div>
                </Link>
              </Col>
            )
          })}
          
        </Row>
      </Col>
    </div>
  )
}
const WeaveData=()=>{
  //申明语言转换
  const {t} = useTranslation();
  const WeaveDataList:any[] = t('methods.WeaveDataList', { returnObjects: true });
  const WeaveAdvantageDataList:any[] = t('methods.WeaveAdvantageDataList', { returnObjects: true });
  const WeavePlanDataList:any[] = t('methods.WeavePlanDataList', { returnObjects: true })


  return (<>
    <div className={`bg${primaryColor} ${styles.bodyCentent}`}>
      <DomTitle bgtitle="WEAVE" title={`${t('methods.WeaveTitle')}`} />
      
    </div>
    <Col xs={12} lg={8} lgOffset={2} className={`pt-30 ${styles.challengeMain}`}>
        <div className={`flex ai-center jc-center font-18 pt-55`}>{t(`methods.WeaveDataSubTopTitle`)}</div>
        <Row className={`pt-30`}>
        {WeaveDataList.map((item,index)=>{
          return (
            <Col lg={4} key={index}>
              <Col md={8} mdOffset={2} className={`text-center pb-35 ${styles.challengeBox}`}>
                <img src={`${require('../../assets/images/home/'+item.img)}`} alt="" />
                <h3 className={`pt-35 font-14`}>{item.title}</h3>
                <p className={`pt-35 font-14 text-left`}>{item.text}</p>
              </Col>
            </Col>
          )
        })}
        </Row>
    </Col>
    <Col xs={12} lg={8} lgOffset={2} className={`pt-30  ${styles.challengeMain}`}>
        <h3 className={`flex ai-center jc-center font-24 pt-55`}>{t(`methods.WeavePlanTitle`)}</h3>
        <div className={`flex ai-center jc-center font-16 pt-20 pb-20`}>{t(`methods.WeavePlanSubTitle`)}</div>
        <Col md={10} mdOffset={1} className={`text-center pt-55 pb-55`}>
          <img src={`${require('../../assets/images/home/plan.png')}`} alt="plan"  className={`${styles.planImg}`} />
        </Col>
        <Row className={`pt-30  pb-55`}>
        {WeavePlanDataList.map((item,index)=>{
          return (
            <Col md={6} className={`pb-8 pt-8`} key={index}>
              <div  className={`flex ai-conter jc-center bg${primaryColor} ${styles.planDataItem}`}>
                <img src={`${require('../../assets/images/home/'+item.img)}`} alt={item.title} />
                <div className={`flex-1 font-14`}>{item.title}</div>
              </div>
            </Col>
            )
          })}
        </Row>
    </Col>
    <Col xs={12} lg={8} lgOffset={2} className={`pt-30 ${styles.challengeLastMain}`}>
        <h3 className={`flex ai-center jc-center font-24 pt-55`}>{t(`methods.WeaveAdvantageTitle`)}</h3>
        <Col md={10} mdOffset={1} className={`flex ai-center jc-center font-16 pt-20 pb-20`}>{t(`methods.WeaveAdvantageSubTitle`)}</Col>
        <Row className={`pt-30`}>
        {WeaveAdvantageDataList.map((item,index)=>{
          return (
            <Col lg={4} key={index}>
              <Col md={10} mdOffset={1} className={`text-center pb-35 ${styles.challengeBox}`}>
                <img src={`${require('../../assets/images/home/'+item.img)}`} alt={item.title} />
                <h3 className={`pt-35 font-14`}>{item.title}</h3>
                <p className={`pt-35 font-14 text-left`}>{item.text}</p>
              </Col>
            </Col>
          )
        })}
        </Row>
    </Col>
  </>)
}
const Web3APIData=()=>{
  //申明语言转换
  const {t} = useTranslation();
  return (<>
    <div className={`bg${primaryColor} ${styles.bodyCentent}`}>
      <DomTitle bgtitle="API" title={`Web3 API`} />
      <Col xs={12} lg={8} lgOffset={2} className={`pt-30 ${styles.challengeLastMain}`}>
        <Col md={10} mdOffset={1} className={`flex ai-center jc-center font-14`}>{t(`methods.Web3ApiTitle`)}</Col>
        <div className={`flex jc-center pt-55 pb-30`}>
          <div className={`flex ai-center ${styles.web3ApiBtn}`}>
            <img src={`${require('../../assets/images/home/user.png')}`} alt='' />
            <div className={`flex-1 pl-20 font-14`} dangerouslySetInnerHTML={{__html:`${t('methods.Web3ApiUse')}`}} ></div>
          </div>
        </div>
        <Row className={`pt-30`}>
          <Col lg={4}>
            <div className={`text-center pb-35 ${styles.challengeBox}`}>
              <h3 className={`pb-35 font-24`}>Web 2.0</h3>
              <div className={`${styles.web3ImgBox}`}>
                <img src={`${require('../../assets/images/home/web3-1.png')}`} alt="" />
              </div>
            </div>
          </Col>
          <Col lg={4}>
            <div className={`text-center pb-35 ${styles.challengeBox}`}>
              <h3 className={`pb-35 font-24`}>Verseport</h3>
              <div className={`flex fd-column ai-center jc-center ${styles.web3ImgBox}`}>
                <img src={`${require('../../assets/images/home/web3-2-1.png')}`} alt="" />
                <img src={`${require('../../assets/images/home/web3-2-2.png')}`} alt="" className={`pt-55`} />
              </div>
            </div>
          </Col>
          <Col lg={4}>
            <div className={`text-center pb-35 ${styles.challengeBox}`}>
              <h3 className={`pb-35 font-24`}>Web 3.0</h3>
              <div className={`${styles.web3ImgBox}`}>
                <img src={`${require('../../assets/images/home/web3-3.png')}`} alt="" />
              </div>
            </div>
          </Col>
        </Row>
    </Col>
    </div>
  </>)
  
}
const ReightNav =()=>{
  return (
    <div className={`${styles.rightCodeBox}`}>
            <div  className={`${styles.codeBox}`}>
              <div className={`${styles.boxMain}`}>
                <span>下载<br />APP</span>
                <div className={`${styles.showBoxCentent}`}>
                  <img src={require('../../assets/icon/code/android-code.png')} alt='' />
                  <p className={`col-blue font-16 mt-5`}>安卓下载</p>
                </div>
              </div>
              <div className={`${styles.boxMain}`}>
                <span>访问<br />H5</span>
                <div className={`${styles.showBoxCentent}`}>
                  <img src={require('../../assets/icon/code/H5-code.png')} alt='' />
                  <p className={`col-blue font-16 mt-5`}>扫码进入</p>
                </div>
              </div>
              <div className={`${styles.boxMain}`}>
                <span>关注<br />公众号</span>
                <div className={`${styles.showBoxCentent}`}>
                  <img src={require('../../assets/icon/code/public-code.jpg')} alt='' />
                  <p className={`col-blue font-16 mt-5`}>微信扫码关注</p>
                </div>
              </div>
              <div className={`${styles.boxMain}`}>
                <span>联系<br />客服</span>
                <div className={`${styles.showBoxCentent}`}>
                  <img src={require('../../assets/icon/code/service-code.jpg')} alt='' />
                  <p className={`col-blue font-16 mt-5`}>扫一扫，联系客服</p>
                </div>
              </div>
            </div>

           
          </div>
  )
}
const Index = () => {
 
  return (
    <>
      <Banner />
      <Brand />
      <TopHundred />
      <Legend />
      {/* <Category />
      <FeedData />
      <ModelData />
      <WeaveData />
      <Web3APIData /> */}
      <ReightNav />
    </>
  );
}

export default Index

