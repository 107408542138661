
import React,{useState,useEffect}  from 'react';

import { Grid, Row, Col } from 'react-flexbox-grid';

import styles from './index.module.less';

// 切换语言
import { useTranslation } from 'react-i18next';
import LazyLoad from 'react-lazyload';


import { simpleFn } from '../../../components/Notification';


import wxPay from '../../../assets/images/explore/wxpay.svg';
import zfbPay from '../../../assets/images/explore/zfb.svg';
import bankCardPay from '../../../assets/images/explore/bankCard.svg'


import TimeContent from '../../../components/TimeContent';



const Exchange =({onChange,itemData}:any)=>{
    console.log("==================itemData",itemData)
    const { t } = useTranslation();
   
    const [amount,changAmount] = useState<any>(1);
 
    
    const changeDaoM = async (e:any)=>{
        console.log('=================',buyComboItem.ProductSN)
        
        if(e && buyComboItem.ProductSN){
            onChange(e,buyComboItem.ProductSN)
        }else{
            onChange(e)
        }

       
    }
    const [payType,setPayType] = useState<number>(1)
    const handleChange = (event:any)=> {
        console.log("================",event.target.value)
        setPayType(event.target.value);
     }
     const formatTimeData=(time:number)=>{
		var date = new Date(time)
            var Y = date.getFullYear() + '-'
            var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
            var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
            var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
            var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
            var s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
            return Y + M + D + h + m + s
	}
    const [buyComboItem,setBuyComboItem] = useState<any>({})
    const buyCombo=(e:any)=>{
        console.log(e)
        setBuyComboItem(e)
    }
    return (
        <div className={`flex ai-center jc-center ${styles.popUps}`} >
            <div className={`${styles.popBg}`} onClick={() => changeDaoM('cancel')}>背景</div>
            <Col xs={10} sm={10} lg={8} xl={6} className={`flex jc-center ai-center fd-column ${styles.popUpsMode}`}>
                <div className={`${styles.popClose}`}  onClick={() => changeDaoM('cancel')}>close</div>
                {/* {(itemData.payStatus===1 || itemData.payStatus===2) ?
                <Row className={`mt-40 ${styles.logTitleMain}`}>
                    <Col lg={12} className={`text-left`}>
                        <h3 className={`font-24`}>确认订单</h3>
                        
                            <div className={`mt-20 font-16`}>
                                <span>订单提交成功,请尽快付款!</span>
                                <span className={`ml-30 col-666`}>提交时间：</span>
                                <span>{formatTimeData(itemData.createTime)}</span>
                            </div>
                            <div className={`mt-15 font-16`}>
                                请您在<span className={`col-red text-center ${styles.timeStyle}`} ><TimeContent data={itemData.createTime} onChange={()=>onChange(false)} /></span>内完成支付，逾期视为自愿放弃
                            </div>
                    </Col>
                </Row>:
                <h3 className={`${styles.logTitle}`}>确认订单</h3>
                } */}
                <h3 className={`${styles.logTitle}`}>购买燃料</h3>
                <Row className={`pt-40 ${styles.logMain}`}>
                   <Col lg={10} lgOffset={1} className={`mb-40`}>
                       <Row>
                            {itemData.map((item:any,index:number)=>{
                                return (
                                    <Col md={3} key={item.ProductSN}>
                                        <div className={`flex ai-center jc-center ${styles.itemFuel} ${buyComboItem.ProductSN===item.ProductSN?styles.curItemFuel:''}`} onClick={()=>buyCombo(item)}>
                                            {item.Desc &&item.Desc<10 && <div className={`${styles.itemDis}`}>{item.Desc} 折</div>}
                                            <div className={`font-14 flex ai-center`}><span className={`font-20 mr-5`}>{item.TotalAmount}</span>次</div>
                                            <div className={`font-14 col-999 flex ai-center ml-5`}>¥ {item.Price/100}</div>
                                        </div>
                                    </Col>
                                )
                            })}
                            
                           {/* <Col md={4}>
                                <LazyLoad><img src={itemData.nftinfo.baseUrl + itemData.nftinfo.dataHash } alt='' width={120} height={120} /></LazyLoad>
                            </Col> */}
                            <Col md={12} className={`pt-20`}>
                                <div className={`flex ai-center`}>

                                    <div className={`col-666 font-14 `}>套餐</div>
                                    <h3 className={`font-18 ml-15`}>{buyComboItem.Name?buyComboItem.Name:'--'}</h3>
                                </div>
                              <div className={`flex ai-center mt-20`}>
                                    <div className={`col-666 font-14 `}>售价</div>
                                    <h3 className={`font-18 ml-15`}>¥ {buyComboItem.DisCount?buyComboItem.DisCount:'0'}</h3>
                                </div>
                                
                                {/* <div className={`flex ai-center mt-20`}>
                                    <div className={`col-666 font-14`}>数量</div>
                                    <h3 className={`font-18 ml-15`}>1</h3>
                                </div> */}
                               
                            </Col>
                            <Col xs={12}>
                                <div className={`flex ai-center mt-40`}>
                                    <div className={`col-666 font-14 `}>支付方式</div>
                                    <div className={`ml-15 space-16 font-14 flex-1`}>
                                        <label className={`flex ai-center`}>
                                            <input type='radio' name='payType' value={1} onClick={handleChange} defaultChecked={payType===1 ? true : false} />
                                            <img src={zfbPay} alt='' className={`ml-5 mr-5 ${styles.payIcon}`} />
                                            支付宝
                                        </label>
                                    </div>
                                </div>


                               <div className={`flex ai-center mt-20`}>
                                    <div className={`col-666 font-14 `}>总计</div>
                                    <div className={`ml-15 flex flex-1`}><span className={`col-333 font-16 mr-5`}>¥</span> <h3 className={`font-18 col-blue`}>{buyComboItem.Price?buyComboItem.Price/100:'0'}</h3></div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={10} lgOffset={1} className={`pt-40 pb-40 ${styles.modeBtnTop}`}>
                        {/* disabled={amount*unitPrice > modeData.balance} */}

                        <div className={`col-red font-12 pb-15 pl-15`}>注意：您24小时内只能取消5次订单，超出次数将限购24小时。</div>
                        <Row>
                            {/* <Col md={4}>
                                <button className={`${styles.medalBtn} ${styles.medalCancelBtn}`}  onClick={()=>changeDaoM('cancel')}>取消订单</button>
                            </Col> */}
                            <Col md={10} mdOffset={1}>
                                <button disabled={!buyComboItem.ProductSN} className={`${styles.medalBtn}`}  onClick={()=>changeDaoM('pay')}>立即支付</button>
                            </Col>
                        </Row>
                        

                        <Row className={` mt-25 ${styles.warnTips}`}>
                            <Col xs={12} className={`flex ai-center font-14`}><img src={require('../../../assets/images/login/tips-icon.png')} alt='' className={`mr-10`} />购买须知</Col>
                            <Col xs={12} className={`mt-10 font-12 flex-1`}>1. Verseport平台的燃料费是固定的，燃料仅可用于在Verseport平台进行作品铸造、转赠、合成等链上行为，不可提现为任何数字资产；为让用户能更便捷的使用Verseport，在任何情况下实际产生的燃料费用均为固定值。
                            <br />2. 燃料次数是链上行为的道具，购买后不支持退款。</Col>
                        </Row>
                    </Col>
                </Row> 
               
            </Col>
            
        </div>
    )
}
export default Exchange;