import React, { useEffect, useState } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import QueueAnim from 'rc-queue-anim';

import styles from "./index.module.less"
import { t } from 'i18next';

// 主题
const themePrimaryColor = 'theme';
const primaryColor = localStorage.getItem(themePrimaryColor) || 'light';

const Resources = () => {
    let netDiskUrl = "http://114.215.209.191:9006/welcome/";//网盘地址
    let apiUrl = process.env.BASE_URL+"/developer/";//api地址
    const goUrl = (url: string) => {
        window.open(url)
    }



    return (
        <>
            <Col lg={12} xl={10} xlOffset={1} >
                <QueueAnim delay={100} animConfig={[
                    { opacity: [1, 0], translateX: [0, 200] },
                    { opacity: [1, 0], translateX: [0, -200] }
                ]}>
                    <div className={`bg${primaryColor} flex ai-center jc-space-between mt-60 ${styles.modeWebMain}`} key="mode1">
                        <Col lg={4} className={`flex ai-center jc-center fd-column ${styles.modeWebBox}`}>
                            <img src={require('../../assets/images/web3/mode1-01.png')} alt="Web 1.0" />
                            <h3>Web 1.0</h3>
                            <p>{t('methods.net')}-{t('methods.people')}</p>
                        </Col>
                        <Col lg={4} className={`flex ai-center jc-center fd-column ${styles.modeWebBox}`}>
                            <img src={require('../../assets/images/web3/mode1-02.png')} alt="Web 2.0" />
                            <h3>Web 2.0</h3>
                            <p>{t('methods.people')}-{t('methods.people')}</p>
                        </Col>
                        <Col lg={4} className={`flex ai-center jc-center fd-column ${styles.modeWebBox}`}>
                            <img src={require('../../assets/images/web3/mode1-03.png')} alt="Web 3.0" />
                            <h3>Web 3.0</h3>
                            <p>{t('methods.people')}-{t('methods.net')}-{t('methods.people')}</p>
                        </Col>
                    </div>

                    <div className={`bg${primaryColor} flex ai-center jc-space-between mt-60 ${styles.modeMain}`} key="mode2">
                        <Col lg={6}>
                            <img src={require('../../assets/images/web3/mode2.png')} alt="Web 3.0" />
                        </Col>
                        <Col lg={6}>
                            <h3 className={`font-24`}>Web 3.0</h3>
                            <div className={`font-14 mt-20 ${styles.modeMainCenter}`}>{t('methods.web3Introduce')}</div>
                        </Col>
                    </div>


                    <div className={`bg${primaryColor} flex ai-center jc-space-between mt-60 ${styles.modeMain}`} key="mode3">
                        <Col lg={6}>
                            <img src={require('../../assets/images/web3/mode3.png')} alt="VP-AssetDisk" />
                        </Col>
                        <Col lg={6}>
                            <h3 className={`font-24`}>VP-AssetDisk</h3>
                            <div className={`font-14 mt-20 ${styles.modeMainCenter}`}>
                                {t('methods.vpDisIntroduce')}
                            </div>
                            <div className={`flex jc-center  mt-30`}>
                                <button className={`btn blue-btn ${styles.modeMainBtn}`} onClick={() => goUrl(netDiskUrl)}>{t('methods.use')} VP-AssetDisk</button>
                            </div>
                        </Col>
                    </div>

                    <div className={`bg${primaryColor} mt-60 ${styles.modeApiMain}`} key="mode4">
                        <h3 className={`text-center ${styles.title}`}>{t('methods.apifordev')}</h3>
                        <div className={`flex jc-space-between ai-center ${styles.apimode}`}>
                            <div className={`text-center`}>
                                <h3>Web 2.0</h3>
                                <img src={require('../../assets/images/web3/mode4-01.png')} alt="Web 2.0" />
                            </div>
                            <div>
                                <img src={require('../../assets/images/web3/mode4-02.png')} alt="Verseport to Web 2.0" />
                            </div>
                            <div className={`text-center`}>
                                <h3>Verseport</h3>
                                <img src={require('../../assets/images/web3/mode4-03.png')} alt="Verseport" />
                            </div>
                            <div>
                                <img src={require('../../assets/images/web3/mode4-04.png')} alt="Verseport to Web 3.0" />
                            </div>
                            <div className={`text-center`}>
                                <h3>Web 3.0</h3>
                                <img src={require('../../assets/images/web3/mode4-05.png')} alt="Web 3.0" />
                            </div>
                        </div>
                        <div className={`flex jc-center mt-75`}>
                            <button className={`btn blue-btn ${styles.modeMainBtn}`} onClick={() => goUrl(apiUrl)}>{t("methods.APIDoc")}</button>
                        </div>
                        <div className={`flex jc-center pt-30`}>
                            <p className={`font-12`}>{t('methods.notice')}</p>
                        </div>


                    </div>


                </QueueAnim>
            </Col>
        </>
    )
}
export default Resources