import Web3 from 'web3';
import React, { useEffect, useState } from 'react';
import styles from "./index.module.less";
import { useTranslation } from 'react-i18next';
import { Col } from 'react-flexbox-grid';
import Hash from 'ipfs-only-hash';
import { getUserInfo, updateProfile, uploaProfileImg } from '../../api/public'
import { simpleFn } from '../../components/Notification/index';
import { log } from 'console';
// import { setTimeout } from 'node:timers/promises';

// let ipfsKey="Verseport IPFS";

let ipfsKey=process.env.IPFS_KEY;
const web3 = new Web3(window.ethereum);
const baseUrl = process.env.BASE_URL+"/ipfs/";

const Settings = () => {
  const [userInfo, setUserInfo] = useState<any>({
    Avatar: "",
    Cover: "",
    DataChainUUID: "",
    NickName: "",
    UserName: "",
  });
  const [avatarItem, setAvatarItem] = useState<any>();
  const [avatarUrl, setAvatarUrl] = useState('');
  const [bannerUrl, setBannerUrl] = useState('');
  const [bannerItem, setBannerItem] = useState<any>();
  const [checkName, setCheckName] = useState(0);
  const avatarFileInput = React.useRef<any>(null);
  const bannerFileInput = React.useRef<any>(null);
  const { t } = useTranslation();

  // 获取用户信息
  const GetUserInfo = async () => {
    const UserName = sessionStorage.getItem('userName');
    const res = await getUserInfo({ UserName: UserName });
    if (res.code === 0) {
      console.log("获取用户信息成功!")
      const {Avatar,Cover,NickName} = res.data
      NickName !== '' ? setCheckName(2) : setCheckName(0);
      setUserInfo(res.data);
      setAvatarUrl(baseUrl +Avatar);
      setBannerUrl(baseUrl + Cover);
    } else {
      console.log("获取用户信息失败", res.code, res.msg);
    }
  }

  useEffect(() => {
    GetUserInfo()
  }, [])
  useEffect(() => {

  }, [avatarItem, bannerItem])

  const avatarUploadClick = () => {
    avatarFileInput.current.click();
  };
  const avatarFileChange = async (event: any) => {
    if (event.target.files.length !== 0) {
      const fileUploaded = event.target.files[0];
      setAvatarItem(fileUploaded);
      setAvatarUrl(URL.createObjectURL(fileUploaded))
    }
  };
  const bannerUploadClick = () => {
    bannerFileInput.current.click();
  };
  const bannerFileChange = async (event: any) => {
    if (event.target.files.length !== 0) {
      const fileUploaded = event.target.files[0];
      setBannerItem(fileUploaded);
      setBannerUrl(URL.createObjectURL(fileUploaded))
    }
  };

  // 计算上传文件的Hash
  const calcuFileIpfsHash = (file: any) => {
    return new Promise((resolve, reject) => {
      let fileReaderObj = new FileReader();
      fileReaderObj.onload = function (e: any) {
        let DataStr: any = fileReaderObj.result;
        let bytes = new Uint8Array(DataStr);
        resolve(bytes)
      }
      fileReaderObj.readAsArrayBuffer(file)
    })
  }

  const UploadFile = async (val: object, type: string) => {
    const TimeStamp = Math.round(+new Date());
    const strSeriesConnMsg = web3.utils.keccak256(ipfsKey).substr(2) + TimeStamp;
    const MsgKeccak256 = web3.utils.keccak256("\u0019Ethereum Signed Message:\n" + new Blob([strSeriesConnMsg]).size + strSeriesConnMsg);
    const metaData = { "name": "", "description": "", "external_url": "", "image": "", "attributes": [], "background_color": "", "animation_url": "", "youtube_url": "" };
    const formData: any = new FormData();
    formData.append("metaData", JSON.stringify(metaData))
    formData.append("size", 350);
    formData.append("file", val);
    formData.append("msgKeccak256", MsgKeccak256.substr(2));
    formData.append("timeStamp", TimeStamp);

    const res = await uploaProfileImg(formData);
    if (res.code === 0) {
      console.log("文件上传成功")
      return res.data.dataHash
    } else {
      console.log("文件上传失败")
      return 0;
    }
  }

  const UpdateProfile = async () => {
    const SDToken = sessionStorage.getItem('socialDataToken')
    let temp = { ...userInfo };
    console.log(avatarItem,'======avatarItem')

    if (avatarItem !== undefined) {
    console.log(22222222,'======2222')

      // const avatarBytes = await calcuFileIpfsHash(avatarItem);
      // const avatarHash = await Hash.of(avatarBytes);

      // if (avatarHash !== userInfo.avatar) {
        
        const res = await UploadFile(avatarItem, 'avatar');
        console.log(res,'======res')
        temp.Avatar = res;
      // }
    }

    if (bannerItem !== undefined) {
      // const bannerBytes = await calcuFileIpfsHash(bannerItem);
      // const bannerHash = await Hash.of(bannerBytes);
      // if (bannerHash !== userInfo.cover) {
        const res = await UploadFile(bannerItem, 'banner');
        temp.Cover = res;
      // }
    }

    const TimeStamp = Math.round(+new Date());
    const strSeriesConnMsg = `${temp.UserName}${SDToken}${TimeStamp}`;
    const MsgKeccak256 = web3.utils.keccak256("\u0019Ethereum Signed Message:\n" + new Blob([strSeriesConnMsg]).size + strSeriesConnMsg);
    const params = {
      Avatar: temp.Avatar,
      Cover: temp.Cover,
      // dataChainUUID: temp.dataChainUUID,
      MsgKeccak256: MsgKeccak256.substr(2),
      NickName: temp.NickName,
      TimeStamp,
      UserName: temp.UserName
    }
    console.log(params,'======444')
    const result = await updateProfile(params);
    setCheckName(2);
    if (result.code === 0) {
      console.log('更新成功');
      simpleFn(t('methods.updateSucc'), 'success');
      let userInfo = {
        ...JSON.parse(sessionStorage.getItem('userInfo')||''),
        ...{
          Avatar:temp.Avatar,
          NickName:temp.NickName
        }
      }
      sessionStorage.setItem('userInfo',JSON.stringify(userInfo));
      setTimeout(()=>{
        window.location.reload()
      },300)
    } else {
      console.log('更新失败')
      simpleFn(t('methods.updateError'), 'error');
    }
  }

  const copyText = () => {
    var copyDOM: any = document.getElementById("copy");  //需要复制文字的节点  
    var range = document.createRange(); //创建一个range
    const aa: any = window.getSelection();
    aa.removeAllRanges();   //清楚页面中已有的selection
    range.selectNode(copyDOM);    // 选中需要复制的节点    
    aa.addRange(range);   // 执行选中元素
    var copyStatus = document.execCommand('copy');    // 执行 copy 操作 
    if (copyStatus) {
      simpleFn(t('methods.copySucc'), 'success');
    } else {
      simpleFn(t('methods.copyError'), 'error');
    }
    
    // 移除选中的元素  
    aa.removeAllRanges();
  }

  return (
    <>
      <Col xs={12} sm={10} md={10} lg={8} smOffset={1} mdOffset={1} lgOffset={2}>
        <div className={`${styles.settings}`}>
          <h1 className={`${styles.title}`}>{t('methods.settinsTitle')}</h1>
          <form className={`${styles.creFrom}`}>
            <div className={`${styles.field}`}>
              <label>{t('methods.profileImg')}</label>
              <p>{t('methods.profileImgDesc')}</p>
              <div className={`${styles.avatarModel}`} onClick={avatarUploadClick}>
                <input type='file' style={{ display: 'none' }} ref={avatarFileInput} onChange={avatarFileChange} />
                {/* 未选择文件状态 */}
                {avatarUrl === '' ? <img src={require('../../assets/icon/image.png')} alt='' style={{ width: 64, height: 64 }} /> : <img src={avatarUrl} style={{
                  borderRadius: '50%'
                }} alt="" />}
              </div>
              <div className={`${styles.field}`}>
                <label>{t('methods.bannerImg')}</label>
                <p>{t('methods.bannerImgDesc')}</p>
                <div className={`${styles.bannerModel}`} onClick={bannerUploadClick}>
                  <input type='file' style={{ display: 'none' }} ref={bannerFileInput} onChange={bannerFileChange} />
                  {/* 未选择文件状态 */}
                  {bannerUrl === '' ? <img src={require('../../assets/icon/image.png')} alt='' style={{ width: 64, height: 64 }} /> : <img src={bannerUrl} style={{
                    borderRadius: '24px'
                  }} alt="" />}
                </div>
              </div>
            </div>
            <div className={`${styles.required} ${styles.field}`}>
              <label>{t('methods.nickName')}</label>
              <input type="text" name="Item-name"
                value={userInfo.NickName}
                style={{
                  border: `1px solid ${checkName === 1 ? 'rgba(219, 40, 40, 0.6)' : 'rgba(34, 36, 38, 0.15)'} `
                }}
                max={30}
                onChange={(e) => {
                  let temp = { ...userInfo };
                  temp.NickName = e.target.value;
                  setUserInfo(temp);
                  if (e.target.value !== '') {
                    setCheckName(2);
                  } else {
                    setCheckName(1)
                  }
                }} />
              {checkName === 1 ? <div style={{ color: 'rgb(219, 40, 40)', marginTop: '12px' }}>昵称不能为空</div> : ''}
            </div>
            <div className={`${styles.field}`}>
              <label>手机号</label>
              <div className={`${styles.disInput}`}>
                <div className={`${styles.content}`}>
                  <div className={`${styles.address}`}>{userInfo.Phone && userInfo.Phone.replace(/(\w{2})\w{7}(\w{2})/, "$1*******$2")}</div>
                </div>
              </div>
            </div>
            <div className={`${styles.field}`}>
              <label>认证</label>
              <div className={`${styles.disInput}`}>
                <div className={`${styles.content}`}>
                  <div className={`${styles.address}`}>已实名认证</div>
                </div>
              </div>
            </div>
            <div className={`${styles.field}`}>
              <label>{t('methods.walAdd')}</label>
              <div className={`${styles.disInput}`}>
                <div className={`${styles.content}`}>
                  <div className={`${styles.address}`} id='copy'>{userInfo.UserName}</div>
                  <button className={`${styles.copyBtn}`}>
                    <div className={`${styles.buttonIcon}`} onClick={(e) => {
                      e.preventDefault();
                      copyText();
                    }}>
                      <img src={require('../../assets/icon/copy.png')} className={`${styles.iconImg}`} />
                    </div>
                  </button>
                </div>
              </div>
            </div>

            {/* Create button */}
            <button className={`${styles.createBtn}`} disabled={checkName !== 2} onClick={async (e) => {
              e.preventDefault();
              setCheckName(0);
              UpdateProfile();
            }}>{t('methods.confirm')}</button>
          </form>
        </div>
      </Col>
    </>
  );
}
export default Settings;