/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { useHistory } from "react-router-dom";
import Qs from 'qs';
import Select from 'react-select';
import LazyLoad from 'react-lazyload';
import { nftlistbycollect,seriesDeail,nftmarketbycollect,getsevtime } from '../../../api/public'
import Pagination from '../../../components/Pagination';
import styles from "./index.module.less"
import errorIconImg from '../../../assets/images/noHeadImg.png'

import Empty from '../../../components/Empty';

// 切换语言
import { useTranslation } from 'react-i18next';


import { simpleFn } from '../../../components/Notification/index';


import BannerAnim, { Element } from 'rc-banner-anim';
// 主题
const themePrimaryColor = 'theme';
const primaryColor =  localStorage.getItem(themePrimaryColor)||'light';


const baseUrl = process.env.BASE_URL+"/ipfs/";
const noImgList = ['noImg1.png', 'noImg2.png', 'noImg3.png']
const SortDetail: React.FC<{ data: object }> = (props: any) => {
  // console.log("queryData123================", props)
  const {t} = useTranslation();
  const history = useHistory();
  const urlParams = props.location.search.slice(1);
	const params = Qs.parse(urlParams);

	const [queryData, changQueryData] = useState<any>({
    skip: 0,
    limit: 20,
    ...params})


  const [NFTList, setNFTList] = useState<any>({});
  const [total, setTotal] = useState<number>(0);

  const getUserInfo: any = sessionStorage.getItem('userInfo')
  let [userDataObj, setUserDataObj] = useState<any>(JSON.parse(getUserInfo) || {})//getUserInfo?JSON.parse(getUserInfo):null;

  

  const [getNFTListLoding,setGetNFTListLoding] = useState<boolean>(false)
  const getNFTList = async () => {
    setGetNFTListLoding(true)
    //nftStatus: 1 铸造中  2 铸造成功 3 上架成功
    // skip: 0,limit: 20
    const res = await nftmarketbycollect({SeriesID:queryData.id});
    if (res.code === 0) {
      // console.log(res.data)
      setNFTList(res.data);
      setGetNFTListLoding(false)
    }
  }
  const [sortDetail,setSortDetail] =useState<any>({})
  const getSortDetail = async()=>{
    const res = await seriesDeail({SeriesID:queryData.id})
    if(res.code===0){
      setSortDetail(res.data)
    }
  }
  const outputMate = (list:any,type:string)=>{
    let item = list.filter((item:any)=>item.trait_type===type)[0]
    return item?item.value:false;
  }
  useEffect(() => {
    getSortDetail();
    getNFTList();
  }, [queryData])

  const goDetail = (e: any) => {
    // console.log(1234)
    // const type = 2;
    const  sn= e.ProductSN;
    props.history.push(`/explore/detail?id=${sn}`, {  sn })
  }
  const Locale = localStorage.getItem('localesLng');
  const LocaleEn_US: object = {
    // Options.jsx
    items_per_page: '/ page',
    jump_to: 'Go to',
    jump_to_confirm: 'confirm',
    page: 'Page',

    // Pagination.jsx
    prev_page: 'Previous Page',
    next_page: 'Next Page',
    prev_5: 'Previous 5 Pages',
    next_5: 'Next 5 Pages',
    prev_3: 'Previous 3 Pages',
    next_3: 'Next 3 Pages',
    page_size: 'Page Size',
  };
  const goBack =()=>{
    history.goBack()
  }
// useEffect(()=>{
//   getServerTime()
// },[])
	const [nowTime,setNowTime]= useState<number>(0);
  const getServerTime= async ()=>{
		const res =await getsevtime({})
		if(res.code===0){
			let time = res.data.time
			setNowTime(time);
			// time < startTime?countFun(startTime,res.data.time):countFun(endTime,res.data.time)
		}
	}
  // const [current, setCurrent] = useState(1);
  // const [queryData, setQueryData] = useState<any>({

  //   skip: 0,
  //   limit: 20
  // })
  return (
    <>
      
   
      <Col lg={10} lgOffset={1}>
        <div className={`pt-40 pb-20`}>
          <span className={`font-18 pointer`} onClick={goBack}>{`< 返回`}</span>
        </div>
        {sortDetail.Meta && 
        <Row className={`flex pt-30 pb-40 bg${primaryColor} ${styles.bodyCentent} ${styles.detailTips}`}>
              <Col md={6} className={`pl-30`}>
                  <div className={`flex ai-center`}>
                    <LazyLoad className={`${styles.sortDetailTips}`}>
                      <img src={baseUrl + JSON.parse(sortDetail.Meta).LogoImgUrl} alt=''  onError={(e: any) => { e.target.onerror = null; e.target.src = require('../../../assets/images/noImg3.png') }} />
                    </LazyLoad>
                    <h3 className={`ml-15 font-24 text-center`}>{sortDetail.Name}</h3>
                  </div>
                  <div className={`flex mt-15 lh-24`}>
                    <div className={`col-666 `}>所属品牌：</div>
                    <div className={`flex-1`}>{sortDetail.Name}</div>
                  </div>
                  <div className={`flex mt-15 lh-24`}>
                    <div className={`col-666 `}>作品数量：</div>
                    <div className={`flex-1`}>{sortDetail.ProductTotalAmount}</div>
                  </div>
                  <div className={`flex mt-15 lh-24`}>
                    <div className={`col-666 `}>系列描述：</div>
                    <div className={`flex-1`}>{sortDetail.Desc}</div>
                  </div>
            
              </Col>
              <Col  mdOffset={1} md={5} className={`pr-20 pl-30`}>
                <LazyLoad>
                  <img src={baseUrl + JSON.parse(sortDetail.Meta).BannerImgUrl} alt='' onError={(e: any) => { e.target.onerror = null; e.target.src = require('../../../assets/images/noImg1.png') }} width={'100%'}/>
                </LazyLoad>
              {/* <BannerAnim prefixCls={`${styles.detailBannerMain}`} autoPlay thumb={false} autoPlaySpeed={3000} type={`across`}>
                {sortDetail.metaDataUser && outputMate(sortDetail.metaDataUser.attributes,'featured_image_url').map((subItem:any)=>{
                    return (
                    <Element 
                          prefixCls={``}
                          key={subItem}
                        >
                      <LazyLoad className={`${styles.detailBanner}`}>
                      <img src={sortDetail.baseUrl + subItem} alt='' onError={(e: any) => { e.target.onerror = null; e.target.src = require('../../../assets/images/noImg1.png') }}/>
                      </LazyLoad>
                    </Element>
                  )
                })}
              </BannerAnim> */}
              </Col>
        </Row>
        }
          
        <Row className={`mt-20 pb-75 ${styles.exploreRow}`}>
          {NFTList.length > 0 ? NFTList.map((item:any, index:number) => {
            // let metaDataSystem: any;
            // console.log("===================123",item)
            // if (item.metaDataSystem !== '') {
            //   metaDataSystem = JSON.parse(item.metaDataSystem);
            //   if (metaDataSystem.thumb_url !== undefined) {
            //     metaDataSystem.thumb_hash = metaDataSystem.thumb_url;
            //   }
            // } else {
            //   metaDataSystem = {
            //     "type": '',
            //     "size": '',
            //     "thumb_hash": ''
            //   }
            // }
            return (
              <Col xl lg md sm xs className={`pointer ${styles.exploreItem}`} key={item.id}>
                <div className={`${styles.cardBg}`} onClick={() => goDetail(item)}>
                  <div className={`${styles.card}`}>
                    <div className={`${styles.cardImg}`}>
                      <LazyLoad className={`${styles.image}`}>
                      <img className={`${styles.trueImg}`} src={ baseUrl + JSON.parse(item.Meta).Thumb_Hash} onError={(e: any) => { e.target.onerror = null; e.target.src = require('../../../assets/images/' + noImgList[Math.floor(Math.random() * noImgList.length)]) }} alt="" />
                        {/* {(metaDataSystem.type !== undefined && metaDataSystem?.type.split('/')[0] == 'image') ? 
                        <img className={`${styles.trueImg}`} src={metaDataSystem.thumb_hash == '' ?
                        NFTList.baseURL + item.dataHash :
                          (item.dataHash == '' ?
                            metaDataSystem.thumb_hash :
                            NFTList.baseURL + metaDataSystem.thumb_hash)} onError={(e: any) => { e.target.onerror = null; e.target.src = require('../../../assets/images/' + noImgList[Math.floor(Math.random() * noImgList.length)]) }} alt="" /> : 
                          <div>
                            <div className={`flex jc-center ai-center ${styles.mask}`}><img style={{ display: 'inline-block' }} src={require('../../../assets/icon/play.png')} alt='' /></div>
                            <img className={`${styles.mediaImg}`} src={NFTList.baseURL + metaDataSystem.thumb_hash} onError={(e: any) => { e.target.onerror = null; e.target.src = require('../../../assets/images/' + noImgList[Math.floor(Math.random() * noImgList.length)]) }} alt='' />
                          </div>
                        } */}
                      </LazyLoad>
                      {nowTime > item.EndTime ? <div className={`${styles.itemTips}`}>已结束</div>:
                      item.LeftAmount==0 && <div className={`${styles.itemTips}`}>已售罄</div>}
                    </div>
                    

                   <Row between="xs" className={`${styles.cardTips}`}>
                      <Col xs className={`ellipsis-1 ${styles.title}`}>
                        {item.Name}
                      </Col>
                      
                    </Row>
                    <Row between="xs" className={`text-center ${styles.cardTips}`}>
                        <Col xs  className={`col-red text-left ${styles.price}`}>
                          ¥ <span className={`font-24`}>{item.Price/100}</span>
                        </Col>
                        
                        <Col xs className={`text-right`}>
                          <p className={`col-999 font-14`}>{nowTime < item.EndTime && "剩余/"}总量</p>
                          <p className={`mt-10 pb-10 font-14`}>{nowTime < item.EndTime && `${item.LeftAmount}/`}{item.TotalAmount}</p>
                        </Col>
                        
                    </Row>
                    
                  </div>
                </div>
              </Col>)
          }) :
          
           getNFTListLoding ? [...Array.from({length:20}).keys()].map((item)=>
              <Col xl lg md sm xs className={`pointer ${styles.exploreItem}`} key={item}>
                  <div className={`${styles.card}`}>
                    <div className={`${styles.cardImg}`}>
                      <LazyLoad className={`${styles.image}`} >
                        <img className={`${styles.trueImg}`} src={require('../../../assets/images/' + noImgList[Math.floor(Math.random() * noImgList.length)])} alt="" />
                        </LazyLoad>
                    </div>
                    <Row between="xs" className={`${styles.cardTips}`}>
                      <Col xs={12} className={`ellipsis-1 ${styles.title}`}>
                        <span style={{background:'#e1e1e1',width:'80%',height:'20px',margin:"10px 5px",display:'inline-block'}}> &nbsp; </span>
                      </Col>
                    </Row>
                    <Row between="xs" className={`${styles.cardTips} ${styles.cardFooter}`}>
                      <Col xs={6} className={`flex ai-center ${styles.orginInfo}`}>
                        <LazyLoad className={`${styles.orginInfoImg}`}></LazyLoad>
                          <span style={{background:'#e1e1e1',width:'100%',height:'20px',display:'inline-block'}}> &nbsp; </span>  
                      </Col>
                      <Col xs={6} className={`flex ai-center`}>
                        <div className={`flex-1 text-right ${styles.orginInfoSymbol}`}>
                        <span style={{background:'#e1e1e1',width:'100%',height:'20px',display:'inline-block'}}> &nbsp; </span>
                        </div>
                      </Col>
                    </Row>
                  </div>
              </Col>
            ):
            <div className='flex jc-center' style={{width:'100%'}}>
              <Empty imageStyle={{
                width: 480,
                height: 'auto',
                display: 'block'
              }}
              description={
                <p style={{ textAlign: 'center', margin: '24px 0px', fontSize: '24px', color: '#9f9f9f' }}>{t(`methods.noData`)}</p>
              }
              image={require('../../../assets/icon/noData.png')} />
            </div>
          }
        </Row>
        {/* <div className={'flex ai-center jc-center pt-20 pb-40'}>
          <Pagination current={current} size='lage' total={NFTList.count} pageSize={10} locale={Locale === 'en' ? LocaleEn_US : undefined} className={`font-20 text-conter ${styles.orginPagination}`} onChange={(current: number, pageSize: number) => {
            let data = Object.assign({}, queryData, { skip: (current - 1) * pageSize });
            setCurrent(current);
            changQueryData({queryData,...data})
          }} />
        </div> */}

      </Col>
    </>
  );
}

export default SortDetail


