
import React,{useState,useEffect}  from 'react';

import { Grid, Row, Col } from 'react-flexbox-grid';



// 切换语言
import { useTranslation } from 'react-i18next';

// api
import { salesPageList,salesPageListItem } from '../../../api/public'

import { simpleFn } from '../../../components/Notification';

import styles from './index.module.less';

import GridMain from '../GridMain';
import Select from 'react-select';

import RcPagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';

const Merge = ({onChange,data,refresh}:any)=>{

    console.log("datadataMain",data);

    const userName = sessionStorage.getItem('userName');
    const [myPageGrid,setMyPageGrid] = useState<any>({});
    const [pageNum,setPageNum]=useState<number>()
   
      const mergeGrid=(e:any)=>{
        
    
      }
     

    const [selectOptions,setSelectOptions] = useState([])
    const [gridCurItemList,setGridCurItemList] = useState<any>({})
    const curList=(e:any)=>{

        setGridCurItemList(e)
    }
    const [isNoMerge,setIsNoMerge] = useState<boolean>(true)
    const mergeItem =[4,9,16,25,36,49,64,81,100]
    useEffect(()=>{
       //计算是否满足合并
        if(gridCurItemList.list && gridCurItemList.list.length>0){
            setIsNoMerge(false)
        }else{
            setIsNoMerge(true)
        }
    },[gridCurItemList])
    const curFN = ()=>{
        let data = {
            ...gridCurItemList,
            pageNum:pageNum
        }
        // console.log(data)
        onChange(data)
    }

    useEffect(()=>{
        getSalesPageList()
    },[])
    
    const getSalesPageList=async ()=>{
        
        const res = await salesPageList({});
        console.log("salesPageList==========",res)
        if(res.code===0){
            if(refresh){
                onChange('ref')
            }
            let count = res.data.map((item:any)=>{
                let json ={
                    ...item,
                    value:item.page,
                    label:item.page
                }
                return json
            })
            setSelectOptions(count)
            // getSalesPageListItem(res.data[0].page)
        }
    }
    const getSalesPageListItem=async (e:any)=>{
        
        const res = await salesPageListItem({page:e});
        console.log("salesPageListItem==========",res)
        if(res.code===0){
            let data ={
                items:res.data
            }
            setMyPageGrid(data)
        }
    }
    const [getPage,setPage] =useState(null);
    useEffect(()=>{
       
        if(refresh){
            setGridCurItemList({})
            getSalesPageListItem(getPage);
        }
    },[refresh])
    return ( 
        <div className={`flex ai-center jc-center ${styles.popUps}`} >
            <div className={`${styles.popBg}`} onClick={() => onChange && onChange()}>背景</div>
            <Col xs={12} sm={10} lg={8} xl={6} className={`pb-40 ${styles.popUpsMode}`}>
                <div className={`${styles.popClose}`}  onClick={() => onChange && onChange()}>close</div>
                <h3 className={`${styles.modeTitle}`}>格子直卖</h3>
                <Row className={`${styles.modeMain}`}>                   
                    <Col xs={6} className={` mt-20`}>

                        
                        <div className={`${styles.myGridBox}`}>
                           <GridMain data={myPageGrid} type="buy" onChange={(e:any)=>curList(e)} />
                        </div>
                        <div className={`flex font-14 mt-15`}>
                            <div className={`flex ai-center ${styles.saleStatusMerge}`}>
                                <div>不可选</div>
                            </div>
                            <div className={`flex ai-center ml-20 ${styles.saleStatusMerge} ${styles.saleStatus2}`}>
                                <div>已售</div>
                            </div>
                            <div className={`flex ai-center ml-20 ${styles.saleStatusMerge} ${styles.saleStatus1}`}>
                                <div>售卖中</div>
                            </div>
                            <div className={`flex ai-center ml-20 ${styles.saleStatusMerge} ${styles.saleStatus3}`}>
                                <div>已选</div>
                            </div>
                        </div>
                        

                    </Col>
                    <Col xs={6} className={` mt-20`}>
                        <div className={` ${styles.field}`}>
                            <label className={`mb-12`}>page</label>
                            <Select options={selectOptions} onChange={(e: any) => {setPage(e.value);getSalesPageListItem( e.value ) }} placeholder="请选择需要购买页..." />  


                        </div>
                        <div className={`mt-20`}>
                            <label className={`mb-12`}>选择左侧格子</label>
                            {/* <div className={`font-16 mt-10 col-666 lh-20`}>请选择右侧的格子</div> */}
                           
                        </div>
                        { gridCurItemList.list && <div className={`mt-20`}>
                            <label className={`mb-12 col-666`}>已选坐标</label>
                            <div className={`mt-10 lh-24 font-14 col-333 ${styles.totalItem}`}>
                                { gridCurItemList.list.map((item:any,index:number)=>{
                                        return (
                                        <div className={`pt-5 flex jc-space-between`}>
                                            <div>位置：{item.location && `${item.location.pageNum} , ${item.location.grid_y} , ${item.location.grid_z}`}</div>
                                           <div className={`ml-20 col-red`}>¥ {item.Price/100}</div>
                                        </div>
                                        )
                                    })
                                }
                            </div>
                            <div className={`text-right pt-10 mt-10 ${styles.total}`}>
                                合计：¥ {gridCurItemList.list.length>0?eval(gridCurItemList.list.map((item:any)=>item.Price/100).join("+")).toFixed(2):'0.00'}
                            </div>
                        </div>}
                            {/* {JSON.stringify(gridCurItemList)} */}

                    </Col>
                    <Col xsOffset={6} xs={5}>
                      <button className={`mt-20 ${styles.createBtn}`} disabled={ isNoMerge } onClick={(e) => curFN()}>立即支付</button>
                    </Col>
                </Row>


            </Col>
            
        </div>
  )
}
export default Merge;
