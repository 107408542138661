import Web3 from 'web3';
import Select, { components } from 'react-select';
import Hash from 'ipfs-only-hash';
import React, { useEffect, useRef, useState } from 'react';
import styles from "./index.module.less"
import { Col } from 'react-flexbox-grid';
import { useTranslation } from 'react-i18next';
import ReactLoading from 'react-loading';
import { useHistory } from "react-router-dom";
import { ownerofhash, hasfilehash, uploadfile, uploaddata, createAssets, castNFT, queryOperStatus, getUserCollectionList } from '../../api/public'
import AddMetaData from './AddMetaData';
import UploadFileCompo from './UploadFileCompo';
import { simpleFn } from '../../components/Notification';
let ipfsKey="Verseport IPFS";

// let ipfsKey="GkMrWfnKM2oL7zZ";
const web3 = new Web3(window.ethereum);



const { Option } = components;
const IconOption = (props: any) => {
  return (
    <Option {...props}>
      <div style={{ display: 'flex' }}>
        <img src={require('../../assets/icon/' + props.data.icon)} style={{ width: 36, height: 36, borderRadius: '50%' }} />
        <div style={{ marginLeft: 16 }}>
          <span className={`${styles.metaDataType}`}>{props.data.label}</span>
          <p style={{ margin: 0, lineHeight: '24px' }}>{props.data.desc}</p>
        </div>
      </div>
    </Option>
  )
}

// loadding
const ShowLoadding = ({ onChange, data }: any) => {
  const [polling, setPolling] = useState(true);
  const [operData, setOperData] = useState(data);
  const [nftID, setNftID] = useState('');
  let intervalHandle = useRef<any>();
  let history = useHistory();
  const { t } = useTranslation();

  const options = [
    // { value: "61", label: "Poriot", icon: "poriot.png" },
    { value: "2071", label: "Mumbai", icon: "polygon.png", desc: t('methods.mumbaiDesc') },
    { value: "2011", label: "Rinkeby", icon: "ethereum.png", desc: t('methods.rinkebyDesc') },

  ];

  const QueryOperStatus = async (val: any) => {
    let DataOperUUID: any = [];
    for (let i = 0; i < val.length; i++) {
      DataOperUUID.push(val[i].DataOperUUID);
    }

    intervalHandle.current = setInterval(async () => {
      const res = await queryOperStatus({ "operUUID": DataOperUUID.join(',') });
      let temp = true;
      let tempOperData = [...operData];

      for (let i = 0; i < res.data.items.length; i++) {
        tempOperData[i].operStatus = res.data.items[i].operStatus
        if (res.data.items[i].operStatus == 2) {
          temp = false;
          setNftID(res.data.items[i].id)
        } else {
          temp = true;
        }
      }
      setOperData(tempOperData);
      setPolling(temp);
    }, 3000);

  }

  useEffect(() => {
    if (polling) {
      QueryOperStatus(data);
    } else {
      clearInterval(intervalHandle.current);
      window.setTimeout(() => {
        if (nftID !== null) {
          history.push(`/explore/detail?type=2&id=${nftID}`, { isCreate: true });
        } else {
          history.push(`/profile`);
        }
      }, 2000)
    }
  }, [polling])

  return (
    <div className={`flex ai-center jc-center ${styles.popUps}`} >
      <div className={`${styles.popBg}`} onClick={() => onChange && onChange(false)}>背景</div>
      <Col xs={8} sm={8} lg={6} xl={4} className={`flex jc-center ai-center fd-column ${styles.popUpsMode}`}>
        <div className={`${styles.addMetaTitle}`}>{t('methods.wait')}</div>
        <div className={`${styles.popClose}`} onClick={() => onChange && onChange(false)} >close</div>
        {operData.map((item: any, index: any) => {
          let currentChain = options.find((optionsItem) =>
            optionsItem.value == item.DestID
          )
          return (
            <div className={`flex`} style={{ padding: '12px' }}>
              <img src={require('../../assets/icon/' + currentChain?.icon)} style={{ width: 24, borderRadius: '50%', marginRight: '8px' }} alt={currentChain?.label} />
              <span style={{ lineHeight: '24px', fontSize: '24px', marginRight: '24px' }}>{currentChain?.label}</span>
              {item.operStatus == 1 ? <ReactLoading type='spin' color='#123456' height={'24px'} width={'24px'} /> : ''}
              {item.operStatus == 2 ? <img src={require(`../../assets/icon/success.png`)} style={{ width: 24, height: 24, marginRight: '8px' }} /> : ''}
              {item.operStatus == 3 ? <img src={require(`../../assets/icon/error.png`)} style={{ width: 24, height: 24, marginRight: '8px' }} /> : ''}
            </div>
          )
        })}
      </Col>
    </div>
  )
}

const CollOption = (props: any) => {
  return (
    <Option {...props}>
      <div style={{ display: 'flex' }}>
        <img src={props.data.icon} style={{ width: 36, height: 36, borderRadius: '50%' }} />
        <div style={{ marginLeft: 16 }}>
          <span className={`${styles.metaDataType}`} style={{ lineHeight: '36px' }}>{props.data.label}</span>
          {/* <p style={{ margin: 0, lineHeight: '24px' }}>{props.data.desc}</p> */}
        </div>
      </div>
    </Option>
  )
}

const Create = (props: any) => {
  const [isShow, setIsShow] = useState(false)
  const [showLoadding, setShowLoadding] = useState(false)
  const [metaDataSystem, setMetaDataSystem] = useState<any>();
  const [metaDataUser, setMetaDataUser] = useState<any>({
    "name": "",
    "description": "",
    "external_url": "",
    "image": "",
    "attributes": [],
    "background_color": "",
    "animation_url": "",
    "youtube_url": ""
  }
  );
  const [properties, setProperties] = useState<any>([{
    "display_type": "",
    "trait_type": "",
    "value": "",
    "max_value": null
  }]);
  const [levels, setLevels] = useState<any>([{
    "display_type": "",
    "trait_type": "",
    "value": 3,
    "max_value": 5
  }]);
  const [stats, setStats] = useState<any>([{
    "display_type": "",
    "trait_type": "",
    "value": 3,
    "max_value": 5
  }]);
  const [metaType, setMetaType] = useState('');
  const [metaList, setMetaList] = useState([]);
  const [destID, setDestID] = useState<any>(['2071']);
  const [amount, setAmount] = useState<any>(1);
  const [checkImg, setCheckImg] = useState(0);
  const [checkPreview, serCheckPreview] = useState(0);
  const [checkName, setCheckName] = useState(0);
  const [operObj, setOperObj] = useState<any>([]);
  const [collectId, setCollectId] = useState([]);
  const [selectOption, setSelectOption] = useState<any>([]);
  const { t } = useTranslation();

  const GetUserCollList = async () => {
    const UserName = sessionStorage.getItem('userName');
    const res = await getUserCollectionList({ userName: UserName })
    let temOption: any = []
    for (let i = 0; i < res.data.items.length; i++) {
      res.data.items[i].metaDataSystem = JSON.parse(res.data.items[i].metaDataSystem);
      temOption.push({
        "value": res.data.items[i].dataChainUUID,
        "label": res.data.items[i].metaDataUser.name,
        "icon": res.data.items[i].metaDataUser.attributes[10].value
      })
    }
    setSelectOption(temOption);
  }

  useEffect(() => {
    GetUserCollList()
  }, [])

  const formatToFileType: any = {
    "mp3": "audio/mp3",
    "wav": "audio/wav",
    "ogg": "audio/ogg",
    "mp4": "video/mp4",
    "webm": "video/webm",
    "png": "image/png",
    "jpg": "image/jpg",
    "jpeg": "image/jpeg",
    "svg": "image/svg+xml",
    "gif": "image/gif",
    "webp": "image/webp"
  }

  const ChainOptions = [
    // { value: "61", label: "Poriot", icon: "poriot.png" },
    { value: "2071", label: "Mumbai", icon: "polygon.png", desc: t('methods.mumbaiDesc') },
    { value: "2011", label: "Rinkeby", icon: "ethereum.png", desc: t('methods.rinkebyDesc') },
  ];


  // 计算上传文件的Hash
  const calcuFileIpfsHash = (file: any) => {
    return new Promise((resolve, reject) => {
      let fileReaderObj = new FileReader();
      fileReaderObj.onload = function (e: any) {
        let DataStr: any = fileReaderObj.result;
        let bytes = new Uint8Array(DataStr);
        resolve(bytes)
      }
      fileReaderObj.readAsArrayBuffer(file)
    })
  }


  // 查询用户是否拥有该HASH
  const checkOwnerofHash = async (val: object) => {
    const fileBytes = await calcuFileIpfsHash(val);
    const ipfsHash = await Hash.of(fileBytes);
    const userName = sessionStorage.getItem('userName');
    const userToken = sessionStorage.getItem('userToken');
    const TimeStamp = Math.round(+new Date());
    const strSeriesConnMsg = `${userName}${userToken}${TimeStamp}`;
    const MsgKecak256 = web3.utils.keccak256(`\u0019Ethereum Signed Message:\n${new Blob([strSeriesConnMsg]).size}${strSeriesConnMsg
      }`)
    console.log(val)
    const res = await ownerofhash({
      "userName": userName,
      "dataHash": ipfsHash,
      "timeStamp": TimeStamp,
      "msgKeccak256": MsgKecak256.substr(2)
    });
    if (res.code == 0 && res.data.isExisted != true) {
      console.log("该文件没有拥有者");
      let temp = { ...metaDataUser };
      temp.image = 'ipfs://' + ipfsHash;
      setMetaDataUser(temp);
      setCheckImg(2);
      checkHasFileHash(ipfsHash, val)
    } else if (res.code == 0 && res.data.isExisted == true) {
      // 提示用户文件已有其他用户上传
      setCheckImg(1)
      console.log("该文件已有拥有者");
    } else {
      console.log('ownerofhash接口调用失败', '错误代码: ', res.code, "res.msg")
    }
  }

  // 查询文件Hash是否已经上传
  const checkHasFileHash = async (val: string, file: any) => {
    const res = await hasfilehash({ hash: val });
    console.log(file)
    let fileType = formatToFileType[`${(file.name).split('.')[1]}`];
    if (res.code == 0 && res.data.isExisted != true) {
      console.log("该文件还没有上传");
      UploadFile(file, fileType, res.data.thumbHash);
    } else if (res.code == 0 && res.data.isExisted == true) {
      console.log("该文件已上传", fileType, file.size)
      setMetaDataSystem({
        "type": fileType,
        "size": file.size,
        "thumb_hash": res.data.thumbHash
      })
    } else {
      console.log('ownerofhash接口调用失败', '错误代码: ', res.code, "res.msg")
    }
  }

  const updateThumb = (val: any, type: any) => {
    console.log('更新了缩略图')
    let temp = { ...metaDataSystem };
    temp.thumb_hash = val;
    temp.type = type;
    setMetaDataSystem(temp);
  }

  // 上传文件
  const UploadFile = async (val: any, type: any, thumb: any) => {
    console.log("文件信息", val)
    const TimeStamp = Math.round(+new Date());
    const strSeriesConnMsg = web3.utils.keccak256(ipfsKey).substr(2) + TimeStamp;
    const MsgKeccak256 = web3.utils.keccak256("\u0019Ethereum Signed Message:\n" + new Blob([strSeriesConnMsg]).size + strSeriesConnMsg);
    const formData: any = new FormData();
    if (val?.type.split('/')[0] == 'image' && (val?.type.split('/')[1] !== "svg+xml" && val?.type.split('/')[1] !== "webp")) {
      formData.append("size", 350);
      formData.append("thumbnail", 1);
    }

    formData.append("file", val);
    formData.append("msgKeccak256", MsgKeccak256.substr(2));
    formData.append("timeStamp", TimeStamp);

    const res = await uploadfile(formData);
    if (res.code == 0) {
      if (val?.type.split('/')[0] !== 'video' && val?.type.split('/')[0] != 'audio') {
        setMetaDataSystem({
          "type": type,
          "size": val.size,
          "thumb_hash": res.data.thumbHash
        })
      }

      console.log("文件上传成功")
    } else {
      simpleFn(t('methods.fileTypeErr'), 'error');
      setCheckImg(3);
      console.log("文件上传失败")
    }
  }


  const handleInPro = () => {
    setIsShow(!isShow);
    if (!isShow) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }

  const handleShowLoadding = () => {
    setIsShow(!showLoadding);
    if (!showLoadding) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }

  const handleAddMetaData = (type: string, list: any) => {
    if (type == 'levels') {
      setLevels(list);
    } else if (type == 'Stats') {
      setStats(list);
    } else {
      setProperties(list)
    }
    setIsShow(false);
  }

  const uploadMetaData = async (val: any) => {
    const TimeStamp = Math.round(+new Date());
    const strSeriesConnMsg = web3.utils.keccak256(ipfsKey).substr(2) + TimeStamp;
    const MsgKeccak256 = web3.utils.keccak256("\u0019Ethereum Signed Message:\n" + new Blob([strSeriesConnMsg]).size + strSeriesConnMsg);
    const params = {
      metaData: JSON.stringify(val),
      dataHash: val.image.substr(7),
      msgKeccak256: MsgKeccak256.substr(2),
      timeStamp: TimeStamp
    }
    const res = await uploaddata(params);
    if (res.code == 0) {
      console.log("元数据上传成功")
      CreateAssets(res, val)
    } else {
      console.log("元数据上传失败")
    }
  }

  const CreateAssets = async (MetaHash: any, MetaData: any) => {

    const UserName = sessionStorage.getItem('userName');
    const TimeStamp = Math.round(+new Date());
    const DestID = 2;
    const DataHash = MetaData.image.substr(7);
    const MetaDataHash = MetaHash.data.metaDataHash;
    const MetaDataSytem = JSON.stringify(metaDataSystem);
    const name = MetaData.name;
    const DataAccessAuth = 3;
    const strSeriesConnMsg = `${UserName}${DestID}${DataHash}${MetaDataHash}${MetaDataSytem}${name}${DataAccessAuth}${TimeStamp}`
    const MsgKeccak256 = web3.utils.keccak256("\u0019Ethereum Signed Message:\n" + new Blob([strSeriesConnMsg]).size + strSeriesConnMsg);

    web3.eth.personal.sign(`\x00` + strSeriesConnMsg, UserName, '').then(async (res: any) => {
      const params = {
        dataHash: DataHash,
        metaData: JSON.stringify(MetaData),
        metaDataHash: MetaDataHash,
        metaDataSystem: MetaDataSytem,
        msgKeccak256: MsgKeccak256.substr(2),
        sig: res.substr(2),
        timeStamp: TimeStamp,
        userName: UserName
      }
      const result = await createAssets(params);
      if (result.code == 0) {
        console.log('数据资产创建成功')
        CastNFT()
      } else {
        console.log("数据资产创建失败")
      }
    })
  }

  const CastNFT = async () => {
    const TimeStamp = Math.round(+new Date());
    const Amount = amount + '';
    const DataHash = metaDataUser.image.substr(7);
    const NFTDestID = destID.join();
    const UserName = sessionStorage.getItem('userName');
    const strSeriesConnMsg = `${UserName}${destID[0]}${DataHash}${Amount}${TimeStamp}`;
    const MsgKeccak256 = web3.utils.keccak256("\u0019Ethereum Signed Message:\n" + new Blob([strSeriesConnMsg]).size + strSeriesConnMsg);

    web3.eth.personal.sign(`\x00` + strSeriesConnMsg, UserName, '').then(async (res: any) => {
      const params = {
        dataHash: DataHash,
        timeStamp: TimeStamp,
        msgKeccak256: MsgKeccak256.substr(2),
        sig: res.substr(2),
        userName: UserName,
        amount: Amount,
        nftDestID: NFTDestID,
        collectId: collectId
      }
      const result = await castNFT(params);
      if (result.code == 0) {
        console.log("数字藏品铸造成功")
        for (let i = 0; i < result.data.castData.length; i++) {
          result.data.castData[i].operStatus = 1;
        }
        setOperObj(result.data.castData);
        setShowLoadding(true);
      } else {
        console.log("数字藏品铸造失败")
      }
    })
  }

  return (
    <>
      <Col xs={12} sm={10} md={10} lg={8} smOffset={1} mdOffset={1} lgOffset={2}>
        <div className={`${styles.creContent}`}>
          <h1 className={`${styles.creTitle}`}>{t('methods.createTitle')}</h1>

          <form className={`${styles.creFrom}`}>
            <p><span style={{ color: 'rgb(235, 87, 87)' }}>*</span>{t('methods.requiredFields')}</p>
            <div className={`${styles.required} ${styles.field}`}>
              <label>{t('methods.createFileType')}</label>
              <p>{t('methods.fileTypeDesc')}</p>
              <UploadFileCompo onChange={checkOwnerofHash} status={checkImg} changeStatus={setCheckImg} changePreStatus={serCheckPreview} changeThumb={updateThumb} />

            </div>

            <div className={`${styles.required} ${styles.field}`}>
              <label>{t('methods.name')}</label>
              <input type="text" name="Item-name"
                style={{
                  border: `1px solid ${checkName == 1 ? 'rgba(219, 40, 40, 0.6)' : 'rgba(34, 36, 38, 0.15)'} `
                }}
                onChange={(e) => {
                  let temp = { ...metaDataUser };
                  temp.name = e.target.value;
                  setMetaDataUser(temp);
                  if (e.target.value !== '') {
                    setCheckName(2);
                  } else {
                    setCheckName(1)
                  }
                }} placeholder={t('methods.itemName')} />
              {checkName == 1 ? <div style={{ color: 'rgb(219, 40, 40)', marginTop: '12px' }}>{t('methods.checkName')}</div> : ''}
            </div>

            <div className={`${styles.field}`}>
              <label>{t('methods.extLink')}</label>
              <p>{t('methods.extLinkDesc')}</p>
              <input type="text" name="External-link" style={{ border: `1px solid rgba(34, 36, 38, 0.15)` }} onChange={(e) => {
                let temp = { ...metaDataUser };
                temp.external_url = e.target.value;
                setMetaDataUser(temp);
              }} placeholder="https://yousite.io/item/123" />
            </div>

            {/* description */}
            <div className={`${styles.field}`}>
              <label>{t('methods.description')}</label>
              <p>{t('methods.descriptionDesc')}</p>
              <textarea placeholder={t('methods.descPlaceholder')} onChange={(e) => {
                let temp = { ...metaDataUser };
                temp.description = e.target.value;
                setMetaDataUser(temp);
              }}></textarea>
            </div>

            {/* Select Collection */}
            <div className={`${styles.field}`}>
              <label>{t("methods.collection")}</label>
              <p>{t("methods.collectionDesc")}</p>
              <Select defaultValue={selectOption[0]} options={selectOption} components={{ Option: CollOption }} onChange={(e) => {
                console.log(e)
                setCollectId(e.value)
              }} />
            </div>

            {/* Properties */}
            <div className={`${styles.metaDataModel}`}>
              <div className={`${styles.general}`}>
                <div style={{ display: 'flex' }}>
                  <img src={require('../../assets/icon/Properties.png')} className={`${styles.iconImg}`} />
                  <div style={{ marginLeft: 16 }}>
                    <span className={`${styles.metaDataType}`}>{t("methods.properties")}</span>
                    <p style={{ margin: 0, lineHeight: '24px' }}>{t("methods.propertiesDesc")}</p>
                  </div>
                </div>
                <div>
                  <button className={`${styles.addButton}`} onClick={(e) => {
                    e.preventDefault();
                    setMetaType('properties');
                    setMetaList(properties);
                    setIsShow(true);

                  }}>
                    <div className={`${styles.buttonIcon}`}>
                      <img src={require('../../assets/icon/add.png')} className={`${styles.iconImg}`} />
                    </div>
                  </button>
                </div>
              </div>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {properties.map((item: any, index: any) => {
                  if (item.trait_type != '' && item.value != '') {
                    return (
                      <div className={`${styles.propItem}`} key={index}>
                        <div className={`${styles.traitType}`}>{item.trait_type}</div>
                        <div className={`${styles.value}`}>{item.value}</div>
                      </div>
                    )
                  }
                })}
              </div>
            </div>

            {/* Levels */}
            <div className={`${styles.metaDataModel}`}>
              <div className={`${styles.general}`}>
                <div style={{ display: 'flex' }}>
                  <img src={require('../../assets/icon/Levels.png')} className={`${styles.iconImg}`} />
                  <div style={{ marginLeft: 16 }}>
                    <span className={`${styles.metaDataType}`}>{t("methods.levels")}</span>
                    <p style={{ margin: 0, lineHeight: '24px' }}>{t("methods.levelsDesc")}</p>
                  </div>
                </div>
                <div>
                  <button className={`${styles.addButton}`} onClick={(e) => {
                    e.preventDefault();
                    setMetaType('levels');
                    setMetaList(levels);
                    setIsShow(true);
                  }}>
                    <div className={`${styles.buttonIcon}`}>
                      <img src={require('../../assets/icon/add.png')} className={`${styles.iconImg}`} />
                    </div>
                  </button>
                </div>
              </div>
              <div>
                {levels.map((item: any, index: any) => {
                  if (item.trait_type != '' && item.value != '') {
                    return (
                      <div className={`${styles.statsItem}`} key={index}>
                        <div className={`${styles.numTrait}`}>
                          <div className={`${styles.traitType}`}>{item.trait_type}</div>
                          <div className={`${styles.value}`}>{item.value} of {item.max_value}</div>
                        </div>
                        <div className={`${styles.bar}`}>
                          <div className={`${styles.barFill}`} style={{ width: `${(item.value / item.max_value * 100).toFixed(0)}%` }}></div>
                        </div>
                      </div>
                    )
                  }
                })}
              </div>
            </div>

            {/* Stats */}
            <div className={`${styles.metaDataModel}`}>
              <div className={`${styles.general}`}>
                <div style={{ display: 'flex' }}>
                  <img src={require('../../assets/icon/Stats.png')} className={`${styles.iconImg}`} />
                  <div style={{ marginLeft: 16 }}>
                    <span className={`${styles.metaDataType}`}>{t("methods.stats")}</span>
                    <p style={{ margin: 0, lineHeight: '24px' }}>{t("methods.statsDesc")}</p>
                  </div>
                </div>
                <div>
                  <button className={`${styles.addButton}`} onClick={(e) => {
                    e.preventDefault();
                    setMetaType('Stats');
                    setMetaList(stats);
                    setIsShow(true);
                  }}>
                    <div className={`${styles.buttonIcon}`}>
                      <img src={require('../../assets/icon/add.png')} className={`${styles.iconImg}`} />
                    </div>
                  </button>
                </div>
              </div>
              <div>
                {stats.map((item: any, index: any) => {
                  if (item.trait_type != '' && item.value != '') {
                    return (
                      <div className={`${styles.statsItem}`} key={index}>
                        <div className={`${styles.numTrait}`}>
                          <div className={`${styles.traitType}`}>{item.trait_type}</div>
                          <div className={`${styles.value}`}>{item.value} of {item.max_value}</div>
                        </div>
                      </div>
                    )
                  }
                })}
              </div>
            </div>
            <div className={`${styles.field}`} style={{ marginTop: '24px' }}>
              <label>{t('methods.amount')}</label>
              <p>{t('methods.amountDesc')}</p>
              <input type="number" name="amount" style={{
                border: `1px solid rgba(34, 36, 38, 0.15)`,
                borderRadius: '8px',
                boxSizing: "border-box",
                outline: 'none',
                lineHeight: '24px',
                width: '100%',
                padding: '6px 12px'
              }} onChange={(e) => { setAmount(e.target.value) }} defaultValue={1} min={1} max={100000000} />
            </div>

            {/* Select Blockchain */}
            <div className={`${styles.field}`} style={{ marginTop: 18 }}>
              <label>{t('methods.chain')}</label>
              <Select
                defaultValue={ChainOptions[0]}
                onChange={(e: any) => {
                  let temp = [...destID];
                  temp[0] = e.value;
                  setDestID(temp)
                }}
                options={ChainOptions}
                components={{ Option: IconOption }}
              />
            </div>

            {/* Create button */}
            <button className={`${styles.createBtn}`} disabled={checkImg !== 2 || checkName !== 2 || checkPreview !== 2} onClick={(e) => {
              e.preventDefault();
              let temp = { ...metaDataUser };
              temp.attributes = [...properties, ...levels, ...stats]

              for (let i = 0; i < temp.attributes.length; i++) {
                if (temp.attributes[i].value == '' || temp.attributes[i].trait_type == '') {
                  temp.attributes.splice(i, 1);
                  i--;
                }
                if (temp.attributes[i]?.value > temp.attributes[i]?.max_value && temp.attributes[i]?.max_value !== null) {
                  temp.attributes.splice(i, 1);
                  i--;
                }
              }
              setMetaDataUser(temp)
              uploadMetaData(temp);
            }}>{t('methods.create')}</button>
          </form>
        </div>
        {isShow ? <AddMetaData type={metaType} data={metaList} addMetaData={handleAddMetaData} onChange={() => handleInPro()} /> : ''}
        {showLoadding ? <ShowLoadding data={operObj} onChange={() => handleShowLoadding()} /> : ''}
      </Col >

    </>
  );
}
export default Create;