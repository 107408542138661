import React from 'react';
import styles from "./index.module.less";
import Banner from '../../assets/images/about/banner.png';
import { Grid, Row, Col } from 'react-flexbox-grid';
// import { Grid, Row, Col } from 'react-flexbox-grid';
const About = () => {
    const bannerTxt1 = 'A metaverse infrastructure platform'
    const bannerTxt2 = 'People-oriented, restore user value in a decentralized way!'
    const title = 'Verseport'
    const content = `Verseport Labs is people-oriented, oriented to users of UGC, Professional or AI of PGC/AIGC, Verseport will build metaverse infrastructure platform, which including building metaverse light portal and backbone network, among which, metaverse light portal consists of user Web3 entrance, developer center, and API interface; The backbone network consists of cross-multi-chain middleware smart contracts and a unique and innovative object chain.

The Verseport metaverse infrastructure platform allows developers to access Web3 applications such as NFT, games, social networking, and e-commerce to build the metaverse ecosystem. The user-generated content, NFT and other assets are easily aggregated through user Web3 portals, mobile APP and other visual portals; For PGC and AIGC assets, API automatic aggregation is supported; Data assets can be reached directly through asset search, and convenient transactions of data assets are supported to realize value circulation. So as to restore the real value of user data assets in a decentralized way!
    
Compared with the existing Web3 technology platform, Verseport has the following core technologies and features:
1) Data asset value model: proposing the data asset value model which consists of transforming data into assets, assets into NFTs, NFTs into objects, and objects into value, and the model is realized through technical mechanisms/modules such as DID, Web3 interface, multi-chain smart contracts, object chains, microservices, trading platforms and incentives.
2) The underlying infrastructure of the object chain: The blocks of the existing blockchain are upgraded to objects of variable size and non-fixed block time interval, and the data are divided into two categories: object data and transaction data, and the data assets are abstracted as object data and stored based on IPFS; User activities are abstracted as transaction data, based on the keccak-256 hash [1] and timestamp of the transaction data object, combined with Proof of History (PoH[2]) and Proof of Stock (PoS[3] ) consensus mechanism to form an object chain; The object chain has the advantages of native public chain that can store PB-level massive data, 100,000-level ultra-high TPS, ultra-low gas fee, and sub-second block generation delay, providing the underlying infrastructure support for Web3 applications and data asset value model.
3) Developer community based on Web3 API: The Web3.0 API of the open data asset value model supports application developers to dock with JSON RPC, HTTP, Web Socket and other interface forms, and quickly and smoothly migrate NFT, Web2.0 games, social networking, e-commerce and other applications and data to Web3. 0 metaverse space, quickly upgraded to Web3.0 applications, forming a metaverse application ecology.
    `
    return (
        <>
        <Row>
            <Col xs={12} lg={10} lgOffset={1}>
                <div className={`${styles.bannerWrap}`}>
                    <img src={Banner} alt="" className={`${styles.img}`}/>
                    <div className={`${styles.txtWrap}`}>
                        <div className={`${styles.txtO}`}>{bannerTxt1}</div>
                        <div className={`${styles.txtT}`}>{bannerTxt2}</div>
                    </div>
                </div>
            </Col>
            <Col xs={12} lg={10} lgOffset={1}>
                <div className={`${styles.title}`}>{title}</div>
            </Col>
            <Col xs={12} lg={10} lgOffset={1}>
                <div className={`${styles.content}`}>{content}</div>
            </Col>                        
        </Row>  
        </>
    )
}
export default About;