import React, { useState } from 'react';
import styles from "../index.module.less"
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-flexbox-grid';

interface Props {
  addMetaData: (type: string, list: any) => void;
  onChange: any;
  type: string;
  data: any;
} 

const AddMetaData = ({ addMetaData, onChange, data, type }: Props) => {
  const [inputItem, setInputItem] = useState<any>(data)
  const { t } = useTranslation();
  return (
    <div className={`flex ai-center jc-center ${styles.popUps}`} >
      <div className={`${styles.popBg}`} onClick={() => onChange && onChange(false)}>背景</div>
      <Col xs={10} sm={10} lg={8} xl={6} className={`flex jc-center ai-center fd-column ${styles.popUpsMode}`}>
        <div className={`${styles.addMetaTitle}`}>{t('methods.add')}{t(`methods.${type}`)}</div>
        <div className={`${styles.popClose}`} onClick={() => onChange && onChange(false)} >close</div>
        <div className={`${styles.addMetaContent}`}>
          <Row className={`${styles.addMetaRow}`}>
            <Col xl lg md sm xs className={`${styles.addMetaDesc}`}>
              <p>{t(`methods.${type}`)}{t('methods.popDesc')}</p>
            </Col>
            <Col xl lg md sm xs className={`${styles.clearBtn}`}></Col>
            <Col xl lg md sm xs className={`${styles.addMetaColName}`}>
              <p>{t('methods.type')}</p>
            </Col>
            <Col xl lg md sm xs className={`${styles.addMetaColName}`}>
              <p>{type == 'properties' ? t('methods.name') : t('methods.value')}</p>
            </Col>
          </Row>
          {inputItem.map((item: any, index: any) => {
            if (type == 'properties') {
              return (
                <Row className={`${styles.addMetaRow}`} key={index}>
                  <Col xl lg md sm xs className='pointer' style={{ flexBasis: '10%', maxWidth: '10%' }}><button className={`${styles.popClose1}`} onClick={(e) => {
                    e.preventDefault()
                    let temp = [...inputItem];
                    temp.splice(index, 1)
                    setInputItem(temp);
                  }}>x</button></Col>
                  <Col xl lg md sm xs style={{ flexBasis: '45%', maxWidth: '45%' }}><input className={`${styles.addMetaInput}`} value={item.trait_type} onChange={(e) => {
                    let temp = [...inputItem];
                    temp[index]["trait_type"] = e.target.value;
                    setInputItem(temp)
                  }} /></Col>
                  <Col xl lg md sm xs style={{ flexBasis: '45%', maxWidth: '45%' }}><input className={`${styles.addMetaInput}`} value={item.value} onChange={(e) => {
                    let temp = [...inputItem];
                    temp[index]["value"] = e.target.value;
                    setInputItem(temp)
                  }} /></Col>
                </Row>
              )
            } else {
              return (
                <Row className={`${styles.addMetaRow}`} key={index}>
                  <Col xl lg md sm xs className='pointer' style={{ flexBasis: '10%', maxWidth: '10%' }}><button className={`${styles.popClose1}`} onClick={(e) => {
                    e.preventDefault()
                    let temp = [...inputItem];
                    temp.splice(index, 1)
                    setInputItem(temp);
                  }}>x</button></Col>
                  <Col xl lg md sm xs style={{ flexBasis: '45%', maxWidth: '45%' }}><input className={`${styles.addMetaInput}`} value={item.trait_type} onChange={(e) => {
                    let temp = [...inputItem];
                    if (type == 'levels') {
                      temp[index]["display_type"] = "number";
                    }
                    temp[index]["trait_type"] = e.target.value;
                    setInputItem(temp)
                  }} /></Col>
                  <Col xl lg md sm xs style={{ flexBasis: '20%', maxWidth: '20%' }}><input className={`${styles.addMetaInput}`} type='number' min={0} value={item.value} max={item.max_value} onChange={(e) => {
                    let temp = [...inputItem];
                    temp[index]["value"] = +e.target.value;
                    setInputItem(temp)
                  }} /></Col>
                  <Col xl lg md sm xs style={{ flexBasis: '5%', maxWidth: '5%' }}>
                    <div style={{ width: 36, height: '36px', lineHeight: '36px' }}>of</div></Col>
                  <Col xl lg md sm xs style={{ flexBasis: '20%', maxWidth: '20%' }}><input className={`${styles.addMetaInput}`} type='number' min={0} value={item.max_value} onChange={(e) => {
                    let temp = [...inputItem];
                    temp[index]["max_value"] = +e.target.value;
                    setInputItem(temp)
                  }} /></Col>

                </Row>
              )
            }
          })}
          <Row className={`${styles.addMetaRow}`}>
            <Col xl lg md sm xs style={{ flexBasis: '20%', maxWidth: '25%', marginBottom: 16 }}>
              <button style={{}} className={`${styles.btn}`} onClick={(e) => {
                let temp = [...inputItem];
                type == 'properties' ?
                  temp.push({
                    "display_type": "",
                    "trait_type": "",
                    "value": "",
                    "max_value": null
                  }) :
                  temp.push({
                    "display_type": "",
                    "trait_type": "",
                    "value": 3,
                    "max_value": 5
                  })
                setInputItem(temp);
                e.preventDefault();
              }}>{t('methods.addMore')}</button>
            </Col>
          </Row>

        </div>
        <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', height: '40px', padding: '12px 0px', width: '100%', borderTop: '1px solid #e5e8eb' }} >
          <button className={`${styles.btn}`} onClick={(e) => {
            e.preventDefault();
            let temp = [...inputItem];
            for (let i = 0; i < temp.length; i++) {
              if (temp[i].value == '' || temp[i].trait_type == '') {
                temp.splice(i, 1);
                i--;
              }
              if (temp[i]?.value > temp[i]?.max_value && type !== 'properties') {
                temp.splice(i, 1);
                i--;
              }
            }
            const res = new Map();
            temp = temp.filter((temp) => (!res.has(temp.trait_type) && res.set(temp.trait_type, 1)) || (!res.has(temp.value) && res.set(temp.value, 1)))
            addMetaData(type, temp);
          }}>{t('methods.save')}</button>
        </div>
      </Col>

    </div>
  )
}

export default AddMetaData;