// import Backend from '../pages/Backend';
import Bill from '../pages/Bill';
import Create from '../pages/Create';
import Collection from '../pages/CollectionList';
import Profile from '../pages/Profile/index';
import Settings from '../pages/Settings';
import Achievements from '../pages/Achievements';
import CreateCollection from '../pages/CreateCollection';
import Order from '../pages/Order';
import Fuel from '../pages/Fuel';
import Give from '../pages/Give';
const privateRoutes = [
  // {
  //   path: '/backend',
  //   component: Backend,
  //   exact: true,
  //   role: 'user',       // 当前路由需要的角色权限
  //   backUrl: '/login'   // 不满足权限跳转的路由
  // },
  {
    path: '/bill',
    component: Bill,
    exact: true,
    role: 'user',       // 当前路由需要的角色权限
    backUrl: '/'   // 不满足权限跳转的路由
  },
  {
    path: '/order',
    component: Order,
    exact: true,
    role: 'user',       // 当前路由需要的角色权限
    backUrl: '/'   // 不满足权限跳转的路由
  },
  {
    path: '/give',
    component: Give,
    exact: true,
    role: 'user',       // 当前路由需要的角色权限
    backUrl: '/'   // 不满足权限跳转的路由
  },
  
  {
    path: '/create',
    component: Create,
    exact: true,
    role: 'user',       // 当前路由需要的角色权限
    backUrl: '/'   // 不满足权限跳转的路由
  },
  {
    path: '/collection',
    component: Collection,
    exact: true,
    role: 'user',       // 当前路由需要的角色权限
    backUrl: '/'   // 不满足权限跳转的路由
  },
  {
    path: '/profile',
    component: Profile,
    exact: true,
    role: 'user',       // 当前路由需要的角色权限
    backUrl: '/'   // 不满足权限跳转的路由
  },
  {
    path: '/settings',
    component: Settings,
    exact: true,
    role: 'user',       // 当前路由需要的角色权限
    backUrl: '/'   // 不满足权限跳转的路由
  },
  {
    path: '/achievements',
    component: Achievements,
    exact: true,
    role: 'user',       // 当前路由需要的角色权限
    backUrl: '/'   // 不满足权限跳转的路由
  },
  {
    path: '/createCollection',
    component: CreateCollection,
    exact: true,
    role: 'user',       // 当前路由需要的角色权限
    backUrl: '/'   // 不满足权限跳转的路由
  },
  {
    path: '/fuel',
    component: Fuel,
    exact: true,
    role: 'user',       // 当前路由需要的角色权限
    backUrl: '/'   // 不满足权限跳转的路由
  }
];

export default privateRoutes;