/* eslint-disable react/jsx-no-target-blank */

import React,{useEffect,useState} from 'react';

import { Link } from 'react-router-dom';
import styles from './Footer.module.less';
import LogoW from '../../assets/images/logo-footer.png';

import { Grid, Row, Col } from 'react-flexbox-grid';

import { withRouter,useHistory,useLocation,useParams } from 'react-router-dom';

// 切换语言
import { useTranslation } from 'react-i18next';

import CryptoJS from "crypto-js";
const SECRET_KEY = CryptoJS.enc.Utf8.parse("f097eae0d2c86d3b");

const Footer = () => {
  const defaultTips = 'Privacy Policy Terms of Service';
  const goApps=()=>{
    const socialDataToken = sessionStorage.getItem('socialDataToken')
    const s = encrypt(socialDataToken)
    const p = sessionStorage.getItem('p');
    const UserName = sessionStorage.getItem('userName');
    const u = encrypt(UserName);
    const t = encrypt(new Date().getTime().toString());
    const isUni = encrypt('1');
    const ep = encrypt(`s=${s}&p=${p}&u=${u}&uni=${isUni}&t=${t}`);
    return ep
}
// 加密
const encrypt = (data: any)=> {
    if (typeof data === "object") {
        try {
            // eslint-disable-next-line no-param-reassign
            data = JSON.stringify(data);
        } catch (error) {
            console.log("encrypt error:", error);
        }
    }
    const dataHex = CryptoJS.enc.Utf8.parse(data);
    const encrypted = CryptoJS.AES.encrypt(dataHex, SECRET_KEY, {
        iv: SECRET_KEY,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.ciphertext.toString();
}
  const footerMapList = [
    {
      title: '发现',
      itemList: [
        {
          url: '/brand',
          title: '品牌馆',
        },
        // {
        //   url: '#',
        //   title: '市场',
        // },
        
      ],
    },
    {
      title: 'VP星世界',
      itemList: [
        {
          url: process.env.BASE_URL +'/game/star/?'+goApps(),
          title: 'VP星世界',
          externa:true
        },
        // {
        //   url: '/apps',
        //   title: '道牌',
        // },
        
      ],
    },
    {
      title: '资源',
      itemList: [
        {
          url: 'https://scan.verseport.com/',
          title: 'VP浏览器',

          externa:true
        },
        {
          url: 'https://www.verseport.com/developer/',
          title: '开发文档',

          externa:true
        },
        {
          url: '/help?type=2',
          title: '帮助中心',
        },
        {
          url: '/help?type=6',
          title: '关于我们',
        },
      ],
    },
    {
      title: '排行榜',
      itemList: [
        {
          url: '/stats',
          title: '作品',
        },
        {
          url: '/stats',
          title: '藏家',
        },
        {
          url: '/stats',
          title: '星豆',
        },
        // {
        //   url: '/stats',
        //   title: '道牌与权重',
        // },
      ],
    },
    {
      title: '我的',
      itemList: [
        {
          url: '/profile',
          title: '我的资产',
        },
        {
          url: '/bill',
          title: '我的星豆',
        },
        {
          url: '/order',
          title: '我的订单',
        },
        {
          url: '/settings',
          title: '设置',
        }
      ],
    },
  ];
  
  const { t} = useTranslation();



  const [visibleBackTopBtn, setVisibleBackTopBtn] = useState(false)

  useEffect(() => {
    // 在 React 中使用 addEventListener 监听事件
    document.addEventListener('scroll', handleScroll, true);
    // 组件卸载时移除事件监听
    return () => document.removeEventListener('scroll', handleScroll)
  }, [visibleBackTopBtn])

  // 滚动事件监听函数
  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.body.scrollTop || document.documentElement.scrollTop  || 0
    // scrollTop 为距离滚动条顶部高度
    // scrollHeight 为整个文档高度
        
    // 我们设定当滚动的距离大于 200 时，显示 【返回顶部】按钮
    if (scrollTop > 200) {
      setVisibleBackTopBtn(true)
    } else {
      setVisibleBackTopBtn(false)
    }
  }
 
  // 点击按钮事件处理函数
  const backToTopHandle = () => {
    // 把页面滚动到页面顶部
    window.scrollTo(0,0)
  }





  
  return (
    <>
    <div className={`${styles.footerBody}`}>
      <Col xs={12} lg={10} lgOffset={1}>
       <Row className={`${styles.footerMain}`}>
          <Col lg={4} className={`${styles.left}`}>
            <img src={LogoW} height="41" alt="" />
            <div className={`${styles.leftCentent} col-E6`}>
              {t('methods.footTips')}
            </div>
          </Col>
          <Col lg={7} lgOffset={1} className={`flex jc-space-between ${styles.right}`}>
           {footerMapList.map((item, index) => {
              return (
                <div key={index}>
                <h3 className={`${styles.rightTitle} pb-15`}>{item.title}</h3>
                  {item.itemList && item.itemList.map((subItem, subIndex) => {
                      return (<div key={'sub' + subIndex} className={`mt-15`}>
                        {subItem.externa?
                        <div className={`pointer ${styles.colWhite}`} onClick={()=>window.open(subItem.url)} key={item.title}>
                                
                                {subItem.title}
                        </div>: <Link to={subItem.url} className={`${styles.colWhite}`}>
                          {subItem.title}
                        </Link> 
                        }
                      </div>)
                  })
                  }
                </div>
              );
            })}
          </Col>
        </Row>
      </Col>

      <div className={`${styles.footerContainer}`}>
        <Col xs={12} lg={10} lgOffset={1} className={`${styles.footerSubMain}`}>
          <div>
            <p className={`font-14 mt-20 col-CD`}>版权所有 © 深圳番多拉信息科技有限公司 
              <a href="https://beian.miit.gov.cn" target={'_blank'} className={`ml-50 col-CD`}>粤ICP备2021041883号</a> 
              <span className={`ml-40 col-CD`}>增值电信业务经营许可证（EDI、ICP）：粤B2-20220611</span>
            </p>
            <p className={`font-14 pt-10`}>
              <span className={`col-CD`}>粤网文〔2023〕0057-010号</span>
              <span className={`ml-50 col-CD`}>粤公网安备 44031102000758号</span>
            </p>
            <div className={`mt-20 flex ai-center`}>
              <div className={`font-14 flex ai-center col-CD`}><img src={require('../../assets/icon/tellicon.png')} alt='' className={`mr-10`} />0755-23408029</div>
              <div className={`font-14 flex ai-center ml-50 col-CD`}><img src={require('../../assets/icon/addressicon.png')} alt='' className={`mr-10`} />深圳市光明区马田街道合水口社区中粮云景广场第1栋709</div>
            </div>
          </div>
          <div className={`flex space-40`}>
              <div className={`text-center`}>
                <img src={require('../../assets/icon/serviceCode.png')} alt='' width={100} />
                <p className={`mt-10`}>客服咨询</p>
              </div>
              <div className={`text-center`}>
                <img src={require('../../assets/icon/code/public-code.jpg')} alt='' width={100} />
                <p className={`mt-10`}>公众号</p>
              </div>
          </div>
        </Col>
      </div>

    </div>

   {visibleBackTopBtn && <div id={styles.backToTop} onClick={backToTopHandle} className={`${styles.returnTop}`}>返回顶部</div>}
          
    </>
    
  );
};

export default Footer;
