import React,{useState,useEffect}  from 'react';

import { Grid, Row, Col } from 'react-flexbox-grid';

import styles from './index.module.less';

import { simpleFn } from '../../Notification';

// web3
import Web3 from 'web3';

import { t } from 'i18next';

// api
import { regbyPhone,getSmsCode,vplogin,changeUser,checkSmsCode } from '../../../api/public';
import { setTimeout } from 'timers';


import {getKeccak256,byteLength,} from '../../../assets/js/web3Public';

import { Link,useHistory,useLocation } from 'react-router-dom';
const web3 = new Web3(Web3.givenProvider);


const Register=({onChange}:any)=>{
    const [userPhone,changUserPhone] = useState('');
    const [userPwd,changUserPwd] = useState('');
    const [userTips,changUserTips] =useState('');
    const [userPwdTips,changUserPwdTips] =useState('');

    const [phoneCode,setPhoneCode] = useState('');
    const [padText,setPadText] = useState('');
    const [padTextAgain,setPadTextAgain] = useState('');

    const [fromTips,setFromTips] = useState('');

    const [inviter,setInviter] = useState('')


    const checkSms = async ()=>{
        const params = {
            Phone: userPhone,
            VeriCode:phoneCode,
        }
        const res = await checkSmsCode(params);
        console.log("查询验证码是否正确============",res)
        if(res.code===0){
            res.data.IsOK?getUserRegister():setFromTips('验证码错误！')
           
        }else{
            // simpleFn("验证码错误！",'error')
            setFromTips('验证码错误！')
        }
    }

    const getUserRegister = async ()=>{

        // checkSms()

        // console.log(userPhone,padText,phoneCode);
        const PassWord = getKeccak256("\u0019Ethereum Signed Message:\n" + byteLength(padText) + padText).substr(2)
        let data={
            Phone:userPhone,
            // code:phoneCode,
            PassWord:PassWord,
            InviterSN:inviter
        }
        const res = await regbyPhone(data)
        console.log('=================',res)
        if(res.code===0){
            simpleFn('注册成功！','success');

            phoneLogin();
        }else{
            setFromTips(res.msg)
        }


    }



    

    const phoneLogin = async ()=>{
        const timestamp = new Date().getTime().toString();
        const uTokenValidPeriod='864000000000';
        const MsgKeccak256 = getKeccak256("\u0019Ethereum Signed Message:\n" + byteLength(padText) + padText).substr(2)
        const data ={
            Phone: userPhone,
            PassWord:MsgKeccak256,
            UTokenValidPeriod: uTokenValidPeriod,
            TimeStamp: timestamp,
        }
        const res = await vplogin(data)
        if(res.code===0){
            sessionStorage.setItem('userInfo',JSON.stringify(res.data))

            sessionStorage.setItem('AuthStatus',res.data.AuthStatus)
            
            sessionStorage.setItem('userName',res.data.UserName);
            sessionStorage.setItem('userMobile',res.data.Phone);
            // // getToken(res.data.userName)
          
            onChange();
        }
        console.log("=========================login",res)
    }
    const [getCodeTips,setGetCodeTips] = useState<string>('获取验证码');
    const [getCodeBtnClick,setGetCodeBtnClick] = useState<boolean>(true);

    const changPhone=()=>{
        console.log("=========================",userPhone)
        const phoneReg:RegExp = /^((1[0-9]{1}[0-9]{1})+\d{8})$/; 
        if(!phoneReg.exec(userPhone)){
            changUserTips('Please enter the correct phone number!')
            setGetCodeBtnClick(true)
            return false;
        }
        changUserTips('')
        setGetCodeBtnClick(false)
    }
    const [isGetCode,setIsGetCode]=useState<boolean>(false);
    // 
    const countdownBtn=()=>{
        let timer: any = null;
        const Countdown = 60;
       if (!timer) {
         let countdown = Countdown;
         timer = setInterval(() => {
           if (countdown > 0 && countdown <= 60) {
             countdown--;
             if (countdown !== 0) {
                setGetCodeBtnClick(true);
                setIsGetCode(true)
               setGetCodeTips("重新发送(" + countdown + ")");
             } else {
               clearInterval(timer);
               setGetCodeTips("获取验证码");
               countdown = 60;
               timer = null;
               setGetCodeBtnClick(false);
               setIsGetCode(false)
             }
           }
         }, 1000);
       }
    }
    //查询用户是否已注册
    const getChangeUser = async()=>{
        const res =await changeUser({Phone:userPhone})
        console.log("getChangeUser===========",res)
        if(res.code===0){
            
            switch (res.data.IsExist) {
                case true:
                    simpleFn('该用户已注册，请直接登录！','error')
                    break;
            
                default:
                    getSmsCodeFn()
                    
                    break;
            }
            
        }
    }
    //
    const getCode= async()=>{
        const phoneReg:RegExp = /^((1[0-9]{1}[0-9]{1})+\d{8})$/; 
        if(!phoneReg.exec(userPhone)){
            changUserTips('请输入正确的手机号码！')
            setGetCodeBtnClick(false)
            return false;
        }
        // let num = 60;
        // let code = setInterval(()=>{
        //     setGetCodeTips(num+' S')
        // },1000)
        getChangeUser()

       
    }
    const getSmsCodeFn=async ()=>{
            const TimeStamp = +new Date()
            const phone = userPhone
            const temp = ''+userPhone+TimeStamp
            const MsgKeccak256 = getKeccak256("\u0019Ethereum Signed Message:\n" + byteLength(temp) + temp).substr(2)
            const params = {
                Phone: phone,
                TimeStamp,
                MsgKeccak256
            }
    
            const res = await getSmsCode(params)
            switch(res.code){
                case 0:
                    simpleFn('发送成功，请留意！','success')
                    countdownBtn();
                break;
                default:
                
                simpleFn('服务器出错,请稍后再试！','error');
                // console.log('服务器出错')
                break;

            }
    }


    const padChecking=()=>{
        
        // console.log("padChecking==============================",padText,padTextAgain)
        if(padText!==padTextAgain){
            changUserPwdTips('两次密码不一致！！！');
        }else{
            changUserPwdTips('')
        }
    }
    const [allChecked, setAllChecked] = useState(false);
    const [checkboxes, setCheckboxes] = useState<any>({
      done: false
    });
  
    function handleChange(e:any) {
      setCheckboxes({
        ...checkboxes,
        [e.target.id]: e.target.checked
      })
    }
  
    useEffect(() => {
      const result = Object.values(checkboxes).every(v => v);
  
      console.log(result);
  
      setAllChecked(result);
    }, [checkboxes]);
    return (
        <>
            
            <h3 className={`${styles.logTitle}`}>用户注册</h3>
            <Row className={`pt-30 ${styles.logMain}`}>
                <Col lg={8} lgOffset={2} className={`flex space-16`}>
                    <span className={`font-18 mt-20`}>+86</span>
                    <input type='text' placeholder={'请输入您的手机号码'}  onChange={e =>{
                            changUserPhone(e.target.value);
                    }}  onKeyUp={changPhone}  className={`${styles.logTex}  ${userTips?styles.hasTips:''}`}  />
                </Col>
                <Col lg={8} lgOffset={2} className={`flex space-16`}>
                    <input type='text' placeholder={'请输入手机验证码'}  onChange={e =>{
                        setPhoneCode(e.target.value);
                        setFromTips('')   
                    }} value={phoneCode} className={`flex-1 ${styles.logTex} `}  />
                        {/* disabled={getCodeBtnClick} */}
                    <button className={`btn blue-btn ${styles.getCodeBtn} ${isGetCode?styles.getCodeClick:''}`}  onClick={getCode} >{getCodeTips}</button>
                </Col>



                <Col lg={8} lgOffset={2}>
                    <input type='password' placeholder={'请设置登录密码'} value={padText}  onChange={e =>{
                        console.log(e.target.value);
                            setPadText(e.target.value);
                    }} onKeyUp={padChecking} className={`${styles.logTex} ${userPwdTips?styles.hasTips:''}`}  />
                </Col>
                <Col lg={8} lgOffset={2}>
                    <input type='password' placeholder={'请再次确认登录密码'} value={padTextAgain}  onChange={e =>{
                        console.log(e.target.value);
                            setPadTextAgain(e.target.value);
                    }} onKeyUp={padChecking} className={`${styles.logTex} ${userPwdTips?styles.hasTips:''}`}  />
                </Col>
                
                <Col lg={8} lgOffset={2} className={`mt-30`}>
                    <input type='text' placeholder={'请输入邀请码（非必填）'} value={inviter}  onChange={e =>{
                        console.log(e.target.value);
                        setInviter(e.target.value);
                    }} className={`${styles.logTex}`}  />
                </Col>
                <Col lg={8} lgOffset={2} className={`flex ai-center jc-center fd-column ${styles.fromTipsBox}`}>
                    {userTips?<p className={`${styles.fromTips}`}>{userTips}</p>:null}
                    {userPwdTips?<p className={`${styles.fromTips}`}>{userPwdTips}</p>:null}
                    {fromTips?<p className={`${styles.fromTips}`}>{fromTips}</p>:null}
                </Col>
                <Col lg={8} lgOffset={2}>
                    <div className={`col-blue font-12 pb-15 pl-15`}>完成注册奖励1000星豆！</div>
                    <button className={`btn blue-btn ${styles.logBtn}`} disabled={userPhone==='' || padText==='' || phoneCode==='' ||!allChecked} onClick={checkSms}>注册并登录</button>
                </Col>
                <Col lg={8} lgOffset={2} className={`text-center pt-20`}>
                                {/* <span className={`pointer ${styles.logForgotPad}`}>{t('methods.loginForgotPwd')}</span> */}
                    <label className={`pointer`}>
                        <input type='checkbox' name="done"
                        value={checkboxes.done}
                        id="done"
                        onChange={handleChange} />
                        <span className={`font-14 col-666`}>注册即为同意</span>
                    </label>
                    <Link to={`/help?type=4`} className={`font-14 col-blue`}>《用户协议》</Link>
                    <Link to={`/help?type=5`} className={`font-14 col-blue`}>《隐私政策》</Link>
                    {/* <Link to={`/help?type=5`} className={`font-14 col-blue`}>《PIN码管理服务免责声明》</Link> */}
                </Col>
                <Col lg={12} className={`${styles.logUnder}`}>已有账号, 立即去<span className={`col-blue pointer ml-10`} onClick={()=> onChange()}>{`登录 >`}</span></Col>
            </Row>
        </>  
    )
}
export default Register;