/* eslint-disable @typescript-eslint/no-unused-expressions */

import React,{useState,useEffect}  from 'react';

import { Grid, Row, Col } from 'react-flexbox-grid';

import styles from './Login.module.less';

// 切换语言
import { useTranslation } from 'react-i18next';

// api
import { metaLogin,userLogin,getUserInfo,vplogin,vpCenterLogin,searchtrusteeship,pincodetrusteeship } from '../../../api/public';

import { simpleFn } from '../../Notification';

// web3
// import detectEthereumProvider from '@metamask/detect-provider';
import Web3 from 'web3';
import { blob } from 'node:stream/consumers';

import Register from '../Register';
import Forget from '../Forget';
import { Link,useHistory,useLocation } from 'react-router-dom';


import { getDecryptKey,getKeccak256,byteLength,getSign,getUtf8ToHex,encrypt,encryptPin } from '../../../assets/js/web3Public';


import '../../../assets/js/protocol.js';
import '../../../assets/js/starx-wsclient.js';


(window as any).starx.init({host: process.env.WS_URL, path: '/'}, function () {
    console.log("ws初始化成功initialized");
})
const Login =({onChange,userData}:any)=>{
    const { t } = useTranslation();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isLoginType,changeLoginType] = useState(true);
    const changLoginType=()=>{
        changeLoginType(!isLoginType)
    }
    const web3 = new Web3(Web3.givenProvider);
    const connectWall = () => {
        //判断用户是否安装MetaMask钱包插件
        if (typeof window.ethereum !== 'undefined') {
            startLin(window.ethereum); 
        } else {
            // alert('请安装 MetaMask！');
            simpleFn('请安装 MetaMask！','error')
        }
    }
    // 钱包MetaMask登录
    const startMetaMask =async (provider: any) => {
        const accounts = await provider.request({ method: 'eth_requestAccounts' }).then((res:any)=>{
            
            // sessionStorage.setItem('provider',JSON.stringify(res));
            // console.log('连接MetaMask钱包成功',res,web3.utils.toChecksumAddress(res[0]))
        
            getMetaLogin(web3.utils.toChecksumAddress(res[0]))
        }).catch((err:any)=>{
            // console.error('连接MetaMask钱包失败',err);
            simpleFn(err.message,'error')
           
        });
    };
    const startLin= async (provider: any) => {
        // web登录
        const accounts = await web3.eth.getAccounts().then((res:any)=>{
            // console.log('连接成功2',res.length)
            // sessionStorage.setItem('provider',JSON.stringify(res));
            if(res.length>0){
                getMetaLogin(res[0])
            }else{
                // console.log('Web3 Login Error')
                startMetaMask(provider)
            }
            
        }).catch((err:any)=>{
            // console.error('连接失败2',err)
            simpleFn(err.message,'error')
        })
            
        // }
        
    }

    // 钱包登录
    const getMetaLogin = async (e:string) =>{

        const timestamp = new Date().getTime().toString(); 
        const userToken= web3.utils.keccak256(e).substr(2);

        const uTokenValidPeriod='864000000000';
        const temp = e.concat(userToken,uTokenValidPeriod,timestamp);
        const msgKeccak256 =web3.utils.keccak256("\u0019Ethereum Signed Message:\n"+ new Blob([temp]).size + temp);
        const data ={
            userName: e,
            userToken: userToken,
            uTokenValidPeriod: uTokenValidPeriod,
            timeStamp: timestamp,
            msgKeccak256: msgKeccak256.substr(2),
        }
        // personal
        web3.eth.personal.sign('\x00'+temp, e,'').then(async (sigRes:any)=>{
            const loginData={
                ...data,
                sig: sigRes.substr(2)
            }
            const res = await metaLogin({...loginData});
            if(res.code === 0){
                console.log("meta Login",res)
                sessionStorage.setItem('userName',e);
                sessionStorage.setItem('userToken',userToken);
                sessionStorage.setItem('socialDataToken',res.data.socialDataToken);
                GetUserInfoData(e)
                onChange(false);
            }else {
            // message.error(res.msg);
            }
        })
    }
    // 获取用户信息
    const GetUserInfoData = async (UserName:string) => {
        const res = await getUserInfo({ userName: UserName });
        if (res.code == 0) {
        // console.log("获取用户信息成功!",res)
        //   res.data.nickName !== '' ? setCheckName(2) : setCheckName(0);
         sessionStorage.setItem('userInfo',JSON.stringify(res.data));
         sessionStorage.setItem('userName',JSON.stringify(res.data.userName));
         userData(res.data)
        } else {
        console.log("获取用户信息失败", res.code, res.msg);
        }
    }


    const [userName,changUserName] = useState('');
    const [userPwd,changUserPwd] = useState('');
    const [userPin,changUserPin] = useState('');
    // let pinKeyData:any = sessionStorage.getItem('pinKey');
    // const [userPIN,changUserPIN] = useState<any>(JSON.parse(pinKeyData)||'');

    // const [showTips,setShowTips] = useState<boolean>(false);

    const [userNameTips,changUserNameTips] =useState('');
    const [userPwdTips,changUserPwdTips] =useState('');
    const [userPinTips,changUserPinTips] =useState('');
    const getUserLogin = async ()=>{
        // console.log(event.target )
        console.log(userName,userPwd,userPin)

        if (userName.length === 0 || userPwd.length === 0 || userPin.length === 0) { 
            userName.length === 0?changUserNameTips('请输入您的登录手机号码'):changUserNameTips('');
            userPwd.length === 0?changUserPwdTips('请输入密码'):changUserPwdTips('');
            userPin.length === 0?changUserPinTips('请输入PIN码'):changUserPinTips('');
            return false;
        } 
        const emailReg:RegExp = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
        const phoneReg:RegExp = /^((1[0-9]{1}[0-9]{1})+\d{8})$/; 
        console.log(!emailReg.test(userName) , !phoneReg.exec(userName))
        if(!emailReg.test(userName) && !phoneReg.exec(userName)){
            changUserNameTips('请输入正确的帐号！')
            return false;
        }
        phoneLogin()
        // const res = await userLogin({
        //     userName:userName,
        //     passWord: userPwd
        // });
        // if(res.code === 0){
        //     console.log('User Login',res)
             
        //     sessionStorage.setItem('userName',res.data.userName);
        //     sessionStorage.setItem('userToken',res.data.userToken);
        //     sessionStorage.setItem('socialDataToken',res.data.socialDataToken);
        //     sessionStorage.setItem('userMobile',res.data.mobile);
        //     GetUserInfo(res.data.userName)
        //     onChange(false);


        // }else{
        //     changUserNameTips(res.msg)
        //     changUserPwdTips(' ')
        // }   
    }

    
    //手机号码登录
    const phoneLogin = async ()=>{
        if(sessionStorage.getItem('AuthStatus')&&sessionStorage.getItem('userName')&&sessionStorage.getItem('userMobile')&&sessionStorage.getItem('userInfo')){
            let obj = JSON.parse(sessionStorage.getItem('userInfo')||'')
            getPrivateKey(obj)
            return ;
        }

        const MsgKeccak256 = web3.utils.keccak256("\u0019Ethereum Signed Message:\n" + byteLength(userPwd) + userPwd).substr(2);
        let tmpObj = {
            password: MsgKeccak256,
            phone:userName
        }
        var join =  (res:any)=> {
            console.log("ws初始化登陆完成～",res);
            if(res.code === 0) {
                const joinObj ={
                    AuthStatus:res.data.auth_status,
                    Avatar:res.data.avatar_2d,
                    NickName:res.data.nickname,
                    Phone:userName,
                    PrivateKeyCipher: res.data.private_key,
                    SocialDataToken:res.data.sd_token,
                    Trusteeship:res.data.trusteeship,
                    UserID:res.data.uid,
                    UserName:res.data.username,
                    UserSn:res.data.user_sn,
                }
                sessionStorage.setItem('AuthStatus',res.data.auth_status)
                sessionStorage.setItem('userName',res.data.username);
                sessionStorage.setItem('userMobile',userName);
                sessionStorage.setItem('userInfo',JSON.stringify(joinObj))
                // getPrivateKey(joinObj)
                
                sessionStorage.setItem('pw',encrypt(userPwd))
                sessionStorage.setItem('p',encrypt(userPin))
                !trusteeship?savePinToServe(joinObj):null;

                sessionStorage.setItem('socialDataToken',res.data.sd_token);
                sessionStorage.setItem('pinKey',JSON.stringify(userPin))
                
                onChange(false);
            }
        };
        (window as any).starx.request("system.login2",tmpObj,join);
    




        // const timestamp = new Date().getTime().toString();
        // const uTokenValidPeriod='864000000000';
        // const MsgKeccak256 = getKeccak256("\u0019Ethereum Signed Message:\n" + byteLength(userPwd) + userPwd).substr(2)
        // const data ={
        //     Phone: userName,
        //     PassWord:MsgKeccak256,
        //     UTokenValidPeriod: uTokenValidPeriod,
        //     TimeStamp: timestamp,
        // }
        // const res = await vplogin(data)
        // if(res.code===0){
        //     sessionStorage.setItem('userInfo',JSON.stringify(res.data))
            
        //     sessionStorage.setItem('AuthStatus',res.data.AuthStatus)
            
        //     sessionStorage.setItem('userName',res.data.UserName);
        //     sessionStorage.setItem('userMobile',res.data.Phone);
        //     // // getToken(res.data.userName)
        //     getUserIsSavedPin(res.data)
        // }else{
        //     changUserNameTips(res.msg)
        //     changUserPwdTips('')
        // }  
        // console.log("=========================login",res)
    }
    // 查询是否托管加密
    const [trusteeship,setTrusteeship]=useState(true)
    const getUserIsSavedPin=async(data: any)=>{
    const res =await searchtrusteeship({Phone:data.Phone})
        if(res.code===0){
            // const {data} = res.data
            console.log("查询pin码托管结果",data)
            // setTrusteeship(data.trusteeship?false:true)
            setTrusteeship(res.data.IsTrusteeship)
            getPrivateKey(data)
        }
    }
    // 托管Pin码
    const savePinToServe=async(e:any)=>{
        let data = {
            UserName:e.UserName,
            PinCode:encryptPin(userPin),
            Trusteeship:true
        }
        const res = await pincodetrusteeship(data)
        getToken(e)
    }
    //解密私钥+签名
    const getPrivateKey = async (data: any) => {
        
        const { PrivateKeyCipher,UserName } = data;
        console.log(data)
        try {
            // 解密私钥
            const decryptRes = getDecryptKey({key:PrivateKeyCipher,pin:userPin});
            const TimeStamp = +new Date();
            const UserToken = getKeccak256(UserName).substr(2);
            const UTokenValidPeriod = 864000000000;
            const temp = UserName.concat(UserToken, UTokenValidPeriod, TimeStamp);
            const MsgKeccak256 = getKeccak256("\u0019Ethereum Signed Message:\n" + byteLength(temp) + temp).substr(2)
            const sigRes = getSign(getUtf8ToHex('\x00' + temp), decryptRes.privateKey);

            const params = {
                UserName,
                UTokenValidPeriod,
                TimeStamp,
                MsgKeccak256,
                Sig: sigRes.signature.substr(2),
                UserToken
            }
            sessionStorage.setItem('privateKey',encrypt(decryptRes.privateKey))
            sessionStorage.setItem('p',encrypt(userPin))
            
            !trusteeship?savePinToServe(params):getToken(params);
            
        } catch (err) {
           console.log("1230============")

           changUserPinTips('Pin码错误！')
        }
    }
    const getToken = async (e:any)=>{
       
        const res = await vpCenterLogin(e)
        console.log("1getToken===================",res)
        if(res.code===0){

            sessionStorage.setItem('socialDataToken',res.data.SocialDataToken);
            onChange(false);
        }
    }
    
    const [showRegister,setShowRegister] = useState<boolean>(false);

    const [showForget,setShowForget]  = useState<boolean>(false);

    const [allChecked, setAllChecked] = useState(false);
    const [checkboxes, setCheckboxes] = useState<any>({
      done: false
    });
  
    function handleChange(e:any) {
      setCheckboxes({
        ...checkboxes,
        [e.target.id]: e.target.checked
      })
    }
  
    useEffect(() => {
      const result = Object.values(checkboxes).every(v => v);
  
      console.log(result);
  
      setAllChecked(result);
    }, [checkboxes]);
    const ChooseLogin:React.FC =()=>{
        return (
            <Row className={`${styles.logMain}`}>
                <Col lg={6}>
                    <h4 className={`${styles.logTips}`}>WEB3</h4>
                    <div className={`flex ai-center jc-center fd-column ${styles.logChangBtn}`} onClick={connectWall}>
                        <img src={require('../../../assets/images/login/metamask-logo.png')} alt="MetaMask" className={`${styles.logBtnIcon}`} />
                        <p className={`pt-15`}>MetaMask</p>
                    </div>
                </Col>
                <Col lg={6}>
                    <h4 className={`${styles.logTips}`}>{t('methods.loginUser')}</h4>
                    <div className={`flex ai-center jc-center fd-column ${styles.logChangBtn}`} onClick={changLoginType}>
                        <img src={require('../../../assets/images/login/user-logo.png')} alt="User" className={`${styles.logBtnIcon}`} />
                        <p className={`pt-15`}>{t('methods.loginUserTips')}</p>
                    </div>
                </Col>
                <Col lg={12} className={`${styles.logUnder}`}>{t('methods.notMenberTips')} <span className={`col-blue pointer`} onClick={()=>setShowRegister(true)}> {t('methods.notMenberTipsBtn')}</span></Col>
            </Row>
        )
    }

   

    return (
        <div className={`flex ai-center jc-center ${styles.popUps}`} >
            <div className={`${styles.popBg}`} onClick={() => onChange && onChange(false) && changUserNameTips('')&&changUserPwdTips('')}>背景</div>
            <Col xs={10} sm={10} lg={10} xl={8} className={`flex jc-center ai-center fd-column ${styles.popUpsMode}`}>
                <div className={`${styles.popClose}`}  onClick={() => onChange && onChange(false) && changUserNameTips('')&&changUserPwdTips('')}>close</div>

                {/* , onChange(false) */}
               {showRegister?<Register onChange={()=>(setShowRegister(false),window.location.reload())} />:
                
                showForget? <Forget onChange={()=>(setShowForget(false),window.location.reload())} />:
                
                <>
                    <h3 className={`${styles.logTitle}`}>{t('methods.loginTitle')}</h3>
                    {/* {isLoginType?
                        <ChooseLogin />
                        : */}
                        <Row className={`pt-30 ${styles.logMain}`}>
                            <Col lg={8} lgOffset={2}>
                                <input type='text' placeholder={t('methods.loginNameTips')}  onChange={e =>{
                                        changUserName(e.target.value);
                                        changUserNameTips('')
                                }} onFocus={e=>{
                                    changUserNameTips('');
                                    changUserPwdTips('')}} className={`${styles.logTex}  ${userNameTips?styles.hasTips:''}`}  />
                            </Col>
                            <Col lg={8} lgOffset={2} className={`pt-10`}>
                                <input type='password' placeholder={t('methods.loginPwdTips')}  onChange={e=>{changUserPwd(e.target.value);changUserPwdTips('')}} onFocus={e=>{
                                    changUserNameTips('');
                                    changUserPwdTips('')
                                    }} className={`${styles.logTex}  ${userPwdTips?styles.hasTips:''}`} />
                            </Col>
                            <Col lg={8} lgOffset={2} className={`pt-10`}>
                                <input type='password' placeholder={'请输入PIN码'} value={userPin}  onChange={e =>{
                                        changUserPin(e.target.value);
                                        changUserPinTips('');
                                }}    className={`${styles.logTex} `}  />
                            </Col>
                          
                            <Col lg={8} lgOffset={2} className={`flex ai-center jc-center fd-column ${styles.fromTipsBox}`}>
                                {userNameTips?<p className={`${styles.fromTips}`}>{userNameTips}</p>:null}
                                {userPwdTips?<p className={`${styles.fromTips}`}>{userPwdTips}</p>:null}
                                {userPinTips?<p className={`${styles.fromTips}`}>{userPinTips}</p>:null}
                            </Col>
                            <Col lg={8} lgOffset={2}>
                                {/* <span className={`pointer ${styles.logForgotPad}`}>{t('methods.loginForgotPwd')}</span> */}
                                <label className={`pointer`}>
                                    <input type='checkbox' name="done"
                                    value={checkboxes.done}
                                    id="done"
                                    onChange={handleChange} />
                                    <span className={`font-14 col-666`}>登录即为同意</span>
                                </label>
                                <Link to={`/help?type=4`} className={`font-14 col-blue`}>《用户协议》</Link>
                                <Link to={`/help?type=5`} className={`font-14 col-blue`}>《隐私政策》</Link>
                                <Link to={`/help?type=5`} className={`font-14 col-blue`}>《PIN码管理服务免责声明》</Link>
                            </Col>
                            <Col lg={8} lgOffset={2} className={`text-center pt-20`}>
                                <button className={`btn blue-btn ${styles.logBtn}`} disabled={userName==='' || userPwd==='' ||userPin==='' ||!allChecked} onClick={getUserLogin}>{t('methods.login')}</button>
                            </Col>
                            <Col lg={8} lgOffset={2} className={`text-center pt-20`}>
                                <span className={`pointer ${styles.logForgotPad}`}  onClick={()=>setShowForget(true)}>{t('methods.loginForgotPwd')}</span>
                            </Col>
                           
                            {/* <Col lg={12} className={`${styles.logUnder}`}>{t('methods.loginChangeTips')} <span className={`col-blue pointer`} onClick={()=>changeLoginType(true)}> {t('methods.loginChangeBtn')}</span></Col> */}
                            <Col lg={12} className={`${styles.logUnder}`}>{t('methods.notMenberTips')} <span className={`col-blue pointer`} onClick={()=>setShowRegister(true)}> {t('methods.notMenberTipsBtn')}</span></Col>
                        </Row>
                        {/* } */}
                </>}

            </Col>
            
        </div>
    )
}
export default Login;