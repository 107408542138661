import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import styles from './inde.module.less';
import { useTranslation } from 'react-i18next';

const ReadMore = () => {
  const { t } = useTranslation();
  const goUrl = (url: string) => {
    window.open(url)
  }
  return (
    <div className={`${styles.bottomContent}`}>
      <Row style={{ height: '100%' }}>
        <Col xlOffset={2} lgOffset={2} xs={12} sm={12} md={12} lg={7} xl={7} >
          <div className={`${styles.bottomText}`}>
            <span>{t('methods.aboutVP')}</span>
            <p>{t('methods.vpDAO')}</p>
          </div>
        </Col>
        <Col xs={11} sm={11} md={11} lg={2} xl={2} className={`${styles.readMore}`}>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%'
          }}><button className={`btn white-btn`} style={{ borderRadius: '24px' }} onClick={(e) => {
            e.preventDefault();
            goUrl('https://www.tally.xyz/governance/eip155:80001:0x9778aCBFB86c394d1F80D1a74f7366A4D85ee316')
          }}>{t('methods.readMore')}</button></div>
        </Col>
      </Row>
    </div>
  )
}

export default ReadMore;