import React, { useState } from 'react';
import styles from "./index.module.less"
import { useTranslation } from 'react-i18next';
import { Col } from 'react-flexbox-grid';
import DatePicker from '../../../components/DatePicker/index'
import Select from 'react-select';
import { shelvesNFT } from '../../../api/public';

interface Props {
  onChange: any;
  itemData: any;
  changeSellInfo: any
}

const Sell = ({ onChange, changeSellInfo, itemData }: Props) => {

  const [startTime, setStartTime] = useState<any>();
  const [endTime, setEndTime] = useState<any>();
  const [amount, setAmount] = useState<any>();
  const [price, setPrice] = useState<any>();
  const [payType, setPayType] = useState(1);
  console.log('itemdata', itemData)
  const { t } = useTranslation();
  const options = [
    { value: '1', label: '星豆' },
    { value: '2', label: '人民币' },
  ];

  const setTimeRange = (sTime: any, eTime: any) => {
    setStartTime(sTime);
    setEndTime(eTime);
  }

  return (
    <>
      <div className={`flex ai-center jc-center ${styles.popUps}`} >
        <div className={`${styles.popBg}`} onClick={() => onChange && onChange(false)}>背景</div>
        <Col xs={8} sm={8} lg={4} xl={4} className={`flex jc-center ai-center fd-column ${styles.popUpsMode}`}>
          <div className={`${styles.addMetaTitle}`}>{t('methods.add')}</div>
          <div className={`${styles.popClose}`} onClick={() => onChange && onChange(false)} >close</div>
          <DatePicker style={{width: '100%'}} changeTime={setTimeRange} />
          <input className={`${styles.addMetaInput}`} onChange={(e) => {
            setAmount(e.target.value)
          }} />
          <input className={`${styles.addMetaInput}`} onChange={(e) => {
            setPrice(e.target.value)
          }}/>
          <Select
            defaultValue={options[0]}
            onChange={(e: any) => {
              setPayType(e.value)
            }}
            className={`${styles.selectItem}`}
            options={options}
          />
          <div className={`flex jc-center ${styles.bottomBtn}`}>
            <button className={`${styles.btn}`} onClick={async (e) => {
              e.preventDefault();
              // changeSellInfo()
              const params = {
                userName: itemData.userInfo.userName,
                tokenID: itemData.tokenID,
                nftDestID: itemData.destID,
                startDate: startTime,
                endDate: endTime,
                amount: amount,
                type: payType,
                price: price
              }
              const result = await shelvesNFT(params);
              console.log('上架结果', result)
              
            }}>{t('methods.save')}</button>
          </div>
        </Col>
      </div>
    </>
  )
}

export default Sell;