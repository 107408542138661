
import React,{useRef,useState,useEffect}  from 'react';

import { Grid, Row, Col } from 'react-flexbox-grid';

import styles from './index.module.less';

// 切换语言
import { useTranslation } from 'react-i18next';

// api
import { addFavouriteFolder,addFavourite,delFavouriteFolder,updateFavouriteFolder} from '../../../api/public'

import { simpleFn } from '../../../components/Notification';

import Select, { components } from 'react-select';
import { sync } from 'resolve';

const Mode = ({onChange,data,itemData}:any)=>{
    // console.log("datadata================",data,JSON.stringify(itemData))
    const { t } = useTranslation();

    const userName = sessionStorage.getItem('userName');
 
    const [checkName, setCheckName] = useState(0);
    const [metaDataUser, setMetaDataUser] = useState<any>({
      name:itemData.name || itemData.metaDataUser.name
      }
    );

  const folderList = data.items.filter((item:any)=>item.isFolder).map((item:any)=>({ value: item.gridFavFolderID, label: item.name, icon: "folder.png",isAdd:false,isSub:true}))
  

  //收藏栏操作
  const options = [
    { value: "", label:`${t('methods.Favorites')}`, icon: "folder-open.png",isAdd:false,isSub:false},
    ...folderList,
    { value: "add", label: `${t('methods.NewFolder')}`, icon: "add-icon.png",isAdd:true,isSub:false},
  ];
  const { Option } = components;
  const [isShowAddField,setIsShowAddField]=useState(false);
  const [checkFolderName, setCheckFolderName] = useState(0);
  const [metaDataFolder, setMetaDataFolder] = useState<any>({
    name:'新建收藏夹',
  })
  const IconOption = (props: any) => (
    <Option {...props}>
      <div className={`flex ai-center ${props.data.isSub && 'pl-20'}`}>
        {props.data.isAdd?<img src={require('../../../assets/images/apps/' + props.data.icon)} style={{ width: 16, marginRight: '8px' }} alt={props.data.label} />:<img src={require('../../../assets/images/apps/' + props.data.icon)} style={{ width: 16, marginRight: '8px' }} alt={props.data.label} />}
        {props.data.label}
      </div>
    </Option>
  
  );
  const folderInput = useRef<any>(null);
  const fieldTree = useRef<any>(null);
  const [showFolderInput,setShowFolderInput]=useState<boolean>(false)
  const setFieldID=(e:any)=>{
    console.log('setFieldID',e)
    setIsShowAddField(e.value==='add');
    setIsCurItem(e.value);
    if(e.value==='add' ){
      toDoFolderInput();
      setShowFolderInput(true);
      console.log('current',fieldTree.current)
    }
  }
  const toDoFolderInput=()=>{
    setTimeout(()=>{
      fieldTree.current.scrollTop =fieldTree.current.scrollHeight;
      folderInput.current.focus();
    },500)
  }
  const blurFolderInput=()=>{
    console.log(metaDataFolder)
    addFolder();
  }



  // 新增收藏夹
  const addFolder = async()=>{
    if(!metaDataFolder.name){
      setShowFolderInput(true);
      setCheckFolderName(1)
      return;
    }
    let folderData={
        userName:userName,
        name: metaDataFolder.name,
        type:1
    };
    const res = await addFavouriteFolder(folderData)
    if(res.code===0){
      setShowFolderInput(false);
      let changData={
        type:'addfolder',
        data:{
          gridFavFolderID: res.data.InsertedID,
          isFolder: 1,
          name: metaDataFolder.name
        }
      }
      onChange(changData)
    }else{
      
    }
    console.log(res)
}
// 删除收藏夹
const delField= async (e:any)=>{
  let folderData={
    userName:userName,
    gridFavFolderID:e.value,
  }
  const res =await delFavouriteFolder(folderData)
  console.log('delFavouriteFolder',res)
  if(res.code===0){
    setShowFolderInput(false);
    let changData={
      type:'delfolder',
      data:{
        gridFavFolderID: e.value,
        isFolder: 1,
        name: metaDataFolder.name
      }
    }
    onChange(changData)
  }
}
// 编辑收藏夹
const editFolderInput = useRef<any>(null);
const [isEditField,setIsEditField] = useState<boolean>(false)
const editField=(e:any)=>{
  console.log("编辑收藏夹",e)
  let folderData={
    name:e.label,
    ...e
  }
  setMetaDataFolder({...folderData});
  setIsEditField(!isEditField);
  setTimeout(()=>{
    editFolderInput.current.focus();
  },500)
}
const blurEditFolderInput=()=>{
  // console.log("blurEditFolderInput===================================",metaDataFolder)
  EditFolder()
}

const EditFolder = async ()=>{
  let folderData={
    userName:userName,
    gridFavFolderID: metaDataFolder.value,
    name: metaDataFolder.name
  }
  const res = await updateFavouriteFolder(folderData)
  if(res.code===0){
    setIsEditField(false);
    let metaDataFolderData={
      name:'新建收藏夹'
    }
    setMetaDataFolder({...metaDataFolderData})
    let changData={
      type:'editfolder',
      data:{
        ...folderData
      }
    }
    onChange(changData)
  }
}



// 当前选中文件夹
const onFieldItem=(e:any)=>{
  console.log(111,e)
  setIsCurItem(e.value)

}
// 新增 or 删除 收藏格子
const addGrid = async (type:number)=> {
  let data ={
      userName:userName,
      name:metaDataUser.name,
      gridID:itemData.id || itemData.gridID,
      gridFavFolderID:isCurItem==='add'?"000000000000000000000000":isCurItem,
      type:type,
      isFolder: 0,
      dataHash:itemData.dataHash || itemData.metaDataUser.image.split('/')[itemData.metaDataUser.image.split('/').length-1],
      location:itemData.location,
      thumbHash:''
  }
  const res = await addFavourite(data)
  // console.log('新增格子',res)
  if(res.code===0){
    setShowFolderInput(false);
    let changData={
      type:type===1?'addgrid':'delgrid',
      data:{
        ...data
      }
    }
    onChange(changData)
  }

}
// 保存收藏
const saveCollect=(type:number)=>{
  addGrid(type)
}
const [isCurItem,setIsCurItem]=useState<any>('')
    return (
        <div className={`flex ai-center jc-center ${styles.popUps}`} >
            <div className={`${styles.popBg}`} onClick={() => onChange && onChange({type:'close'})}>背景</div>
            <Col xs={10} sm={8} lg={4} xl={3} className={`${styles.popUpsMode}`}>
                <div className={`${styles.popClose}`}  onClick={() => onChange && onChange({type:'close'})}>close</div>
                <h3 className={`${styles.modeTitle}`}>{t('methods.CollectGrids')}</h3>
                <div className={`pb-30 pl-20 pr-20 ${styles.modeMain}`}>
                  
                        
                       <div className={`mt-10 flex ai-center ${styles.field}`}>
                            <label className={`font-14`}>{t('methods.name')}</label>
                            <input type="text" name="Item-name"
                                style={{
                                border: `1px solid ${checkName == 1 ? 'rgba(219, 40, 40, 0.6)' : 'rgba(34, 36, 38, 0.15)'} `
                                }}
                                onChange={(e) => {
                                let temp = { ...metaDataUser };
                                temp.name = e.target.value;
                                setMetaDataUser(temp);
                                if (e.target.value !== '') {
                                    setCheckName(2);
                                } else {
                                    setCheckName(1)
                                }
                              }} value={metaDataUser.name} placeholder={t('methods.checkGridName')} />
                        </div>

                        {checkName == 1 ? <div className={`font-12 mt-10 col-red pl-65 border-box`}>{t('methods.checkName')}</div> : ''}
                        {isShowAddField?
                        <>
                        <div className={`mt-20 ${styles.fieldTree}`} ref={fieldTree}>
                            <div className={`pl-5 pr-15 pt-20 pb-20`}>
                              {options.map((item)=>{
                                return (!item.isAdd && item.isSub &&
                                  <div className={`${styles.fieldItem} ${item.value===isCurItem?styles.fieldCurItem:''}  `} key={item.value} onClick={()=>onFieldItem(item)}>
                                    <div className={`flex ai-center space-8  font-14 col-666 pointer pl-10 ${item.isSub?styles.fieldTreeItem:styles.fieldTreeTitle}`}>
                                      <img src={require('../../../assets/images/apps/'+item.icon)} alt="" />
                                      {isEditField && metaDataFolder.value===item.value?
                                      
                                      <input type="text" ref={editFolderInput} className={`${styles.folderText}`}  name="edit-folder-name" placeholder={t('methods.checkFolderName')}
                                        style={{
                                          border: `1px solid ${checkFolderName == 1 ? 'rgba(219, 40, 40, 0.6)' : '#01A8FF'} `
                                        }}
                                        defaultValue={metaDataFolder.name}
                                        onChange={(e) => {
                                        let temp = { ...metaDataFolder };
                                        temp.name = e.target.value;
                                        setMetaDataFolder(temp);
                                        if (e.target.value !== '') {
                                            setCheckFolderName(2);
                                        } else {
                                            setCheckFolderName(1)
                                        }
                                        }}
                                        onBlur={blurEditFolderInput}
                                      /> 

                                      :<span>{item.label}</span>}

                                      {item.isSub && !isEditField && !showFolderInput && <div className={`space-8 font-12 ml-30 ${styles.fieldTreeItemEdit}`}>
                                        <span className={`col-red`} onClick={()=>delField(item)}>{t('methods.Remove')}</span>
                                        <span className={`col-blue`} onClick={()=>editField(item)}>{t('methods.Edit')}</span>
                                      </div>}
                                    </div> 
                                  </div>
                                  )
                              })}
                             {showFolderInput && isCurItem==='add' &&
                              <div className={`${styles.fieldItem}  ${'add'===isCurItem?styles.fieldCurItem:''}  `} >
                                  <div className={`flex ai-center space-8  font-14 col-666 pointer pl-10`}>
                                      <img src={require('../../../assets/images/apps/folder.png')} alt="" />
                                      <input type="text" ref={folderInput} className={`${styles.folderText}`}  name="Folder-name" placeholder={t('methods.checkFolderName')}
                                      style={{
                                        border: `1px solid ${checkFolderName == 1 ? 'rgba(219, 40, 40, 0.6)' : '#01A8FF'} `
                                      }}
                                      defaultValue={metaDataFolder.name}
                                      onChange={(e) => {
                                      let temp = { ...metaDataFolder };
                                      temp.name = e.target.value;
                                      setMetaDataFolder(temp);
                                      if (e.target.value !== '') {
                                          setCheckFolderName(2);
                                      } else {
                                          setCheckFolderName(1)
                                      }
                                      }}
                                      onBlur={blurFolderInput}
                                      />
                                  </div>
                                </div>
                              }
                            </div>
                        </div>
                        {checkFolderName===1 && <div className={`font-12 mt-15 col-red`}>{t('methods.checkFolderName')}</div>}
                        </>:
                        <div className={`mt-20 flex ai-center ${styles.field}`}>
                            <label className={`font-14`}>{t('methods.File')}</label>
                            <Select
                                defaultValue={options[0]}
                                isSearchable={false}
                                onChange={(e) => {
                                 setFieldID(e)
                                }}
                                options={options}
                                className={`${styles.fieldSelect}`}
                                components={{ Option: IconOption }}

                              />
                        </div>}

                         <div className={`flex ai-conter jc-space-between mt-30`}>
                         
                            <div className={`space-8`}>
                                {isShowAddField?<button className={`${styles.btnMore}`} onClick={()=>{setShowFolderInput(true);toDoFolderInput();setIsCurItem('add')}}>{t('methods.NewFolder')}</button>:<button className={`${styles.btnMore}`} onClick={()=>{setIsShowAddField(true);setIsCurItem('more')}}>{t('methods.More')}...</button>}
                            </div>
                            <div className={`space-8 flex ai-conter jc-flex-end`}>
                                <button className={`${styles.btnSave}`} onClick={()=>saveCollect(1)}>{t('methods.save')}</button>
                                <button className={`${styles.btnCancel}`} onClick={()=>saveCollect(2)}>{t('methods.Remove')}</button>
                            </div>
                         </div>
                        
                   
                </div>
              


            </Col>
            
        </div>
    )
}
export default Mode;
