
import React,{useState,useEffect}  from 'react';

import { Grid, Row, Col } from 'react-flexbox-grid';



// 切换语言
import { useTranslation } from 'react-i18next';

// api
import { getMyGridList,myPageCount } from '../../../api/public'

import { simpleFn } from '../../../components/Notification';

import styles from './index.module.less';

import GridMain from '../GridMain';
import Select from 'react-select';


const Merge = ({onChange,data,baseUrl}:any)=>{

    console.log("datadataMain",data);

    const userName = sessionStorage.getItem('userName');
    const [myPageGrid,setMyPageGrid] = useState<any>({});
    const [pageNum,setPageNum]=useState<number>()
    const getLogPages =async (e:number)=>{
        setPageNum(e)
        let dataMise = {
            userName:userName,
            skip: 0,
            limit: 100,
            page:e
        }
        const res = await getMyGridList(dataMise);
        console.log('getMyGridList',res)
        if(res.code===0){
            setMyPageGrid(res.data)
        }
        setIsNoMerge(true)
        setGridCurItemList({})
      }
      const mergeGrid=(e:any)=>{
        
    
      }
      const getMyPageCount=async ()=>{
        let dataMise = {
            userName:userName
        }
        const res = await myPageCount(dataMise);
        if(res.code===0){
            let count = res.data.map((item:any)=>{
                let json ={
                    ...item,
                    value:item.page,
                    label:item.page
                }
                return json
            })
            setSelectOptions(count)
        }
      }
    useEffect(()=>{
      getMyPageCount()
    },[])
    const [selectOptions,setSelectOptions] = useState([])
    const [gridCurItemList,setGridCurItemList] = useState<any>({})
    const curList=(e:any)=>{

        setGridCurItemList(e)
    }
    const [isNoMerge,setIsNoMerge] = useState<boolean>(true)
    const mergeItem =[4,9,16,25,36,49,64,81,100]
    useEffect(()=>{
       //计算是否满足合并
        if(gridCurItemList.list && mergeItem.includes(gridCurItemList.list.length) && gridCurItemList.y.length === Math.sqrt(gridCurItemList.list.length) && gridCurItemList.z.length === Math.sqrt(gridCurItemList.list.length)){
            setIsNoMerge(false)
        }else{
            setIsNoMerge(true)
        }
    },[gridCurItemList])
    const curFN = ()=>{
        let data = {
            ...gridCurItemList,
            pageNum:pageNum
        }
        // console.log(data)
        onChange(data)
    }
    return ( 
        <div className={`flex ai-center jc-center ${styles.popUps}`} >
            <div className={`${styles.popBg}`} onClick={() => onChange && onChange()}>背景</div>
            <Col xs={12} sm={10} lg={8} xl={6} className={`pb-40 ${styles.popUpsMode}`}>
                <div className={`${styles.popClose}`}  onClick={() => onChange && onChange()}>close</div>
                <h3 className={`${styles.modeTitle}`}>合并格子</h3>
                <Row className={`${styles.modeMain}`}>
                    <Col xs={8}>
                        <div className={`mt-20 ${styles.field}`}>
                            <label className={`mb-12`}>page</label>
                            <Select options={selectOptions} onChange={(e: any) => { getLogPages( e.value ) }} placeholder="请选择需要合并格子的所在页..." />  
                        </div>

                    </Col>
                    <Col xs={5} className={`mt-20`}>
                        <div className={``}>
                            <label className={`mb-12`}>选择格子</label>
                            <div className={`font-16 mt-10 col-666 lh-20`}>请选择右侧要合并的格子<p className={`col-red font-14 mt-10`}>注：只允许合并自己拥有的格子！可对邻近的2*2到10*10的格子进行合并。</p></div>
                            <div className={`font-14 mt-15`}>
                                <div className={`flex ai-center ${styles.saleStatusMerge}`}>
                                    <div>不可选</div>
                                </div>
                                <div className={`flex ai-center ${styles.saleStatusMerge} ${styles.saleStatus2}`}>
                                    <div>可选</div>
                                </div>
                                <div className={`flex ai-center ${styles.saleStatusMerge} ${styles.saleStatus3}`}>
                                    <div>已选</div>
                                </div>
                            </div>
                        </div>
                        { gridCurItemList.list && <div className={`mt-20`}>
                            <label className={`mb-12`}>已选坐标</label>
                            <div className={`mt-10 lh-24 font-16 col-333`}>
                                { gridCurItemList.list.map((item:any,index:number)=>{
                                        return (<span style={{display:'inline-block'}} className={`pt-5`}>
                                           （{item.location && `${item.location.pageNum} , ${item.location.grid_y} , ${item.location.grid_z}`}）
                                        </span>)
                                    })
                                }
                            </div>
                        </div>}
                            {/* {JSON.stringify(gridCurItemList)} */}

                    </Col>
                    <Col xs={6} className={`mt-20`}>
                        <div className={`${styles.myGridBox}`}>

                           <GridMain data={myPageGrid} onChange={(e:any)=>curList(e)} />
                        </div>

                    </Col>
                    <Col xs={8}>
                      <button className={`mt-20 ${styles.createBtn}`} disabled={ isNoMerge } onClick={(e) => curFN()}>保存</button>
                    </Col>
                </Row>


            </Col>
            
        </div>
  )
}
export default Merge;
