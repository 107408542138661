
import React,{useState,useEffect}  from 'react';

import { Grid, Row, Col } from 'react-flexbox-grid';

import styles from './index.module.less';

// 切换语言
import { useTranslation } from 'react-i18next';
import LazyLoad from 'react-lazyload';


import { simpleFn } from '../../../../components/Notification';


import wxPay from '../../../../assets/images/explore/wxpay.svg';
import zfbPay from '../../../../assets/images/explore/zfb.svg';
import bankCardPay from '../../../../assets/images/explore/bankCard.svg'

import TimeContent from '../../../../components/TimeContent';

const baseUrl = process.env.BASE_URL+"/ipfs/";
const Exchange =({onChange,itemData,showDetailBtn}:any)=>{
    console.log("==================itemData",itemData)
    const { t } = useTranslation();
 
    
    const changeDaoM = async (e:any)=>{
        
        if(e){
            onChange(e,payType,itemData.OrderID)
        }else{
            onChange(e)
        }

       
    }
    const [payType,setPayType] = useState<number>((itemData.PayWay === 0 || itemData.PayWay === 2 || itemData.PayWay=== 4 || itemData.PayWay===5 )?1:(itemData.PayWay===1 || itemData.PayWay===3 )?2:3)
    const handleChange = (event:any)=> {
        console.log("================",event.target.value)
        setPayType(event.target.value);
     }
     const formatTimeData=(time:number)=>{
		var date = new Date(time)
            var Y = date.getFullYear() + '-'
            var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
            var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
            var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
            var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
            var s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
            return Y + M + D + h + m + s
	}
    return (
        <div className={`flex ai-center jc-center ${styles.popUps}`} >
            <div className={`${styles.popBg}`} onClick={() => onChange && onChange(false)}>背景</div>
            <Col xs={10} sm={10} lg={8} xl={6} className={`flex jc-center ai-center fd-column ${styles.popUpsMode}`}>
                <div className={`${styles.popClose}`}  onClick={() => onChange && onChange(false)}>close</div>
                {(itemData.payStatus===1 || itemData.payStatus===2) ?
                <Row className={`mt-40 ml-40 ${styles.logTitleMain}`}>
                    <Col lg={12} className={`text-left`}>
                        <h3 className={`font-24`}>订单详情</h3>
                        
                            <div className={`mt-20 font-14`}>
                                <span>订单提交成功,请尽快付款!</span>
                                <span className={`ml-30 col-666`}>提交时间：</span>
                                <span>{formatTimeData(itemData.createTime)}</span>
                            </div>
                            <div className={`mt-15 font-14`}>
                                请您在<span className={`col-red text-center ${styles.timeStyle}`} ><TimeContent data={itemData.createTime} onChange={()=>onChange(false)}/></span>内完成支付，逾期视为自愿放弃
                            </div>
                    </Col>
                </Row>:
                <h3 className={`${styles.logTitle}`}>订单详情</h3>
                }
                <Row className={`pt-40 ${styles.logMain}`}>
                    
                   <Col lg={10} lgOffset={1} className={`mb-40`}>
                    { itemData.Products.map((subItem:any,index:any)=>{
                            return (
                                <div className={`flex ai-center`} key={subItem.Image}>
                                    <div className={`pl-10`}>
                                            <LazyLoad>
                                            <img className={`${styles.fuelImg}`} src={subItem.ProductType==501?require('../../../../assets/icon/mine/fuel-icon.gif'):baseUrl + subItem.Image} alt="" />
                                            </LazyLoad>
                                        </div>
                                        <div className={`pl-30 flex-1`}>
                                            <div className={`flex ai-center`}>
                                                <h3 className={`font-18 `}>{subItem.NftName}</h3>
                                            </div>
                                          <div className={`flex ai-center mt-10`}>
                                                <div className={`col-666 font-14 `}>售价</div>
                                                <h3 className={`font-18 ml-15`}>¥ <span className={`col-red`}>{subItem.Price/100}</span></h3>
                                            </div>
                                            <div className={`flex ai-center mt-10`}>
                                                <div className={`col-666 font-14`}>数量</div>
                                                <h3 className={`font-18 ml-15`}>{subItem.Num}</h3>
                                            </div>
                                        
                                        </div>
                                    </div>
                                
                            )
                        })
                    }
                       
                            <Col xs={12}>
                                <div className={`flex ai-center mt-40`}>
                                    <div className={`col-666 font-14 `}>支付方式</div>
                                    {(itemData.PayStatus===1 || itemData.PayStatus===2) ? 
                                        <div className={`ml-15 space-16 font-14 flex-1`}>
                                        <label className={`flex ai-center`}>
                                            <input type='radio' name='payType' value={1} onClick={handleChange} defaultChecked={payType===1 ? true : false} />
                                            <img src={zfbPay} alt='' className={`ml-5 mr-5 ${styles.payIcon}`} />
                                            支付宝
                                        </label>
                                        {/* <label className={`flex ai-center`}>
                                            <input type='radio' name='payType' value={2} onClick={handleChange} defaultChecked={payType===2 ? true : false}  />
                                            <img src={wxPay} alt='' className={`ml-5 mr-5 ${styles.payIcon}`} />
                                            微信
                                         </label> */}
                                        {/* <label className={`flex ai-center`}>
                                            <input type='radio' name='payType' value={3} onClick={handleChange} defaultChecked={payType===3 ? true : false}  />
                                            <img src={bankCardPay} alt='' className={`ml-5 mr-5 ${styles.payIcon}`} />
                                            银行卡
                                        </label> */}
                                    </div>:
                                    (itemData.PayStatus===4 || itemData.PayStatus===5 || itemData.PayStatus===6 || itemData.PayStatus===7) ? <div className={`ml-15 space-16 font-14 flex-1`}>
                                        {(itemData.PayWay === 2 || itemData.PayWay=== 4 || itemData.PayWay===5 )&& <label className={`flex ai-center`}>
                                            <img src={zfbPay} alt='' className={`ml-5 mr-5 ${styles.payIcon}`} />
                                            支付宝
                                        </label>}
                                        {(itemData.PayWay===1 || itemData.PayWay===3 )&& <label className={`flex ai-center`}>
                                            <img src={wxPay} alt='' className={`ml-5 mr-5 ${styles.payIcon}`} />
                                            微信
                                         </label>}
                                        {/* <label className={`flex ai-center`}>
                                            <img src={bankCardPay} alt='' className={`ml-5 mr-5 ${styles.payIcon}`} />
                                            银行卡
                                        </label> */}
                                         {(itemData.PayWay===0 )&& <label className={`flex ai-center`}>
                                            --
                                         </label>}
                                    </div>
                                    :<div className={`ml-15 space-16 font-14 flex-1`}>--</div>
                                    }


                                </div>

                                <div className={`flex ai-center mt-20`}>
                                    <div className={`col-666 font-14`}>订单状态</div>
                                    <div className={`ml-15 flex flex-1 font-14`}>
                                        {/* 1 创建待确认订单，  2 待支付 ，3 取消支付  4 已支付 5 转移中  6 转移成功  7 转移失败  8 订单超时失效 */}
                                        {(itemData.PayStatus===1 || itemData.PayStatus===2) && '待支付'}
                                        {itemData.PayStatus===3 && '已取消'}
                                        {itemData.PayStatus===4 && '已支付'}
                                        {itemData.PayStatus===5 && '数字作品转移中'}
                                        {itemData.PayStatus===6 && '数字作品转移成功'}
                                        {itemData.PayStatus===7 && '数字作品转移失败'}
                                        {itemData.PayStatus===8 && '订单超时，未支付'}
                                    </div>
                                </div> 
                                <div className={`flex ai-center mt-20`}>
                                    <div className={`col-666 font-14`}>下单时间</div>
                                    <div className={`ml-15 flex flex-1 font-14`}>
                                        {formatTimeData(itemData.CreateTime)}
                                    </div>
                                </div>    
                                {<div className={`flex ai-center mt-20`}>
                                    <div className={`col-666 font-14`}>支付时间</div>
                                    <div className={`ml-15 flex flex-1 font-14`}>
                                        {!itemData.PayTime?'--':formatTimeData(itemData.PayTime)}
                                    </div>
                                </div> }     
                               <div className={`flex ai-center mt-20`}>
                                    <div className={`col-666 font-14 `}>总计&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                                    <div className={`ml-15 flex flex-1`}><span className={`col-333 font-16 mr-5`}>¥</span> <h3 className={`font-18 col-red`}>{itemData.TotalPrice/100}</h3></div>
                                </div>
                            </Col>
                        
                    </Col>
                   {(itemData.PayStatus===1 || itemData.PayStatus===2) && <Col lg={10} lgOffset={1} className={`pt-40 pb-40 ${styles.modeBtnTop}`}>

                        <div className={`col-red font-12 pb-15 pl-15`}>注意：您24小时内只能取消5次订单，超出次数将限购24小时。</div>
                         <Row>
                            <Col xs={4}>
                                <button className={`${styles.medalBtn} ${styles.medalCancelBtn}`}  onClick={()=>changeDaoM('cancel')}>取消订单</button>
                            </Col>
                            <Col xs={8}>
                                <button className={`${styles.medalBtn}`}  onClick={()=>changeDaoM('pay')}>立即支付</button>
                            </Col>
                        </Row>
                        {showDetailBtn && (itemData.PayStatus===4 ||itemData.payStatus===5 || itemData.payStatus===6)  && <Row>
                            
                            <Col md={12}>
                                <button className={`${styles.medalBtn}`}  onClick={()=>changeDaoM('see')}>查看详情</button>
                            </Col>
                        </Row>}
                        <Row className={` mt-25 ${styles.warnTips}`}>
                            <Col xs={12} className={`flex ai-center font-14`}><img src={require('../../../../assets/images/login/tips-icon.png')} alt='' className={`mr-10`} />购买须知</Col>
                            <Col xs={12} className={`mt-10 font-12 flex-1`}>数字作品为虚拟数字商品，而非实物，一经售出，不支持退换。本商品仅限实名认证为年满18周岁的中国大陆用户购买。数字作品的版权由发行方或原创者拥有，除另行取得版权拥有者书面同意外，用户不得将数字作品用于任何商业用途。请勿对数字作品进行炒作、场外交易、欺诈，或以任何其他非法方式进行使用。</Col>
                        </Row>
                    </Col>}
                </Row> 
               
            </Col>
            
        </div>
    )
}
export default Exchange;