import React, { useEffect, useState } from 'react';
import styles from "./index.module.less";
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Link,useHistory } from "react-router-dom";
import {generalsheLvesList,alipagepayFuel,myTransrdList,getUserInfo,createFuelOrder,getOrderStatus} from '../../api/public';
import ReadMore from "../Profile/ReadMore";
import Empty from '../../components/Empty';

import RcPagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';

import Order from './Order';
import Pay from '../Explore/Detail/Pay';
import Popup from '../../components/Popup';

import { simpleFn } from '../../components/Notification/index';
import { t, use } from 'i18next';
// 主题
const themePrimaryColor = 'theme';
const primaryColor =  localStorage.getItem(themePrimaryColor)||'light';


const Fuel = () => {
    const history = useHistory();
    const [fuelList,setFuelList]=useState<any>([])
    const getFuelList = async()=>{
        const res = await generalsheLvesList({})
        if(res.code===0){
            setFuelList(res.data)
        }
    }
    const bugFuel= async (e:any)=>{
        const res =await alipagepayFuel({OrderID:e});
        if(res.code===0){
            setShowBuy(false);
            setShowPayState(true);
            window.open(res.data.URL);
        }
    }
    const [transrdList,setTransrdList] = useState<any>({})
    const getMyTransrdList = async()=>{
        const res = await myTransrdList(queryData)
        if(res.code===0){
            setTransrdList(res.data)
        }
    }

    const [current, setCurrent] = useState(1);
    const Locale = localStorage.getItem('localesLng');
    const LocaleEn_US: object = {
    // Options.jsx
    items_per_page: '/ page',
    jump_to: 'Go to',
    jump_to_confirm: 'confirm',
    page: 'Page',

    // Pagination.jsx
    prev_page: 'Previous Page',
    next_page: 'Next Page',
    prev_5: 'Previous 5 Pages',
    next_5: 'Next 5 Pages',
    prev_3: 'Previous 3 Pages',
    next_3: 'Next 3 Pages',
    page_size: 'Page Size',
    };
    const [queryData, setQueryData] = useState<any>({
        UserName:sessionStorage.getItem('userName'),
        PayStatus:undefined,// 1 待支付   2 已支付 3 转移中  4 转移成功  5 转移失败
        skip: 0,
        limit: 10
    })
    useEffect(()=>{
        GetUserInfo();
        getFuelList();
    },[])
    useEffect(()=>{
        getMyTransrdList();
    },[queryData])
   // 获取用户信息
const [userInfo,setUserInfo]=useState<any>({})
  const GetUserInfo = async () => {
    const res = await getUserInfo(queryData);
    if (res.code == 0) {
      setUserInfo(res.data);
    } else {
      console.log("获取用户信息失败", res.code, res.msg);
    }
  }
    const formatTimeData=(time:number)=>{
        var date = new Date(time)
        var Y = date.getFullYear() + '-'
        var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
        var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
        var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
        var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
        var s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
        return Y + M + D + h + m + s
    }
    const [showBuy,setShowBuy] = useState<boolean>(false);

    
    const changeBuyMode=(e:any,id:any)=>{
        switch (e) {
            case 'cancel':
                setShowBuy(false)
                break;
            default:
                createFuelItemOrder(id)
                break;
        }
    }
    const [orderNum,setOrderNum]=useState<string>('')
    const createFuelItemOrder=async(e:any)=>{
        const userName = sessionStorage.getItem('userName');
        const res = await createFuelOrder({Products:[{ProductSN:e,Num: 1}],UserName:userName})
        if(res.code===0){
            setOrderNum(res.data.OrderID);
            bugFuel(res.data.OrderID);
        }else if(res.code===10){
            setShowPopup(true)
        }else{
            simpleFn(res.msg,'error')
        }
    }
    const [showPayState,setShowPayState]=useState<boolean>(false);
    // 订单支付后弹出
	const payOrder=(e:number)=>{
		if(e===1){
			console.log('支付问题？')
			history.push(`/help?type=3`)
		}else if(e===2){
			// getOrderDetail(orderNum,2);
            setShowPayState(false);
            GetUserInfo();
            getFuelList();
            getMyTransrdList();
		}
	}
	// const getOrderDetail = async (orderNum:string,n:number)=>{
	// 	const res = await getOrderStatus({orderID:orderNum});
	// 	if(res.code===0){
			
	// 	}else{

	// 	}
	// }
    const [showPopup,setShowPopup] = useState<boolean>(false);
    const popupChange=(e:boolean)=>{
        setShowPopup(false);
        if(e){
            history.push(`/order`);
        }
    }
    return (
        <>
             <div className={`${styles.fuelMain}`}>
                
                <Col md={12} lg={10} lgOffset={1} className={`relative`}>
                    <div className={`bg${primaryColor} ml-0 mr-0 ${styles.fuelTop}`}>
                        <div className={`flex ai-center jc-space-between ${styles.fuelBuy}`}>
                            <div className='flex ai-center'>
                                <img src={require('../../assets/icon/mine/fuel-icon.gif')} className={`${styles.fuelImg}`} alt='' />
                                <span className={`font-26 ml-45`}>燃料次数</span>
                                <span className={`font-42 col-blue ml-50`}>{userInfo.TransFerTimes}</span>
                            </div>
                            <div>
                                <button className={`btn blue-btn ${styles.buyFuelBtn}`} onClick={()=>setShowBuy(true)}>购买次数</button>
                            </div>
                        </div>
                        <div className={`lh-24 flex ${styles.fuelTips}`}>
                           
                            <div className={`font-14 col-999`}>
                            燃料是您在Verseport平台进行数字作品铸造、转赠、合成等链上行为所需消耗的道具，每发生1次链上行为都将消耗1次燃料。平台给每个新注册用户免费赠送20次燃料，赠送燃料全部消耗后需要用户购买燃料次数进行补充。
                            </div>
                        </div>
                    </div>
                    {/* ${styles.fuelBillMain} */}
                    <div className={`bg${primaryColor} ml-0 mr-0 mt-20 ${styles.fuelBillList} `}>
                        <div className={`flex ai-center font-18  ${styles.fuelBillListTitle} `}>
                            <img src={require('../../assets/images/fuel/bill-icon.png')} alt='' className={`mr-15`} /> 账单记录
                        </div>
                        <div className={`flex mt-20 mb-20 col-333 ${styles.listTitle}`}>
                            <Col xs>操作</Col>
                            <Col xs>类型</Col>
                            <Col xs>时间</Col>
                            <Col xs>消耗</Col>
                        </div>
                        {transrdList.count>0 ?
                        <>
                        {transrdList.items.map((item:any,index:number)=>{
                            return (
                                <div className={`flex pt-20 pb-20 col-333`} key={item.CreateTime}>
                                    <Col xs>{item.MarketID?'购买':'转赠'}</Col>
                                    <Col xs>{item.MarketID?'补充燃料':'消耗燃料'}</Col>
                                    <Col xs>{formatTimeData(item.CreateTime)}</Col>
                                    <Col xs className={`${item.MarketID?'':'col-red'}`}>{item.MarketID?'+'+item.Times:'-1'}</Col>
                                </div>
                            )
                        })}
                        </>:
                        <Empty
                            imageStyle={{
                              width: 480,
                              height: 'auto',
                              display: 'block'
                            }}
                            description={
                              <p style={{ textAlign: 'center', margin: '24px 0px', fontSize: '24px', color: '#9f9f9f' }}>{t(`methods.noData`)}</p>
                            }
                            image={require('../../assets/icon/noData.png')}
                          />
                        }



                        <div className={'flex ai-center jc-center pt-20 pb-40'}>
                              <RcPagination current={current}  total={transrdList.count} pageSize={10} showPrevNextJumpers={false} locale={Locale === 'en' ? LocaleEn_US : undefined} className={`font-20 text-conter ${styles.orginPagination}`} onChange={(current: number, pageSize: number) => {
                                let data = Object.assign({}, queryData, { skip: (current - 1) * pageSize });
                                setCurrent(current);
                                setQueryData(data);
                              }}  />
                            </div>

                    </div>

                    
                </Col>

            </div>
            <ReadMore />
            {showBuy && <Order itemData={fuelList} onChange={(e:any,id:any)=>changeBuyMode(e,id)} />}
            {showPayState && <Pay onChange={(e:any)=>payOrder(e)} />}
            {/* ? */}
            {showPopup && <Popup onChange={(e:boolean)=>popupChange(e)} title={``} content={<>
					<p className={`mt-20 `}>您当前有未支付订单，请支付完再继续购买！</p>
                    <p className={`mt-20 `}>立即前往支付？</p>
					<p className={`font-12 col-red mt-20`}>*24小时内锁单5单未支付，将限制交易24小时</p></>} />}

            {/* <Row>
                <Col md={12} lg={10} lgOffset={1}>
                    <Row className={`bg${primaryColor} mt-30 ml-0 mr-0 ${styles.billTop}`}>
                        {fuelList && fuelList.map((item:any,index:number)=>{
                            return (
                                <Col md={4} className={`${styles.billLeftBox}`} key={item.id} onClick={()=>bugFuel(item)}>
                                    <div className={`flex ai-center jc-center ${styles.medalBox}`}>
                                    <div className={'pr-20'}>
                                        <p className={`font-18`}>{item.times}</p>
                                        <p className={`col-blue font-30 pt-10`}>{item.price}</p>
                                    </div>
                                    </div>
                                </Col>
                            )
                        })
                        }
                    </Row> 
                </Col>                      
            </Row> */}
        </>
    )
}
export default Fuel;