
import React,{useState,useEffect}  from 'react';

import { Grid, Row, Col } from 'react-flexbox-grid';

import styles from './index.module.less';

// 切换语言
import { useTranslation } from 'react-i18next';
import LazyLoad from 'react-lazyload';


import { simpleFn } from '../../../components/Notification';


import wxPay from '../../../assets/images/explore/wxpay.svg';
import zfbPay from '../../../assets/images/explore/zfb.svg';
import bankCardPay from '../../../assets/images/explore/bankCard.svg'

import TimeContent from '../../../components/TimeContent';



const NFTDetail =({onChange,itemData,ipfsUrl,showDetailBtn}:any)=>{
    // console.log("==================itemData",itemData)
    const { t } = useTranslation();
 
    

    const [amount,changAmount] = useState<any>(1)
    return (
        <div className={`flex ai-center jc-center ${styles.popUps}`} >
            <div className={`${styles.popBg}`} onClick={() => onChange && onChange(false)}>背景</div>
            <Col xs={10} sm={10} lg={8} xl={6} className={`flex jc-center ai-center fd-column ${styles.popUpsMode}`}>
                <div className={`${styles.popClose}`}  onClick={() => onChange && onChange(false)}>close</div>
              
                <h3 className={`${styles.logTitle}`}>开启盲盒</h3>
                
                <Row className={`pt-40 ${styles.logMain}`}>
                    
                    <Col lg={10} lgOffset={1} className={`mb-40`}>
                       <div className={`flex ai-center`}>
                           <div className={`pl-10`}>
                                <LazyLoad><img src={ipfsUrl + itemData.Image } alt='' width={120} height={120} /></LazyLoad>
                            </div>
                            <div className={`pl-30 flex-1`}>
                                <div className={`flex ai-center`}>
                                    <h3 className={`font-18 `}>{itemData.NftName}</h3>
                                </div>
                      
                                <div className={`flex ai-center mt-30`}>
                                    <div className={`col-666 font-14`}>拥有数量</div>
                                    <h3 className={`font-18 ml-15`}>{itemData.Amount}</h3>
                                </div>
                                <div className={`flex ai-center mt-10`}>
                                    <div className={`col-666 font-14`}>开启数量</div>
                                    <input type='number' placeholder={'输入开启数量'} min={1} max={itemData.Amount<10?itemData.Amount:10}  className={`flex-1 ml-15 ${styles.logTex} `} value={amount} onChange={e =>{
                                        changAmount(e.target.value);
                                    }} />
                                </div>
                                </div>
                            </div>
                    </Col> 
                    <Col lg={10} lgOffset={1} className={`pt-40 pb-40 ${styles.modeBtnTop}`}>
                        <div className={`col-red font-12 pb-15 pl-15`}>注意：每次盲盒最大开启数量为10个，开启后不可恢复。</div>
                        <Row>
                            <Col md={4}>
                                <button className={`${styles.medalBtn} ${styles.medalCancelBtn}`} onClick={() => onChange && onChange(false)}>取消</button>
                            </Col>
                            <Col md={8}>
                                <button className={`${styles.medalBtn}`}  onClick={() => onChange && onChange(itemData,amount)}>开启盲盒</button>
                            </Col>
                        </Row>
                    </Col>
                </Row> 
               
            </Col>
            
        </div>
    )
}
export default NFTDetail;