/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import RcPagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';

export default (props: any) => {
  return (
      <div>
        <RcPagination {...props}
          hideOnSinglePage
          showQuickJumper />
      </div>
  )
}