/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { useHistory } from "react-router-dom";
import Qs from 'qs';
import Select from 'react-select';
import LazyLoad from 'react-lazyload';
import { topCollectList } from '../../api/public'
import Pagination from '../../components/Pagination';
import styles from "./index.module.less"
import errorIconImg from '../../assets/images/noHeadImg.png'

import Empty from '../../components/Empty';

// 切换语言
import { useTranslation } from 'react-i18next';
import { simpleFn } from '../../components/Notification/index';


const noImgList = ['noImg1.png', 'noImg2.png', 'noImg3.png'];
const baseUrl = process.env.BASE_URL+"/ipfs/";
const Brand: React.FC<{ data: object }> = (props: any) => {
  // console.log("queryData123================", props)
  const {t} = useTranslation();
  const history = useHistory();
  const [brandList,setBrandList] =useState<any>([]);

  const getBrandList = async ()=>{
    const res = await topCollectList({})
    console.log(res)
    if(res.code===0){
      setBrandList(res.data)
    }
  }
  const outputMate = (list:any,type:string)=>{
    let item = list.filter((item:any)=>item.trait_type===type)[0]
    return item.value
  }
  const goSort=(e:string)=>{
    history.push('/brand/sort?did='+e)
  }
  useEffect(()=>{
    getBrandList()
  },[])
  return (
    <>
      <div className={`flex ai-center jc-center ${styles.brandBanner}`}>
          <div className={`col-fff text-center`}>
            <h3 className={`font-46`}>诚邀品牌入驻</h3>
            <p className={`font-32 pt-60`}>创新笃行，赋能产业，为各大品牌开启元宇宙之门。</p>
            {/* <button className={`mt-40 ${styles.bannerBtn}`}>{`参加入驻 >`}</button> */}
          </div>
      </div>
      <Col lg={10} lgOffset={1} className={`pt-60 pb-60`}>

        
        <Row className={`${styles.exploreRow}`}>
           {brandList.map((item:any)=>{
            return (<Col lg={4} md={6} sm={6} className={`mb-30 pl-15 pr-15`} key={item.sceneId}>
              <div className={`pointer ${styles.brandMain}`} onClick={()=>goSort(item.sceneId)}>
                  <div className={` ${styles.brandItem}`}>
                        <LazyLoad>
                          <img src={baseUrl+ item.banner} alt='' key={item} className={`${styles.brandItemBanner}`} onError={(e: any) => { e.target.onerror = null; e.target.src = require('../../assets/images/noImg1.png') }}/>
                        </LazyLoad>
                        <div className={`${styles.brandItemHeading}`}>
                        <LazyLoad>
                          <img src={ baseUrl + item.logo} alt=''  onError={(e: any) => { e.target.onerror = null; e.target.src = require('../../assets/images/noImg3.png') }} />
                        </LazyLoad>
                          <h3 className={`pt-35 font-16`}>{item.name}</h3>
                          <Row className={`pt-15 col-blue font-14`}>
                            {/* <Col xs={6} className={`${styles.itemLeft}`}>共 {item.SeriesCount} 个系列</Col> */}
                            <Col xs={12}>共 {item.issued} 个数字作品</Col>
                          </Row>
                        </div>
                        <div className={`font-14 col-666 pt-30 text-center ellipsis-3 lh-20`}>{item.desc}</div>
                  </div>
              </div>
            </Col>)})
            } 
        </Row>
      

      </Col>
    </>
  );
}

export default Brand;


