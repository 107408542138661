/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-restricted-globals */
/* eslint-disable react/style-prop-object */
/* eslint-disable react-hooks/rules-of-hooks */

import React,{useState,useEffect}  from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import { Link,useHistory,useLocation } from 'react-router-dom';
import Qs from 'qs';

// 切换语言
import { useTranslation } from 'react-i18next';

// 主题
import ThemeColor from '../Theme/ThemeColor'

//Login
import Login from '../User/Login';
// import Verified from '../Verified'

import ReactTooltip from 'react-tooltip';

import {contractInfo} from '../../api/public'

import Select from 'react-select';

import CryptoJS from "crypto-js";
const SECRET_KEY = CryptoJS.enc.Utf8.parse("f097eae0d2c86d3b");
// css
require("./TopMenu.less");

// const { t, i18n} = useTranslation();
// 左边导航
const MenuLeft  = ({style,onChange}:any) => {
    const { t } = useTranslation();
    const history = useHistory();
    const resourcesNavList =[
        {
            url:"/download",
            title:"Download",
            icon:"DAO",
            externa:false
        },
        {
            url:"http://scan.verseport.com",
            title:"Browser",
            icon:"DAO",
            externa:true
        },
        {
            url:"https://web3.verseport.com/developer/",
            title:"Docs",
            icon:"Docs",
            externa:true
        },
        
        // {
        //     url:"https://www.tally.xyz/governance/eip155:80001:0xc73AAaf50215cB8e3d7a60d8BC0582FA57ecFD3d",
        //     title:"DAO",
        //     icon:"DAO",
        //     externa:true
        // },
        // 1 ,必读指南 // 2 ，帮助中心 // 3 联系客服 // 4 用户协议 // 5 隐私政策 // 6 关于我们 // 7 当前版本
       
        {
            url:'/help?type=8',
            title:'Version',
            icon:"About",
            externa:false
        },
        {
            url:'/help?type=2',
            title:'Help',
            icon:"About",
            externa:false
        },
        {
            url:"https://verseport-pro.oss-cn-shenzhen.aliyuncs.com/doc/Star_World_of_Verseport_white_paper_V1.0.pdf",
            title:"WhiteBook",
            icon:"DAO",
            externa:true
        },
        {
            url:'https://verseport-pro.oss-cn-shenzhen.aliyuncs.com/doc/Verseport_blue_paper_V1.1.pdf',
            title:"BlueBook",
            icon:"DAO",
            externa:true
        },
        {
            url:'/help?type=6',
            title:'About',
            icon:"About",
            externa:false
        },
        
    ]
    const exploreNavList =[
        
        {
            url:"/brand",
            title:"品牌馆",
            icon:"DAO",
            externa:false,
        },
        {
            url:"/",
            title:"市场",
            icon:"DAO",
            externa:false
        },
       
    ]
    const goExternalLink=(e:any)=>{
        console.log(e)
        if(e.externa){

            window.open(e.url)
        }else{
           
            goExploreLink(e);
            window.location.reload() 
        }
    }
    const goExploreLink=(e:any)=>{
        console.log(e)
        history.push(e.url)
    }
    const goApps=()=>{

        const socialDataToken = sessionStorage.getItem('socialDataToken')
        const s = encrypt(socialDataToken)
        const p = sessionStorage.getItem('p');
        const UserName = sessionStorage.getItem('userName');
        const u = encrypt(UserName);
        const t = encrypt(new Date().getTime().toString());
        const isUni = encrypt('1');
        const ep = encrypt(`s=${s}&p=${p}&u=${u}&uni=${isUni}&t=${t}`)
        
        window.open(process.env.BASE_URL +'/hall/star/?'+ep);
    }
    const goEdit=()=>{
        const s = sessionStorage.getItem('pw')
        const p = encrypt(sessionStorage.getItem('userMobile'));
        const t = encrypt(new Date().getTime().toString());
        const isUni = '1';
        const ep = encrypt(`s=${s}&p=${p}&uni=${isUni}&t=${t}`)
        
        window.open(process.env.BASE_URL +'/sedit/user/login?'+ep);
        // window.open('http://localhost:8000/sedit/user/login?'+ep)
    }
    // 加密
    const encrypt = (data: any)=> {
        if (typeof data === "object") {
            try {
                // eslint-disable-next-line no-param-reassign
                data = JSON.stringify(data);
            } catch (error) {
                console.log("encrypt error:", error);
            }
        }
        const dataHex = CryptoJS.enc.Utf8.parse(data);
        const encrypted = CryptoJS.AES.encrypt(dataHex, SECRET_KEY, {
            iv: SECRET_KEY,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        return encrypted.ciphertext.toString();
    }




    return (
        <Col xs={style?12:6} className={`${style?style:'flex jc-space-between ai-center navMenuLeft'} link${primaryColor}`}>
            <Link to="/brand" onClick={()=> onChange && onChange()}>品牌馆</Link>
            {/* <div className={`userNavMenu`}>
                <div className={`flex jc-center ai-center userBtn`}  onClick={()=>goExploreLink({url:'/explore'})}>
                    {t('methods.explore')}
                </div>
               
                <div className={`${primaryColor}UserLinkNav userLinkNav navLink`}>
                    
                    {exploreNavList.map((item,index)=>{
                        return  (
                            <div className={`navItem`} onClick={()=>goExploreLink(item)} key={item.title}>
                                
                                {item.title}
                            </div>
                        ) 
                    })}
                    
                </div>
            </div> */}
            {/* <Link to="/create" onClick={()=> onChange && onChange()}>{t('methods.create')}</Link> */}
            <div className={`userNavMenu`}>
                <div className={`flex jc-center ai-center userBtn`}>
                    {t('methods.apps')}
                </div>
                 
                <div className={`${primaryColor}UserLinkNav userLinkNav navLink`}>
                    <div className={`navItem`} onClick={()=>goApps()}>星世界</div>
                    <div className={`navItem`} onClick={()=>goEdit()}>场景编辑器</div>
                </div>
            </div>
            {/* <Link to="/apps" onClick={()=> onChange && onChange()}>{t('methods.apps')}</Link> */}
            
            {/* <Link to="/resources" onClick={()=> onChange && onChange()}>{t('methods.resources')}</Link> */}
            <div className={`userNavMenu`}>
                <div className={`flex jc-center ai-center userBtn`}>
                    {t('methods.resources')}
                </div>
                
                <div className={`${primaryColor}UserLinkNav userLinkNav navLink`}>
                    {/* NavItem */}
                    {resourcesNavList.map((item,index)=>{
                        return  (
                            <div className={`navItem`} onClick={()=>goExternalLink(item)} key={item.title}>
                                {/* <img src={require('../../assets/images/menu/'+item.icon+'-' + primaryColor + '-icon.png')} className={`userNavIcon`} alt={item.title} />
                                <img src={require('../../assets/images/menu/'+item.icon+'-hover-icon.png')}  className={`userNavIconHover`} alt={item.title} /> */}
                            {t(`methods.${item.title}`)}</div>
                        ) 
                    })}
                    
                </div>
            </div>

            <Link to={{pathname:'/stats',state:{type:1}}} onClick={()=> onChange && onChange()}>{t('methods.stats')}</Link>
        </Col>
    );
};

// 导航右边

// 主题
const themePrimaryColor = 'theme';
const changeThemeSetting = (key: any, color: any) => {
  // cookie.set(themePrimaryColor, key);
  localStorage.setItem(themePrimaryColor,key)
//   console.log("颜色",color,key)
  document.body.className = key+'BodyBg';
  window.location.reload();
};
const primaryColor =  localStorage.getItem(themePrimaryColor)||'light';
document.body.className = primaryColor+'BodyBg';

const language = localStorage.getItem('localesLng')||'en';



// const isLogin = sessionStorage.getItem('userName');

const MenuRight = ({showNavMenu,onChange}:any) => {

    const urlParams = location.search.slice(1);
    const params = Qs.parse(urlParams);
//   console.log('params=======================', )








    const { t, i18n} = useTranslation();

    const history = useHistory()

    const languageFn= (lang:string)=>{
        
        i18n.changeLanguage(lang)
        window.location.reload();
        
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isShowPop,changeShowPop] = useState(false);
    // const [isShowReal,setIsShowReal] = useState(false);
    // useEffect(()=>{
    //     sessionStorage.getItem('userInfo')!== null && JSON.parse(sessionStorage.getItem('userInfo')||'').authStatus ===0? setIsShowReal(true) :setIsShowReal(false);
    // },[])
    
    // 是否已登陆
    const [isLogin,changeLogin] = useState(sessionStorage.getItem('userName'));
    const handleInClick=(data:boolean)=>{
        changeShowPop(!isShowPop);
        // const phoneUser = JSON.parse().phone
        changeLogin(sessionStorage.getItem('userName'));
        // console.log('=====================',isShowPop,data,sessionStorage.getItem('userInfo')!==null , sessionStorage.getItem('userName'),sessionStorage.getItem('userName')===null)

    

        // data && sessionStorage.getItem('userInfo')!== null && sessionStorage.getItem('userName')===''?setIsShowReal(true):setIsShowReal(false);


        if(!isShowPop){
            document.body.style.overflow = 'hidden';
        }else{
            document.body.style.overflow = 'auto';
        }
    }
    const logOut=()=>{
        changeLogin('')
        sessionStorage.clear();
        // setIsShowReal(false);
        window.location.href='/'
    }

    const [userDataObj,setUserDataObj] = useState<object>({})
    const selectOptions = [
        { value: "en", label: 'EN' },
        { value: "zh", label: '中文' },
    ]
   const changeQuery=(e:any)=>{
        languageFn(e.event)
   }
//    useLocation()
//    let useLocation:any = useLocation();

    const urlLocation:any = useLocation().state;
    const [searchInput,setSearchInput] = useState<string>((urlLocation && urlLocation.key) || '')
    const onKeyDownchange = (e:any) => {
        
            if(e.keyCode === 13){
                history.push("/explore", {'key':e.target.value})
            }
        }
    return (
        <>
            <Col xs={6} className={`flex jc-space-between ai-center navMenuRight link${primaryColor}`}>

                <div className=' jc-space-between ai-center menuNavAbbreviation'>
                  {showNavMenu?
                    <img alt="menu" src={require('../../assets/images/menu/nav-close-' + primaryColor + '.png')} className="menuNavBtn" onClick={() => onChange && onChange()}  />
                    :
                    <img alt="menu" src={require('../../assets/images/menu/nav-' + primaryColor + '.png')} className="menuNavBtn" onClick={() => onChange && onChange()} />
                    } 
                </div>     
                
                <div className={`flex jc-space-between ai-center search  search${primaryColor}`}>
                    {/* <input className='searchInput' placeholder={t('methods.search')} value={searchInput} onChange={e=>setSearchInput(e.target.value)} disabled={true}  onKeyDown={e=> onKeyDownchange(e)}  />
                    <i className='searchIcon'></i> */}
                </div>
                <>
                <div className='flex ai-center jc-center modeBtn'>
                    
                    <div data-tip={`${primaryColor==='light'?t('methods.darkMode'):t('methods.lightMode')}`}><ThemeColor value={primaryColor} onChange={(key: any, color: any) => changeThemeSetting(key, color)} /></div>
                    {/* <div className={`flex ai-center jc-flex-end line line${primaryColor}`}></div> */}
                    {/* <div className=''>
                        <Select options={selectOptions} defaultValue={selectOptions.filter(item=>language===item.value)[0]} onChange={(e: any) => { changeQuery({ event: e.value }) }} isSearchable={false} styles={{
                            control:(base) => ({
                                ...base,
                                background:'#E6EFFE',
                                border:'none',
                                margin:'0 10px',
                                minWidth:'88px',
                                borderRadius: '10px',
                                minHeight: '30px',
                                '> div:first-of-type':{
                                    
                                    height:"30px",
                                    "> div":{
                                        minWidth:'32px',
                                        textOverflow: 'initial'
                                    }
                                    
                                }
                            })
                        }} />                
                    </div> */}
                </div>
                    
                {isLogin!==null?
                    <UserBtn onChange={() => logOut()} data={userDataObj} />:    
                    <button className={`btn blue-btn loginBtn`} onClick={()=> handleInClick(false) }>{t('methods.login')}</button>
                }                            
                </>                                 
            </Col>
            {isShowPop?<Login onChange={()=> {handleInClick(true);window.location.reload();}} userData={(e:any)=>{setUserDataObj(e);window.location.reload();}} />:null}
            <ReactTooltip backgroundColor={`${primaryColor==='light'?'#E6EFFE':'#1A1A1A'}`} textColor={`${primaryColor==='light'?'#333':'#fff'}`} />
            {/* {isShowReal?<Verified onChange={()=> setIsShowReal(false)} />:null} */}
        </>
    );
};

const UserBtn =({onChange,data}:any)=>{
    const { t } = useTranslation();
    console.log(data)
    const getUserInfo:any = sessionStorage.getItem('userInfo')
    let userDataObj = JSON.parse(getUserInfo) || data;
   

    const navList =[
        {
            url:"/profile",
            title:"Profile",
            icon:"Profile"
        },
        // {
        //     url:"/achievements",
        //     title:"Achievements",
        //     icon:"Achievements"
        // },
        // {
        //     url:"/",
        //     title:"My Collections",
        //     icon:"MyCollections"
        // },
        {
            url:"/fuel",
            title:"Fuel",
            icon:"MyFuel"
        },
        // {
        //     url:"/bill",
        //     title:"My Bill",
        //     icon:"MyBill"
        // },
        {
            url:"/order",
            title:"myOrder",
            icon:"Order"  
        },
        {
            url:"/give",
            title:"myGive",
            icon:"Give"  
        },
        {
            url:"/settings",
            title:"Settings",
            icon:"Settings"
        },
        // {
        //     url:"/collection",
        //     title:"My Collections",
        //     icon:"Collection"
        // },
        
    ]

    return(
        <>
            <div className={`userNavMenu`}>
                <div className={`flex jc-center ai-center userBtn`}>
                    <img src={userDataObj.avatar && userDataObj.baseUrl ?(userDataObj.baseUrl+userDataObj.avatar):require('../../assets/icon/mine/avatar.png')}  className={`userAvatar`}  onError={(e: any) => { e.target.onerror = null; e.target.src = require('../../assets/icon/mine/avatar.png') }} alt={`user`} />
                </div>
                
                <div className={`${primaryColor}UserLinkNav userLinkNav`}>
                    {/* NavItem */}
                    {navList.map((item,index)=>{
                        return  (
                            <Link to={item.url} key={item.title}>
                                <img src={require('../../assets/icon/mine/'+item.icon+'-' + primaryColor + '-icon.png')} className={`userNavIcon`} alt={item.title} width={20} height={20} />
                                <img src={require('../../assets/icon/mine/'+item.icon+'-hover-icon.png')}  className={`userNavIconHover`} alt={item.title} width={20} height={20} />
                            {t(`methods.${item.title}`)}</Link>
                        ) 
                    })}
                    
                    
                    <div className={`pointer logOutBtn`} onClick={()=> onChange && onChange()}>
                        <img src={require('../../assets/icon/mine/logout-' + primaryColor + '-icon.png')} className={`userNavIcon`}  alt={`Log Out`} width={20} height={20}/>
                        <img src={require('../../assets/icon/mine/logout-hover-icon.png')}  className={`userNavIconHover`}  alt={`Log Out`} width={20} height={20} />
                        {t('methods.logout')}
                    </div>
                </div>
            </div>
            
        </>
                    
    )
}


const Menu : React.FC =()=>{
    const [showNavMenu,changeShowNavMenu] = useState(false);
    const handleNav=()=>{
        changeShowNavMenu(!showNavMenu)
    }
    console.log("=============================userLogin",sessionStorage.getItem('userName'));
    
    return (
        <>
        <Row className={`nav nav${primaryColor}`}  middle="xs">
            <Col xs={12} sm={12} md={12} lg={10}>
                <Row between="xs">
                    <Col className={`flex jc-center ai-center`}>
                        <Link className={`logoImg`} to="/">
                           {process.env.NODE_ENV === 'development'? 
                           <img alt="Verseport" src={require('../../assets/images/menu/Testnet.png')} style={{width:'230px'}}  />: 
                           <img alt="Verseport" src={require('../../assets/images/logo-' + primaryColor + '.png')} style={{width:'230px'}} />}

                        </Link>
                    </Col> 
                    <Col xs sm md lg className={`flex jc-space-between navMenu${primaryColor}`} style={{padding:0}}>
                        <div className={`flex jc-space-between navMenu`}>
                            <MenuLeft />
                            <MenuRight showNavMenu={showNavMenu} onChange={() => handleNav()} />
                        </div>
                    </Col> 

                </Row>
            </Col>
        </Row>
        {showNavMenu?<NavMain onChange={() => handleNav()}  />:null}
        
        </>
    )
}

const NavMain =({onChange}:any)=>{
    return (
        <div className={`NavMain`}>
            <div className={`NavMenuBg`} onClick={() => onChange && onChange()}>背景</div>
            <MenuLeft style='NavMenu' onChange={()=>onChange && onChange()} />
        </div>
    )
}

const  menu =({style}:any)=>{

    //暂时不用
  const getContractInfo= async(id:string)=>{
    if(sessionStorage.getItem(id)){
        return false;
    }
    const res = await contractInfo({destID:id})
    if(res.code===0){
    //   res.data.destID===2067?abiKey=res.data.contractAddress:userAbiKey=res.data.contractAddress;
      sessionStorage.setItem(id,JSON.stringify(res.data))
    }
  }
//   useEffect(()=>{
//     getContractInfo(process.env.USER_DES_ID||'67');
//     getContractInfo(process.env.DES_ID||'70');
//   },[process.env.USER_DES_ID,process.env.DES_ID])


    return (
        <>
            
            <Grid  className={`main`} style={style}>
               <Menu />    
            </Grid>
        </>
    )
}
export default menu;