import Web3 from 'web3';
import React, { useEffect, useState } from 'react';
import { deleteCollection, getUserCollectionList, nftlistbycollect } from '../../api/public';
import { Col, Row } from 'react-flexbox-grid';
import styles from './index.module.less';
import { useTranslation } from 'react-i18next';
import Empty from '../../components/Empty';
import LazyLoad from 'react-lazyload';
import { useHistory } from 'react-router-dom';

const web3 = new Web3(window.ethereum);

const CreateCollection = () => {
  const [collList, setCollList] = useState([]);
  const [baseUrl, setBaseUrl] = useState(process.env.BASE_URL+'/ipfs/');
  const { t } = useTranslation();

  const noImgList = ['noImg1.png', 'noImg2.png', 'noImg3.png']

  let history = useHistory();
  const GetUserCollList = async () => {
    const UserName = sessionStorage.getItem('userName');
    const res = await getUserCollectionList({ userName: UserName })
    for (let i = 0; i < res.data.items.length; i++) {
      res.data.items[i].metaDataSystem = JSON.parse(res.data.items[i].metaDataSystem);
      const NFTresult:any = await nftlistbycollect({
        'dataChainUUID': res.data.items[i].dataChainUUID
      });
      console.log(NFTresult);
      res.data.items[i].count = NFTresult.data.count;
    }
    setCollList(res.data.items);
  }

  const DeleteCollection = async (val: string) => {
    const UserName = sessionStorage.getItem('userName');
    const DataChainUUID = val;
    const TimeStamp = Math.round(+new Date());
    const SocialDataToken = sessionStorage.getItem('socialDataToken');
    const strSeriesConnMsg = `${UserName}${SocialDataToken}${TimeStamp}`;
    const MsgKecak256 = web3.utils.keccak256(`\u0019Ethereum Signed Message:\n${new Blob([strSeriesConnMsg]).size}${strSeriesConnMsg
      }`);
    console.log(DataChainUUID.length)
    const res = await deleteCollection({
      "userName": UserName,
      "dataChainUUID": DataChainUUID,
      "timeStamp": TimeStamp,
      "msgKeccak256": MsgKecak256.substr(2)
    });

    if (res.code == 0) {
      console.log("集合删除成功")
    } else {
      console.log("集合删除失败")
    }
  }

  const getCollNFTCount = async (val: string) => {
    console.log('val', val)
    const result = await nftlistbycollect({
      'dataChainUUID': val
    });
    console.log(result);
  }

  useEffect(() => {
    GetUserCollList()
  }, [])

  return (
    <Col xs={12} sm={10} md={10} lg={8} smOffset={1} mdOffset={1} lgOffset={2}>
      <div className={`flex fd-column ${styles.settings}`}>
        <h1 className={`${styles.title}`}>{t('methods.myColls')}</h1>
        <div>{t('methods.myCollsDesc')}</div>
        <div style={{
          justifyContent: 'flex-start',
          margin: '24px 0px',
          display: 'flex',
        }}>
          <div style={{
            fontWeight: 400,
            fontSize: '16px',
            color: 'rgb(112, 122, 131)'
          }}>
            <a className={`${styles.btn}`} onClick={() => {

              history.push(`/createCollection`);
            }}>{t('methods.createColl')}</a>
          </div>
        </div>
      </div>
      {(collList.length != 0) ?
        <Col lg={12}>
          <Row className={`${styles.exploreRow}`}>
            {collList.length > 0 ? collList.map((item: any, index) => {
              console.log(item)
              return (
                <Col xl={4} lg={4} md={6} sm={6} xs={6} className={`pointer ${styles.exploreItem}`} key={index}>
                  <div className={`${styles.card}`}>
                    <div className={`flex jc-flex-end`}>
                      <div className={`${styles.mask}`} onClick={() => {
                        DeleteCollection(item.dataChainUUID);
                        console.log(item)
                      }}>
                        <img src={require('../../assets/images/collection/more.png')} />
                      </div>
                    </div>
                    <LazyLoad className={`${styles.image}`}>
                      <img className={`${styles.childImg}`} src={item.metaDataUser.attributes[11].value} alt="" onError={(e: any) => { e.target.onerror = null; e.target.src = require('../../assets/images/' + noImgList[Math.floor(Math.random() * noImgList.length)]) }} />
                    </LazyLoad>
                    <div className={`${styles.avatarDiv}`}>
                      <img style={{ objectFit: 'cover', width: '100%', height: '100%', borderRadius: '50%' }} src={item.metaDataUser.attributes[10].value} />
                    </div>
                    <Row between="xs" className={`${styles.cardTips}`}>
                      <Col xs={12} className={`fd-row jc-center flex ai-center`}  >
                        <div className={`${styles.nftName}`}>
                          {item.metaDataUser.name}
                        </div>
                        <img src={require('../../assets/images/collection/aut.png')} />
                      </Col>
                      <Col xs={12} className={`fd-column flex ai-center ${styles.exploreColl}`} >
                        <span>{t('methods.exploreThe')} {item.metaDataUser.name} {t('methods.collection')}</span>
                      </Col>
                      <Col xs={12} className={`fd-column flex ai-center ${styles.exploreColl}`} >
                        <span>{item.count} {t('methods.items')}</span>
                      </Col>
                    </Row>
                  </div>
                </Col>)
            }) : <></>
            }
          </Row>

        </Col> :
        <Empty
          imageStyle={{
            width: 480,
            height: 'auto',
            display: 'block'
          }}
          description={
            <p style={{ textAlign: 'center', margin: '24px 0px', fontSize: '24px', color: '#9f9f9f' }}>{t(`methods.noData`)}</p>
          }
          image={require('../../assets/icon/noData.png')}
        />
      }
    </Col>
  )
}

export default CreateCollection;