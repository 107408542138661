import Web3 from 'web3';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from "../index.module.less"
import { uploadfile } from '../../../api/public';
// let ipfsKey="Verseport IPFS";

let ipfsKey="GkMrWfnKM2oL7zZ";
const web3 = new Web3(window.ethereum);

interface Props {
  onChange: any;
  status: any;
  changeStatus: any;
  changePreStatus: any;
  changeThumb: (val: any, type: any) => void
}

const UploadFileCompo: React.FC<Props> = ({ onChange, status, changeStatus, changeThumb, changePreStatus }: any) => {
  const [fileItem, setFileItem] = useState<any>();
  const [previewImg, setPreviewImg] = useState<any>();
  const [fileSrc, setFileSrc] = useState<any>();
  const hiddenFileInput = React.useRef<any>(null);
  const previewFileInput = React.useRef<any>(null);
  const { t } = useTranslation();

  // 获取视频基本信息
  const getVideoBasicInfo = (videoSrc: any) => {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video')
      video.src = videoSrc
      // 视频一定要添加预加载
      video.preload = 'auto'
      // 视频一定要同源或者必须允许跨域
      video.crossOrigin = 'Anonymous'
      // 监听：异常
      video.addEventListener('error', error => {
        reject(error)
      })
      // 监听：加载完成基本信息,设置要播放的时常
      video.addEventListener('loadedmetadata', () => {
        const videoInfo = {
          video,
          width: video.videoWidth,
          height: video.videoHeight,
          duration: video.duration
        }
        resolve(videoInfo)
      })
    })
  }

  // 获取视频当前帧图像信息与饱和度
  const getVideoPosterInfo = (videoInfo: any) => {
    return new Promise(resolve => {
      const { video, width, height } = videoInfo
      video.addEventListener('canplay', () => {
        const canvas = document.createElement('canvas')
        canvas.width = width
        canvas.height = height
        const ctx: any = canvas.getContext('2d')
        // 将视频对象直接绘入canvas
        ctx.drawImage(video, 0, 0, width, height)
        // 获取图像的整体平均饱和度
        const posterUrl = canvas.toDataURL('image/jpg')
        resolve({ posterUrl })
      })
    })
  }


  // 根据视频地址与播放时长获取图片信息与图片平均饱和度
  const getVideoPosterByFrame = async (videoSrc: any, targetTime: any) => {
    const videoInfo: any = await getVideoBasicInfo(videoSrc);
    const { video, duration } = videoInfo;
    video.currentTime = targetTime;
    return await getVideoPosterInfo(videoInfo);
  }

  const UploadFile = async (val: any, fileType: any) => {
    console.log('上传视频缩略图')
    const TimeStamp = Math.round(+new Date());
    const strSeriesConnMsg = web3.utils.keccak256(ipfsKey).substr(2) + TimeStamp;
    const MsgKeccak256 = web3.utils.keccak256("\u0019Ethereum Signed Message:\n" + new Blob([strSeriesConnMsg]).size + strSeriesConnMsg);
    const formData: any = new FormData();
    
    formData.append("size", 350);
    formData.append("thumbnail", 1);
    formData.append("file", val);
    formData.append("msgKeccak256", MsgKeccak256.substr(2));
    formData.append("timeStamp", TimeStamp);

    const res = await uploadfile(formData);
    if (res.code == 0) {
      // res.data.thumbHash
      changeThumb(res.data.thumbHash, fileType);
      setPreviewImg(process.env.BASE_URL+'/ipfs/' + res.data.thumbHash);//https://vp.fanduola.com
      changePreStatus(2);
      console.log("文件上传成功")
    } else {
      console.log("文件上传失败")
    }
  }

  const base64ConvertFile = function (urlData: any) { // 64转file
    var arr = urlData.split(',')
    var type = arr[0].match(/:(.*?);/)[1]
    var fileExt = type.split('/')[1]
    var bstr = atob(arr[1])
    var n = bstr.length
    var u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr],  Math.round(+new Date())+ '.' + fileExt, {
      type: type
    });
  }

  // 选择文件
  const uploadClick = () => {
    hiddenFileInput.current.click();
  };
  // QmYQmzxKtfpkgPwVdTRkeeZm11kPAhsKvc68cNAVHamuHV
  const handleFileChange = async (event: any) => {
    if (event.target.files.length !== 0) {
      changeStatus(0);
      const fileUploaded = event.target.files[0];
      if (fileUploaded.type.split('/')[0] == 'audio') {
        changeThumb('QmYQmzxKtfpkgPwVdTRkeeZm11kPAhsKvc68cNAVHamuHV', 'audio/mp3')
        setPreviewImg(process.env.BASE_URL+'/ipfs/' + 'QmYQmzxKtfpkgPwVdTRkeeZm11kPAhsKvc68cNAVHamuHV');
        changePreStatus(2);
      } else if (fileUploaded.type.split('/')[0] == 'video') {
        let temp: any = await getVideoPosterByFrame(URL.createObjectURL(fileUploaded), 1);
        let videoFileItem = base64ConvertFile(temp.posterUrl);
        setPreviewImg(URL.createObjectURL(videoFileItem))
        UploadFile(videoFileItem, fileUploaded.type);
      } else if (fileUploaded.type.split('/')[0] == 'image') {
        changePreStatus(2);
      }
      setFileItem(fileUploaded)
      setFileSrc(URL.createObjectURL(fileUploaded))
      await onChange(fileUploaded)
    } else {
      // changeStatus(1);
    }
  };

  const previewUploadClick = () => {
    previewFileInput.current.click();
  };
  const previewFileChange = async (event: any) => {
    if (event.target.files.length !== 0) {
      const fileUploaded = event.target.files[0];
      UploadFile(fileUploaded, fileItem.type);
    }
  };
  return (
    <>
      <div className={`${styles.uploadModel}`} style={{ border: `3px dashed ${status == 1 ? 'rgb(219, 40, 40)' : 'rgb(204, 204, 204)'} ` }} onClick={uploadClick}>
        <input type='file'
          accept='image/jpg,image/webp,image/png,image/jpeg,image/gif,image/svg+xml,video/mp4,video/webm,audio/mp3,audio/wav,audio/ogg'
          style={{ display: 'none' }} ref={hiddenFileInput} onChange={handleFileChange} />
        {/* 未选择文件状态 */}
        {fileItem === undefined ? <img src={require('../../../assets/icon/image.png')} alt='' style={{ width: 84, height: 84 }} /> : ''}
        {/* 图片类型文件 */}
        {fileItem?.type.split('/')[0] === 'image' ? <img src={fileSrc} alt="" /> : ''}
        {/* 视频类型文件 */}
        {fileItem?.type.split('/')[0] === 'video' ? <video src={fileSrc} controls loop preload="auto"></video> : ''}
        {/* 音频类型文件 */}
        {fileItem?.type.split('/')[0] === 'audio' ? <audio style={{ width: '100%', height: '100%', backgroundImage: `url(${require('../../../assets/images/create/all.png')})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center' }} src={fileSrc} controls={true} loop={true} ></audio> : ''}
      </div>
      {status == 1 ? <div style={{ color: 'rgb(219, 40, 40)', marginTop: '12px' }}>{t('methods.checkFile')}</div> : ''}
      {(fileItem?.type.split('/')[0] === 'audio' && status != 1 && status != 0) ?
        <div className={`${styles.required} ${styles.field}`} style={{ marginTop: '24PX' }}>
          <label>{t('methods.previewImg')}</label>
          <p>{t('methods.previewImgDesc')}</p>
          <div className={`${styles.uploadModel}`} style={{ border: `3px dashed rgb(204, 204, 204)` }} onClick={previewUploadClick}>
            <input type='file'
              accept='image/png,image/jpg,image/jpeg,image/gif,image/svg+xml'
              style={{ display: 'none' }} ref={previewFileInput} onChange={previewFileChange} />
            {/* 未选择文件状态 */}
            {previewImg === undefined ? <img src={require('../../../assets/images/create/all.png')} alt='' style={{ width: '100%', height: '100%', objectFit: 'contain' }} /> :
              <img src={previewImg} alt="" />}
          </div>
        </div> : ''}
      {(fileItem?.type.split('/')[0] === 'video' && status != 1 && status != 0) ?
        <div className={`${styles.required} ${styles.field}`} style={{ marginTop: '24PX' }}>
          <label>{t('methods.previewImg')}</label>
          <p>{t('methods.previewImgDesc')}</p>
          <div className={`${styles.uploadModel}`} style={{ border: `3px dashed rgb(204, 204, 204)` }} onClick={previewUploadClick}>
            <input type='file'
              accept='image/png,image/jpg,image/jpeg,image/gif,image/svg+xml'
              style={{ display: 'none' }} ref={previewFileInput} onChange={previewFileChange} />
            {/* 未选择文件状态 */}
            {previewImg === undefined ? <img src={require('../../../assets/icon/image.png')} alt='' style={{ width: 84, height: 84 }} /> :
              <img src={previewImg} alt="" />}
          </div>
        </div> : ''}
    </>
  )
}

export default UploadFileCompo;