
import { Col } from 'react-flexbox-grid';
import styles from "./index.module.less"
import LazyLoad from 'react-lazyload';
import { useTranslation } from "react-i18next";
import ReactTooltip from 'react-tooltip';
import { simpleFn } from '../../../components/Notification';
import { useEffect, useState } from 'react';

// 转赠弹窗
const CreateModal = ({ onChange, itemData }: any) => {
	const { t } = useTranslation();
	const [videoImg, setVideoImg] = useState('');
	const [fileType, setFileType] = useState('');

	const copyText = (value: any) => {
    const textarea = document.createElement('textarea')
    textarea.readOnly = true
    textarea.style.position = 'absolute'
    textarea.style.left = '-9999px'
    textarea.value = value
    document.body.appendChild(textarea)
    // 选中值并复制
    textarea.select()
    textarea.setSelectionRange(0, textarea.value.length)
    const copyStatus = document.execCommand('Copy')
    document.body.removeChild(textarea)
    if (copyStatus) {
      simpleFn(t('methods.copySucc'), 'success');
    } else {
      simpleFn(t('methods.copyError'), 'error');
    }
  }


	  // 获取视频基本信息
		const getVideoBasicInfo = (videoSrc: any) => {
			return new Promise((resolve, reject) => {
				const video = document.createElement('video')
				video.src = videoSrc
				// 视频一定要添加预加载
				video.preload = 'auto'
				// 视频一定要同源或者必须允许跨域
				video.crossOrigin = 'Anonymous'
				// 监听：异常
				video.addEventListener('error', error => {
					reject(error)
				})
				// 监听：加载完成基本信息,设置要播放的时常
				video.addEventListener('loadedmetadata', () => {
					const videoInfo = {
						video,
						width: video.videoWidth,
						height: video.videoHeight,
						duration: video.duration
					}
					resolve(videoInfo)
				})
			})
		}
	
		// 获取视频当前帧图像信息与饱和度
		const getVideoPosterInfo = (videoInfo: any) => {
			return new Promise(resolve => {
				const { video, width, height } = videoInfo
				video.addEventListener('canplay', () => {
					const canvas = document.createElement('canvas')
					canvas.width = width
					canvas.height = height
					const ctx: any = canvas.getContext('2d')
					// 将视频对象直接绘入canvas
					ctx.drawImage(video, 0, 0, width, height)
					// 获取图像的整体平均饱和度
					const posterUrl = canvas.toDataURL('image/jpg')
					resolve({ posterUrl })
				})
			})
		}
	
		// 根据视频地址与播放时长获取图片信息与图片平均饱和度
		const getVideoPosterByFrame = async (videoSrc: any, targetTime: any) => {
			const videoInfo: any = await getVideoBasicInfo(videoSrc);
			const { video, duration } = videoInfo;
			video.currentTime = targetTime;
			return await getVideoPosterInfo(videoInfo);
		}

		useEffect(() => {
			setFileType(itemData.filetype.split('/')[0]);
			getVideoPosterByFrame(itemData.img_url, 1).then((res: any) => {
				setVideoImg(res.posterUrl);
			})
		}, [])

	return (
		<>
			<div className={`flex ai-center jc-center ${styles.popUps}`} >
				<div className={`${styles.popBg}`} onClick={() => onChange && onChange(false)}>背景</div>
				<Col xs={6} sm={6} lg={5} xl={4} className={`flex jc-center ai-center fd-column ${styles.popUpsMode}`}>
					<div className={`${styles.addMetaTitle}`}>
						<h4>{t('methods.created') + itemData.name + '!'}</h4></div>
					<div className={`${styles.popClose}`} onClick={() => { onChange && onChange(false) }} >close</div>
					<div className={`${styles.modalCtn}`}>
						<div className={`flex fd-column ai-center`}>
							<p>{t('methods.woot')} {itemData.name} !</p>
							<div style={{minHeight: '200px'}}>
								<LazyLoad className={``}>
									{fileType == 'video' ? <img className={`${styles.resultImg}`} src={videoImg} alt="" /> : ''}
									{fileType == 'image' ? <img className={`${styles.resultImg}`} src={itemData.img_url} alt="" /> : ''}
									{fileType == 'audio' ? <img className={`${styles.resultImg}`} src={require('../../../assets/icon/music.png')} alt="" /> : ''}
								</LazyLoad>
							</div>
							<div style={{ marginTop: '24px', fontWeight: 600, fontSize: 14, color: 'rgb(112, 122, 131)' }}>{t('methods.share')}</div>
						</div>
					</div>
					<div className={`flex jc-center ${styles.bottomBtn}`}>
						<a className={`${styles.btn}`} onClick={() => {
							window.open("https://twitter.com/intent/tweet?text=" + encodeURIComponent('Check out this item on Verseport') + "&url=" + encodeURIComponent(window.location.href));
						}}><img src={require('../../../assets/images/collection/twitter.png')} /></a>
						<a className={`${styles.btn}`} onClick={() => {
            window.open("https://www.facebook.com/sharer/sharer.php?u=" + encodeURI(window.location.href));
          }}><img src={require('../../../assets/images/collection/facebook.png')} /></a>
						<a className={`${styles.btn}`} onClick={() => {
            window.open("https://t.me/share/url?url=" + encodeURI(window.location.href));
          }}><img src={require('../../../assets/images/collection/telegram.png')} /></a>
						<a className={`${styles.btn}`} data-tip data-for='copy' onClick={() => {
            copyText(window.location.href);
          }}><img src={require('../../../assets/images/collection/copylink.png')} /></a>
					</div>
				</Col>
			</div>
			<ReactTooltip id='copy' type='info'>
        <span>copy</span>
      </ReactTooltip>
		</>
	)
}

export default CreateModal;