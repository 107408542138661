
import React from 'react';
// 切换语言
import { useTranslation } from 'react-i18next';

const FormatMsgTime =({data}:any)=>{
    //申明语言转换
    const {t} = useTranslation();

    const dateTime = new Date(data) // 将传进来的字符串或者毫秒转为标准时间
    const year = dateTime.getFullYear()
    const month = dateTime.getMonth() + 1<10?'0'+(dateTime.getMonth() + 1):dateTime.getMonth() + 1;
    const day = dateTime.getDate()<10?'0'+(dateTime.getDate()):dateTime.getDate();
    const hour = dateTime.getHours()<10?'0'+dateTime.getHours():dateTime.getHours();
    const minute = dateTime.getMinutes()<10?'0'+dateTime.getMinutes():dateTime.getMinutes();
    const second = dateTime.getSeconds()<10?'0'+dateTime.getSeconds():dateTime.getSeconds();
    const millisecond = dateTime.getTime() // 将当前编辑的时间转换为毫秒
    const now = new Date() // 获取本机当前的时间
    const nowNew = now.getTime() // 将本机的时间转换为毫秒
    var milliseconds = 0
    var timeSpanStr
    milliseconds = nowNew - millisecond
    if (milliseconds <= 1000 * 60 * 1) { // 小于一分钟展示为刚刚
        timeSpanStr = t('methods.JustNow')
    } else if (1000 * 60 * 1 < milliseconds && milliseconds <= 1000 * 60 * 60) { // 大于一分钟小于一小时展示为分钟
        timeSpanStr = Math.round((milliseconds / (1000 * 60))) + t(`methods.MinutesAgo`)
    } else if (1000 * 60 * 60 * 1 < milliseconds && milliseconds <= 1000 * 60 * 60 * 24) { // 大于一小时小于一天展示为小时
        timeSpanStr = Math.round(milliseconds / (1000 * 60 * 60)) + t('methods.HoursAgo')
    } else if (1000 * 60 * 60 * 24 < milliseconds && milliseconds <= 1000 * 60 * 60 * 24 * 15) { // 大于一天小于十五天展示位天
        timeSpanStr = Math.round(milliseconds / (1000 * 60 * 60 * 24)) + t('methods.DaysAgo')
    } else if (milliseconds > 1000 * 60 * 60 * 24 * 15 && year === now.getFullYear()) {
        timeSpanStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second
    } else {
        timeSpanStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second
    }

    return (
        <>
            {timeSpanStr}
        </>
    )
}
export default FormatMsgTime;