
import React,{useState,useEffect}  from 'react';

import { Grid, Row, Col } from 'react-flexbox-grid';

import styles from './index.module.less';

// 切换语言
import { useTranslation } from 'react-i18next';
import LazyLoad from 'react-lazyload';


import { simpleFn } from '../../../../components/Notification';


import wxPay from '../../../../assets/images/explore/wxpay.svg';
import zfbPay from '../../../../assets/images/explore/zfb.svg';
import bankCardPay from '../../../../assets/images/explore/bankCard.svg'




const Pay =({onChange,data}:any)=>{
    console.log("==================Pay",data)
    const { t } = useTranslation();
    const changeDaoM = async (e:number)=>{
        
        onChange(e,data)
    }
    
    return (
        <div className={`flex ai-center jc-center ${styles.popUps}`} >
            {/*  onClick={() => onChange && onChange(false)} */}
            <div className={`${styles.popBg}`}>背景</div>
            <Col xs={10} sm={10} lg={8} xl={6} className={`flex jc-center ai-center fd-column ${styles.popUpsMode}`}>
                {/* <div className={`${styles.popClose}`}  onClick={() => onChange && onChange(false)}>close</div> */}
                <h3 className={`${styles.logTitle}`}>支付确认</h3>
                <Row className={`pt-60 ${styles.logMain}`}>
                   <Col lg={10} lgOffset={1} className={`mb-40 text-center`}>
                       <Row>
                           <Col xs={6}>
                                <button className={`btn gray-btn ${styles.orderPayBtn}`} onClick={()=>changeDaoM(1)}>支付遇到问题？</button>
                           </Col>
                           <Col xs={6}>
                                <button className={`btn blue-btn ${styles.orderPayBtn}`} onClick={()=>changeDaoM(2)}>我已支付</button>
                           </Col>
                           
                        </Row>
                    </Col>
                   
                </Row> 
               
            </Col>
            
        </div>
    )
}
export default Pay;