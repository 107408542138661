import React from 'react';
import BannerAnim, { Element } from 'rc-banner-anim';
import TweenOne from 'rc-tween-one';
import styles from './index.module.less';
import 'rc-banner-anim/assets/index.css';
const BgElement = Element.BgElement;
class Demo extends React.Component {
  render() {
    return (
      <BannerAnim prefixCls="banner-user" autoPlay style={{height: '500px', borderRadius: 20}}>
        <Element
          prefixCls="banner-user-elem"
          key="0"
          className={`${styles.bannerAnimElem}`}
        >
          <BgElement
            key="bg"
            className={`${styles.bannerAnimElem} ${styles.bg}`}
            style={{
              background: `url(${require('../../../assets/images/Achievements/cj-banner01.png')}) no-repeat` ,
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
              overflow: 'hidden',
              backgroundSize: 'cover',
              borderRadius: 20
            }}
          />
          <TweenOne className="banner-user-title" animation={{ y: 30, opacity: 0, type: 'from' }}>
          </TweenOne>
          <TweenOne className="banner-user-text"
            animation={{ y: 30, opacity: 0, type: 'from', delay: 100 }}
          ></TweenOne>
        </Element>
        <Element
          prefixCls="banner-user-elem"
          key="1"
        >
          <BgElement
            key="bg"
            className="bg"
            style={{
              background: `url(${require('../../../assets/images/Achievements/cj-banner02.png')}) no-repeat`,
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
              overflow: 'hidden',
              backgroundSize: 'cover',
              borderRadius: 20
            }}
          />
          <TweenOne className="banner-user-title" animation={{ y: 30, opacity: 0, type: 'from' }}></TweenOne>
          <TweenOne className="banner-user-text"
            animation={{ y: 30, opacity: 0, type: 'from', delay: 100 }}
          ></TweenOne>
        </Element>
      </BannerAnim>);
  }
}

export default Demo;