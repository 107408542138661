import { url } from "inspector";
import React from "react";
const styles = require("./ThemeColor.less");


const themePrimaryColor = 'theme';
let primaryColor =  localStorage.getItem(themePrimaryColor) || 'light';


const Tag = ({ color, check, ...rest }:any) => (

  <div
    {...rest}
    style={{width:'24px',height:'24px'}}
    className={`flex ai-conter jc-conter`}
  >
    <img alt="Verseport" src={require('../../assets/images/menu/mode-' + primaryColor + '-icon.png')}   />
  </div>
  
);
const ThemeColor = ({colors, value, onChange}:any) => {
  let colorList = colors;
  if (!colors) {
    colorList = [
      {
        key: 'light',
        color: '#fff',
      },
      {
        key: 'dark',
        color: '#000',
      }
    ]
  };
  return (
    <>
      
      {colorList.map(({ key, color }:any) => (
          value !== key?<Tag
              className={styles.colorBlock}
              color={color}
              key={key}
              check={value === key}
              onClick={() => onChange && onChange(key,color)}
            />:null
      ))}
    
    </>
  )
}






export default ThemeColor;