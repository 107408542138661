import React from 'react';
import Notification from 'rc-notification';
import 'rc-notification/assets/index.css';

let notification: any = null;
Notification.newInstance(
  {
    maxCount: 5,
    style: {
      top: 120,
      left: '50%',
      transform: `translate(-50%, 0px)`
    }
  },
  n => {
    notification = n;
  },
);
const boxStyle={
  lineHeight: '14px'
}
const imgStyle ={
  width: 14, 
  height: 14, 
  marginRight: '8px'
}
export const simpleFn = (content: any, type: any) => {
  notification.notice({
    duration: 3,
    content: <div className={`flex font-14`} style={boxStyle}>
      <img src={require(`../../assets/icon/${type}.png`)} alt={type} style={imgStyle}/>
      {content}
    </div>,
    onClose() {
      console.log('simple close');
    },
  });
}