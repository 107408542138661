import React, { useEffect, useRef, useState } from 'react';
import styles from "./index.module.less";
import { Grid, Row, Col } from 'react-flexbox-grid';
// import { Grid, Row, Col } from 'react-flexbox-grid';

// api
import { helpList,noticeList } from '../../api/public'

import { Link,useHistory,useLocation } from 'react-router-dom';

import Qs from 'qs'
// 主题
const themePrimaryColor = 'theme';
const primaryColor =  localStorage.getItem(themePrimaryColor)||'light';

const DownloadPage = (props: any)=>{
    const history = useHistory();
    
    return (
        <>
            <div className={`${styles.pageBg} flex ai-center`}>
                <Col md={10} mdOffset={1} lg={10} lgOffset={1} >
                    <div className={`flex`}>
                        <Col lg={8}>
                            <div>

                                <img src={require('../../assets/images/download/Logo.png')} width={'100%'} alt='' />
                            </div>
                            <div className={`flex mt-50`}>
                                <Col xs={6} xsOffset={1} className={`col-fff lh-32 font-18`}>Verseport平台归属于深圳番多拉信息科技有限公司，是一个专注于“一格一世界”的用户创意承载与沉浸式体验的Web3.0平台；突破全流程合规与数据确权等技术瓶颈，让用户真正获得数据资产的所有权和控制权。Verseport是国内Web3.0赛道创新最有成效、技术最为夯实的领跑者，将为艺术家、知名企业和产业开启元宇宙之门（MetaVerse portal）。</Col>
                                <Col xsOffset={1} xs={3}>
                                    <div className={`${styles.codeBox} pt-20 pb-20`}>
                                        <img src={require('../../assets/icon/code/android-code.png')}  alt='' />
                                        <p className={`col-blue mt-20`}>安卓扫码下载APP</p>
                                    </div>
                                    
                                </Col>
                            </div>
                        </Col>
                        <Col lg={4}>

                            <img src={require('../../assets/images/download/iPhone.png')} width={'100%'} />
                        </Col>
                    </div>
                </Col>
            </div>
        </>
    )
}
export default DownloadPage;