/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-restricted-globals */
/* eslint-disable react/style-prop-object */
/* eslint-disable react-hooks/rules-of-hooks */

import React,{useState,useEffect}  from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import { Link,useHistory,useLocation } from 'react-router-dom';
import Qs from 'qs';

// 切换语言
import { useTranslation } from 'react-i18next';

// 主题
import ThemeColor from '../Theme/ThemeColor'
// 样式
import styles from  "./index.module.less"

import LazyLoad from 'react-lazyload';
// api
import { getsevtime } from '../../api/public'

// const { t, i18n} = useTranslation();


const TimeContent = (props:any) => {
	// const [nowTime,setNowTime] = useState<number>(0)


	const getServerTime= async ()=>{
		const res =await getsevtime({})
		console.log(234,res)
		if(res.code===0){
			let dataTime = res.data.time
			// setNowTime(time);
			timeDownDOM(dataTime)
		}
	}
	useEffect(()=>{
		getServerTime()
	},[])

	const timeDownDOM=(time:number)=>{
		let startTime= time;
		clearInterval(timer);
		timer = setInterval(()=>{
			let sysSecond:number = (props.data + (5 * 60 * 1000)) - startTime;//计算剩余的毫秒数
			let minute = Math.floor(sysSecond / 1000  / 60 % 60);//计算剩余的分钟数
			let second = Math.floor(sysSecond / 1000  % 60);//计算剩余的秒数
			if(sysSecond>0){
				setDataState({
					minute: minute < 10 ? "0" + minute : minute,
					second: second < 10 ? "0" + second : second
				  })
				startTime = startTime + 1000;
			}else if(sysSecond<0){
				props.onChange()
				setDataState({
					minute:'00',
					second:'00'
				  })
				  clearInterval(timer)
			}
		},1000);
	}
	const [dataState,setDataState]  = useState<any>({
		day: '00',
		hour: '00',
		minute: '00',
		second: '00'
	  })
	let timer:any = null;
	return <>{dataState.minute}:{dataState.second}</>;
}


export default TimeContent;