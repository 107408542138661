
import React,{useState,useEffect  ,CSSProperties, ReactNode  }  from 'react';

import { Grid, Row, Col } from 'react-flexbox-grid';

import styles from './index.module.less';

// 切换语言
import { useTranslation } from 'react-i18next';

const Exchange =({onChange,title,content}:any)=>{
    console.log("==================content",content)
    const { t } = useTranslation();
    const changeDaoM = async (e:number)=>{
        
        onChange(e)
    }
    const [discount,setDiscount] = useState<number>(10)
    
    return (
        <div className={`flex ai-center jc-center ${styles.popUps}`} >
            {/*  onClick={() => onChange && onChange(false)} */}
            <div className={`${styles.popBg}`}>背景</div>
            <Col xs={10} sm={10} lg={8} xl={6} className={`flex jc-center ai-center fd-column ${styles.popUpsMode}`}>
                {/* <div className={`${styles.popClose}`}  onClick={() => onChange && onChange(false)}>close</div> */}
                <h3 className={`${styles.logTitle}`}>{title}</h3>
                <Row className={`pt-20 ${styles.logMain}`}>
                   <Col lg={10} lgOffset={1} className={`text-center`}>
                       <Row>
                            <Col xs={12} className={`pb-40`}>
                                {content}
                            </Col>
                           <Col xs={6}>
                                <button className={`btn gray-btn ${styles.orderPayBtn}`} onClick={()=>onChange(false)}>关闭</button>
                           </Col>
                           <Col xs={6}>
                                <button className={`btn blue-btn ${styles.orderPayBtn}`} onClick={()=>onChange(true)}>确定</button>
                           </Col>
                           
                        </Row>
                    </Col>
                   
                </Row> 
               
            </Col>
            
        </div>
    )
}
export default Exchange;