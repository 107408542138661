import React from 'react';
import { Row, Col } from 'react-flexbox-grid';

const MedalList: React.FC<any> = () => {
  return (
    <>
      <Row style={{ marginTop: '48px' }}>
        <Col xl={4} lg={4} md={6} sm={6} xs={12} style={{height: '160px'}}>
          <div className={`flex ai-center`}>
            <img src={require('../../../assets/images/Achievements/shhdj.png')} style={{position: 'absolute'}}/>
            <div style={{backgroundColor: '#FFF', height: '160px', width: '70%', marginLeft: 115, textAlign: 'center'}}>
              <p>11111111</p>
            </div>
          </div>
        </Col>
        <Col xl={4} lg={4} md={6} sm={6} xs={12}>
          <div>
            <img style={{ width: '50%', height: 'auto' }} src={require('../../../assets/images/Achievements/jcyr.png')} />
          </div>
        </Col>
        <Col xl={4} lg={4} md={6} sm={6} xs={12}>
          <div>
            <img style={{ width: '50%', height: 'auto' }} src={require('../../../assets/images/Achievements/zwz.png')} />
          </div>
        </Col>
        <Col xl={4} lg={4} md={6} sm={6} xs={12}>
          <div>
            <img style={{ width: '50%', height: 'auto' }} src={require('../../../assets/images/Achievements/tcsr.png')} />
          </div>
        </Col>
        <Col xl={4} lg={4} md={6} sm={6} xs={12}>
          <div>
            <img style={{ width: '50%', height: 'auto' }} src={require('../../../assets/images/Achievements/yzox.png')} />
          </div>
        </Col>
        <Col xl={4} lg={4} md={6} sm={6} xs={12}>
          <div>
            <img style={{ width: '50%', height: 'auto' }} src={require('../../../assets/images/Achievements/djdr.png')} />
          </div>
        </Col>
      </Row>
    </>
  )
}

export default MedalList;