
import React,{useState,useEffect}  from 'react';

import { Grid, Row, Col } from 'react-flexbox-grid';



// 切换语言
import { useTranslation } from 'react-i18next';

// api
import { ownerofhash,hasfilehash,uploadfile } from '../../../api/public'

import { simpleFn } from '../../../components/Notification';

import styles from './index.module.less';
import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'


const Main = ({onChange,data,type,baseUrl}:any)=>{
    console.log("main================",type)
    const slectionSort = (list: any) => {
        const length = list.length;
        if (length <= 1) {
          return list;
        }
        for (let i = 0; i < length - 1; i++) {
          let min = i;
          for (let j = i + 1; j < length; j++) {
            if (list[min] > list[j]) {
              min = j;
            }
          }
          if(min !== i){
            const temp = list[i];
            list[i] = list[min];
            list[min] = temp;
          }
        }
        return list;
      }

    console.log("datadataMain",data,baseUrl)
    const { t } = useTranslation();
    // const [gridCur,setGridCur] = useState<number>(-1);
    // const [gridItemCur,setGridItemCur] = useState<any>(undefined)
    // const onclickGridCur = (e:any,num:number)=>{
    //     console.log('click',e,num,e.metaDataUser.image.split('/')[e.metaDataUser.image.split('/').length-1])
    //     setGridItemCur(e);
    //     setGridCur(num);
    //     onChange(e);
    // }
    const [curList,setCurList] = useState<any>([]);
    const [YAxis,setYAxis] =useState<any>([]);
    const [ZAxis,setZAxis] =useState<any>([]);
    useEffect(()=>{
        setCurList([])
        setYAxis([])
        setZAxis([]);
    },[data])
    //点击
    const onclickGridCur = (e:any)=>{
        if(type && (e.status===3 || e.lockAmount===1)){
            return false;
        }
        let list = curList;
        let isAllList =  list.some((item:any) => item.id === e.id);
        if(!isAllList){
            list.push(e);
            setCurList(list);
            let data={
                y:Array.from(new Set(list.map((item:any)=>item.location.grid_y))).sort(),
                z:Array.from(new Set(list.map((item:any)=>item.location.grid_z))).sort(),
                list:list
            }
            setYAxis(slectionSort(data.y));
            setZAxis(slectionSort(data.z));
            onChange(data)
        }else{
            let removeList = list.filter((i:any)=>i.id!==e.id)
            setCurList(removeList)
            let data={
                y:Array.from(new Set(removeList.map((item:any)=>item.location.grid_y))).sort(),
                z:Array.from(new Set(removeList.map((item:any)=>item.location.grid_z))).sort(),
                list:removeList
            }
            setYAxis(data.y);
            setZAxis(data.z);
            onChange(data)
        }
        
    }

    // 格子
    let GridArr = new Array(100);
    for(let i=0;i<GridArr.length;i++){
        GridArr[i] = i;
    }
    const onclickGridNo=(e:object)=>{
        console.log('非本人')
    }
    return (
        <>
        {/* {JSON.stringify(YAxis)}
        {JSON.stringify(ZAxis)} */}
        <div className={`${styles.gridMain}`}>
            {GridArr.map((item)=>{
                return (
                    <div className={`${styles.boardBox}`} key={item} onClick={()=>onclickGridNo(item)}></div>
                )
            })}
            {data.items && data.items.length>0 && data.items.map((item:any,index:number)=>{
                
                const styleItemTop = (item.location.grid_z );
                const styleItemLeft = (item.location.grid_y );
                return (
                    <div className={`${styles.boardBoxItem} ${item.status===2?item.lockAmount===1?styles.saleStatus4:styles.saleStatus1:styles.saleStatus2} ${curList.some((f:any) => f.id === item.id)?styles.gridCur:''}`} style={{top:`calc((${styleItemTop} - 1) * 10%)`,left:`calc((${styleItemLeft} - 1) * 10%)`,width:`calc(10% * ${item.size.width})`,height:`calc(10% * ${item.size.height})`,display:`${item.filter!==1?'block':'none'}`}} key={item.id} onClick={()=>onclickGridCur(item)}></div>
                )
            })}
        </div>
    </>
  )
}
export default Main;
