import ReactTooltip from 'react-tooltip';
import styles from "./index.module.less"
import { simpleFn } from '../../../components/Notification/index';
import { useTranslation } from 'react-i18next';
import { shareNFT } from '../../../api/public';

const Share = (props: any) => {
// console.log(props)
  const { t } = useTranslation();

  const copyText = (value: any) => {
    const textarea = document.createElement('textarea')
    textarea.readOnly = true
    textarea.style.position = 'absolute'
    textarea.style.left = '-9999px'
    textarea.value = value
    document.body.appendChild(textarea)
    // 选中值并复制
    textarea.select()
    textarea.setSelectionRange(0, textarea.value.length)
    const copyStatus = document.execCommand('Copy')
    document.body.removeChild(textarea)
    if (copyStatus) {
      simpleFn(t('methods.copySucc'), 'success');
    } else {
      simpleFn(t('methods.copyError'), 'error');
    }
  }

  const ShareNFT = async (val: any) => {
    const params = {
      destID: props.data.destID,
      tokenID: props.data.tokenID,
      userName: props.data.userInfo.userName,
      cmd:val
    }
    const result = await shareNFT(params);
    console.log(result)
  }

  return (
    <ReactTooltip id='enrich' className={`${styles.toolTip1}`} backgroundColor={'#FFF'} delayHide={1000} textColor={'#333'} effect='solid' place='bottom' clickable={true}>
      <ul className={`${styles.shareUl}`} style={{ zIndex: 999 }}>
        <li className={`${styles.shareLi}`} onClick={() => {
          
          copyText(window.location.href);
        }}
        >
          <div className={`flex ai-center ${styles.liCont}`}>
            <img src={require('../../../assets/images/share/logo_icon.png')} />
            <span>{t('methods.copyLink')}</span>
          </div>
        </li>
        {/* <li className={`${styles.shareLi}`}
          onClick={() => {
            ShareNFT(2)
            window.open("https://www.facebook.com/sharer/sharer.php?u=" + encodeURI(window.location.href));
          }}
        >
          <div className={`flex ai-center ${styles.liCont}`}>
            <img src={require('../../../assets/images/share/facebook.png')} />
            <span>{t('methods.shareTo')}Facebook</span>
          </div>
        </li>
        <li className={`${styles.shareLi}`}
          onClick={() => {
            ShareNFT(3)
            window.open("https://twitter.com/intent/tweet?text=" + encodeURIComponent('Check out this item on Verseport') + "&url=" + encodeURIComponent(window.location.href));
          }}
        >
          <div className={`flex ai-center ${styles.liCont}`}>
            <img src={require('../../../assets/images/share/twitter.png')} />
            <span>{t('methods.shareTo')}Twitter</span>
          </div>
        </li> */}
        <li className={`${styles.shareLi}`}
          onClick={() => {
            ShareNFT(4)
            window.open("https://service.weibo.com/share/share.php?url=" + encodeURIComponent(window.location.href));
          }}
        >
          <div className={`flex ai-center ${styles.liCont}`}>
            <img src={require('../../../assets/images/share/weibo.png')} />
            <span>{t('methods.shareTo')}{t('methods.Weibo')}</span>
          </div>
        </li>
      </ul >
    </ReactTooltip >
  );
}
export default Share;