import Login from '../pages/Login/index';
import Home from '../pages/Home';
import Expolore from '../pages/Explore';
import ExpoloreDetail from '../pages/Explore/Detail';

// import Backend from '../pages/Backend';
import Stats from '../pages/Stats';
import Resources from '../pages/Resources';

import Brand from '../pages/Brand';
import BrandSort from '../pages/Brand/Sort';
import BrandSortDetail from '../pages/Brand/Detail'

import Apps from '../pages/Apps';
import About from '../pages/About';

import Help from '../pages/Help';
import Download from '../pages/DownloadPage';

import routerInterface from "./routerInterface";

/**
 * @path 路由地址
 * @name 路由名称
 * @hideMenu 是否在菜单栏显示路由
 * @component 路由指向模块
 * @exact 是都严格匹配
 * @role 当前路由需要的角色权限
 * @backUrl 不满足权限跳转的路由
 * @children 子项
 * **/
const publicRoutes:Array<routerInterface>  = [
  // {
  //   path: '/login',
  //   component: Login,
  //   exact: true,
  //   role: "all"
  // },
  {
    path: '/',
    component: Home,
    exact: true,
    role: "all"
  },
  {
    path: '/brand',
    component: Brand,
    exact: true,
    role: "all"
  },
  {
    path: '/brand/sort',
    component: BrandSort,
    exact: true,
    role: "all"
  },
  {
    path: '/brand/sort/detail',
    component: BrandSortDetail,
    exact: true,
    role: "all"
  },
  // {
  //   path: '/explore',
  //   component: Expolore,
  //   exact: true,
  //   role: "all"
  // },
  {
    path: '/explore/detail',
    component: ExpoloreDetail,
    exact: true,
    role: "all"
  },
  {
    path: '/stats',
    component: Stats,
    exact: true,
    role: "all"
  },
  {
    path: '/resources',
    component: Resources,
    exact: true,
    role: "all"
  },
  {
    path: '/apps',
    component: Apps,
    exact: true,
    role: "all"
  },
  {
    path: '/about',
    component: About,
    exact: true,
    role: "all"
  },
  {
    path: '/help',
    component: Help,
    exact: true,
    role: "all"
  },
  {
    path: '/download',
    component: Download,
    exact: true,
    role: "all"
  }
];

export default publicRoutes;